import React from 'react';
import {
    FormGroup,
    Label,
    Input,

} from 'reactstrap';
import PropTypes from 'prop-types'

export class InputSelect extends React.Component {

    render() {
        return <FormGroup>
            {this.props.showLabel ? <Label> {this.props.label}</Label> : ""}
            <Input type="select" name={this.props.id_label} id={this.props.id_label} onChange={this.props.onInputChange}
                   value={this.props.selectedValue}
                   disabled={!(this.props.showInput)}
                   className={!(this.props.showInput) ? 'disabled' : ''}
            >
                {this.props.data.map((prop, key) => {
                    let result = "";
                    this.props.dataDescription.map((val, k) => {
                        result += prop[val] + " - ";
                        return true;
                    });
                    return <option key={key} value={prop[this.props.dataValue]}> {result.slice(0, -3)} </option>
                })}
            </Input>
        </FormGroup>
    }
}

InputSelect.propTypes = {
    label: PropTypes.string,
    showLabel: PropTypes.bool,
    showInput: PropTypes.bool,
    id_label: PropTypes.string,
    data: PropTypes.array,
    dataValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    dataDescription: PropTypes.array,
    onInputChange: PropTypes.func,
    selectedValue: PropTypes.number
};
InputSelect.defaultProps = {
    showLabel: true,
    showInput: true,
};
