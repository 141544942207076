import CyaReadApiClient from "./CyaReadApiClient";

export default class CyaApiClient extends CyaReadApiClient {

    constructor(controllerName) {
        super(controllerName);
        this.GetAll = this.GetAll.bind(this);
        this.GetById = this.GetById.bind(this);
        this.Create = this.Create.bind(this);
        this.Update = this.Update.bind(this);
        this.Delete = this.Delete.bind(this);
    }

    GetAll() {
        return this.MakeCall('get', this.ControllerName + '/all');
    }

    GetById(id) {
        return this.MakeCall('get', this.ControllerName + '/byId', {id:id});
    }

    Create(dto) {
        return this.MakeCall('post', this.ControllerName, dto);
    }

    Update(id, dto) {
        return this.MakeCall('put', this.ControllerName, {id:id, dto:dto});
    }

    Delete(id) {
        return this.MakeCall('delete', this.ControllerName, {id:id});
    }
}