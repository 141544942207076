import React from 'react';
import "react-table/react-table.css";
import 'react-confirm-alert/src/react-confirm-alert.css'
import {Row, Col, Modal, ModalHeader, ModalBody,} from 'reactstrap';
import {LoadingPage} from "../../components/custom/views/LoadingPage";
import {toast} from "react-toastify";
import BankApiClient from "../../utils/api-client/BankApiClient";
import {CyaTableLayout} from "../../components/custom/tables/CyaTableLayout";
import AuthService from "../../utils/AuthService";
import {InputForm} from "../../components/custom/inputs/InputForm";
import {BankModel} from "../../models/BankModel";
import { SuccessResponse} from "../../const/api_consts";
import {YesOrNoModal} from "../../components/custom/views/YesOrNoModal";

export default class Bank extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: new BankModel(),
            loading: true,
            banks: [],
            selected: null,
            selectedRowId: -1,
            selectedRowData: {},
            activeTab: '1',
            confirmToggle: false,
            modalOpened: false,
            modalText: "Crear nuevo",
            modelCreated: "Banco Guardado",
            modelUpdated: "Banco Actualizado",
            modelError: "Error en Banco",
        };
        this.BankApiClient = new BankApiClient();
        this.Auth = new AuthService();
    }

    componentDidMount() {
        this.BankApiClient.GetAll().then(response => {
            this.setState({banks: response.data, loading: false});
        });
    }

    addCallback = () => {
        this.toggleModal();
    };

    deleteCallback = () => {
        this.setState({confirmToggle: true});
    };

    deleteBank = (e,confirm) =>{
        e.preventDefault();
        if (confirm){
            this.setState({loading: true});
            if (this.state.selectedRowId > 0) {
                this.BankApiClient.Delete(this.state.selectedRowId).then((response) => {
                    if (response !== SuccessResponse) {
                        toast.error("Error eliminado banco.");
                    } else {
                        this.props.history.push({pathname: '/refresh/banks'});
                        toast.success("Banco eliminado.");
                    }
                    this.setState({loading: false});
                    this.toggleConfirmation();
                }).catch(() => {
                    toast.warn("Error eliminado banco.");
                });
            }
        }else {
            this.toggleConfirmation();
        }

    };

    viewCallback = () => {
        this.toggleModal();
        this.setState({data: Object.assign({}, this.state.selectedRowData)});
    };

    GetTrProps = (state, rowInfo) => {
        if (rowInfo && rowInfo.row) {
            return {
                onClick: () => {
                    this.setState({
                        selected: rowInfo.index,
                        selectedRowId: rowInfo.row.bankId,
                        selectedRowData: this.state.banks.find(x => x.bankId === rowInfo.row.bankId)
                    });
                },
                onDoubleClick: () => {
                    this.toggleModal();
                    this.setState({data: Object.assign({}, this.state.selectedRowData)});
                },
                style: {
                    background: rowInfo.index === this.state.selected ? '#00AFEC' : rowInfo.index % 2 === 0 ? '#DDDDDD' : '#FFFFFF',
                    color: rowInfo.index === this.state.selected ? 'white' : 'black'
                }
            }
        }
        return {};
    };

    onInputChange = (event) => {
        let data = this.state.data;
        data[event.target.name] = event.target.value;
        this.setState({data});
    };

    toggleModal = (clean=false)=> {
        this.setState({modalOpened: !this.state.modalOpened});
        if (this.state.modalOpened || clean) {
            this.cleanDataAndTable();
        }
    };

    toggleConfirmation = () => {
        this.setState({confirmToggle: !this.state.confirmToggle});
        if (this.state.confirmToggle){
            this.cleanDataAndTable();
        }
    };

    cleanDataAndTable = () => {
        this.setState({
            data: new BankModel(),
            selected: null,
            selectedRowId: -1,
            selectedRowData: {},
        });
    };

    validSaveButton = () => {
        return this.Auth.GetPathPermission(this.props.location.pathname, true);
    };

    onSubmit = (event) => {
        event.preventDefault();

        this.setState({loading: true});
        if (this.state.data.bankId > 0) {
            this.BankApiClient.Update(this.state.data.bankId, this.state.data)
                .then(response => {
                    if (response.response !== undefined && response.response.data === "Error") {
                        this.setState({loading: false});
                        toast.error(this.state.modelError);
                    } else {
                        toast.success(this.state.modelUpdated);
                        this.toggleModal(true);

                    }
                }).then(() => {
                this.BankApiClient.GetAll().then(response => {
                    this.setState({banks: response.data, loading: false});
                }).catch(err => {
                    this.setState({loading: false});
                    toast.error(this.state.modelError);
                })
            });
        }
        else {
            this.BankApiClient.Create(this.state.data)
                .then(response => {
                    if (response.response !== undefined && response.response.data === "Error") {
                        this.setState({loading: false});
                        toast.error(this.state.modelError);
                    } else {
                        toast.success(this.state.modelUpdated);
                        this.toggleModal(true);
                    }
                }).then(() => {
                this.BankApiClient.GetAll().then(response => {
                    this.setState({banks: response.data, loading: false});
                }).catch(() => {
                    this.setState({loading: false});
                    toast.error(this.state.modelError);
                });
            });
        }
        window.scrollTo(0, 0);
    };

    render() {
        const bankColumns = [
            {Header: "ID", accessor: "bankId", show: false},
            {Header: "Nombre", accessor: "description"},
        ];
        return (<div>
                <YesOrNoModal modalOpen={this.state.confirmToggle}
                              closeDeleteModal={this.toggleConfirmation}
                              message={'¿Desea Eliminar el siguiente Banco?'}
                              body={this.state.selectedRowData.description}
                              confirmation={(e,value)=>this.deleteBank(e,value)}
                />
                <LoadingPage color={"3e5569"} loading={this.state.loading} type={"spin"}/>
                <Modal isOpen={this.state.modalOpened} toggle={this.toggleModal}>
                    <ModalHeader
                        toggle={this.toggleModal}>{this.state.data.bankId > 0 ? "Editar Banco" : "Crear Nuevo "}</ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col>
                                <InputForm
                                    id_label={"description"}
                                    label={"Nombre:"}
                                    placeholder={"Nombre"}
                                    type={"text"}
                                    value={this.state.data.description}
                                    onInputChange={this.onInputChange}
                                />
                            </Col>
                        </Row>
                        <hr/>
                        <Row>
                            <Col>
                                <input type="submit"
                                       disabled={!(this.validSaveButton())}
                                       className={!(this.validSaveButton()) ? 'btn btn-success disabled' : 'btn btn-success'}
                                       value="Guardar"
                                       onClick={this.onSubmit}
                                />
                            </Col>
                        </Row>

                    </ModalBody>
                </Modal>
                <CyaTableLayout
                    title={"Bancos"}
                    data={this.state.banks}
                    columns={bankColumns}
                    loading={this.state.loading}
                    trProps={this.GetTrProps}
                    enableButtons={this.state.selectedRowId >= 0}
                    viewCallback={this.viewCallback}
                    viewButtonText={"Editar"}
                    massButtonText={"Anadir"}
                    defaultPageSize={7}
                    enableMassButtonDefault={true}
                    multipleActionsButton={true}
                    multipleActionsCallback={this.addCallback}
                    showDeleteButton={this.Auth.GetPathPermission(this.props.location.pathname + "/delete", false)}
                    deleteCallback={this.deleteCallback}
                />
            </div>
        );
    }
}
