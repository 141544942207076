import React from "react";
import PropTypes from 'prop-types'
import ScrollMenu from "react-horizontal-scrolling-menu";
import {InputImage} from "./InputImage";
import {Button, Col, Row} from "reactstrap";
import CyaHelper from "../../../utils/CyaHelper";
import {AnnexImageModel} from "../../../models/AnnexImageModel";

export class InputImageCarousel extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            annexImageModels: [],
            selected: ""
        };
        this.onSelect = this.onSelect.bind(this);
        this.onAdd = this.onAdd.bind(this);
        this.moveRight = this.moveRight.bind(this);
        this.moveLeft = this.moveLeft.bind(this);
        this.deleteImage = this.deleteImage.bind(this);
        this.reassignSequence = this.reassignSequence.bind(this);
    }

    reassignSequence() {
        let aux = this.state.annexImageModels;
        aux.map((currentValue, index) => {
            currentValue.sequence = index;
            return currentValue;
        });
        this.setState({
            annexImageModels: aux
        });
        this.props.onImageChange(aux.slice(0, aux.length - 1));
    }

    onSelect = key => {
        this.setState({selected: key});
    };

    onAdd(add,arrayImages=[]) {
        if (add) {
            let images = this.state.annexImageModels;

            if (arrayImages.length>0 && this.props.multiple){
                CyaHelper.GetArrayImagesInBase64(arrayImages).map(x=>{
                    if (images.find(function (element) {return element.image.filename===x.filename;}) ===undefined) {
                        let img = new AnnexImageModel();
                        img.image = x;
                        images.push(img);
                    }
                    return x;
                });
            }
            images.push(new AnnexImageModel());
            this.setState({
                annexImageModels: images
            });
        }
        this.reassignSequence();
    };


    moveRight() {
        let index = this.state.annexImageModels.indexOf(this.state.annexImageModels.find(x => x.image.imageGuid === this.state.selected));
        if (index >= 0 && index < this.state.annexImageModels.length - 2) {
            let aux = this.state.annexImageModels;
            CyaHelper.ArrayMove(aux, index, index + 1);
            this.setState({
                annexImageModels: aux
            });
            this.reassignSequence();
        }
    }

    moveLeft() {
        let index = this.state.annexImageModels.indexOf(this.state.annexImageModels.find(x => x.image.imageGuid === this.state.selected));
        if (index > 0 && index <= this.state.annexImageModels.length - 2) {
            let aux = this.state.annexImageModels;
            CyaHelper.ArrayMove(aux, index, index - 1);
            this.setState({
                annexImageModels: aux
            });
            this.reassignSequence();
        }
    }

    deleteImage() {
        let index = this.state.annexImageModels.indexOf(this.state.annexImageModels.find(x => x.image.imageGuid === this.state.selected));
        if (index >= 0 && index <= this.state.annexImageModels.length - 2) {
            let aux = this.state.annexImageModels;
            aux.splice(index, 1);
            this.setState({
                annexImageModels: aux,
                selected: aux[index].image.imageGuid
            });
            this.reassignSequence();
        }
    }

    componentWillMount() {
        let images = (typeof(this.props.annexImageModels) === "undefined" ? [] : this.props.annexImageModels)
            .map(x => {
                return {
                    annexId: x.annexId,
                    imageId: x.imageId,
                    sequence: x.sequence,
                    image: {
                        imageId: x.image.imageId,
                        hash: x.image.hash,
                        filename: x.image.filename,
                        fileContent: x.image.fileContent,
                        imageGuid: CyaHelper.Guid()
                    }
                };
            });
        images.push(new AnnexImageModel());
        this.setState({
            annexImageModels: images
        });
    }

    render() {
        const ArrowLeft = <Button size="lg" color="info">{'<'}</Button>;
        const ArrowRight = <Button size="lg" color="info">{'>'}</Button>;
        const Menu = (list) => list.map(annexImageModel => {
            return <InputImage
                rotatedImage={this.props.rotatedImage}
                selectedId={this.state.selected}
                key={annexImageModel.image.imageGuid}
                id_label={annexImageModel.image.imageGuid}
                image={annexImageModel.image}
                width={"300px"}
                height={"450px"}
                callback={this.onAdd}
                multiple={this.props.multiple}
                loadingCallback={this.props.loadingCallback}
                acceptPDF={this.props.acceptPDF}
                addPadding={true}
            />;
        });
        const menu = Menu(this.state.annexImageModels);

        return <div>
            {/*<Row>*/}
                {/*<Col style={{textAlign: "center"}}>*/}
                    {/*agregar file select para subida masiva de imagenes y pdfs*/}
                {/*</Col>*/}
            {/*</Row>*/}
            <Row>
                <Col>
                    <ScrollMenu
                        data={menu}
                        wheel={false}
                        hideSingleArrow={true}
                        arrowLeft={ArrowLeft}
                        arrowRight={ArrowRight}
                        selected={this.state.selected}
                        onSelect={this.onSelect}
                    />
                </Col>
            </Row>
            <Row>
                <Col xs={4} md={5} style={{textAlign: "right"}}>
                    <Button size="lg" color="info" onClick={this.moveLeft}>{'<<'}</Button>
                </Col>
                <Col xs={4} md={2} style={{textAlign: "center"}}>
                    <Button size="lg" color="danger" onClick={this.deleteImage}>{'X'}</Button>
                </Col>
                <Col xs={4} md={5} style={{textAlign: "left"}}>
                    <Button size="lg" color="info" onClick={this.moveRight}>{'>>'}</Button>
                </Col>
            </Row>
        </div>;
    }
}

InputImageCarousel.propTypes = {
    annexImageModels: PropTypes.array,
    onImageChange: PropTypes.func,
    multiple: PropTypes.bool,
    acceptPDF: PropTypes.bool,
    loadingCallback: PropTypes.func,
};
InputImageCarousel.defaultProps = {
    multiple: false,
    acceptPDF: false,
};
