const CheckFieldValidator = {
    number: {
        secondValue: null,
        rules: [
            {
                test: (value) => value.length > 0,
                message: 'Campo en blanco'
            },
            {
               test: (value) => value.length <= 10,
               message: 'Cantidad máxima de carácteres excedidos'
            }
        ],
        errors: [],
        valid: false,
        state: ''
    },
    comment: {
        secondValue: null,
        rules: [
            
            {
               test: (value) => value.length <= 250,
               message: 'Cantidad máxima de carácteres excedidos'
            }
        ],
        errors: [],
        valid: true,
        state: ''
    },
    orderOf: {
        secondValue: null,
        rules: [
            {
                test: (value) => value.length > 0,
                message: 'Campo en blanco'
            }
        ],
        errors: [],
        valid: false,
        state: ''
    },
    concept: {
        secondValue: null,
        rules: [
            {
                test: (value) => value.length > 0,
                message: 'Campo en blanco'
            }
        ],
        errors: [],
        valid: false,
        state: ''
    },
    amount: {
        secondValue: null,
        rules: [
            {
                test: (value) => value > 0,
                message: 'Importe no puede ser cero'
            }
        ],
        errors: [],
        valid: false,
        state: 0
    },
    usedAmount: {
        secondValue: "amount",
        rules: [
            {
                test: (value) => value >0,
                message: 'Importe utilizado no puede ser cero'
            },
            {
                test: (value, amount) => amount >= value,
                message: 'Importe utilizado debe ser menor o igual que el Importe'
            }
        ],
        errors: [],
        valid: false,
        state: 0
    },
};

export default CheckFieldValidator;