//export const BaseURL="http://186.6.129.71:7080";
export const BaseURL =
    !process.env.NODE_ENV || process.env.NODE_ENV === "development"
        ? "http://localhost:3000"
        : "https://cya.com.do";

export const ApiURL =
    !process.env.NODE_ENV || process.env.NODE_ENV === "development"
        ? // "http://192.168.1.102:45459" : "http://mamserver.dyndns.org:7080";
          "http://localhost:3275"
        : "https://api.cya.com.do";
//"https://api.cya.com.do" : "https://api.cya.com.do";
// "http://localhost:5000" : "https://api.cya.com.do";

export const ReportURL =
    !process.env.NODE_ENV || process.env.NODE_ENV === "development"
        ? // "http://192.168.1.102:45461" : "http://mamserver.dyndns.org:6080";
          "http://localhost:35593"
        : "https://rpt.cya.com.do";
// "https://rpt.cya.com.do" : "https://rpt.cya.com.do";

export const loginURL = "/User/authenticate";
export const DateFormat = "DD-MM-YYYY";

export const CurrencyPrecision = 2;
export const CurrencyPrefix = "RD$";
export const SuccessResponse = "Success";
export const preDashboardUrl = "/init";
export const projectsPage = preDashboardUrl + "/projects";
export const loginPage = "/login";
export const dashboardPage = "/dashboard";

export const ProducerDocTypeRNC = 1;
export const ProducerDocTypeACP = 2;
export const ProducerDocTypeCommRegister = 3;
export const ProducerDocTypeDGII = 4;

export const ProjectDocTypePUR = 1;
export const ProjectDocTypeCPND = 2;
export const ProjectDocTypeCDND = 3;

export const InvestorDBDocTypeCommRegister = 1;
export const InvestorDBDocTypeRNC = 2;
export const InvestorDBDocTypeSocialStatuses = 3;
export const InvestorDBDocTypePayrollAndAssembly = 4;

export const UnsavedChangesMsg =
    "¿Seguro que desea salir de esta página antes de guardar los cambios?";

export const ModalStyle = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
    },
};
