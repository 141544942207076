import React from "react";
import PropTypes from "prop-types";
import { Card, CardBody, Col, Row, Form, Tooltip } from "reactstrap";
import Datetime from "react-datetime";
import { DateFormat } from "../../../const/api_consts";
import { SimpleTable } from "../tables/SimpleTable";
import * as S from "../../../components/dashboard-components";
import AuthService from "../../../utils/AuthService";
import CyaHelper from "../../../utils/CyaHelper";
import "react-toastify/dist/ReactToastify.min.css";
import Select from "react-select";

export class ValidationComponent extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         tooltipOpen: false,
         selected: null,
         selectedRowId: -1,
         stageSelected: null,
         isOpenDateTime: false,
      };
      this.Auth = new AuthService();
   }

   componentDidMount() {
      const options = this.props.stageOptions;
      const value = this.props.data.stageId;
      const _stage = options.find((item) => item.value == value);

      this.setState({
         stageSelected: _stage ?? options[0],
      });

      if (_stage == null) {
         this.props.data.stageId = options[0].stageId;
      }
   }

   tProps = (state, rowInfo) => {
      if (rowInfo && rowInfo.row && !this.props.data.closed) {
         return {
            onClick: () => {
               this.setState({
                  selected: rowInfo.index,
                  selectedRowId: rowInfo.row.investorId,
               });
            },
            onDoubleClick: () => {
               if (this.state.selected === rowInfo.index) {
                  this.props.deleteInvestorFromValidation(
                     this.props.data,
                     this.state.selected
                  );
               }
            },
            style: {
               background:
                  rowInfo.index === this.state.selected
                     ? "#00AFEC"
                     : rowInfo.index % 2 === 0
                     ? "#DDDDDD"
                     : "#FFFFFF",
               color: rowInfo.index === this.state.selected ? "white" : "black",
            },
         };
      }
      return {};
   };

   toggle = () => {
      this.setState({
         tooltipOpen: !this.state.tooltipOpen,
      });
   };
   onCloseValidationClick = (event) => {
      event.preventDefault();
      this.props.onBlockedChange(this.props.data.validationNumber);
   };
   onSubmitClick = (event) => {
      event.preventDefault();
      this.props.onSubmitClick(this.props.data);
   };
   onDateChange = (moment) => {
      this.props.onDateChange(this.props.data, moment);
   };

   generateReport = (event) => {
      event.preventDefault();
   };
   downloadReport = (event) => {
      event.preventDefault();
   };

   render() {
      let investors = [
         { Header: "InvestorId", accessor: "investorId", show: false },
         { Header: "RNC", accessor: "rnc", width: 150 },
         { Header: "Nombre", accessor: "name" },
      ];

      return (
         <Col md={4} xs={12}>
            <Card>
               {" "}
               {/*className="card-hover"*/}
               <CardBody>
                  <Row>
                     <Col md="12">
                        <Form
                           style={
                              this.props.data.closed && !this.props.firstOne
                                 ? { WebkitFilter: "grayscale(65%)" }
                                 : {}
                           }
                        >
                           <Col md={12}>
                              <Row>
                                 <Col
                                    md={12}
                                    id={
                                       "TooltipVal" +
                                       String(this.props.data.validationNumber)
                                    }
                                 >
                                    <div
                                       disabled={!this.props.firstOne}
                                       className={
                                          (this.props.data.closed
                                             ? this.props.firstOne
                                                ? "btn-danger "
                                                : "btn-danger disable"
                                             : "btn-primary") +
                                          " btn btn-lg text-center "
                                       }
                                       style={{ width: "100%", height: "50px" }}
                                       onClick={(e) => {
                                          if (this.props.firstOne) {
                                             this.onCloseValidationClick(e);
                                             this.onSubmitClick(e);
                                          }
                                       }}
                                    >
                                       {this.props.data.validationOrdinal !=
                                          null &&
                                       this.props.data.validationOrdinal !==
                                          undefined
                                          ? this.props.data.validationOrdinal +
                                            " Validación"
                                          : -1}
                                    </div>
                                    <Tooltip
                                       placement="right"
                                       isOpen={this.state.tooltipOpen}
                                       target={
                                          "TooltipVal" +
                                          String(
                                             this.props.data.validationNumber
                                          )
                                       }
                                       toggle={this.toggle}
                                    >
                                       {this.props.data.closed
                                          ? this.props.firstOne
                                             ? "Abrir Validación"
                                             : "Validación Bloqueada/Cerrada"
                                          : "Cerrar Validación"}
                                    </Tooltip>
                                 </Col>
                              </Row>
                              <Row className="mt-2 align-items-center">
                                 <Col md="6" sm="12">
                                    <S.Statistics
                                       textColor="orange"
                                       icon="fas fa-hand-holding-usd"
                                       subtitle="Inversión"
                                       title={CyaHelper.ToCurrency(
                                          this.props.data.investor !== null &&
                                             this.props.data.investor.length > 0
                                             ? this.props.data.investor.reduce(
                                                  function (prev, cur) {
                                                     return (
                                                        prev + cur.checkAmount
                                                     );
                                                  },
                                                  0
                                               )
                                             : 0
                                       )}
                                    />
                                 </Col>
                                 <Col md="6" sm="12">
                                    <S.Statistics
                                       textColor="warning"
                                       icon="fas fa-bookmark"
                                       title={
                                          <Select
                                             isDisabled={this.props.data.closed}
                                             value={this.state.stageSelected}
                                             onChange={(e) => {
                                                this.setState({
                                                   stageSelected: e,
                                                });
                                                this.props.data.stageId =
                                                   e.stageId;
                                             }}
                                             options={this.props.stageOptions}
                                          />
                                       }
                                    />
                                 </Col>
                              </Row>
                              <Row className="mt-2">
                                 <Col md="6" sm="12">
                                    <S.Statistics
                                       textColor="primary"
                                       icon="fas fa-edit"
                                       subtitle="Cheques"
                                       title={CyaHelper.ToCurrency(
                                          this.props.data.investor !== null &&
                                             this.props.data.investor.length > 0
                                             ? this.props.data.investor.reduce(
                                                  function (prev, cur) {
                                                     return prev + cur.amount;
                                                  },
                                                  0
                                               )
                                             : 0
                                       )}
                                    />
                                 </Col>
                                 <Col
                                    md="6"
                                    sm="12"
                                    style={{ paddingLeft: "10px" }}
                                 >
                                    <S.Statistics
                                       textColor="success"
                                       icon="fas fa-calendar-alt"
                                       title={
                                          <div
                                             onMouseLeave={() =>
                                                this.state.isOpenDateTime &&
                                                this.setState({
                                                   isOpenDateTime: false,
                                                })
                                             }
                                          >
                                             <Datetime
                                                inputProps={{
                                                   readOnly: true,
                                                   style: {
                                                      backgroundColor:
                                                         "transparent",
                                                   },
                                                   disabled:
                                                      this.props.data.closed,

                                                   onClick: () =>
                                                      this.setState({
                                                         isOpenDateTime: true,
                                                      }),
                                                }}
                                                locale={"es-do"}
                                                open={this.state.isOpenDateTime}
                                                utc={true}
                                                timeFormat={false}
                                                defaultValue={new Date()}
                                                dateFormat={DateFormat}
                                                closeOnSelect={true}
                                                value={
                                                   this.props.data.date == null
                                                      ? new Date()
                                                      : new Date(
                                                           this.props.data.date
                                                        )
                                                }
                                                onChange={(moment) => {
                                                   this.onDateChange(moment);
                                                   this.setState({
                                                      isOpenDateTime: false,
                                                   });
                                                }}
                                             />
                                          </div>
                                       }
                                    />
                                 </Col>
                              </Row>
                              <Row className="mt-2">
                                 <Col md="6" sm="12">
                                    <S.Statistics
                                       icon="mdi mdi-file-multiple"
                                       subtitle="Anexos"
                                       title={CyaHelper.ToCurrency(
                                          this.props.data.investor !== null &&
                                             this.props.data.investor.length > 0
                                             ? this.props.data.investor.reduce(
                                                  function (prev, cur) {
                                                     return (
                                                        prev + cur.checkAmount
                                                     );
                                                  },
                                                  0
                                               )
                                             : 0
                                       )}
                                    />
                                 </Col>
                              </Row>
                           </Col>
                           <br />
                           <SimpleTable
                              data={
                                 this.props.data.investor !== null &&
                                 this.props.data.investor.length > 0
                                    ? this.props.data.investor
                                    : []
                              }
                              columns={investors}
                              trProps={this.tProps}
                              noDataText={"No hay inversionistas asociados."}
                              loading={this.props.loading}
                              defaultPageSize={3}
                           />
                           <Row>
                              <Col
                                 md="12"
                                 style={{
                                    textAlign: "center",
                                    opacity: this.props.firstOne ? 1 : 0,
                                 }}
                              >
                                 <i className="fas fa-info mr-1 text-info" />
                                 <label className="text-info">
                                    Hacer doble click para eliminar
                                    inversionista
                                 </label>
                              </Col>
                           </Row>
                           <hr />

                           {this.props.firstOne && !this.props.data.closed ? (
                              <Row>
                                 <Col md="6">
                                    <input
                                       type="submit"
                                       disabled={!this.props.validSaveButton}
                                       className={
                                          !this.props.validSaveButton
                                             ? "btn btn-success disabled"
                                             : "btn btn-success"
                                       }
                                       value={
                                          this.props.data.validationId > 0
                                             ? "Actualizar"
                                             : "Guardar"
                                       }
                                       onClick={this.onSubmitClick}
                                    />
                                    <input
                                       type="submit"
                                       style={{ marginLeft: "5px" }}
                                       disabled={
                                          !(
                                             this.props.validSaveButton &&
                                             !this.props.data.closed
                                          )
                                       }
                                       className={
                                          "btn btn-danger " +
                                          (!(
                                             this.props.validSaveButton &&
                                             !this.props.data.closed
                                          )
                                             ? " disabled"
                                             : "")
                                       }
                                       value="Borrar"
                                       onClick={(event) =>
                                          this.props.deleteValidation(
                                             event,
                                             this.props.data
                                          )
                                       }
                                    />
                                 </Col>

                                 <Col md="6" style={{ textAlign: "right" }}>
                                    <input
                                       type="submit"
                                       disabled={
                                          !(
                                             this.props.validSaveButton &&
                                             !this.props.data.closed
                                          )
                                       }
                                       className={
                                          !(
                                             this.props.validSaveButton &&
                                             !this.props.data.closed
                                          )
                                             ? "btn btn-secondary disabled"
                                             : "btn btn-secondary"
                                       }
                                       value="Añadir Inversionista"
                                       onClick={(event) =>
                                          this.props.openInvestorsModal(
                                             event,
                                             this.props.data
                                          )
                                       }
                                    />
                                 </Col>
                              </Row>
                           ) : (
                              <Row>
                                 <Col md="12" style={{ textAlign: "center" }}>
                                    <input
                                       type="submit"
                                       disabled={true}
                                       className={"btn btn-danger disabled"}
                                       value="Bloqueada"
                                    />
                                 </Col>
                              </Row>
                           )}
                        </Form>

                        <br style={{ marginTop: "1px" }} />

                        <Row>
                           <Col md="6" style={{ textAlign: "center" }}>
                              <input
                                 type="submit"
                                 className={"btn btn-primary btn-block disable"}
                                 disabled={true}
                                 value="Generar reporte"
                                 onClick={this.generateReport}
                              />
                           </Col>
                           <Col md="6" style={{ textAlign: "center" }}>
                              <input
                                 type="submit"
                                 className={"btn btn-warning btn-block disable"}
                                 disabled={true}
                                 value="Descargar reporte"
                                 onClick={this.downloadReport}
                              />
                           </Col>
                        </Row>
                     </Col>
                  </Row>
               </CardBody>
            </Card>
         </Col>
      );
   }
}
ValidationComponent.propTypes = {
   data: PropTypes.object,
   stageOptions: PropTypes.array,
   loading: PropTypes.bool,
   validSaveButton: PropTypes.func,
   onBlockedChange: PropTypes.func,
   onDateChange: PropTypes.func,
   openInvestorsModal: PropTypes.func,
   onSubmitClick: PropTypes.func,
   deleteValidation: PropTypes.func,
   deleteInvestorFromValidation: PropTypes.func,
   firstOne: PropTypes.bool,
};
ValidationComponent.defaultProps = {
   validSaveButton: function () {
      return true;
   },
   firstOne: false,
   stageOptions: [],
};
