import React from 'react';
import "react-table/react-table.css";
import "react-confirm-alert/src/react-confirm-alert.css";
import ReportApiClient from "../../utils/api-client/ReportApiClient";
import {Button, Card, CardBody, CardTitle, Col, Row} from "reactstrap";
import {LoadingPage} from "../../components/custom/views/LoadingPage";
import ScrollMenu from "react-horizontal-scrolling-menu";
import {MultiSelectFilter} from "../../components/custom/report-filters/MultiSelectFilter";
import {DateFilter} from "../../components/custom/report-filters/DateFilter";
import {CurrencyFilter} from "../../components/custom/report-filters/CurrencyFilter";
import {ReportURL} from "../../const/api_consts";
import {BooleanFilter} from "../../components/custom/report-filters/BooleanFilter";
import {SelectFilter} from "../../components/custom/report-filters/SelectFilter";
import Moment from "moment";
import {PercentageFilter} from "../../components/custom/report-filters/PercentageFilter";

require('moment/locale/es-do');

//class MyProvider extends React.Component{

//}

export default class Report extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            loading: true,
            selected: [],
            title: "",
            filtersType: [],
            filters: []
        };

        this.reportsType = this.props.location.pathname.split('/')[2];
        switch (this.reportsType) {
            case "audit":
                this.reportsTitle = "Reportes de Auditoria";
                this.reportIcon = "mdi mdi-checkbox-multiple-marked-outline mr-2";
                break;
            case "personal":
                this.reportsTitle = "Reportes de personal";
                this.reportIcon = "mdi mdi-checkbox-multiple-marked-outline mr-2";
                break;
            case "check":
                this.reportsTitle = "Reportes de Cheques";
                this.reportIcon = "fas fa-edit mr-2";
                break;
            case "annex":
                this.reportsTitle = "Reportes de Anexos";
                this.reportIcon = "mdi mdi-file-multiple mr-2";
                break;
            case "investor":
                this.reportsTitle = "Reportes de Inversionistas";
                this.reportIcon = "fas fa-hand-holding-usd mr-2";
                break;
            case "item":
                this.reportsTitle = "Reportes de Partidas";
                this.reportIcon = "fas fa-file-alt mr-2";
                break;
            case "validation":
                this.reportsTitle = "Reportes de Validaciones";
                this.reportIcon = "mdi mdi-checkbox-marked-outline mr-2";
                break;
            default:
                this.reportsTitle = "";
                this.reportIcon = "";
                break;
        }

        this.ReportApiClient = new ReportApiClient();
        this.onSubmit = this.onSubmit.bind(this);
        this.onBooleanChange = this.onBooleanChange.bind(this);
        this.onDateChange = this.onDateChange.bind(this);
        this.onCurrencyChange = this.onCurrencyChange.bind(this);
        this.onMultiSelectChange = this.onMultiSelectChange.bind(this);

        ["Stage","Investor","Check","Validation","Item","Annex", "AnnexIsrretentionType" ].map(filterName => {
            this.ReportApiClient.GetReportFilter(filterName).then(response => {
                let aux = this.state.filtersType;
                aux[filterName] = response.data;
                this.setState({filtersType: aux});
            });
            return true;
        });
        this.ReportApiClient.GetReportsByCategory(this.reportsType).then(response => this.setState({data: response.data, loading: false}));
    }

    onSubmit() {
        let filter = [];
        this.state.selected.filters.map(x => {
            if(this.state.filters[x.name] !== undefined){
                let current = this.state.filters[x.name];
                if(Array.isArray(current)){
                    current.map(i => {
                        filter.push(x.name + "=" + i.value);
                        return true;
                    });
                } else if (Moment.isMoment(current)) {
                    filter.push(x.name + "=" + current.format("YYYY-MM-DD"));
                } else if(current.value !== undefined) {
                    filter.push(x.name + "=" + (x.name === "Validation" ? current.label : current.value));
                } else {
                    filter.push(x.name + "=" + current);
                }
            }
            return true;
        });
        filter = filter.join("&");
        let reportApiUrl = ReportURL + "/" + this.state.selected.name + (filter.length > 0 ? "?" + filter : "");
        let token = this.ReportApiClient.GetToken();

        let mapInput = document.createElement("input");
        mapInput.type = "text";
        mapInput.name = "Token";
        mapInput.value = token;
        mapInput.setAttribute("type", "hidden");

        let mapForm = document.createElement("form");
        mapForm.target = "_blank";
        mapForm.method = "post";
        mapForm.action = reportApiUrl;
        mapForm.appendChild(mapInput);

        document.body.appendChild(mapForm);

        mapForm.submit();
    };

    onSelect = key => {
        let filters = [];
        let selected = this.state.data.find(x => x.name === key);
        if(this.state.filtersType["Validation"] !== undefined
            && selected.filters.find(x => x.type === "Validation") !== undefined)
        {
            filters["Validation"] = this.state.filtersType["Validation"][0];
        }
        this.setState({
            selected: selected,
            filters: filters
        });
    };

    onBooleanChange(e, filterName) {
        let filters = this.state.filters;
        filters[filterName] = filters[filterName] === undefined ? true : !filters[filterName];
        this.setState({filters: filters});
    };

    onDateChange(e, filterName) {
        let filters = this.state.filters;
        filters[filterName] = e;
        this.setState({filters: filters});
    };

    onCurrencyChange(e, maskedValue, floatValue, filterName) {
        let filters = this.state.filters;
        filters[filterName] = floatValue;
        this.setState({filters: filters});
    };

    onPercentageChange(e, filterName) {
        let filters = this.state.filters;
        filters[filterName] = e.floatValue;
        this.setState({filters: filters});
    };

    onMultiSelectChange(e, filterName) {
        let filters = this.state.filters;
        filters[filterName] = e;
        this.setState({filters: filters});
    };

    renderFilters() {
        let size = 4;
        let result = [];
        for (let i = 0; i < this.state.selected.filters.length; i += size)
            result.push(this.state.selected.filters.slice(i, size + i));
        return result.map((row, rowIndex) => (
            <Row key={rowIndex} style={{marginBottom: 10}}> {
                row.map((x, colIndex) => {
                    let returnVal = <p>{x.type}</p>;
                    switch (x.type) {
                        case "Validation":
                            returnVal = <SelectFilter filterId={x.name} label={x.displayName}
                                                           data={this.state.filtersType[x.type]}
                                                           defaultValue={this.state.filtersType[x.type][0]}
                                                           value={this.state.filters[x.name]}
                                                           onInputChange={(e) => this.onMultiSelectChange(e, x.name)}
                            />;
                            break;
                        case "Boolean":
                            returnVal = <BooleanFilter filterId={x.name} label={x.displayName}
                                                       checked={this.state.filters[x.name]}
                                                       onInputChange={(e) => this.onBooleanChange(e, x.name)}
                            />;
                            break;
                        case "DateTime":
                            returnVal = <DateFilter filterId={x.name} label={x.displayName}
                                                    value={this.state.filters[x.name]}
                                                    onInputChange={(e) => this.onDateChange(e, x.name)}
                            />;
                            break;
                        case "DateTime Nullable`1":
                            returnVal = <DateFilter filterId={x.name} label={x.displayName}
                                                    value={this.state.filters[x.name]}
                                                    onInputChange={(e) => this.onDateChange(e, x.name)}
                            />;
                            break;
                        case "Double":
                            if(x.name.indexOf("Percentage") > -1){
                                returnVal = <PercentageFilter filterId={x.name} label={x.displayName}
                                                            value={this.state.filters[x.name]}
                                                            onInputChange={(e) => this.onPercentageChange(e,x.name)}
                                            />;
                            }
                            else{
                                returnVal = <CurrencyFilter filterId={x.name} label={x.displayName}
                                                            value={this.state.filters[x.name]}
                                                            onInputChange={(e,m,f) => this.onCurrencyChange(e,m,f, x.name)}
                                            />;
                            }
                            break;
                        case "Double Nullable`1":
                            if(x.name.indexOf("Percentage") > -1){
                                returnVal = <PercentageFilter filterId={x.name} label={x.displayName}
                                                              value={this.state.filters[x.name]}
                                                              onInputChange={(e) => this.onPercentageChange(e,x.name)}
                                />;
                            }
                            else{
                                returnVal = <CurrencyFilter filterId={x.name} label={x.displayName}
                                                            value={this.state.filters[x.name]}
                                                            onInputChange={(e,m,f) => this.onCurrencyChange(e,m,f, x.name)}
                                />;
                            }
                            break;
                        default:
                            let filterType = x.type.substr(0, x.type.indexOf(' '));
                            returnVal = <MultiSelectFilter filterId={x.name} label={x.displayName}
                                                       data={this.state.filtersType[filterType]}
                                                       value={this.state.filters[x.name]}
                                                       onInputChange={(e) => this.onMultiSelectChange(e, x.name)}
                            />;
                            break;
                    }
                    return <Col key={colIndex} md={3}>{returnVal}</Col>;
                }
            )}
            </Row>
        ));
    }

    render() {
        const ArrowLeft = <Button size="lg" color="secondary">{'<'}</Button>;
        const ArrowRight = <Button size="lg" color="secondary">{'>'}</Button>;
        const Menu = (list) => list.map(x => {
            return <Button className="btn" style={{backgroundColor:"#2962FF", marginRight: 10, marginTop: 5, marginBottom: 5}} size="lg" key={x.name} active>{x.displayName}</Button>;
        });
        const menu = Menu(this.state.data);

        return <div>
            <Card>
                <CardTitle className="bg-light border-bottom p-3 mb-0">
                    <i className={this.reportIcon}/>{this.reportsTitle +
                    (this.state.selected.length === 0 ? "" : " - " + this.state.selected.displayName)}
                </CardTitle>
                <CardBody>
                    <LoadingPage color={"3e5569"} loading={this.state.loading} type={"spin"}/>
                    <Row>
                        <Col md={12}>
                            <ScrollMenu
                                data={menu}
                                wheel={false}
                                hideSingleArrow={true}
                                arrowLeft={ArrowLeft}
                                arrowRight={ArrowRight}
                                onSelect={this.onSelect}
                            />
                        </Col>
                    </Row>
                    <div style={{backgroundColor: "#F6F9FC", width: "100%", minHeight: 350, height: "auto", marginTop: 10, marginBottom: 10, padding: 10}}>
                        {this.state.selected.length === 0 ? null : this.renderFilters()}
                    </div>
                    <Row>
                        <Col md={12}>
                            <input type="submit"
                                   className="btn btn-primary"
                                   value="Generar"
                                   disabled={this.state.selected.length === 0}
                                   onClick={this.onSubmit}/>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </div>;
    }
}
