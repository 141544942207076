import React from 'react';
import "react-table/react-table.css";
import {
    Card,
    CardTitle,
    CardBody,
    Row,
    Col,
    Form,
    Label,
    FormGroup
} from 'reactstrap';
import {LoadingPage} from "../../components/custom/views/LoadingPage";
import {InputForm} from "../../components/custom/inputs/InputForm";
import {toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.min.css';
import AuthService from "../../utils/AuthService";
import {PersonModel} from "../../models/PersonModel";
import Datetime from "react-datetime";
import {DateFormat} from "../../const/api_consts";
import {InputSelectForm} from "../../components/custom/inputs/InputSelectForm";
import {InputSelect} from "../../components/custom/inputs/InputSelect";
import {InputImage} from "../../components/custom/inputs/InputImage";
import PersonApiClient from "../../utils/api-client/PersonApiClient";
import {ImageModel} from "../../models/ImageModel";
import CreatableSelect from "react-select/lib/Creatable";
import CyaHelper from "../../utils/CyaHelper";
import FieldValidator from "../../utils/field-validators/FieldValidator";
import PersonFieldValidator from "../../utils/field-validators/PersonFieldValidator";
import {CyaContext} from "../../utils/CyaContext";

export default class CreatePerson extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: new PersonModel(),
            initialData: new PersonModel(),
            idTypeData: [],
            civilStatusData: [],
            genderData: [],
            prefixData: [],
            cityData: [],
            positionData: [],
            mask: [],
            options: [],
            loading: true,
            title: "Crear Personal",
            modelCreated: "Personal Guardado.",
            modelUpdated: "Personal Actualizado.",
            modelError: "Error en Personal."
        };
        this.Auth = new AuthService();
        this.PersonApiClient = new PersonApiClient();
        this.Validator = new FieldValidator(PersonFieldValidator);
        this.editPromise = this.editPromise.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.changeMask = this.changeMask.bind(this);
        this.changeGenderOptions = this.changeGenderOptions.bind(this);
        this.onDateChange = this.onDateChange.bind(this);
        this.onSelectChange = this.onSelectChange.bind(this);
        this.onMultiPositionChange = this.onMultiPositionChange.bind(this);
        this.validSaveButton = this.validSaveButton.bind(this);

    }

    componentDidMount() {
        this.PersonApiClient.Options().then(response =>{
            let isMale = this.state.data.genderId===1;
            this.setState({
                options: response,
                cityData: response.city,
                genderData: response.gender,
                idTypeData: response.idType,
                prefixData: response.prefix.filter(x => {return x.isMale===isMale;}),
                civilStatusData: response.civilStatus.filter(x => {return x.isMale===isMale;}),
            });

        }).then(()=>{
            this.PersonApiClient.Positions().then(response=>{
                let positions = response.data.map(x => {
                    x.value= x.positionId;
                    x.label= x.name;
                    return x;
                });
                this.setState({positionData: positions});
            }).then(()=>{
                if (this.props.location.state !== undefined && this.props.location.state?.id) {
                    this.PersonApiClient.GetById(this.props.location.state.id).then(response => {
                        this.editPromise(response);
                    });
                } else {
                    this.changeMask(1);
                    this.setState({loading: false});
                }
            });
        });
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if (!nextState.loading && CyaHelper.ObjectCompare(nextState.data, this.state.data))
            this.Validator.initValidation(nextState.data);

        return true;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let isFormDirty = !CyaHelper.ObjectCompare(this.state.initialData, this.state.data);
        this.context.toggleExitConfirmation(isFormDirty);
    }

    changeGenderOptions(genderId){
        let isMale = Number(genderId)===1;
        let prefixGender = this.state.options.prefix.filter(x => {return x.isMale===isMale;});
        let civilStatusGender = this.state.options.civilStatus.filter(x => {return x.isMale===isMale;});
        this.setState({prefixData: prefixGender, civilStatusData: civilStatusGender});
    }

    validSaveButton(){
        return (this.Auth.GetPathPermission(this.props.location.pathname, true) && this.Validator.validForm());
    }

    editPromise(response) {
        this.changeMask(1);
        this.changeGenderOptions(response.genderId);
        if (response.personPosition === null)
            response.personPosition = [];
        if (response.idNumberImage === null)
            response.idNumberImage = new ImageModel();
        if (response.sirecineImage === null)
            response.sirecineImage = new ImageModel();

        if (response.personPosition.length>0){
            response.personPosition=response.personPosition.map(x=>{
                x.value= x.positionId;
                x.label= x.position.name;
                return x;
            });
        }

        this.context.toggleExitConfirmation(false);
        this.setState({
            initialData: JSON.parse(JSON.stringify(response)), //copy data variable
            data: response,
            title: "Editar Personal",
            loading: false
        });
    }

    onSelectChange(event) {
        let selectedPositions = event.map(x => {
            return {
                positionId: x.value,
                name: x.label,
            }
        });
        let aux = this.state.data;
        aux["positions"] = selectedPositions;
        this.setState({
            data: aux,
            selectedPositions: event,
        });
    }

    onInputChange(event) {
        if (!this.state.loading && event.target.name==="genderId" && event.target.value!==this.state.data.genderId){
            this.changeGenderOptions(event.target.value);
        }
        if (event.target.name === "idtypeId") {
            this.changeMask(event.target.value);
        }
        let aux = this.state.data;

        aux[event.target.name] = event.target.value;
        this.setState({data: aux});
    }

    changeMask(value) {
        let mask = [];
        switch (Number(value)) {
            case 1:
                mask = [/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/];
                break;
            default:
                mask = [];
                break;
        }
        this.setState({
            mask: mask
        });
    }

    onDateChange(moment) {
        let aux = this.state.data;
        aux.sirecineExpDate = moment.format("YYYY-MM-DD");
        this.setState({
            data: aux
        });
    }

    onMultiPositionChange(positions) {
        let aux = this.state.data;

        aux.personPosition = positions.map(x => {
            if (x.positionId===undefined){
                x.position={
                    positionId:0,
                    name:x.value,
                };
            }

            return x
        });

        this.setState({data: aux});
    }

    onSubmit(event) {
        event.preventDefault();
        this.setState({loading: true});

        if (this.state.data.personId > 0) {
            this.PersonApiClient.Update(this.state.data.personId, this.state.data)
                .then(response => {
                    if (response.response !== undefined && response.response.data === "Error") {
                        this.setState({loading: false});
                        toast.error(this.state.modelError);
                    } else {
                        this.editPromise(response);
                        toast.success(this.state.modelUpdated);
                    }
                })
                .catch(() => {
                    this.setState({loading: false});
                    toast.error(this.state.modelError);
                });
        }
        else {
            this.PersonApiClient.Create(this.state.data)
                .then(response => {
                    if (response.response !== undefined && response.response.data === "Error") {
                        this.setState({loading: false});
                        toast.error(this.state.modelError);
                    } else {
                        this.editPromise(response);
                        toast.success(this.state.modelUpdated);
                    }
                })
                .catch(() => {
                    this.setState({loading: false});
                    toast.error(this.state.modelError);
                });
        }
        window.scrollTo(0, 0);
    }

    render() {
        return <div className="auth-wrapper d-flex">
            <div className="container">
                <Card>
                    <CardTitle className="mb-0 p-3 border-bottom bg-light"><i
                        className="mdi mdi-border-right mr-2"/>{this.state.title}
                    </CardTitle>
                    <CardBody>
                        <LoadingPage color={"3e5569"} loading={this.state.loading} type={"spin"}/>
                        <Row>
                            <Col md="12">
                                <Form>
                                    <Row>
                                        <Col md="6">
                                            <InputForm id_label={"name"} label={"Nombre:"} placeholder={""}
                                                       type={"text"} value={this.state.data.name}
                                                       onInputChange={this.onInputChange}/>
                                            {this.Validator.showErrors("name")}
                                        </Col>
                                        <Col md="3">
                                            <InputForm
                                                id_label={"nickname"}
                                                label={"Apodo:"}
                                                placeholder={""}
                                                type={"text"}
                                                value={this.state.data.nickname}
                                                onInputChange={this.onInputChange}
                                            />
                                            {this.Validator.showErrors("nickname")}
                                        </Col>
                                        <Col md="3">
                                                <Row>
                                                    <Col md="5">
                                                        <InputSelect
                                                            id_label={"prefixId"}
                                                            data={this.state.prefixData}
                                                            label={"Prefijo:"}
                                                            dataDescription={["description"]}
                                                            dataValue={"prefixId"}
                                                            selectedValue={Number(this.state.data.prefixId)}
                                                            onInputChange={this.onInputChange}/>
                                                    </Col>
                                                    <Col md="7">
                                                        <InputSelect
                                                            id_label={"genderId"}
                                                            data={this.state.genderData}
                                                            label={"Sexo:"}
                                                            dataDescription={["name"]}
                                                            dataValue={"genderId"}
                                                            selectedValue={Number(this.state.data.genderId)}
                                                            onInputChange={this.onInputChange}/>
                                                    </Col>
                                                </Row>
                                        </Col>
                                    </Row>



                                    <Row>
                                        <Col md="6">
                                            <InputForm
                                                id_label={"address"}
                                                label={"Dirección:"}
                                                placeholder={""}
                                                type={"text"}
                                                value={this.state.data.address}
                                                onInputChange={this.onInputChange}
                                            />
                                            {this.Validator.showErrors("address")}
                                        </Col>
                                        <Col md="3">
                                            <InputSelect
                                                id_label={"cityId"}
                                                data={this.state.cityData}
                                                label={"Ciudad:"}
                                                dataDescription={["name"]}
                                                dataValue={"cityId"}
                                                selectedValue={Number(this.state.data.cityId)}
                                                onInputChange={this.onInputChange}/>
                                        </Col>
                                        <Col md="3">
                                            <InputForm
                                                id_label={"nationality"}
                                                label={"Nacionalidad:"}
                                                placeholder={""}
                                                type={"text"}
                                                value={this.state.data.nationality}
                                                onInputChange={this.onInputChange}
                                            />
                                            {this.Validator.showErrors("nationality")}
                                        </Col>
                                    </Row>



                                    <Row>

                                        <Col md={6}>
                                            <InputSelect
                                                id_label={"civilStatusId"}
                                                data={this.state.civilStatusData}
                                                label={"Estado Civil:"}
                                                dataDescription={["name"]}
                                                dataValue={"civilStatusId"}
                                                selectedValue={Number(this.state.data.civilStatusId)}
                                                onInputChange={this.onInputChange}/>
                                        </Col>
                                        <Col md={6}>
                                            <InputForm
                                                id_label={"occupation"}
                                                label={"Ocupación:"}
                                                placeholder={""}
                                                type={"text"}
                                                value={this.state.data.occupation}
                                                onInputChange={this.onInputChange}
                                            />
                                            {this.Validator.showErrors("occupation")}
                                        </Col>

                                    </Row>

                                    <Row>
                                        <Col md={12}>
                                            <Row>
                                                <Col style={{marginTop: "1rem"}} md={12}>
                                                    <h5>Cedula/Pasaporte</h5>
                                                    <hr style={{marginTop: "0rem"}}/>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md="6">
                                                    <InputSelectForm
                                                        label={"ID:"}
                                                        idSelect={"idtypeId"}
                                                        idForm={"idNumber"}
                                                        formValue={this.state.data.idNumber}
                                                        data={this.state.idTypeData}
                                                        dataValue={"idtypeId"}
                                                        dataDescription={"description"}
                                                        formType={"text"}
                                                        placeholder={""}
                                                        selectedValue={Number(this.state.data.idtypeId)}
                                                        mask={this.state.mask}
                                                        showMask={this.state.showMask}
                                                        onInputChange={this.onInputChange}
                                                    />
                                                    {this.Validator.showErrors("idNumber")}
                                                </Col>
                                                <Col md="6">
                                                    {CyaHelper.ImageExists(this.state.data.idNumberImage) ?
                                                        <InputImage id_label={"idNumberImage"} width={"fit-content"}
                                                                    label={"Copia de ID:"}
                                                                    image={this.state.data.idNumberImage}
                                                                    acceptPDF={true}
                                                                    hash={this.state.data.idNumberImage.hash}
                                                                    showViewButton={true} showImageMagnify={false}/>
                                                        :
                                                        <div>
                                                            <InputImage id_label={"idNumberImage"} width={"fit-content"}
                                                                        label={"Copia de ID:"}
                                                                        image={this.state.data.idNumberImage}
                                                                        acceptPDF={true}
                                                                        showViewButton={true} showImageMagnify={false}/>
                                                        </div>
                                                    }
                                                </Col>


                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12}>
                                            <Row>
                                                <Col style={{marginTop: "1rem"}} md={12}>
                                                    <h5>Sirecine</h5>
                                                    <hr style={{marginTop: "0rem"}}/>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md="3">
                                                    <InputForm
                                                        id_label={"sirecineNumber"}
                                                        label={"Número de Sirecine:"}
                                                        placeholder={""}
                                                        type={"text"}
                                                        value={this.state.data.sirecineNumber}
                                                        onInputChange={this.onInputChange}
                                                    />
                                                    {this.Validator.showErrors("sirecineNumber")}
                                                </Col>
                                                <Col md="3">
                                                    <Label htmlFor={"date"}>Fecha Expiración Sirecine: </Label>
                                                    <Datetime inputProps={{readOnly: true}}
                                                              locale={"es-do"}
                                                              utc={true}
                                                              timeFormat={false}
                                                              defaultValue={this.state.data.sirecineExpDate}
                                                              dateFormat={DateFormat}
                                                              closeOnSelect={true}
                                                              value={new Date(this.state.data.sirecineExpDate)}
                                                              onChange={this.onDateChange}
                                                    />
                                                </Col>
                                                <Col md={6}>
                                                    {CyaHelper.ImageExists(this.state.data.sirecineImage) ?
                                                        <InputImage id_label={"sirecineImage"} width={"fit-content"}
                                                                    label={"Copia de Sirecine:"}
                                                                    image={this.state.data.sirecineImage}
                                                                    acceptPDF={true}
                                                                    hash={this.state.data.sirecineImage.hash}
                                                                    showViewButton={true} showImageMagnify={false}/>
                                                        :
                                                        <div>
                                                            <InputImage id_label={"sirecineImage"} width={"fit-content"}
                                                                        label={"Copia de Sirecine:"}
                                                                        image={this.state.data.sirecineImage}
                                                                        acceptPDF={true}
                                                                        showViewButton={true} showImageMagnify={false}/>
                                                        </div>
                                                    }
                                                </Col>


                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12}>
                                            <FormGroup>
                                                <Label htmlFor={"positions"}>Posiciones: </Label>
                                                <CreatableSelect value={this.state.data.personPosition}
                                                                 options={this.state.positionData}
                                                                 id={"positions"}
                                                                 name={"positions"}
                                                                 isLoading={this.state.loading}
                                                                 isClearable
                                                                 isMulti
                                                                 placeholder={"Seleccionar o crear posiciones"}
                                                                 onChange={this.onMultiPositionChange} />
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                </Form>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <hr/>
                                <input type="submit"
                                       disabled={!(this.validSaveButton())}
                                       className={!(this.validSaveButton()) ? 'btn btn-primary disabled' : 'btn btn-primary'}
                                       value="Guardar"
                                       onClick={this.onSubmit}/>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </div>
        </div>
    }
}
CreatePerson.contextType = CyaContext;
