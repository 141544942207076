import React from "react";
import PropTypes from "prop-types";

import {
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    CardSubtitle,
    ListGroup,
    ListGroupItem
} from 'reactstrap';

import Chart from 'react-c3-component';
import 'c3/c3.css';

let colors = ["text-purple","text-cyan","text-orange"];

export default class PieChart extends React.Component {
    render() {
        return (
            <Card className="card-hover" style={{height:this.props.height}}>
                <CardBody>
                    <div className="d-md-flex align-items-center">
                        <div>
                            <CardTitle>{this.props.title}</CardTitle>
                            <CardSubtitle>{this.props.subtitle}</CardSubtitle>
                        </div>
                    </div>
                    <Row className="mt-4">
                        <Col lg="6">
                            <div>
                                <Chart
                                    style={{ height: '200px', width: '100%' }}
                                    config={{
                                        data: {
                                            columns: this.props.data,
                                            type: 'donut',
                                            tooltip: {
                                                show: true
                                            }
                                        },
                                        donut: {
                                            label: {
                                                show: false
                                            },
                                            title: "Etapas",
                                            width: 30,
                                        },
                                        legend: {
                                            hide: true
                                        },
                                        color: {
                                            pattern: ['#7e74fb', '#4FC3F7', '#ff821c']
                                        }
                                    }}
                                />
                            </div>
                        </Col>
                        <Col lg="6">
                            <ListGroup>
                                {this.props.data.map((x, i) => {
                                    return <ListGroupItem key={i} className="border-0 mt-3 p-0"><i className={`fas fa-circle mr-1 ` + colors[i] + ` font-12`}/> {x[0]} <span className="float-right"><strong>{x[1]}%</strong></span></ListGroupItem>
                                })}
                            </ListGroup>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        );
    }
}

PieChart.propTypes = {
    data: PropTypes.array,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    height: PropTypes.string
};

PieChart.defaultProps = {
  height: "350px",
};
