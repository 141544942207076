import React, { useEffect, useState } from "react";
import {
   Button,
   Col,
   Form,
   Input,
   InputGroup,
   InputGroupAddon,
   InputGroupText,
   Row,
} from "reactstrap";
import { LoadingPage } from "../../components/custom/views/LoadingPage";
import logolighticon from "../../assets/images/logo-light-icon.png";
import logolighttext from "../../assets/images/logo-light-text.png";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import PayRequesterApiClient from "../../utils/api-client/PayRequesterApiClient";
import AuthService from "../../utils/AuthService";
import md5 from "md5";

const apiClient = new PayRequesterApiClient();
const authApiClient = new AuthService();

export const PayRequesterLogin = () => {
   const { linkCode } = useParams();
   const history = useHistory();
   const { path } = useRouteMatch();

   const [loading, setLoading] = useState(false);
   const [pinCode, setPinCode] = useState(false);
   const [errorMessage, setErrorMessage] = useState("");

   useEffect(() => {
      setLoading(true);
      apiClient
         .Validate(linkCode)
         .then((response) => {})
         .catch((error) => {
            if (error.status === 404) {
               history.replace(path.replace(":linkCode", ""));
            }
            console.error(error);
         })
         .finally(() => setLoading(false));
   }, []);

   const onSubmit = (e) => {
      e.preventDefault();
      setLoading(true);

      let formData = new FormData();
      formData.append("linkCode", linkCode);
      formData.append("pinCode", md5(pinCode));

      apiClient
         .Authenticate(formData)
         .then((response) => {
            if (response?.token) {
               authApiClient._SetToken(response.token);
               history.push("/payrequest");
            }
         })
         .catch((error) => {
            console.error(error);
            const message = error?.data?.message;
            message && setErrorMessage(`Error: ${message}`);
         })
         .finally(() => setLoading(false));
   };

   return (
      <div className="auth-wrapper  d-flex align-items-center">
         <div className="container">
            <LoadingPage color={"3e5569"} loading={loading} type={"spin"} />
            <Row className="no-gutters justify-content-center">
               <Col md="6" lg="4" className="bg-white">
                  <div
                     className="pt-4 pb-2 text-center"
                     style={{ backgroundColor: "#0093FE" }}
                  >
                     <b className="logo-icon">
                        <img
                           src={logolighticon}
                           alt="homepage"
                           className="light-logo"
                        />
                     </b>
                     <span className="logo-text" style={{ marginLeft: "20px" }}>
                        <img
                           src={logolighttext}
                           className="light-logo"
                           alt="homepage"
                        />
                     </span>
                  </div>
                  <div className="p-4">
                     <h3 className="font-medium mb-3 text-center">
                        Inicio de sesi&oacute;n
                     </h3>
                     <Form className="mt-3" id="loginform" action="/dashboard">
                        <InputGroup className="mb-3" size="lg">
                           <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                 <i className="ti-pencil" />
                              </InputGroupText>
                           </InputGroupAddon>
                           <Input
                              type="password"
                              id="pinCode"
                              name="pinCode"
                              autoComplete="current-password"
                              onChange={(e) => {
                                 setPinCode(e.target.value);
                              }}
                              placeholder="Pin"
                           />
                        </InputGroup>
                        <span className="text-danger">{errorMessage}</span>
                        <Row className="mb-3 mt-2">
                           <Col xs="12">
                              <Button
                                 color="primary"
                                 onClick={onSubmit}
                                 size="lg"
                                 style={{ backgroundColor: "#0093FE" }}
                                 type="submit"
                                 block
                              >
                                 Entrar
                              </Button>
                           </Col>
                        </Row>
                     </Form>
                  </div>
               </Col>
            </Row>
         </div>
      </div>
   );
};
