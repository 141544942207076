import CreateProject from "../views/project/CreateProject";
import Project from "../views/home/Project";
import Producer from "../views/producer/Producer";
import CreateProducer from "../views/producer/CreateProducer";
import User from "../views/user/User";
import CreateUser from "../views/user/CreateUser";
import Investor from "../views/investor/Investor";
import CreateInvestor from "../views/investor/CreateInvestor";
import { preDashboardUrl } from "../const/api_consts";
import Person from "../views/person/Person";
import CreatePerson from "../views/person/CreatePerson";
import { Contracts } from "../views/contracts/Contracts";
import Notary from "../views/notary/Notary";
import CreateNotary from "../views/notary/CreateNotary";
import PaymentMethod from "../views/payment-method/PaymentMethod";
import CreatePaymentMethod from "../views/payment-method/CreatePaymentMethod";
import TaxRecord from "../views/misc/TaxRecord";

let loginRoutes = [
   {
      path: "/payrequest",
      name: "Solicitud de pago",
      icon: "fas fa-film",
   },

   {
      navlabel: true,
      name: "Personal",
      icon: "mdi mdi-dots-horizontal",
   },

   // Proyects components and routes
   {
      collapse: true,
      path: preDashboardUrl + "/projects",
      name: "Proyectos",
      state: "proyectsname",
      icon: "fas fa-film",
      child: [
         {
            path: preDashboardUrl + "/projects",
            name: "Listado",
            mini: "B",
            icon: "mdi mdi-format-list-bulleted",
            component: Project,
         },
         {
            path: preDashboardUrl + "/projects/new",
            name: "Crear",
            mini: "B",
            icon: "mdi mdi-plus-circle",
            component: CreateProject,
         },
      ],
   },

   // Producer components and routes
   {
      collapse: true,
      path: preDashboardUrl + "/producers",
      name: "Productoras",
      state: "producername",
      icon: "fas fa-spinner",
      child: [
         {
            path: preDashboardUrl + "/producers",
            name: "Listado",
            mini: "B",
            icon: "mdi mdi-format-list-bulleted",
            component: Producer,
         },
         {
            path: preDashboardUrl + "/producers/new",
            name: "Crear",
            mini: "B",
            icon: "mdi mdi-plus-circle",
            component: CreateProducer,
         },
      ],
   },

   // Personal components and routes
   {
      collapse: true,
      path: preDashboardUrl + "/person",
      name: "Personal",
      state: "personalname",
      icon: "fas fa-user-circle",
      child: [
         {
            path: preDashboardUrl + "/person",
            name: "Listado",
            mini: "B",
            icon: "mdi mdi-format-list-bulleted",
            component: Person,
         },
         {
            path: preDashboardUrl + "/person/new",
            name: "Crear",
            mini: "B",
            icon: "mdi mdi-plus-circle",
            component: CreatePerson,
         },
      ],
   },

   // Investor components and routes
   {
      collapse: true,
      path: preDashboardUrl + "/investorsdb",
      name: "Inversionistas",
      state: "investordbname",
      icon: "fas fa-hand-holding-usd",
      child: [
         {
            path: preDashboardUrl + "/investorsdb",
            name: "Listado",
            mini: "B",
            icon: "mdi mdi-format-list-bulleted",
            component: Investor,
         },
         {
            path: preDashboardUrl + "/investorsdb/new",
            name: "Crear",
            mini: "B",
            icon: "mdi mdi-plus-circle",
            component: CreateInvestor,
         },
      ],
   },

   // Staff components and routes
   {
      collapse: true,
      path: preDashboardUrl + "/users",
      name: "Usuarios",
      state: "usersname",
      icon: "fas fa-users",
      child: [
         {
            path: preDashboardUrl + "/users",
            name: "Listado",
            mini: "B",
            icon: "mdi mdi-format-list-bulleted",
            component: User,
         },
         {
            path: preDashboardUrl + "/users/new",
            name: "Crear",
            mini: "B",
            icon: "mdi mdi-plus-circle",
            component: CreateUser,
         },
      ],
   },
   {
      collapse: true,
      path: preDashboardUrl + "/notaries",
      name: "Notarios",
      state: "notariesname",
      icon: "fas fa-gavel",
      child: [
         {
            path: preDashboardUrl + "/notaries",
            name: "Listado",
            mini: "B",
            icon: "mdi mdi-format-list-bulleted",
            component: Notary,
         },
         {
            path: preDashboardUrl + "/notaries/new",
            name: "Crear",
            mini: "B",
            icon: "mdi mdi-plus-circle",
            component: CreateNotary,
         },
      ],
   },
   // {
   //    collapse: true,
   //    path: preDashboardUrl + "/paymentmethods",
   //    name: "Formas de pago",
   //    state: "paymentmethodsname",
   //    icon: "fas fa-credit-card",
   //    child: [
   //       {
   //          path: preDashboardUrl + "/paymentmethods",
   //          name: "Listado",
   //          mini: "B",
   //          icon: "mdi mdi-format-list-bulleted",
   //          component: PaymentMethod,
   //       },
   //       {
   //          path: preDashboardUrl + "/paymentmethods/new",
   //          name: "Crear",
   //          mini: "B",
   //          icon: "mdi mdi-plus-circle",
   //          component: CreatePaymentMethod,
   //       },
   //    ],
   // },
   // {
   //    state: "contractsname",
   //    icon: "fas fa-file",
   //    path: preDashboardUrl + "/contracts",
   //    name: "Contratos",
   //    component: Contracts,
   // },
   {
      collapse: false,
      path: preDashboardUrl + "/taxrecord",
      name: "Registros Fiscales",
      state: "taxrecordsname",
      icon: "mdi mdi-bank",
      component: TaxRecord,
   },
   {
      isVisible: false,
      path: preDashboardUrl + "/users/edit",
      name: "Editar",
      component: CreateUser,
   },
   {
      isVisible: false,
      path: preDashboardUrl + "/paymentmethods/edit",
      name: "Editar",
      component: CreatePaymentMethod,
   },
   {
      isVisible: false,
      path: preDashboardUrl + "/notaries/edit",
      name: "Editar",
      component: CreateNotary,
   },
   {
      isVisible: false,
      path: preDashboardUrl + "/person/edit",
      name: "Editar",
      component: CreatePerson,
   },
   {
      isVisible: false,
      path: preDashboardUrl + "/producers/edit",
      name: "Editar",
      component: CreateProducer,
   },
   {
      isVisible: false,
      path: preDashboardUrl + "/projects/edit",
      name: "Editar",
      component: CreateProject,
   },
   {
      isVisible: false,
      path: preDashboardUrl + "/investorsdb/edit",
      name: "Editar",
      component: CreateInvestor,
   },
];
export default loginRoutes;
