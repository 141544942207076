import React from 'react';
import "react-table/react-table.css";
import {CyaTableLayout} from "../../components/custom/tables/CyaTableLayout";
import UserApiClient from "../../utils/api-client/UserApiClient";
import {preDashboardUrl, SuccessResponse} from "../../const/api_consts";
import AuthService from "../../utils/AuthService";
import {
    Row,
    Col,
} from 'reactstrap';
import Modal from "react-modal";
import {toast} from "react-toastify";


export default class User extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            selected: null,
            loading: true,
            selectedRowId: -1,
            selectedRowData: {},
            title: "Usuarios",
            deleteText: "Borrar",
            modalDelete:false,
        };
        this.GetProps = this.GetProps.bind(this);
        this.closeDeleteModal = this.closeDeleteModal.bind(this);
        this.deleteModel = this.deleteModel.bind(this);
        this.Auth = new AuthService();
        this.UserApiClient = new UserApiClient();

    }

    componentDidMount(){
        this.UserApiClient.GetAll().then(response => this.setState({data: response.data, loading: false}));
    }

    viewCallback = () => {
        if (this.state.selectedRowId >= 0) {
            this.props.history.push({
                pathname: preDashboardUrl+'/users/edit',
                state: {id: this.state.selectedRowId}
            });
        }
    };

    closeDeleteModal() {
        if (this.state.modalDelete)
            this.setState({modalDelete: false});
    }

    deleteCallback = () => {
        if (this.state.selectedRowId > 0) {
            this.setState({modalDelete: true});
        }
    };

    deleteModel(){
        this.setState({loading: true});
        if (this.state.selectedRowId > 0) {
            this.UserApiClient.Delete(this.state.selectedRowId).then((response) => {
                if (response !== SuccessResponse) {
                    toast.error("Error eliminado usuario.");
                } else {
                    this.props.history.push({pathname: '/refresh'+preDashboardUrl+'/users'});
                    toast.success("Usuario eliminado.");
                }
            }).catch(() => {
                toast.warn("Error eliminado usuario.");
            });
        }
        this.setState({loading: false});
        this.closeDeleteModal();
    }

    GetProps(state, rowInfo) {
        if (rowInfo && rowInfo.row) {
            return {
                onClick: () => {
                    this.setState({
                        selected: rowInfo.index,
                        selectedRowId: rowInfo.row.userId,
                        selectedRowData: this.state.data.find(x => x.userId === rowInfo.row.userId)
                    });
                },
                onDoubleClick: () => {
                    if (this.state.selected === rowInfo.index) {
                        this.props.history.push({
                            pathname: preDashboardUrl+'/users/edit',
                            state: {id: rowInfo.row.userId}
                        });
                    }
                },
                style: {
                    background: rowInfo.index === this.state.selected ? '#00AFEC' : rowInfo.index % 2 === 0 ? '#DDDDDD' : '#FFFFFF',
                    color: rowInfo.index === this.state.selected ? 'white' : 'black'
                }
            }
        }
        return {};
    }

    render() {
        let self = this;
        let columns = [
            {Header: "ID", accessor: "userId", show: false},
            {Header: "Nombre", accessor: "name"},
            {Header: "Usuario", accessor: "username"},
            {Header: "Email ", accessor: "email"},
            {Header: "Rol", accessor: "rolDescription"},
        ];
        const customStyles = {
            content: {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)'
            }
        };
        Modal.setAppElement('body');

        return <div className="auth-wrapper d-flex">
            <Modal
                isOpen={this.state.modalDelete}
                onRequestClose={this.closeDeleteModal}
                style={customStyles}
            >
                <Row>
                    <Col md={10}><h4>Confirmación</h4></Col>
                    <Col md={2} style={{textAlign: 'right'}}>
                        <button className={"btn btn-danger"} style={{textAlign: 'right'}}
                                onClick={this.closeDeleteModal}>X
                        </button>
                    </Col>
                </Row>
                <hr/>
                <h5>¿Desea eliminar el siguiente usuario? </h5>
                <ul>
                    {self.state.selectedRowId > 0 ?
                        <li key={0}>{this.state.selectedRowData.name +' - ' +this.state.selectedRowData.username }</li>
                        : ""}
                </ul>
                <Row>
                    <Col md={12}>
                        <button className={"btn btn-danger float-right"} onClick={this.deleteModel}>Eliminar
                        </button>
                    </Col>
                </Row>

            </Modal>
            <div className="container">
                <div>
                    <CyaTableLayout
                        title={"Usuarios"}
                        data={this.state.data}
                        columns={columns}
                        loading={this.state.loading}
                        trProps={this.GetProps}
                        enableButtons={this.state.selectedRowId >= 0}
                        showDeleteButton={this.Auth.GetPathPermission(this.props.location.pathname+"/delete",false)}
                        deleteCallback={this.deleteCallback}
                        viewCallback={this.viewCallback}
                    />
                </div>
            </div>
        </div>
    }
}
