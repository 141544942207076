import CyaApiClient from "./CyaApiClient";

export default class ProjectApiClient extends CyaApiClient {

    constructor(){
        super("Project");
        this.Open = this.Open.bind(this);
        this.Close = this.Close.bind(this);
        this.SetLocalProject = this.SetLocalProject.bind(this);
        this.GetProject = this.GetProject.bind(this);
    }

    Open(projectId) {
        return this.MakeCall('get', this.ControllerName + '/open', {projectId:projectId});
    }

    Close(projectId) {
        return this.MakeCall('get', this.ControllerName + '/close',{projectId:projectId});
    }

    SetLocalProject(id_project, projectName, producerName){
        localStorage.setItem('id_project', id_project);
        localStorage.setItem('producerName', producerName);
        localStorage.setItem('projectName', projectName);
        return this.GetById(id_project);
    }

    GetProject() {
        return {projectName:localStorage.getItem('projectName'), producerName:localStorage.getItem('producerName'), projectId:localStorage.getItem('id_project')};
    }

    GetProjectCountries(projectId){
        return this.MakeCall('get', this.ControllerName + '/getProjectCountries', {id:projectId});
    }

    UpdateProjectCountries(projectId, data){
        return this.MakeCall('put', this.ControllerName + '/updateProjectCountries', {id:projectId, dto:data});
    }

    GetCountries() {
        return this.MakeCall('get', this.ControllerName + '/countries');
    }

    GetProjectsByUserId(userId) {
        return this.MakeCall('get', this.ControllerName + '/projectsByUserId', {userId});
    }
}
