import CyaReadApiClient from "./CyaReadApiClient";

export default class ReportApiClient extends CyaReadApiClient {

    constructor(){
        super("Report");
        this.GetReportFilter = this.GetReportFilter.bind(this);
        this.GetReportsByCategory = this.GetReportsByCategory.bind(this);
    }

    GetReportsByCategory(categoryName) {
        return this.MakeCall('get', this.ControllerName + '/getReportsByCategory', {categoryName: categoryName});
    }

    GetReportFilter(filterType) {
        return this.MakeCall('get', this.ControllerName + '/get' + filterType + 'Filter');
    }
}