import CyaApiClient from "./CyaApiClient";

export default class CrewCastApiClient extends CyaApiClient {

    constructor(){
        super("CrewCast");
    }

    Types() {
        return this.MakeCall('get', this.ControllerName + '/crewCastTypes');
    }
    AddCrewCast(data) {
        return this.MakeCall('post', this.ControllerName + '/addCrewCast',data);
    }
}
