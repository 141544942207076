import React from 'react';
import {
    Label,
    FormGroup
} from 'reactstrap';
import PropTypes from 'prop-types'
import Select from "react-select";

export class MultiSelectFilter extends React.Component {
    render() {
        return <FormGroup>
                <Label style={{fontSize: '20px'}}>{this.props.label}:</Label>
                <Select options={this.props.data}
                        id={this.props.filterId}
                        name={this.props.filterId}
                        isMulti
                        closeMenuOnSelect={false}
                        value={this.props.value === undefined ? null : this.props.value}
                        placeholder={"Seleccione " + this.props.label}
                        isClearable
                        onChange={this.props.onInputChange}
                />
            </FormGroup>
    }
}

MultiSelectFilter.propTypes = {
    filterId: PropTypes.string,
    label: PropTypes.string,
    data: PropTypes.array,
    value: PropTypes.array,
    onInputChange: PropTypes.func
};