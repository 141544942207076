import React from 'react';
import {
    FormGroup,
    Label
} from 'reactstrap';
import PropTypes from 'prop-types'
import CurrencyInput from "react-currency-input";
import {CurrencyPrecision, CurrencyPrefix} from "../../../const/api_consts";

export class CurrencyFilter extends React.Component {

    render() {
        return <FormGroup>
            <Label style={{fontSize: '20px'}}>{this.props.label}:</Label>
            <CurrencyInput
                className="form-control"
                prefix={CurrencyPrefix}
                precision={CurrencyPrecision}
                id={this.props.filterId}
                name={this.props.filterId}
                allowNegative={true}
                value={this.props.value === undefined ? null : this.props.value}
                onChangeEvent={this.props.onInputChange}/>
        </FormGroup>
    }
}

CurrencyFilter.propTypes = {
    filterId: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.number,
    onInputChange: PropTypes.func
};