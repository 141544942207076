import React from 'react';
import {Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, FormGroup, Label, Input} from 'reactstrap';
import PerfectScrollbar from 'react-perfect-scrollbar'
import BudgetTable from "../tables/BudgetTable";
import {withStyles } from '@material-ui/core/styles';
import {Typography} from '@material-ui/core';
import Paper from '@material-ui/core/Paper';

const BudgetModal = (props) => {
    const {columns, onChangeEvent, rows, toggle, isOpen, classes} = props;

    return (
        <div>
            <Modal style={{minWidth: '80%'}} size={'lg'} isOpen={isOpen} toggle={toggle}>
                <ModalHeader toggle={toggle}>Estimación de costo</ModalHeader>
                <ModalBody>
                    <Paper elevation={3} className={classes.paper}>
                        <PerfectScrollbar option={{suppressScrollX:true}}  className={classes.scrollbar}>
                            <Typography style={{marginBottom:15}} >Detalles de estimación</Typography>

                            <Row>
                                <Col md={4}>
                                    <FormGroup>
                                        <Label for="exampleEmail">Tarifa</Label>
                                        <Input/>
                                    </FormGroup>
                                </Col>
                                <Col md={4}>
                                    <FormGroup>
                                        <Label for="exampleEmail">Porciento</Label>
                                        <Input/>
                                    </FormGroup>
                                </Col>
                                <Col md={4}>
                                    <FormGroup>
                                        <Label for="exampleEmail">Total</Label>
                                        <Input />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <hr className="my-2"/>
                            <Button size={'sm'} color="primary">Agrrgar nuevo</Button>
                        </PerfectScrollbar>
                    </Paper>

                    <Paper elevation={3} className={classes.paper}>
                        <BudgetTable columns={columns} onChangeEvent={onChangeEvent} rows={rows}/>
                    </Paper>
                </ModalBody>
                <ModalFooter className={classes.footer}>
                    <Button color="success" onClick={toggle}>Guardar Cambios</Button>{' '}
                    <Button color="danger" onClick={toggle}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}

const useStyles = {
    scrollbar: {
        maxHeight: 250,
        padding:15,
    },
    paper: {
        marginBottom: 15,
    },
    footer:{
        justifyContent:'flex-start'
    }
};


export default withStyles(useStyles)(BudgetModal);
