import CyaApiClient from "./CyaApiClient";

export default class UserApiClient extends CyaApiClient {

    constructor(){
        super("User");
        this.Roles = this.Roles.bind(this);
    }
    Roles() {
        return this.MakeCall('get', this.ControllerName + '/roles');
    }

    GetUserProjects(userId) {
        return this.MakeCall('get', this.ControllerName + '/userProjects', {userId});
    }

    SaveUserProjects(userProjectRequestDto, userId) {
        return this.MakeCall('post', this.ControllerName + '/userProjects?userId='+userId, userProjectRequestDto);
    }

    ChangePassword(oldPassword, newPassword, userId) {
        return this.MakeCall('post', this.ControllerName + '/changePassword?userId='+userId, {oldPassword, newPassword});
    }
}
