import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { Col, Row } from "reactstrap";
import { SimpleTable } from "../../components/custom/tables/SimpleTable";

const style = {
   content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "65%",
      maxHeight: "80vh",
      overflow: "hidden",
   },
};

const columns = [
   { Header: "Proyecto", accessor: "name" },
   { Header: "Productora", accessor: "producerName" },
   { Header: "Productor General", accessor: "generalProducer" },
];
export default function ({
   isOpen,
   setModalOpen,
   projectsData,
   setSelectedProject,
}) {
   const [selected, setSelected] = useState(null);

   useEffect(() => {
      if (isOpen) {
         document.body.style.overflow = "hidden";
      } else {
         document.body.style.overflow = "unset";
      }

      return () => (document.body.style.overflow = "unset");
   }, [isOpen]);

   Modal.setAppElement("body");

   function onRequestClose() {
      setModalOpen(false);
   }

   function trProps(state, rowInfo) {
      if (rowInfo && rowInfo.row) {
         return {
            onClick: () => {
               setSelected(rowInfo.index);
            },
            onDoubleClick: () => {
               setSelectedProject(projectsData[rowInfo.index]);
               setModalOpen(false);
               setSelected(null);
            },
            style: {
               background:
                  rowInfo.index === selected
                     ? "#00AFEC"
                     : rowInfo.index % 2 === 0
                     ? "#DDDDDD"
                     : "#FFFFFF",
               color: rowInfo.index === selected ? "white" : "black",
            },
         };
      }
      return {};
   }

   return (
      <Modal {...{ isOpen, onRequestClose, style }}>
         <Row>
            <Col md={10}>
               <h4>Proyectos</h4>
            </Col>
            <Col md={2} style={{ textAlign: "right" }}>
               <button
                  className={"btn btn-danger"}
                  style={{ textAlign: "right" }}
                  onClick={() => setModalOpen(false)}
               >
                  X
               </button>
            </Col>
         </Row>
         <Row>
            <SimpleTable
               data={projectsData}
               columns={columns}
               trProps={trProps}
               style={{ height: "400px" }}
            />
         </Row>
      </Modal>
   );
}
