import React from 'react';
import {
    Card,
    CardBody,
    CardTitle,
    Row,
    Col,
    Label,
    Form,
    FormGroup,
} from 'reactstrap';
import {ItemModel} from "../../models/ItemModel";
import ItemApiClient from "../../utils/api-client/ItemApiClient";
import FieldValidator from "../../utils/field-validators/FieldValidator";
import ItemFieldValidator from "../../utils/field-validators/ItemFieldValidator";
import {LoadingPage} from "../../components/custom/views/LoadingPage";
import {InputForm} from "../../components/custom/inputs/InputForm";
import {InputCurrency} from "../../components/custom/inputs/InputCurrency";
import Select from 'react-select';
import CreatableSelect from 'react-select/lib/Creatable';
import {toast} from "react-toastify";
import AuthService from "../../utils/AuthService";
import {CyaContext} from "../../utils/CyaContext";
import CyaHelper from "../../utils/CyaHelper";

export default class CreateItem extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            data: new ItemModel(),
            initialData: new ItemModel(),
            loading: true,
            selectedItem: null,
            itemsData: [],
            itemDetail: [],
            itemDetailOptions: [],
            title: "Crear Partida",
            modelCreated:"Partida Guardada.",
            modelUpdated:"Partida Actualizada.",
            modelError:"Error Salvando."
        };
        this.Auth = new AuthService();
        this.ItemApiClient = new ItemApiClient();
        this.Validator = new FieldValidator(ItemFieldValidator);
        this.onInputChange = this.onInputChange.bind(this);
        this.handleCurrencyChange = this.handleCurrencyChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onItemChange = this.onItemChange.bind(this);
        this.onMultiItemChange = this.onMultiItemChange.bind(this);
        this.editPromise = this.editPromise.bind(this);

        this.ItemApiClient.GetListForItems().then(response => {
            let items = response.data.map(x => {
                return {
                    value: x.itemId,
                    label: "[" + x.code + "] " + x.name,
                    parentItemId: x.parentItemId
                }
            });
            this.setState({itemsData: items});
        }).then(() => {
            if (this.props.location.state !== undefined && this.props.location.state?.id) {
                this.ItemApiClient.GetById(this.props.location.state.id).then(response => {
                    this.editPromise(response);
                });
            }
            else {
                this.setState({
                    loading: false
                });
            }
        });
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if (!nextState.loading && CyaHelper.ObjectCompare(nextState.data, this.state.data))
            this.Validator.initValidation(nextState.data);

        return true;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!this.state.loading){
            let isFormDirty = !CyaHelper.ObjectCompare(this.state.initialData, this.state.data);
            this.context.toggleExitConfirmation(isFormDirty);
        }
    }

    editPromise(response) {
        let selectedItem = this.state.itemsData.find(x => x.value === response.parentItemId);
        this.setState({
            initialData: JSON.parse(JSON.stringify(response)), //copy data variable
            data: response,
            title: "Editar Partida No. " + response.code,
            loading: false,
            selectedItem: selectedItem,
            itemDetail: typeof(response.itemDetail) !== "undefined" ?
                response.itemDetail.map(x => {
                    return {
                        value: x.description,
                        label: x.description
                    }
                }) : [],
            itemDetailOptions: typeof(response.itemDetail) !== "undefined" ?
                response.itemDetail.map(x => {
                    return {
                        value: x.description,
                        label: x.description
                    }
                }) : []
        });
    }

    onInputChange(event) {
        let aux = this.state.data;
        aux[event.target.name] = event.target.value;
        this.setState({data: aux});
    }

    handleCurrencyChange(event, maskedvalue, floatvalue) {
        let aux = this.state.data;
        aux[event.target.name] = floatvalue;
        aux.total = aux.subtotal + aux.itbis;
        this.setState({data: aux});
    }

    onItemChange(item) {
        let aux = this.state.data;
        aux.parentItemId = item === null ? 0 : item.value;
        aux.isGrandson = !(typeof(item) === "undefined" ||
            item === null ||
            item.parentItemId === 0 ||
            this.state.itemsData
                .filter(x => x.parentItemId === 0)
                .find(x => x.itemId === item.parentItemId));

        if(aux.isGrandson && aux.subtotal === 0)
            this.Validator.changeValidForField("subtotal", false);

        this.setState({data: aux, selectedItem: item});
    }

    onMultiItemChange(item) {
        let aux = this.state.data;
        aux.itemDetail = item.map(x => {
            return {
                description: x.value
            }});
        this.setState({data: aux, itemDetail: item});
    }

    onSubmit(event) {
        event.preventDefault();
        this.setState({loading: true});

        if (this.state.data.itemId > 0) {
            this.ItemApiClient.Update(this.state.data.itemId, this.state.data)
                .then(response => {
                    this.editPromise(response);
                    toast.success(this.state.modelUpdated);
                })
                .catch(err => {
                    this.setState({loading: false});
                    toast.error(this.state.modelError);
                });
        }
        else {
            this.ItemApiClient.Create(this.state.data)
                .then(response => {
                    toast.success(this.state.modelCreated);
                    this.context.toggleExitConfirmation(false);
                    this.props.history.push({pathname: '/refresh/items/new'});
                })
                .catch(err => {
                    this.setState({loading: false});
                    toast.error(this.state.modelError);
                });
        }
        window.scrollTo(0, 0);
    }

    render() {
        return <div>
            <Card>
                <CardTitle className="bg-light border-bottom p-3 mb-0">
                    <i className="fas fa-file-alt mr-2"/>{this.state.title}
                </CardTitle>
                <CardBody>
                    <LoadingPage color={"3e5569"} loading={this.state.loading}
                                 type={"spin"}/>
                    <Row>
                        <Col md="12">
                            <Form>
                                <Row>
                                    <Col md="4">
                                        <InputForm
                                            id_label={"code"}
                                            label={"Código:"}
                                            placeholder={""}
                                            type={"text"}
                                            value={this.state.data.code}
                                            onInputChange={this.onInputChange}
                                        />
                                        {this.Validator.showErrors("code")}
                                    </Col>
                                    <Col md="8">
                                        <InputForm
                                            id_label={"name"}
                                            label={"Nombre:"}
                                            placeholder={""}
                                            type={"text"}
                                            value={this.state.data.name}
                                            onInputChange={this.onInputChange}
                                        />
                                        {this.Validator.showErrors("name")}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="12">
                                        <FormGroup>
                                            <Label htmlFor={"itemId"}>Padre: </Label>
                                            <Select options={this.state.itemsData}
                                                    id={"itemId"}
                                                    name={"itemId"}
                                                    value={this.state.selectedItem}
                                                    isLoading={this.state.loading}
                                                    placeholder={"Seleccione partida padre"}
                                                    isClearable
                                                    onChange={this.onItemChange}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                {   typeof(this.state.selectedItem) === "undefined" ||
                                    this.state.selectedItem === null ||
                                    this.state.selectedItem.parentItemId === 0 ||
                                    this.state.itemsData
                                        .filter(x => x.parentItemId === 0)
                                        .find(x => x.itemId === this.state.selectedItem.parentItemId)
                                 ? null :
                                <div>
                                    <Row>
                                        <Col md="12">
                                            <FormGroup>
                                                <Label htmlFor={"itemDetail"}>Detalles: </Label>
                                                <CreatableSelect value={this.state.itemDetail}
                                                                 options={this.state.itemDetailOptions}
                                                                 id={"itemDetail"}
                                                                 name={"itemDetail"}
                                                                 isLoading={this.state.loading}
                                                                 isClearable
                                                                 isMulti
                                                                 placeholder={"Digite detalles de la partida"}
                                                                 onChange={this.onMultiItemChange} />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="4">
                                            <InputCurrency
                                                id_label={"subtotal"}
                                                label={"Subtotal:"}
                                                placeholder={""}
                                                value={this.state.data.subtotal}
                                                onInputChange={this.handleCurrencyChange}
                                            />
                                            {this.Validator.showErrors("subtotal")}
                                        </Col>
                                        <Col md="4">
                                            <InputCurrency
                                                id_label={"itbis"}
                                                label={"ITBIS:"}
                                                placeholder={""}
                                                value={this.state.data.itbis}
                                                onInputChange={this.handleCurrencyChange}
                                            />
                                        </Col>
                                        <Col md="4">
                                            <InputCurrency
                                                id_label={"total"}
                                                label={"Total:"}
                                                placeholder={""}
                                                value={this.state.data.total}
                                                readOnly={true}
                                            />
                                        </Col>
                                    </Row>
                                </div>}
                                <hr/>
                                <Row>
                                    <Col md={2}>
                                        <input type="submit"
                                               disabled={!(this.Validator.validForm() && this.Auth.GetPathPermission(this.props.location.pathname,true))}
                                               className={!(this.Validator.validForm()&& this.Auth.GetPathPermission(this.props.location.pathname,true)) ? 'btn btn-primary disabled' : 'btn btn-primary'}
                                               value="Guardar"
                                               onClick={this.onSubmit}/>
                                    </Col>
                                </Row>
                            </Form>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </div>;
    }
}

CreateItem.contextType = CyaContext;
