import React from "react";
import "react-table/react-table.css";
import {
    Card,
    CardBody,
    CardTitle,
    Col,
    Form,
    FormGroup,
    Label,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane,
} from "reactstrap";
import { LoadingPage } from "../../components/custom/views/LoadingPage";
import { InputForm } from "../../components/custom/inputs/InputForm";
import Datetime from "react-datetime";
import {
    DateFormat,
    ProducerDocTypeACP,
    ProducerDocTypeCommRegister,
    ProducerDocTypeDGII,
    ProducerDocTypeRNC,
} from "../../const/api_consts";
import ProjectApiClient from "../../utils/api-client/ProjectApiClient";
import AuthService from "../../utils/AuthService";
import { ProducerModel } from "../../models/ProducerModel";
import { InputSelect } from "../../components/custom/inputs/InputSelect";
import PersonApiClient from "../../utils/api-client/PersonApiClient";
import MaskedInput from "react-text-mask";
import CyaHelper from "../../utils/CyaHelper";
import { InputImage } from "../../components/custom/inputs/InputImage";
import ProducerApiClient from "../../utils/api-client/ProducerApiClient";
import { toast } from "react-toastify";
import { ImageModel } from "../../models/ImageModel";
import FieldValidator from "../../utils/field-validators/FieldValidator";
import ProducerFieldValidator from "../../utils/field-validators/ProducerFieldValidator";
import { ProducerRepresentativeModel } from "../../models/ProducerRepresentativeModel";
import classnames from "classnames";
import { ProducerDocumentModel } from "../../models/ProducerDocumentModel";
import Modal from "react-modal";
import { InputSelectForm } from "../../components/custom/inputs/InputSelectForm";
import { SimpleTable } from "../../components/custom/tables/SimpleTable";
import { CyaContext } from "../../utils/CyaContext";

export default class CreateProducer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: new ProducerModel(),
            initialData: new ProducerModel(),
            dataRepresentative: new ProducerRepresentativeModel(),
            loading: true,
            options: { gender: [], city: [], idType: [] },
            representativesData: [],
            civilStatusRepresentativeData: [],
            prefixRepresentativeData: [],
            representativeMask: [],
            mask: [],
            activeTab: "1",
            selected: null,
            selectedRowId: -1,
            selectedRowData: {},
            modalRepresentatives: false,
            title: "Crear Productora",
            modelCreated: "Productora Guardado.",
            modelUpdated: "Productora Actualizado.",
            modelError: "Error en Productora.",
            modalRepresentativesTitle: "Crear Representante",
        };
        this.Auth = new AuthService();
        this.Validator = new FieldValidator(ProducerFieldValidator);
        this.ProjectApiClient = new ProjectApiClient();
        this.PersonApiClient = new PersonApiClient();
        this.ProducerApiClient = new ProducerApiClient();

        this.editPromise = this.editPromise.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.validSaveButton = this.validSaveButton.bind(this);
        this.tProps = this.tProps.bind(this);
        this.onDateChange = this.onDateChange.bind(this);
        this.submitRepresentative = this.submitRepresentative.bind(this);
        this.validEditButton = this.validEditButton.bind(this);
        this.addRepresentativesModal = this.addRepresentativesModal.bind(this);
        this.getValueFromDocument = this.getValueFromDocument.bind(this);
        this.deleteRepresentativeRow = this.deleteRepresentativeRow.bind(this);
        this.changeRepresentativeGenderOptions =
            this.changeRepresentativeGenderOptions.bind(this);

        this.closeRepresentativesModal =
            this.closeRepresentativesModal.bind(this);
        this.openRepresentativesModal =
            this.openRepresentativesModal.bind(this);
        this.changeRepresentativeMask =
            this.changeRepresentativeMask.bind(this);
        this.onRepresentativeInputChange =
            this.onRepresentativeInputChange.bind(this);
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if (
            !nextState.loading &&
            CyaHelper.ObjectCompare(nextState.data, this.state.data)
        )
            this.Validator.initValidation(nextState.data);

        return true;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!this.state.loading) {
            let isFormDirty = !CyaHelper.ObjectCompare(
                this.state.initialData,
                this.state.data
            );
            this.context.toggleExitConfirmation(isFormDirty);
        }
    }

    componentDidMount() {
        this.PersonApiClient.Options()
            .then((response) => {
                this.setState({
                    options: response,
                    cityData: response.city,
                });
            })
            .then(() => {
                if (
                    this.props.location.state !== undefined &&
                    this.props.location.state?.id
                ) {
                    this.ProducerApiClient.GetById(
                        this.props.location.state.id
                    ).then((response) => {
                        this.editPromise(response);
                    });
                } else {
                    this.setState({ loading: false });
                }
            });
    }

    toggle = (tab) => {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab,
            });
        }
    };

    closeRepresentativesModal() {
        if (this.state.modalRepresentatives)
            this.setState({
                modalRepresentatives: false,
                selected: null,
                selectedRowId: -1,
                selectedRowData: {},
                dataRepresentative: new ProducerRepresentativeModel(),
            });
    }

    addRepresentativesModal() {
        this.changeRepresentativeMask(1);
        this.changeRepresentativeGenderOptions(1);
        this.setState({
            dataRepresentative: new ProducerRepresentativeModel(),
            selected: null,
            selectedRowId: -1,
            selectedRowData: {},
            modalRepresentatives: true,
            modalRepresentativesTitle: "Crear Representante",
        });
    }

    openRepresentativesModal() {
        if (!this.state.modalRepresentatives && this.state.selectedRowId > -1) {
            this.changeRepresentativeMask(this.state.selectedRowData.idtypeId);
            this.changeRepresentativeGenderOptions(
                this.state.selectedRowData.genderId
            );
            this.setState({
                dataRepresentative: this.state.selectedRowData,
                modalRepresentatives: true,
                modalRepresentativesTitle: "Editar Representante",
            });
        }
    }

    tProps(state, rowInfo) {
        let self = this;
        if (rowInfo && rowInfo.row) {
            return {
                onClick: () => {
                    this.setState({
                        selected: rowInfo.index,
                        selectedRowId: rowInfo.index,
                        selectedRowData:
                            this.state.data.producerRepresentative[
                                rowInfo.index
                            ],
                    });
                },
                onDoubleClick: () => {
                    self.openRepresentativesModal();
                },
                style: {
                    background:
                        rowInfo.index === this.state.selected
                            ? "#00AFEC"
                            : rowInfo.index % 2 === 0
                            ? "#DDDDDD"
                            : "#FFFFFF",
                    color:
                        rowInfo.index === this.state.selected
                            ? "white"
                            : "black",
                },
            };
        }
        return {};
    }

    changeRepresentativeMask(value) {
        let mask = [];
        switch (Number(value)) {
            case 1:
                mask = [
                    /\d/,
                    /\d/,
                    /\d/,
                    "-",
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                    "-",
                    /\d/,
                ];
                break;
            default:
                mask = [];
                break;
        }
        this.setState({
            representativeMask: mask,
        });
    }

    editPromise(response) {
        if (
            response.producerDocument != null &&
            response.producerDocument.length > 0
        ) {
            response.producerDocument.map((x) => {
                if (x.image === null) x.image = new ImageModel();
                return true;
            });
        } else {
            response.producerDocument = [];
            response.producerDocument.push(
                new ProducerDocumentModel(ProducerDocTypeRNC),
                new ProducerDocumentModel(ProducerDocTypeACP),
                new ProducerDocumentModel(ProducerDocTypeCommRegister),
                new ProducerDocumentModel(ProducerDocTypeDGII)
            );
            if (response.rnc) {
                response.producerDocument.find(
                    (x) => x.producerDocumentTypeId === ProducerDocTypeRNC
                ).value = response.rnc;
            }
        }

        if (!response.rnc) {
            response.rnc = response.producerDocument.find(
                (x) => x.producerDocumentTypeId === ProducerDocTypeRNC
            ).value;
        }

        if (response.producerRepresentative != null) {
            response.producerRepresentative.map((x) => {
                x.idtypeId = 1;
                if (x.image === null) x.image = new ImageModel();
                return true;
            });
        } else {
            response.producerRepresentative = [];
        }

        this.setState({
            initialData: Object.assign({}, response), //copy data variable
            data: response,
            title: "Editar Productora ",
            loading: false,
        });
    }

    changeRepresentativeGenderOptions(genderId) {
        let isMale = Number(genderId) === 1;
        let prefixGender = this.state.options.prefix.filter((x) => {
            return x.isMale === isMale;
        });
        let civilStatusGender = this.state.options.civilStatus.filter((x) => {
            return x.isMale === isMale;
        });
        this.setState({
            prefixRepresentativeData: prefixGender,
            civilStatusRepresentativeData: civilStatusGender,
        });
    }

    onDateChange(moment, dateName, type = -1) {
        let aux = this.state.data;
        if (type !== -1) {
            aux.producerDocument.find((x) => x.producerDocumentTypeId === type)[
                dateName
            ] = moment.format("YYYY-MM-DD");
        } else {
            aux[dateName] = moment.format("YYYY-MM-DD");
        }
        this.setState({ data: aux });
    }

    onInputChange(event, isProducerDocument = false, type = 1, valueName = "") {
        let aux = this.state.data;

        if (isProducerDocument)
            aux.producerDocument.find((x) => x.producerDocumentTypeId === type)[
                valueName
            ] = event.target.value;
        else aux[event.target.name] = event.target.value;

        if (
            isProducerDocument &&
            type === ProducerDocTypeRNC &&
            valueName === "value"
        ) {
            aux.rnc = event.target.value;
        }

        this.setState({ data: aux });
    }

    getValueFromDocument(type, value) {
        let res = "";
        let aux = this.state.data.producerDocument.find(
            (x) => x.producerDocumentTypeId === type
        );
        if (aux !== undefined) {
            res = aux[value];
        } else {
            if (value === "expires" || value === "issuedAt") {
                res = new Date();
            }
            if (value === "image") {
                res = new ImageModel();
            }
        }
        return res;
    }

    onRepresentativeInputChange(event) {
        if (
            !this.state.loading &&
            event.target.name === "genderId" &&
            event.target.value !== this.state.dataRepresentative.genderId
        ) {
            this.changeRepresentativeGenderOptions(event.target.value);
        }
        if (event.target.name === "idtypeId") {
            this.changeRepresentativeMask(event.target.value);
        }
        let aux = this.state.dataRepresentative;
        aux[event.target.name] = event.target.value;

        this.setState({ dataRepresentative: aux });
    }

    submitRepresentative(event) {
        event.preventDefault();

        if (this.state.selectedRowId > -1) {
            // let data = this.state.data;
        } else {
            let data = this.state.data;
            data.producerRepresentative.push(this.state.dataRepresentative);
            this.setState({ data });
        }

        this.closeRepresentativesModal();
        window.scrollTo(0, 0);
    }

    deleteRepresentativeRow(event) {
        event.preventDefault();
        let data = this.state.data;
        data.producerRepresentative.splice(this.state.selected, 1);
        this.setState({
            data: data,
            selected: null,
            selectedRowId: -1,
            selectedRowData: {},
        });
        toast.success("Representante Eliminado");
        window.scrollTo(0, 0);
    }

    validSaveButton() {
        return this.Auth.GetPathPermission(this.props.location.pathname, true);
    }

    validEditButton() {
        return (
            !this.state.modalRepresentatives && this.state.selectedRowId > -1
        );
    }

    onSubmit(event) {
        event.preventDefault();
        this.setState({ loading: true });
        let data = this.state.data;

        data.producerDocument = data.producerDocument.map((images) => {
            if (images.image.fileContent.length === 0) {
                return { ...images, image: null, imageId: null };
            } else {
                return {
                    ...images,
                    image: { ...images.image, imageId: images.imageId },
                };
            }
        });

        data.producerRepresentative = data.producerRepresentative.map(
            (images) => {
                if (typeof images.image == "undefined") {
                    return {
                        ...images,
                        image: null,
                        idNumberImageId: null,
                    };
                }
                if (images.image.fileContent.length === 0) {
                    return {
                        ...images,
                        image: null,
                        idNumberImageId: null,
                    };
                } else {
                    return {
                        ...images,
                        image: {
                            ...images.image,
                            imageId: images.idNumberImageId,
                        },
                    };
                }
            }
        );

        data.cityId = Number(this.state.dataRepresentative.cityId);

        if (this.state.data.producerId > 0) {
            this.ProducerApiClient.Update(this.state.data.producerId, data)
                .then((response) => {
                    if (
                        response.response !== undefined &&
                        response.response.data === "Error"
                    ) {
                        this.setState({ loading: false });
                        toast.error(this.state.modelError);
                    } else {
                        this.editPromise(response);
                        toast.success(this.state.modelUpdated);
                    }
                })
                .catch((err) => {
                    this.setState({ loading: false });
                    toast.error(this.state.modelError);
                });
        } else {
            this.ProducerApiClient.Create(data)
                .then((response) => {
                    if (
                        response.response !== undefined &&
                        response.response.data === "Error"
                    ) {
                        this.setState({ loading: false });
                        toast.error(this.state.modelError);
                    } else {
                        this.editPromise(response);
                        toast.success(this.state.modelUpdated);
                    }
                })
                .catch(() => {
                    this.setState({ loading: false });
                    toast.error(this.state.modelError);
                });
        }
        window.scrollTo(0, 0);
    }

    render() {
        let columns = [
            // {Header: "ID", accessor: "investorRepresentativeId", show: false},
            { Header: "Nombre", accessor: "name" },
            { Header: "Identificación", accessor: "idNumber" },
            {
                Header: "Ciudad",
                accessor: "cityId",
                Cell: (props) => {
                    if (props.value > 0) {
                        let option = this.state.options.city;
                        let value = option.find(
                            (city) =>
                                city.cityId.toString() ===
                                props.value.toString()
                        );
                        return value.name;
                    } else {
                        return "";
                    }
                },
            },
            { Header: "Cargo", accessor: "occupation" },
        ];
        const customStyles = {
            content: {
                top: "50%",
                left: "50%",
                right: "auto",
                bottom: "auto",
                marginRight: "-50%",
                transform: "translate(-50%, -50%)",
                width: "65%",
            },
        };
        Modal.setAppElement("body");
        return (
            <div className="auth-wrapper d-flex">
                <Modal
                    isOpen={this.state.modalRepresentatives}
                    onRequestClose={this.closeRepresentativesModal}
                    style={customStyles}
                >
                    <Row>
                        <Col md={10}>
                            <h4>{this.state.modalRepresentativesTitle}</h4>
                        </Col>
                        <Col md={2} style={{ textAlign: "right" }}>
                            <button
                                className={"btn btn-danger"}
                                style={{ textAlign: "right" }}
                                onClick={this.closeRepresentativesModal}
                            >
                                X
                            </button>
                        </Col>
                    </Row>

                    <hr />

                    <Row>
                        <Col md="12">
                            <Form>
                                <Row>
                                    <Col md="6">
                                        <InputForm
                                            id_label={"name"}
                                            label={"Nombre:"}
                                            placeholder={""}
                                            type={"text"}
                                            value={
                                                this.state.dataRepresentative
                                                    .name
                                            }
                                            onInputChange={
                                                this.onRepresentativeInputChange
                                            }
                                        />
                                        {this.state.dataRepresentative.name
                                            ?.length > 0 ? (
                                            ""
                                        ) : (
                                            <div className="error mb-2">
                                                * Campo en blanco
                                            </div>
                                        )}
                                    </Col>
                                    <Col md="3">
                                        <InputSelect
                                            id_label={"civilStatusId"}
                                            data={
                                                this.state
                                                    .civilStatusRepresentativeData
                                            }
                                            label={"Estado Civil:"}
                                            dataDescription={["name"]}
                                            dataValue={"civilStatusId"}
                                            selectedValue={Number(
                                                this.state.dataRepresentative
                                                    .civilStatusId
                                            )}
                                            onInputChange={
                                                this.onRepresentativeInputChange
                                            }
                                        />
                                    </Col>
                                    <Col md="3">
                                        <Row>
                                            <Col md="5">
                                                <InputSelect
                                                    id_label={"prefixId"}
                                                    data={
                                                        this.state
                                                            .prefixRepresentativeData
                                                    }
                                                    label={"Prefijo:"}
                                                    dataDescription={[
                                                        "description",
                                                    ]}
                                                    dataValue={"prefixId"}
                                                    selectedValue={Number(
                                                        this.state
                                                            .dataRepresentative
                                                            .prefixId
                                                    )}
                                                    onInputChange={
                                                        this
                                                            .onRepresentativeInputChange
                                                    }
                                                />
                                            </Col>
                                            <Col md="7">
                                                <InputSelect
                                                    id_label={"genderId"}
                                                    data={
                                                        this.state.options
                                                            .gender
                                                    }
                                                    label={"Sexo:"}
                                                    dataDescription={["name"]}
                                                    dataValue={"genderId"}
                                                    selectedValue={Number(
                                                        this.state
                                                            .dataRepresentative
                                                            .genderId
                                                    )}
                                                    onInputChange={
                                                        this
                                                            .onRepresentativeInputChange
                                                    }
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col md={12}>
                                        <InputForm
                                            id_label={"address"}
                                            label={"Dirección:"}
                                            placeholder={""}
                                            type={"text"}
                                            value={
                                                this.state.dataRepresentative
                                                    .address
                                            }
                                            onInputChange={
                                                this.onRepresentativeInputChange
                                            }
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="3">
                                        <InputSelect
                                            id_label={"cityId"}
                                            data={this.state.options.city}
                                            label={"Ciudad:"}
                                            dataDescription={["name"]}
                                            dataValue={"cityId"}
                                            selectedValue={Number(
                                                this.state.dataRepresentative
                                                    .cityId
                                            )}
                                            onInputChange={
                                                this.onRepresentativeInputChange
                                            }
                                        />
                                    </Col>
                                    <Col md="3">
                                        <InputForm
                                            id_label={"nationality"}
                                            label={"Nacionalidad:"}
                                            placeholder={""}
                                            type={"text"}
                                            value={
                                                this.state.dataRepresentative
                                                    .nationality
                                            }
                                            onInputChange={
                                                this.onRepresentativeInputChange
                                            }
                                        />
                                    </Col>
                                    <Col md="6">
                                        <InputForm
                                            id_label={"occupation"}
                                            label={"Cargo:"}
                                            placeholder={""}
                                            type={"text"}
                                            value={
                                                this.state.dataRepresentative
                                                    .occupation
                                            }
                                            onInputChange={
                                                this.onRepresentativeInputChange
                                            }
                                        />
                                        {this.state.dataRepresentative
                                            .occupation?.length > 0 ? (
                                            ""
                                        ) : (
                                            <div className="error mb-2">
                                                * Campo en blanco
                                            </div>
                                        )}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12}>
                                        <Row>
                                            <Col
                                                style={{ marginTop: "1rem" }}
                                                md={12}
                                            >
                                                <h5>Cedula/Pasaporte</h5>
                                                <hr
                                                    style={{
                                                        marginTop: "0rem",
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="6">
                                                <InputSelectForm
                                                    label={"ID:"}
                                                    idSelect={"idtypeId"}
                                                    idForm={"idNumber"}
                                                    formValue={
                                                        this.state
                                                            .dataRepresentative
                                                            .idNumber
                                                    }
                                                    data={
                                                        this.state.options
                                                            .idType
                                                    }
                                                    dataValue={"idTypeId"}
                                                    dataDescription={
                                                        "description"
                                                    }
                                                    formType={"text"}
                                                    placeholder={""}
                                                    selectedValue={Number(
                                                        this.state
                                                            .dataRepresentative
                                                            .idtypeId
                                                    )}
                                                    mask={
                                                        this.state
                                                            .representativeMask
                                                    }
                                                    showMask={
                                                        this.state.showMask
                                                    }
                                                    onInputChange={
                                                        this
                                                            .onRepresentativeInputChange
                                                    }
                                                />
                                                {Number(
                                                    this.state
                                                        .dataRepresentative
                                                        .idtypeId
                                                ) > 1 ||
                                                (Number(
                                                    this.state
                                                        .dataRepresentative
                                                        .idtypeId
                                                ) === 1 &&
                                                    this.state
                                                        .dataRepresentative
                                                        .idNumber?.length ===
                                                        13) ? (
                                                    ""
                                                ) : (
                                                    <div className="error mb-2">
                                                        * Faltan caracteres
                                                    </div>
                                                )}
                                                {this.state.dataRepresentative
                                                    .idNumber?.length > 0 ? (
                                                    ""
                                                ) : (
                                                    <div className="error mb-2">
                                                        * Campo en blanco
                                                    </div>
                                                )}
                                            </Col>
                                            <Col md="6">
                                                {CyaHelper.ImageExists(
                                                    this.state
                                                        .dataRepresentative
                                                        .image
                                                ) ? (
                                                    <InputImage
                                                        id_label={"image"}
                                                        width={"fit-content"}
                                                        label={"Copia de ID:"}
                                                        acceptPDF={true}
                                                        image={
                                                            this.state
                                                                .dataRepresentative
                                                                .image
                                                        }
                                                        hash={
                                                            this.state
                                                                .dataRepresentative
                                                                .image.hash
                                                        }
                                                        showViewButton={true}
                                                        showImageMagnify={false}
                                                    />
                                                ) : (
                                                    <div>
                                                        <InputImage
                                                            id_label={"image"}
                                                            width={
                                                                "fit-content"
                                                            }
                                                            label={
                                                                "Copia de ID:"
                                                            }
                                                            acceptPDF={true}
                                                            image={
                                                                this.state
                                                                    .dataRepresentative
                                                                    .image
                                                            }
                                                            showViewButton={
                                                                true
                                                            }
                                                            showImageMagnify={
                                                                false
                                                            }
                                                        />
                                                    </div>
                                                )}
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <hr />

                                <Row>
                                    <Col md="6">
                                        <input
                                            type="submit"
                                            disabled={
                                                !(
                                                    this.validSaveButton() &&
                                                    this.state
                                                        .dataRepresentative
                                                        .occupation?.length >
                                                        0 &&
                                                    this.state
                                                        .dataRepresentative.name
                                                        ?.length > 0 &&
                                                    ((Number(
                                                        this.state
                                                            .dataRepresentative
                                                            .idtypeId
                                                    ) === 1 &&
                                                        this.state
                                                            .dataRepresentative
                                                            .idNumber?.length ===
                                                            13) ||
                                                        (Number(
                                                            this.state
                                                                .dataRepresentative
                                                                .idtypeId
                                                        ) > 1 &&
                                                            this.state
                                                                .dataRepresentative
                                                                .idNumber
                                                                ?.length > 0))
                                                )
                                            }
                                            className={
                                                !(
                                                    this.validSaveButton() &&
                                                    this.state
                                                        .dataRepresentative
                                                        .occupation?.length >
                                                        0 &&
                                                    this.state
                                                        .dataRepresentative.name
                                                        ?.length > 0 &&
                                                    ((Number(
                                                        this.state
                                                            .dataRepresentative
                                                            .idtypeId
                                                    ) === 1 &&
                                                        this.state
                                                            .dataRepresentative
                                                            .idNumber?.length ===
                                                            13) ||
                                                        (Number(
                                                            this.state
                                                                .dataRepresentative
                                                                .idtypeId
                                                        ) > 1 &&
                                                            this.state
                                                                .dataRepresentative
                                                                .idNumber
                                                                ?.length > 0))
                                                )
                                                    ? "btn btn-success disabled"
                                                    : "btn btn-success"
                                            }
                                            value="Añadir"
                                            onClick={this.submitRepresentative}
                                        />
                                    </Col>
                                </Row>
                            </Form>
                        </Col>
                    </Row>
                </Modal>

                <div className="container">
                    <Card>
                        <CardTitle className="mb-0 p-3 border-bottom bg-light">
                            <i className="mdi mdi-border-right mr-2" />
                            {this.state.title}
                        </CardTitle>
                        <CardBody className="border-top">
                            <LoadingPage
                                color={"3e5569"}
                                loading={this.state.loading}
                                type={"spin"}
                            />

                            <Nav tabs>
                                <NavItem>
                                    <NavLink
                                        className={classnames({
                                            active:
                                                this.state.activeTab === "1",
                                        })}
                                        onClick={() => {
                                            this.toggle("1");
                                        }}
                                    >
                                        <Label>General</Label>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        className={classnames({
                                            active:
                                                this.state.activeTab === "2",
                                        })}
                                        onClick={() => {
                                            this.toggle("2");
                                        }}
                                    >
                                        <Label>Representantes</Label>
                                    </NavLink>
                                </NavItem>
                            </Nav>

                            <TabContent activeTab={this.state.activeTab}>
                                <TabPane tabId="1">
                                    <Row>
                                        <Col md="12">
                                            <Card body>
                                                <Form>
                                                    <Row>
                                                        <Col md="12">
                                                            <InputForm
                                                                id_label={
                                                                    "name"
                                                                }
                                                                label={
                                                                    "Nombre:"
                                                                }
                                                                placeholder={""}
                                                                type={"text"}
                                                                value={
                                                                    this.state
                                                                        .data
                                                                        .name
                                                                }
                                                                onInputChange={
                                                                    this
                                                                        .onInputChange
                                                                }
                                                            />
                                                            {this.Validator.showErrors(
                                                                "name"
                                                            )}
                                                        </Col>
                                                    </Row>

                                                    <Row>
                                                        <Col md="9">
                                                            <InputForm
                                                                id_label={
                                                                    "address"
                                                                }
                                                                label={
                                                                    "Dirección:"
                                                                }
                                                                placeholder={""}
                                                                type={"text"}
                                                                value={
                                                                    this.state
                                                                        .data
                                                                        .address
                                                                }
                                                                onInputChange={
                                                                    this
                                                                        .onInputChange
                                                                }
                                                            />
                                                            {this.Validator.showErrors(
                                                                "address"
                                                            )}
                                                        </Col>
                                                        <Col md="3">
                                                            <InputSelect
                                                                id_label={
                                                                    "cityId"
                                                                }
                                                                data={
                                                                    this.state
                                                                        .options
                                                                        .city
                                                                }
                                                                label={
                                                                    "Ciudad:"
                                                                }
                                                                dataDescription={[
                                                                    "name",
                                                                ]}
                                                                dataValue={
                                                                    "cityId"
                                                                }
                                                                selectedValue={Number(
                                                                    this.state
                                                                        .data
                                                                        .cityId
                                                                )}
                                                                onInputChange={
                                                                    this
                                                                        .onInputChange
                                                                }
                                                            />
                                                        </Col>
                                                    </Row>

                                                    <Row>
                                                        <Col md={12}>
                                                            <Row>
                                                                <Col
                                                                    style={{
                                                                        marginTop:
                                                                            "1rem",
                                                                    }}
                                                                    md={12}
                                                                >
                                                                    <h5>RNC</h5>
                                                                    <hr
                                                                        style={{
                                                                            marginTop:
                                                                                "0rem",
                                                                        }}
                                                                    />
                                                                </Col>
                                                            </Row>

                                                            <Row>
                                                                <Col md={12}>
                                                                    <Row>
                                                                        <Col md="3">
                                                                            <FormGroup>
                                                                                <Label>
                                                                                    Número
                                                                                </Label>
                                                                                <MaskedInput
                                                                                    className="form-control"
                                                                                    showMask={
                                                                                        true
                                                                                    }
                                                                                    mask={[
                                                                                        /\d/,
                                                                                        "-",
                                                                                        /\d/,
                                                                                        /\d/,
                                                                                        "-",
                                                                                        /\d/,
                                                                                        /\d/,
                                                                                        /\d/,
                                                                                        /\d/,
                                                                                        /\d/,
                                                                                        "-",
                                                                                        /\d/,
                                                                                    ]}
                                                                                    type={
                                                                                        "text"
                                                                                    }
                                                                                    name={
                                                                                        "rncNumber"
                                                                                    }
                                                                                    id={
                                                                                        "rncNumber"
                                                                                    }
                                                                                    onChange={(
                                                                                        event
                                                                                    ) =>
                                                                                        this.onInputChange(
                                                                                            event,
                                                                                            true,
                                                                                            ProducerDocTypeRNC,
                                                                                            "value"
                                                                                        )
                                                                                    }
                                                                                    guide={
                                                                                        false
                                                                                    }
                                                                                    value={this.getValueFromDocument(
                                                                                        ProducerDocTypeRNC,
                                                                                        "value"
                                                                                    )}
                                                                                />
                                                                                {this.Validator.showErrors(
                                                                                    "rnc"
                                                                                )}
                                                                            </FormGroup>
                                                                        </Col>
                                                                        <Col
                                                                            md={
                                                                                2
                                                                            }
                                                                        >
                                                                            <Label
                                                                                htmlFor={
                                                                                    "rncIssuedAt"
                                                                                }
                                                                            >
                                                                                Expedición:{" "}
                                                                            </Label>
                                                                            <Datetime
                                                                                inputProps={{
                                                                                    readOnly: true,
                                                                                }}
                                                                                locale={
                                                                                    "es-do"
                                                                                }
                                                                                utc={
                                                                                    true
                                                                                }
                                                                                timeFormat={
                                                                                    false
                                                                                }
                                                                                defaultValue={
                                                                                    new Date()
                                                                                }
                                                                                dateFormat={
                                                                                    DateFormat
                                                                                }
                                                                                closeOnSelect={
                                                                                    true
                                                                                }
                                                                                value={
                                                                                    new Date(
                                                                                        this.getValueFromDocument(
                                                                                            ProducerDocTypeRNC,
                                                                                            "issuedAt"
                                                                                        )
                                                                                    )
                                                                                }
                                                                                onChange={(
                                                                                    moment
                                                                                ) =>
                                                                                    this.onDateChange(
                                                                                        moment,
                                                                                        "issuedAt",
                                                                                        ProducerDocTypeRNC
                                                                                    )
                                                                                }
                                                                            />
                                                                        </Col>
                                                                        <Col
                                                                            md={
                                                                                2
                                                                            }
                                                                        >
                                                                            <Label
                                                                                htmlFor={
                                                                                    "rncExpires"
                                                                                }
                                                                            >
                                                                                Vence:{" "}
                                                                            </Label>
                                                                            <Datetime
                                                                                inputProps={{
                                                                                    readOnly: true,
                                                                                }}
                                                                                locale={
                                                                                    "es-do"
                                                                                }
                                                                                utc={
                                                                                    true
                                                                                }
                                                                                timeFormat={
                                                                                    false
                                                                                }
                                                                                defaultValue={
                                                                                    new Date()
                                                                                }
                                                                                dateFormat={
                                                                                    DateFormat
                                                                                }
                                                                                closeOnSelect={
                                                                                    true
                                                                                }
                                                                                value={
                                                                                    new Date(
                                                                                        this.getValueFromDocument(
                                                                                            ProducerDocTypeRNC,
                                                                                            "expires"
                                                                                        )
                                                                                    )
                                                                                }
                                                                                onChange={(
                                                                                    moment
                                                                                ) =>
                                                                                    this.onDateChange(
                                                                                        moment,
                                                                                        "expires",
                                                                                        ProducerDocTypeRNC
                                                                                    )
                                                                                }
                                                                            />
                                                                        </Col>
                                                                        <Col
                                                                            md={
                                                                                5
                                                                            }
                                                                        >
                                                                            {CyaHelper.ImageExists(
                                                                                this.getValueFromDocument(
                                                                                    ProducerDocTypeRNC,
                                                                                    "image"
                                                                                )
                                                                            ) ? (
                                                                                <InputImage
                                                                                    id_label={
                                                                                        "rncImage"
                                                                                    }
                                                                                    width={
                                                                                        "fit-content"
                                                                                    }
                                                                                    label={
                                                                                        "Copia de RNC:"
                                                                                    }
                                                                                    acceptPDF={
                                                                                        true
                                                                                    }
                                                                                    image={this.getValueFromDocument(
                                                                                        ProducerDocTypeRNC,
                                                                                        "image"
                                                                                    )}
                                                                                    hash={
                                                                                        this.getValueFromDocument(
                                                                                            ProducerDocTypeRNC,
                                                                                            "image"
                                                                                        )
                                                                                            .hash
                                                                                    }
                                                                                    showViewButton={
                                                                                        true
                                                                                    }
                                                                                    showImageMagnify={
                                                                                        false
                                                                                    }
                                                                                />
                                                                            ) : (
                                                                                <div>
                                                                                    <InputImage
                                                                                        id_label={
                                                                                            "rncImage"
                                                                                        }
                                                                                        width={
                                                                                            "fit-content"
                                                                                        }
                                                                                        label={
                                                                                            "Copia de RNC:"
                                                                                        }
                                                                                        acceptPDF={
                                                                                            true
                                                                                        }
                                                                                        image={this.getValueFromDocument(
                                                                                            ProducerDocTypeRNC,
                                                                                            "image"
                                                                                        )}
                                                                                        showViewButton={
                                                                                            true
                                                                                        }
                                                                                        showImageMagnify={
                                                                                            false
                                                                                        }
                                                                                    />
                                                                                </div>
                                                                            )}
                                                                        </Col>
                                                                    </Row>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>

                                                    <Row>
                                                        <Col md={12}>
                                                            <Row>
                                                                <Col
                                                                    style={{
                                                                        marginTop:
                                                                            "1rem",
                                                                    }}
                                                                    md={12}
                                                                >
                                                                    <h5>
                                                                        Registro
                                                                        Mercantil
                                                                    </h5>
                                                                    <hr
                                                                        style={{
                                                                            marginTop:
                                                                                "0rem",
                                                                        }}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col md={12}>
                                                                    <Row>
                                                                        <Col
                                                                            md={
                                                                                3
                                                                            }
                                                                        >
                                                                            <InputForm
                                                                                id_label={
                                                                                    "commercialRegisterNumber"
                                                                                }
                                                                                label={
                                                                                    "Número:"
                                                                                }
                                                                                placeholder={
                                                                                    ""
                                                                                }
                                                                                type={
                                                                                    "text"
                                                                                }
                                                                                value={this.getValueFromDocument(
                                                                                    ProducerDocTypeCommRegister,
                                                                                    "value"
                                                                                )}
                                                                                onInputChange={(
                                                                                    event
                                                                                ) =>
                                                                                    this.onInputChange(
                                                                                        event,
                                                                                        true,
                                                                                        ProducerDocTypeCommRegister,
                                                                                        "value"
                                                                                    )
                                                                                }
                                                                            />
                                                                        </Col>
                                                                        <Col
                                                                            md={
                                                                                2
                                                                            }
                                                                        >
                                                                            <Label
                                                                                htmlFor={
                                                                                    "commercialRegisterExpeditionDate"
                                                                                }
                                                                            >
                                                                                Expedición:{" "}
                                                                            </Label>
                                                                            <Datetime
                                                                                inputProps={{
                                                                                    readOnly: true,
                                                                                }}
                                                                                locale={
                                                                                    "es-do"
                                                                                }
                                                                                utc={
                                                                                    true
                                                                                }
                                                                                timeFormat={
                                                                                    false
                                                                                }
                                                                                defaultValue={
                                                                                    new Date()
                                                                                }
                                                                                dateFormat={
                                                                                    DateFormat
                                                                                }
                                                                                closeOnSelect={
                                                                                    true
                                                                                }
                                                                                value={
                                                                                    new Date(
                                                                                        this.getValueFromDocument(
                                                                                            ProducerDocTypeCommRegister,
                                                                                            "issuedAt"
                                                                                        )
                                                                                    )
                                                                                }
                                                                                onChange={(
                                                                                    moment
                                                                                ) =>
                                                                                    this.onDateChange(
                                                                                        moment,
                                                                                        "issuedAt",
                                                                                        ProducerDocTypeCommRegister
                                                                                    )
                                                                                }
                                                                            />
                                                                        </Col>
                                                                        <Col
                                                                            md={
                                                                                2
                                                                            }
                                                                        >
                                                                            <Label
                                                                                htmlFor={
                                                                                    "commercialRegisterExpirationDate"
                                                                                }
                                                                            >
                                                                                Vence:{" "}
                                                                            </Label>
                                                                            <Datetime
                                                                                inputProps={{
                                                                                    readOnly: true,
                                                                                }}
                                                                                locale={
                                                                                    "es-do"
                                                                                }
                                                                                utc={
                                                                                    true
                                                                                }
                                                                                timeFormat={
                                                                                    false
                                                                                }
                                                                                defaultValue={
                                                                                    new Date()
                                                                                }
                                                                                dateFormat={
                                                                                    DateFormat
                                                                                }
                                                                                closeOnSelect={
                                                                                    true
                                                                                }
                                                                                value={
                                                                                    new Date(
                                                                                        this.getValueFromDocument(
                                                                                            ProducerDocTypeCommRegister,
                                                                                            "expires"
                                                                                        )
                                                                                    )
                                                                                }
                                                                                onChange={(
                                                                                    moment
                                                                                ) =>
                                                                                    this.onDateChange(
                                                                                        moment,
                                                                                        "expires",
                                                                                        ProducerDocTypeCommRegister
                                                                                    )
                                                                                }
                                                                            />
                                                                        </Col>

                                                                        <Col
                                                                            md={
                                                                                5
                                                                            }
                                                                        >
                                                                            {CyaHelper.ImageExists(
                                                                                this.getValueFromDocument(
                                                                                    ProducerDocTypeCommRegister,
                                                                                    "image"
                                                                                )
                                                                            ) ? (
                                                                                <InputImage
                                                                                    id_label={
                                                                                        "commercialRegisterImage"
                                                                                    }
                                                                                    width={
                                                                                        "fit-content"
                                                                                    }
                                                                                    label={
                                                                                        "Copia de Registro Mercantil:"
                                                                                    }
                                                                                    acceptPDF={
                                                                                        true
                                                                                    }
                                                                                    image={this.getValueFromDocument(
                                                                                        ProducerDocTypeCommRegister,
                                                                                        "image"
                                                                                    )}
                                                                                    hash={
                                                                                        this.getValueFromDocument(
                                                                                            ProducerDocTypeCommRegister,
                                                                                            "image"
                                                                                        )
                                                                                            .hash
                                                                                    }
                                                                                    showViewButton={
                                                                                        true
                                                                                    }
                                                                                    showImageMagnify={
                                                                                        false
                                                                                    }
                                                                                />
                                                                            ) : (
                                                                                <div>
                                                                                    <InputImage
                                                                                        id_label={
                                                                                            "commercialRegisterImage"
                                                                                        }
                                                                                        width={
                                                                                            "fit-content"
                                                                                        }
                                                                                        label={
                                                                                            "Copia de Registro Mercantil:"
                                                                                        }
                                                                                        acceptPDF={
                                                                                            true
                                                                                        }
                                                                                        image={this.getValueFromDocument(
                                                                                            ProducerDocTypeCommRegister,
                                                                                            "image"
                                                                                        )}
                                                                                        showViewButton={
                                                                                            true
                                                                                        }
                                                                                        showImageMagnify={
                                                                                            false
                                                                                        }
                                                                                    />
                                                                                </div>
                                                                            )}
                                                                        </Col>
                                                                    </Row>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>

                                                    <Row>
                                                        <Col md={12}>
                                                            <Row>
                                                                <Col
                                                                    style={{
                                                                        marginTop:
                                                                            "1rem",
                                                                    }}
                                                                    md={12}
                                                                >
                                                                    <h5>
                                                                       Registro Fiscal
                                                                    </h5>
                                                                    <hr
                                                                        style={{
                                                                            marginTop:
                                                                                "0rem",
                                                                        }}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col md={12}>
                                                                    <Row>
                                                                        <Col
                                                                            md={
                                                                                3
                                                                            }
                                                                        >
                                                                            <InputForm
                                                                                id_label={
                                                                                    "taxRecordNumber"
                                                                                }
                                                                                label={
                                                                                    "Número:"
                                                                                }
                                                                                placeholder={
                                                                                    ""
                                                                                }
                                                                                type={
                                                                                    "text"
                                                                                }
                                                                                value={this.state.data?.taxRecordNumber ?? ""}
                                                                                onInputChange={(
                                                                                    event
                                                                                ) =>
                                                                                    this.onInputChange(event)
                                                                                }
                                                                            />
                                                                        </Col>
                                                                        
                                                                        <Col
                                                                            md={
                                                                                4
                                                                            }
                                                                        >
                                                                            <Label>
                                                                                Vence:{" "}
                                                                            </Label>
                                                                            <Datetime
                                                                                inputProps={{
                                                                                    readOnly: true,
                                                                                }}
                                                                                locale={
                                                                                    "es-do"
                                                                                }
                                                                                utc={
                                                                                    true
                                                                                }
                                                                                timeFormat={
                                                                                    false
                                                                                }
                                                                                dateFormat={
                                                                                    DateFormat
                                                                                }
                                                                                closeOnSelect={true}
                                                                                value={this.state.data?.taxRecordExpirationDate ?? ""}
                                                                                onChange={(
                                                                                    moment
                                                                                ) =>
                                                                                    this.onDateChange(
                                                                                        moment,
                                                                                        "taxRecordExpirationDate"
                                                                                    )
                                                                                }
                                                                            />
                                                                        </Col>
                                                                    </Row>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>

                                                    <Row>
                                                        <Col md={12}>
                                                            <Row>
                                                                <Col
                                                                    style={{
                                                                        marginTop:
                                                                            "1rem",
                                                                    }}
                                                                    md={12}
                                                                >
                                                                    <h5>
                                                                        AC-P
                                                                    </h5>
                                                                    <hr
                                                                        style={{
                                                                            marginTop:
                                                                                "0rem",
                                                                        }}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col md={12}>
                                                                    <Row>
                                                                        <Col
                                                                            md={
                                                                                3
                                                                            }
                                                                        >
                                                                            <InputForm
                                                                                id_label={
                                                                                    "ACPNumber"
                                                                                }
                                                                                label={
                                                                                    "Número:"
                                                                                }
                                                                                placeholder={
                                                                                    ""
                                                                                }
                                                                                type={
                                                                                    "text"
                                                                                }
                                                                                value={this.getValueFromDocument(
                                                                                    ProducerDocTypeACP,
                                                                                    "value"
                                                                                )}
                                                                                onInputChange={(
                                                                                    event
                                                                                ) =>
                                                                                    this.onInputChange(
                                                                                        event,
                                                                                        true,
                                                                                        ProducerDocTypeACP,
                                                                                        "value"
                                                                                    )
                                                                                }
                                                                            />
                                                                        </Col>
                                                                        <Col
                                                                            md={
                                                                                2
                                                                            }
                                                                        >
                                                                            <Label
                                                                                htmlFor={
                                                                                    "ACPExpeditionDate"
                                                                                }
                                                                            >
                                                                                Expedición:{" "}
                                                                            </Label>
                                                                            <Datetime
                                                                                inputProps={{
                                                                                    readOnly: true,
                                                                                }}
                                                                                locale={
                                                                                    "es-do"
                                                                                }
                                                                                utc={
                                                                                    true
                                                                                }
                                                                                timeFormat={
                                                                                    false
                                                                                }
                                                                                defaultValue={
                                                                                    new Date()
                                                                                }
                                                                                dateFormat={
                                                                                    DateFormat
                                                                                }
                                                                                closeOnSelect={
                                                                                    true
                                                                                }
                                                                                value={
                                                                                    new Date(
                                                                                        this.getValueFromDocument(
                                                                                            ProducerDocTypeACP,
                                                                                            "issuedAt"
                                                                                        )
                                                                                    )
                                                                                }
                                                                                onChange={(
                                                                                    moment
                                                                                ) =>
                                                                                    this.onDateChange(
                                                                                        moment,
                                                                                        "issuedAt",
                                                                                        ProducerDocTypeACP
                                                                                    )
                                                                                }
                                                                            />
                                                                        </Col>
                                                                        <Col
                                                                            md={
                                                                                2
                                                                            }
                                                                        >
                                                                            <Label
                                                                                htmlFor={
                                                                                    "ACPExpirationDate"
                                                                                }
                                                                            >
                                                                                Vence:{" "}
                                                                            </Label>
                                                                            <Datetime
                                                                                inputProps={{
                                                                                    readOnly: true,
                                                                                }}
                                                                                locale={
                                                                                    "es-do"
                                                                                }
                                                                                utc={
                                                                                    true
                                                                                }
                                                                                timeFormat={
                                                                                    false
                                                                                }
                                                                                defaultValue={
                                                                                    new Date()
                                                                                }
                                                                                dateFormat={
                                                                                    DateFormat
                                                                                }
                                                                                closeOnSelect={
                                                                                    true
                                                                                }
                                                                                value={
                                                                                    new Date(
                                                                                        this.getValueFromDocument(
                                                                                            ProducerDocTypeACP,
                                                                                            "expires"
                                                                                        )
                                                                                    )
                                                                                }
                                                                                onChange={(
                                                                                    moment
                                                                                ) =>
                                                                                    this.onDateChange(
                                                                                        moment,
                                                                                        "expires",
                                                                                        ProducerDocTypeACP
                                                                                    )
                                                                                }
                                                                            />
                                                                        </Col>
                                                                        <Col
                                                                            md={
                                                                                5
                                                                            }
                                                                        >
                                                                            {CyaHelper.ImageExists(
                                                                                this.getValueFromDocument(
                                                                                    ProducerDocTypeACP,
                                                                                    "image"
                                                                                )
                                                                            ) ? (
                                                                                <InputImage
                                                                                    id_label={
                                                                                        "ACPImage"
                                                                                    }
                                                                                    width={
                                                                                        "fit-content"
                                                                                    }
                                                                                    label={
                                                                                        "Copia de AC-P:"
                                                                                    }
                                                                                    acceptPDF={
                                                                                        true
                                                                                    }
                                                                                    image={this.getValueFromDocument(
                                                                                        ProducerDocTypeACP,
                                                                                        "image"
                                                                                    )}
                                                                                    hash={
                                                                                        this.getValueFromDocument(
                                                                                            ProducerDocTypeACP,
                                                                                            "image"
                                                                                        )
                                                                                            .hash
                                                                                    }
                                                                                    showViewButton={
                                                                                        true
                                                                                    }
                                                                                    showImageMagnify={
                                                                                        false
                                                                                    }
                                                                                />
                                                                            ) : (
                                                                                <div>
                                                                                    <InputImage
                                                                                        id_label={
                                                                                            "ACPImage"
                                                                                        }
                                                                                        width={
                                                                                            "fit-content"
                                                                                        }
                                                                                        label={
                                                                                            "Copia de AC-P :"
                                                                                        }
                                                                                        acceptPDF={
                                                                                            true
                                                                                        }
                                                                                        image={this.getValueFromDocument(
                                                                                            ProducerDocTypeACP,
                                                                                            "image"
                                                                                        )}
                                                                                        showViewButton={
                                                                                            true
                                                                                        }
                                                                                        showImageMagnify={
                                                                                            false
                                                                                        }
                                                                                    />
                                                                                </div>
                                                                            )}
                                                                        </Col>
                                                                    </Row>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>

                                                    <Row>
                                                        <Col md={12}>
                                                            <Row>
                                                                <Col
                                                                    style={{
                                                                        marginTop:
                                                                            "1rem",
                                                                    }}
                                                                    md={12}
                                                                >
                                                                    <h5>
                                                                        Certificación
                                                                        DGII
                                                                    </h5>
                                                                    <hr
                                                                        style={{
                                                                            marginTop:
                                                                                "0rem",
                                                                        }}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col md={12}>
                                                                    <Row>
                                                                        <Col
                                                                            md={
                                                                                3
                                                                            }
                                                                        >
                                                                            <InputForm
                                                                                id_label={
                                                                                    "DGIICertificationNumber"
                                                                                }
                                                                                label={
                                                                                    "Número:"
                                                                                }
                                                                                placeholder={
                                                                                    ""
                                                                                }
                                                                                type={
                                                                                    "text"
                                                                                }
                                                                                value={this.getValueFromDocument(
                                                                                    ProducerDocTypeDGII,
                                                                                    "value"
                                                                                )}
                                                                                onInputChange={(
                                                                                    event
                                                                                ) =>
                                                                                    this.onInputChange(
                                                                                        event,
                                                                                        true,
                                                                                        ProducerDocTypeDGII,
                                                                                        "value"
                                                                                    )
                                                                                }
                                                                            />
                                                                        </Col>

                                                                        <Col
                                                                            md={
                                                                                3
                                                                            }
                                                                        >
                                                                            <Label
                                                                                htmlFor={
                                                                                    "DGIICertificationDate"
                                                                                }
                                                                            >
                                                                                Fecha:{" "}
                                                                            </Label>
                                                                            <Datetime
                                                                                inputProps={{
                                                                                    readOnly: true,
                                                                                }}
                                                                                locale={
                                                                                    "es-do"
                                                                                }
                                                                                utc={
                                                                                    true
                                                                                }
                                                                                timeFormat={
                                                                                    false
                                                                                }
                                                                                defaultValue={
                                                                                    new Date()
                                                                                }
                                                                                dateFormat={
                                                                                    DateFormat
                                                                                }
                                                                                closeOnSelect={
                                                                                    true
                                                                                }
                                                                                value={
                                                                                    new Date(
                                                                                        this.getValueFromDocument(
                                                                                            ProducerDocTypeDGII,
                                                                                            "issuedAt"
                                                                                        )
                                                                                    )
                                                                                }
                                                                                onChange={(
                                                                                    moment
                                                                                ) =>
                                                                                    this.onDateChange(
                                                                                        moment,
                                                                                        "issuedAt",
                                                                                        ProducerDocTypeDGII
                                                                                    )
                                                                                }
                                                                            />
                                                                        </Col>
                                                                        <Col
                                                                            md={
                                                                                6
                                                                            }
                                                                        >
                                                                            {CyaHelper.ImageExists(
                                                                                this.getValueFromDocument(
                                                                                    ProducerDocTypeDGII,
                                                                                    "image"
                                                                                )
                                                                            ) ? (
                                                                                <InputImage
                                                                                    id_label={
                                                                                        "DGIICertificationImage"
                                                                                    }
                                                                                    width={
                                                                                        "fit-content"
                                                                                    }
                                                                                    label={
                                                                                        "Copia de Certificación DGII:"
                                                                                    }
                                                                                    acceptPDF={
                                                                                        true
                                                                                    }
                                                                                    image={this.getValueFromDocument(
                                                                                        ProducerDocTypeDGII,
                                                                                        "image"
                                                                                    )}
                                                                                    hash={
                                                                                        this.getValueFromDocument(
                                                                                            ProducerDocTypeDGII,
                                                                                            "image"
                                                                                        )
                                                                                            .hash
                                                                                    }
                                                                                    showViewButton={
                                                                                        true
                                                                                    }
                                                                                    showImageMagnify={
                                                                                        false
                                                                                    }
                                                                                />
                                                                            ) : (
                                                                                <div>
                                                                                    <InputImage
                                                                                        id_label={
                                                                                            "DGIICertificationImage"
                                                                                        }
                                                                                        width={
                                                                                            "fit-content"
                                                                                        }
                                                                                        label={
                                                                                            "Copia de Certificación DGII:"
                                                                                        }
                                                                                        acceptPDF={
                                                                                            true
                                                                                        }
                                                                                        image={this.getValueFromDocument(
                                                                                            ProducerDocTypeDGII,
                                                                                            "image"
                                                                                        )}
                                                                                        showViewButton={
                                                                                            true
                                                                                        }
                                                                                        showImageMagnify={
                                                                                            false
                                                                                        }
                                                                                    />
                                                                                </div>
                                                                            )}
                                                                        </Col>
                                                                    </Row>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                </Form>
                                            </Card>
                                        </Col>
                                    </Row>
                                </TabPane>
                                <TabPane tabId="2">
                                    <Row>
                                        <Col sm="12">
                                            <Card body>
                                                <SimpleTable
                                                    data={
                                                        this.state.data
                                                            .producerRepresentative
                                                    }
                                                    columns={columns}
                                                    trProps={this.tProps}
                                                    loading={this.state.loading}
                                                    defaultPageSize={5}
                                                />
                                                <br />
                                                <Row>
                                                    <Col md="6">
                                                        <input
                                                            type="submit"
                                                            className={
                                                                "btn btn-success"
                                                            }
                                                            value="Añadir"
                                                            onClick={
                                                                this
                                                                    .addRepresentativesModal
                                                            }
                                                        />{" "}
                                                        <input
                                                            type="submit"
                                                            disabled={
                                                                !this.validEditButton()
                                                            }
                                                            className={
                                                                !this.validEditButton()
                                                                    ? "btn btn-warning disabled"
                                                                    : "btn btn-warning"
                                                            }
                                                            value="Editar"
                                                            onClick={
                                                                this
                                                                    .openRepresentativesModal
                                                            }
                                                        />
                                                    </Col>
                                                    <Col
                                                        md="6"
                                                        style={{
                                                            textAlign: "right",
                                                        }}
                                                    >
                                                        <input
                                                            type="submit"
                                                            disabled={
                                                                !this.validSaveButton()
                                                            }
                                                            className={
                                                                !this.validSaveButton()
                                                                    ? "btn btn-danger disabled"
                                                                    : "btn btn-danger"
                                                            }
                                                            value="Eliminar"
                                                            onClick={
                                                                this
                                                                    .deleteRepresentativeRow
                                                            }
                                                        />
                                                    </Col>
                                                </Row>
                                            </Card>
                                        </Col>
                                    </Row>
                                </TabPane>
                            </TabContent>

                            <Row>
                                <Col md={12}>
                                    <hr style={{ marginTop: 0 }} />
                                    <input
                                        type="submit"
                                        disabled={
                                            !(
                                                this.validSaveButton() &&
                                                this.Validator.validForm()
                                            )
                                        }
                                        className={
                                            !this.validSaveButton()
                                                ? "btn btn-primary disabled"
                                                : "btn btn-primary"
                                        }
                                        value="Guardar"
                                        onClick={this.onSubmit}
                                    />
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </div>
            </div>
        );
    }
}
CreateProducer.contextType = CyaContext;
