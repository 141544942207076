import React from 'react';
import ReactLoading from 'react-loading';
import PropTypes from "prop-types";
import Modal from "react-modal";

const customStyles = {
    content : {
        top                   : '50%',
        left                  : '50%',
        right                 : 'auto',
        bottom                : 'auto',
        marginRight           : '-50%',
        transform             : 'translate(-50%, -50%)'
    }
};

export class LoadingPage extends React.Component {

    render() {
        Modal.setAppElement('body');
        return <div className="row">
            <Modal
                isOpen={this.props.loading}
                shouldCloseOnEsc={false}
                style={customStyles}
                contentLabel="Cargando..."
            >
                <ReactLoading type={this.props.type} color={this.props.color} />
            </Modal>
        </div>
    }
}

LoadingPage.propTypes = {
    type: PropTypes.string,
    color: PropTypes.string,
    loading: PropTypes.bool
};