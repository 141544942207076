import {InvestorDocumentModel} from "./InvestorDocumentModel";
import {InvestorDBDocTypeCommRegister, InvestorDBDocTypeRNC, InvestorDBDocTypeSocialStatuses, InvestorDBDocTypePayrollAndAssembly} from "../const/api_consts";

export class InvestorDbModel {
    constructor(){
        this.investorDocument.push(
            new InvestorDocumentModel(InvestorDBDocTypeCommRegister),
            new InvestorDocumentModel(InvestorDBDocTypeRNC),
            new InvestorDocumentModel(InvestorDBDocTypeSocialStatuses),
            new InvestorDocumentModel(InvestorDBDocTypePayrollAndAssembly))
    }

    investorId = 0;
    name = "";
    address = "";
    cityId = 1;
    fiscalEndYear = new Date();
    rnc="";
    investorDocument=[];
    investorRepresentative=[];
}






