import React from 'react';
import {Card, CardBody, CardTitle, Col, FormGroup, Label, Row} from 'reactstrap';
import AnnexApiClient from "../../utils/api-client/AnnexApiClient";
import {AnnexModel} from "../../models/AnnexModel";
import {InputForm} from "../../components/custom/inputs/InputForm";
import {InputSelect} from "../../components/custom/inputs/InputSelect";
import {InputTextArea} from "../../components/custom/inputs/InputTextArea";
import {InputSelectForm} from "../../components/custom/inputs/InputSelectForm";
import ItemApiClient from "../../utils/api-client/ItemApiClient";
import {InputTaxForm} from "../../components/custom/inputs/InputTaxForm";
import {LoadingPage} from "../../components/custom/views/LoadingPage";
import Datetime from "react-datetime";
import {DateFormat} from "../../const/api_consts";
import {InputCurrency} from "../../components/custom/inputs/InputCurrency";
import {InputImageCarousel} from "../../components/custom/inputs/InputImageCarousel";
import {MultiTable} from "../../components/custom/tables/MultiTable";
import CyaHelper from "../../utils/CyaHelper";
import CheckApiClient from "../../utils/api-client/CheckApiClient";
import Select from 'react-select';
import FieldValidator from "../../utils/field-validators/FieldValidator";
import AnnexFieldValidator from "../../utils/field-validators/AnnexFieldValidator";
import {toast} from "react-toastify";
import AuthService from "../../utils/AuthService";
import {InputNcfForm} from "../../components/custom/inputs/InputNcfForm";
import {CyaContext} from "../../utils/CyaContext";
import {AnnexValidator} from "../../utils/validators/AnnexValidator";
import Compress from "compress.js";
import md5 from "md5";
import moment from "moment";
import { YesOrNoModal } from "../../components/custom/views/YesOrNoModal";

require('moment/locale/es-do');

export default class CreateAnnex extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            data: new AnnexModel(),
            initialData: new AnnexModel(),
            loading: true,
            checkLoading: true,
            title: "Crear Anexo",
            annexTypeData: [],
            idTypeData: [],
            stagesData: [],
            annexExpenseTypeData: [],
            annexIsrretentionTypeData: [],
            itemsData: [],
            checksData: [],
            checkAvailable: 0,
            selectedCheck: 0,
            itemsLoading: true,
            assignItems: [],
            selectedAnnexType: [],
            annexImages: [],
            showSaveButton: false,
            mask: [],
            remainingAmount: 0,
            modelCreated: "Anexo Guardado.",
            modelUpdated: "Anexo Actualizado.",
            modelError: "Error salvando anexo.",
            existsNfcAndId: null,
            loadingNfcAndId: false,
            responseError: null,
            warnings: [],
            openWarningModal: false
        };

        this.onEdit = false;
        this.ignoredKeys = [];
        this.Validator = new FieldValidator(AnnexFieldValidator);
        this.Auth = new AuthService();
        this.ItemApiClient = new ItemApiClient();
        this.CheckApiClient = new CheckApiClient();
        this.AnnexApiClient = new AnnexApiClient();
        this.AnnexApiClient.GetTypes().then(response => this.setState({annexTypeData: response.data}));
        this.AnnexApiClient.GetIdTypes().then(response => this.setState({idTypeData: response.data}));
        this.AnnexApiClient.GetStages().then(response => this.setState({stagesData: response.data}));
        this.AnnexApiClient.GetIsrRetentionTypes().then(response => this.setState({annexIsrretentionTypeData: response.data}));
        this.AnnexApiClient.GetExpenseTypes().then(response => this.setState({annexExpenseTypeData: response.data}));
    }

    componentDidMount() {
        this.ItemApiClient.GetListForAnnexes().then(response => {
            let items = response.data.map(x => {
                return {id: x.itemId, name: "[" + x.code + "] " + x.name, amount: x.amountAvailable, newAmount: 0}
            });
            this.setState({itemsData: items, itemsLoading: false});
        }).then(() => {
            this.CheckApiClient.GetListForAnnexes().then(response => {
                this.setState({
                    checkLoading: false,
                    checksData: response.data.filter(y => y.investorCheck.length > 0).map(x => {
                        return {
                            label: x.number + " - " + x.bankDescription,
                            value: x.checkId,
                            available: x.amountAvailable,
                            date: x.date,
                            investors: x.investorCheck
                        };
                    })

                });
            }).then(() => {
                if (this.props.location.state !== undefined && this.props.location.state?.id) {
                    this.AnnexApiClient.GetById(this.props.location.state.id).then(response => {
                        let aux = this.state.checksData;
                        let index = aux.findIndex(x => x.value === response.checkId);
                        if (aux[index] !== undefined) {
                            aux[index].available += response.totalDue;
                            this.setState({checksData: aux});
                        }
                        aux = this.state.itemsData;
                        response.annexItem.map(x => {
                            index = aux.findIndex(y => y.id === x.itemId);
                            aux[index].amount += x.amount;
                            return x;
                        });
                        this.setState({itemsData: aux});
                        this.editPromise(response);
                    });
                } else {
                    this.changeMask(1);
                    let aux = this.state.data;
                    let annexType = this.state.annexTypeData[0];
                    aux.itbispercentage = annexType.itbisvalue * 100;
                    aux.isrretentionPercentage = annexType.isrretentionValue * 100;
                    aux.itbisretentionPercentage = annexType.itbisretentionValue * 100;

                    this.setState({
                        initialData: Object.assign({}, aux),
                        data: aux,
                        selectedAnnexType: annexType,
                        loading: false
                    });
                }
            });
        });
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if (!nextState.loading
            && CyaHelper.ObjectCompare(nextState.data, this.state.data)
            && !this.onEdit) {
            this.Validator.initValidation(nextState.data);
        }
        return true;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!this.state.loading) {
            let isFormDirty = !CyaHelper.ObjectCompare(this.state.initialData, this.state.data);
            this.context.toggleExitConfirmation(isFormDirty);
        }
    }

    editPromise = (response) => {
        this.onEdit = true;
        const self = this;
        let left = self.state.itemsData;
        this.state.assignItems.map(x => {
            left.push(x);
            return x;
        });
        self.setState({
            itemsData: left
        });
        if (response.comment === null)
            response.comment = "";

        let checkIndex = self.state.checksData.findIndex(x => x.value === response.checkId);
        let check = checkIndex >= 0 ?
            self.state.checksData[checkIndex] :
            {
                label: "",
                value: 0,
                available: 0,
                date: new Date('3000-01-01'),
                investors: []
            };

        let annexItems = response.annexItem.map(y => {
            let item = self.state.itemsData.find(x => x.id === y.itemId);
            return {
                id: item.id,
                name: item.name,
                amount: item.amount,
                newAmount: y.amount
            };
        });

        response.checkDate = check.date;
        response.checkAvailable = check.available;
        response.annexImage.sort((a, b) => a.sequence - b.sequence);
        response.investorBlocked = check.investors.findIndex(x => x.investorBlocked) >= 0;

        this.changeMask(response.idTypeId);

        let annexType = self.state.annexTypeData.find(x => x.annexTypeId === response.annexTypeId);
        if (Number(response.annexExpenseTypeId) !== 1) {
            this.ignoredKeys.push("ncf");
        }
        self.setState({
            initialData: JSON.parse(JSON.stringify(response)), //copy data variable
            data: response,
            annexImages: response.annexImage,
            title: "Editar Anexo " + response.name,
            loading: false,
            selectedCheck: check,
            checkAvailable: check.available,
            assignItems: annexItems,
            selectedAnnexType: annexType
        });
        this.onEdit = false;

        if(response.warnings?.length > 0){
         this.setState({warnings: response.warnings})
         this.toggleWarningModal()
        }
    };

    calculateValues = () => {
        let aux = this.state.data;

        aux.itbis = (aux.itbispercentage / 100) * aux.subtotal;
        aux.isrretention = (aux.isrretentionPercentage / 100) * aux.subtotal;
        aux.itbisretention = (aux.itbisretentionPercentage / 100) * aux.itbis;

        aux.totalExpense = aux.subtotal + aux.itbis + aux.isc + aux.tip + aux.otherTaxes;
        aux.totalDue = aux.totalExpense - aux.isrretention - aux.itbisretention;

        aux.annexItem = this.state.assignItems.map(x => {
            return {
                itemId: x.id,
                amount: x.newAmount,
                amountPercent: aux.subtotal === 0 ? 0 : x.newAmount / aux.subtotal,
                availableAmount: x.amount
            };
        });

        this.setState({
            data: aux
        });
    };

    onDateChange = (moment) => {
        let aux = this.state.data;
        aux.date = moment.format("YYYY-MM-DD");
        this.setState({
            data: aux
        });
    };

    onCurrencyChange = (event, maskedvalue, floatvalue) => {
        let aux = this.state.data;
        aux[event.target.name] = floatvalue;
        let sum = aux.annexItem.reduce(function (prev, cur) {
            return prev + cur.amount;
        }, 0);
        this.setState({
            data: aux,
            remainingAmount: aux.subtotal - sum < 0 ? 0 : aux.subtotal - sum
        });
        this.calculateValues();
    };

    onInputChange = (event) => {
        let aux = this.state.data;
        aux[event.target.name] = event.target.type === "checkbox" ? event.target.checked : event.target.value;
        if (event.target.name === "idTypeId") {
            this.changeMask(event.target.value);
        } else if (event.target.name === "annexExpenseTypeId") {
            let number = Number(event.target.value);
            if (number === 1) {
                this.ignoredKeys = [];
            } else if (number === 2) {
                aux.ncf = "";
                if (this.ignoredKeys.indexOf("ncf") < 0)
                    this.ignoredKeys.push("ncf");
            }
        }
        this.setState({
            data: aux
        });
    };

    onIdBlur = () => {
        let aux = this.state.data;
        this.AnnexApiClient.GetNameByID(aux.id).then(response => {
                if (response.length > 0) {
                    aux.name = response;
                    this.setState({
                        data: aux
                    });
                }
            }
        );
    };

    changeMask = (value) => {
        let mask = [];
        switch (Number(value)) {
            case 1:
                mask = [/\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/];
                break;
            case 2:
                mask = [/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/];
                break;
            default:
                mask = [];
                break;
        }
        this.setState({
            mask: mask
        });
    };

    onAnnexTypeInputChange = (event) => {
        let aux = this.state.data;
        aux[event.target.name] = event.target.value;
        let annexType = this.state.annexTypeData.find(x => x.annexTypeId === Number(aux[event.target.name]));
        aux.itbispercentage = annexType.itbisvalue * 100;
        aux.isrretentionPercentage = annexType.isrretentionValue * 100;
        aux.itbisretentionPercentage = annexType.itbisretentionValue * 100;
        this.setState({
            data: aux,
            selectedAnnexType: annexType
        });
        this.calculateValues();
    };

    onTaxInputChange = (event) => {
        let aux = this.state.data;
        aux[event.target.name] = CyaHelper.CleanStringToFloat(event.target.value);
        aux.totalExpense = aux.subtotal + aux.itbis + aux.isc + aux.tip + aux.otherTaxes;
        aux.totalDue = aux.totalExpense - aux.isrretention - aux.itbisretention;
        this.setState({
            data: aux
        });
    };

    onCheckSelectChange = (check) => {
        if (typeof (check) !== "undefined") {
            check = check || {
                label: "",
                value: 0,
                available: 0,
                date: new Date('3000-01-01'),
                investors: []
            };

            let aux = this.state.data;
            aux.checkId = check.value;
            aux.checkDate = check.date;
            aux.checkAvailable = check.available;
            this.setState({
                data: aux,
                selectedCheck: check,
                checkAvailable: check.available,
            });
        }
    };

    onImageChange = (images) => {
        let aux = this.state.data;
        aux.annexImage = images;
        this.setState({
            data: aux
        });
    };

    myCallback = (dataLeft, dataRight) => {
        let aux = this.state.data;
        aux.annexItem = dataRight.map(x => {
            return {
                itemId: x.id,
                amount: x.newAmount,
                amountPercent: aux.subtotal === 0 ? 0 : x.newAmount / aux.subtotal,
                availableAmount: x.amount
            };
        });
        this.setState({
            data: aux,
            itemsData: dataLeft,
            assignItems: dataRight
        });
    };

    myValidatorCallback = (valid) => {
        if (valid !== this.state.showSaveButton) {
            this.setState({
                showSaveButton: valid,
            });
        }
    };

    renderEditable = (cellInfo) => {
        try {
            return (
                <span title={"Presionar SHIFT asigna el monto restante del anexo aun no dividido entre las partidas"}>
            <div
                contentEditable
                suppressContentEditableWarning
                onBlur={e => {
                    const assignItems = [...this.state.assignItems];
                    assignItems[cellInfo.index][cellInfo.column.id] = CyaHelper.CleanStringToFloat(e.target.innerHTML);
                    let aux = this.state.data;
                    aux.annexItem = assignItems.map(x => {
                        return {
                            itemId: x.id,
                            amount: x.newAmount,
                            amountPercent: aux.subtotal === 0 ? 0 : x.newAmount / aux.subtotal,
                            availableAmount: x.amount
                        };
                    });
                    let sum = aux.annexItem.reduce(function (prev, cur) {
                        return prev + cur.amount;
                    }, 0);
                    this.setState({
                        data: aux,
                        assignItems: assignItems,
                        remainingAmount: aux.subtotal - sum < 0 ? 0 : aux.subtotal - sum
                    });
                }}
                onKeyUp={e => {
                    if (e.key !== "Shift") return;
                    const assignItems = [...this.state.assignItems];
                    let sum = assignItems.reduce(function (prev, cur) {
                        return prev + cur.newAmount;
                    }, 0) - CyaHelper.CleanStringToFloat(e.target.innerHTML);

                    let aux = this.state.data;
                    assignItems[cellInfo.index][cellInfo.column.id] = aux.subtotal - sum < 0 ? 0 : aux.subtotal - sum;
                    aux.annexItem = assignItems.map(x => {
                        return {
                            itemId: x.id,
                            amount: x.newAmount,
                            amountPercent: aux.subtotal === 0 ? 0 : x.newAmount / aux.subtotal,
                            availableAmount: x.amount
                        };
                    });
                    this.setState({
                        data: aux,
                        assignItems: assignItems,
                        remainingAmount: 0
                    });
                }}


                dangerouslySetInnerHTML={{
                    __html: CyaHelper.ToCurrency(CyaHelper.CleanStringToFloat(this.state.assignItems[cellInfo.index][cellInfo.column.id].toString()))
                }}

            /></span>
            );
        } catch (error) {
            return (<div> </div>)
        }

    };

    onSubmit = (event, addNew = false) => {
        event.preventDefault();
        this.setState({
            loading: true
        });

        if (this.state.data.annexId > 0) {
            let data = this.state.data
            data['annexImage'] = this.state.data.annexImage.map(image => {
                image['annexId'] = this.state.data.annexId
                // image.image["imageId"] = image.imageId
                return {...image}
            })

            this.AnnexApiClient.Update(this.state.data.annexId, data)
                .then(response => {
                    this.editPromise(response);
                    toast.success(this.state.modelUpdated);
                    this.context.toggleExitConfirmation(false);
                    if (addNew)
                        this.props.history.push({pathname: '/refresh/annexes/new'});
                })
                .catch(this.handleResponseError)
                .finally(() => this.setState({loading: false}));
        } else {
            this.AnnexApiClient.Create(this.state.data)
                .then(response => {
                    toast.success(this.state.modelCreated);
                    this.context.toggleExitConfirmation(false);
                    if (addNew) {
                        this.props.history.push({pathname: '/refresh/annexes/new'});
                    } else {
                        this.editPromise(response);
                        this.setState({loading: false});
                    }
                })
                .catch(this.handleResponseError)
                .finally(() => this.setState({loading: false}));
        }
        window.scrollTo(0, 0);
    };

    checkNfcAndId = () => {
        const {id, idTypeId, ncf} = this.state.data;
        let isNumber = !Number.isNaN(Number(ncf.substr(1)));
        let nfcFirstCheck = ncf.length === 19 && ncf.startsWith("A");
        let nfcSecondCheck = ncf.length === 11 && ncf.startsWith("B");
        let nfcThirdCheck = ncf.length === 13 && ncf.startsWith("E");
        const validNfc = isNumber && (nfcFirstCheck || nfcSecondCheck || nfcThirdCheck);

        let idFirstCheck = id.length === 12 && Number(idTypeId) === 1;
        let idSecondCheck = id.length === 13 && Number(idTypeId) === 2;
        let idThirdCheck = Number(idTypeId) === 3;
        const validId = (idFirstCheck || idSecondCheck || idThirdCheck);
        if (validNfc && validId) {
            this.setState({loadingNfcAndId: true});
            this.AnnexApiClient.ExistsNfcAndRnc(id, idTypeId, ncf).then(x => {
                    this.setState({existsNfcAndId: x, loadingNfcAndId: false});
                }
            );
        }

    };


    dataURLtoFile = (dataurl, filename) => {
        let arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, {type: mime});
    }

    rotatedImage = async (base64, imageModel) => {
        let aux = this.state.data;
        let images = aux.annexImage
        let fileName = moment().format("YYYY-MM-DD-hh-mm-ss-") + imageModel.imageId + '.jpg'
        let imgFile = this.dataURLtoFile(base64, fileName)

        new Compress().compress([imgFile], {
            size: 1,
            quality: .5
        }).then((results) => {
            const compressImg = results[0];
            images.map(image => {
                if (image.filename === imageModel.filename) {
                    image.imageId = 0
                    image.image.imageId = 0;
                    image.image.fileContent = compressImg.data;
                    image.image.hash = md5(compressImg.data);
                    // image.image.filename = fileName;
                    // image.image.imageGuid = CyaHelper.Guid()
                }
            })
            aux['annexImage'] = images
            this.setState({
                data: aux,
            });
        });
    }

    handleResponseError = (error) => {
        toast.error(this.state.modelError);
        this.setState({responseError: error})
    }

    toggleWarningModal = () => {
      this.setState({ openWarningModal: !this.state.openWarningModal });
   }

    render() {
        let columnsLeft = [
            {Header: "ItemID", accessor: "id", show: false},
            {Header: "Nombre", accessor: "name"},
            {
                Header: "Disponible",
                accessor: "amount",
                style: {textAlign: 'right'},
                Cell: props => CyaHelper.ToCurrency(props.value),
                width: 150
            },
        ];
        let columnsRight = [
            {Header: "ItemID", accessor: "id", show: false},
            {Header: "Nombre", accessor: "name"},
            {
                Header: "Disponible",
                accessor: "amount",
                style: {textAlign: 'right'},
                Cell: props => CyaHelper.ToCurrency(props.value),
                width: 150
            },
            {
                Header: "Monto",
                accessor: "newAmount",
                style: {textAlign: 'right'},
                Cell: this.renderEditable,
                width: 150
            }
        ];
        const isButtonDisabled = !(
            (this.state.data.annexId > 0 || (this.state.existsNfcAndId!=null && !this.state.existsNfcAndId)) &&
            this.state.showSaveButton &&
            this.Validator.validForm(this.ignoredKeys) &&
            this.Auth.GetPathPermission(this.props.location.pathname, true)
        );


        return <div>
            <YesOrNoModal
               modalOpen={this.state.openWarningModal}
               closeDeleteModal={this.toggleWarningModal}
               message={"AVISO"}
               confirmation={false}
            >
               <ul>{this.state.warnings.map(
                  (item, index) => 
                     <li key={index}>{item}</li>
                  )}
               </ul>
            </YesOrNoModal>

            <Card>
                <CardTitle className="mb-0 p-3 border-bottom bg-light"><i
                    className="mdi mdi-file-multiple mr-2"/>{this.state.title}</CardTitle>
                <CardBody>
                    <LoadingPage color={"3e5569"} loading={this.state.loading} type={"spin"}/>
                    <Row>
                        <Col md={12}>
                            {this.state.annexImages.length > 0 ?
                                <InputImageCarousel rotatedImage={this.rotatedImage}
                                                    annexImageModels={this.state.annexImages}
                                                    onImageChange={this.onImageChange} multiple={true}
                                                    loadingCallback={(loading) => {
                                                        this.setState({loading})
                                                    }}/>
                                : <div><InputImageCarousel rotatedImage={this.rotatedImage}
                                                           annexImageModels={this.state.annexImages}
                                                           loadingCallback={(loading) => {
                                                               this.setState({loading})
                                                           }} onImageChange={this.onImageChange} multiple={true}/>
                                </div>}
                            <hr/>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={3}>
                            <InputNcfForm
                                label={"NCF:"}
                                idSelect={"annexExpenseTypeId"}
                                idForm={"ncf"}
                                formValue={this.state.data.ncf}
                                data={this.state.annexExpenseTypeData}
                                dataValue={"annexExpenseTypeId"}
                                dataDescription={"description"}
                                formType={"text"}
                                placeholder={""}
                                selectedValue={Number(this.state.data.annexExpenseTypeId)}
                                onInputChange={this.onInputChange}
                                onBlur={this.checkNfcAndId}
                            />
                            {Number(this.state.data.annexExpenseTypeId) === 1 ? this.Validator.showErrors("ncf") : null}
                            {(this.state.existsNfcAndId != null && this.state.existsNfcAndId && !this.state.loadingNfcAndId) &&
                            <span className={"error"}>Ya existe un anexo con este NFC y ID</span>}
                            {this.state.loadingNfcAndId && <span className={'success'}>Validando ID y NFC...</span>}
                        </Col>
                        <Col md={3}>
                            <InputSelectForm
                                label={"ID:"}
                                idSelect={"idTypeId"}
                                idForm={"id"}
                                formValue={this.state.data.id}
                                data={this.state.idTypeData}
                                dataValue={"idTypeId"}
                                dataDescription={"description"}
                                formType={"text"}
                                placeholder={""}
                                selectedValue={Number(this.state.data.idTypeId)}
                                mask={this.state.mask}
                                onInputChange={this.onInputChange}
                                onBlur={() => {
                                    this.onIdBlur();
                                    this.checkNfcAndId()
                                }}
                            />
                            {this.Validator.showErrors("id")}
                        </Col>
                        <Col md={6}>
                            <InputCurrency id_label={"subtotal"}
                                           label={"Subtotal:"}
                                           placeholder={""}
                                           value={this.state.data.subtotal}
                                           onInputChange={this.onCurrencyChange}
                            />
                            {this.Validator.showErrors("subtotal")}
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <InputForm id_label={"name"} label={"Nombre:"} placeholder={""}
                                       type={"text"} value={this.state.data.name} onInputChange={this.onInputChange}/>
                            {this.Validator.showErrors("name")}
                        </Col>
                        <Col md={3}>
                            <InputTaxForm
                                label={"ITBIS:"}
                                amountId={"itbis"}
                                percentId={"itbispercentage"}
                                amountValue={String(this.state.data.itbis)}
                                percentValue={this.state.data.itbispercentage}
                                onInputChange={this.onTaxInputChange}
                                onPercentInputChange={(values) => {
                                    if (!this.onEdit) {
                                        const {floatValue} = values;
                                        let aux = this.state.data;

                                        aux.itbispercentage = floatValue;
                                        aux.itbis = (aux.itbispercentage / 100) * aux.subtotal;

                                        aux.totalExpense = aux.subtotal + aux.itbis + aux.isc + aux.tip + aux.otherTaxes;
                                        aux.totalDue = aux.totalExpense - aux.isrretention - aux.itbisretention;

                                        this.setState({
                                            data: aux
                                        });
                                    }
                                }}
                            />
                        </Col>
                        <Col md={3}>
                            <InputTaxForm
                                label={"ITBIS Retenido:"}
                                amountId={"itbisretention"}
                                percentId={"itbisretentionPercentage"}
                                amountValue={String(this.state.data.itbisretention)}
                                percentValue={this.state.data.itbisretentionPercentage}
                                onInputChange={this.onTaxInputChange}
                                onPercentInputChange={(values) => {
                                    if (!this.onEdit) {
                                        const {floatValue} = values;
                                        let aux = this.state.data;

                                        aux.itbisretentionPercentage = floatValue;
                                        aux.itbisretention = (aux.itbisretentionPercentage / 100) * aux.itbis;

                                        aux.totalExpense = aux.subtotal + aux.itbis + aux.isc + aux.tip + aux.otherTaxes;
                                        aux.totalDue = aux.totalExpense - aux.isrretention - aux.itbisretention;

                                        this.setState({
                                            data: aux
                                        });
                                    }
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={3}>
                            <Label htmlFor={"date"}>Fecha: </Label>
                            <Datetime locale={"es-do"}
                                      inputProps={{readOnly: true, style: {backgroundColor: "transparent"}}}
                                      utc={true}
                                      timeFormat={false}
                                      defaultValue={new Date(this.state.data.date)}
                                      dateFormat={DateFormat}
                                      closeOnSelect={true}
                                      value={new Date(this.state.data.date)}
                                      onChange={this.onDateChange}/>
                        </Col>
                        <Col md={3}>
                            <InputSelect
                                id_label={"annexTypeId"}
                                data={this.state.annexTypeData}
                                label={"Tipo:"}
                                dataDescription={["description"]}
                                dataValue={"annexTypeId"}
                                selectedValue={Number(this.state.data.annexTypeId)}
                                onInputChange={this.onAnnexTypeInputChange}/>
                        </Col>
                        <Col md={3}>
                            <InputTaxForm
                                label={"ISR Retenido:"}
                                amountId={"isrretention"}
                                percentId={"isrretentionPercentage"}
                                amountValue={String(this.state.data.isrretention)}
                                percentValue={this.state.data.isrretentionPercentage}
                                onInputChange={this.onTaxInputChange}
                                onPercentInputChange={(values) => {
                                    if (!this.onEdit) {
                                        const {floatValue} = values;
                                        let aux = this.state.data;

                                        aux.isrretentionPercentage = floatValue;
                                        aux.isrretention = (aux.isrretentionPercentage / 100) * aux.subtotal;

                                        aux.totalExpense = aux.subtotal + aux.itbis + aux.isc + aux.tip + aux.otherTaxes;
                                        aux.totalDue = aux.totalExpense - aux.isrretention - aux.itbisretention;

                                        this.setState({
                                            data: aux
                                        });
                                    }
                                }}
                            />
                        </Col>
                        <Col md={3}>
                            <InputCurrency id_label={"otherTaxes"}
                                           label={"Otros Impuestos:"}
                                           placeholder={""}
                                           value={this.state.data.otherTaxes}
                                           onInputChange={(event, maskedvalue, floatValue) => {
                                               let aux = this.state.data;
                                               aux.otherTaxes = floatValue;

                                               aux.totalExpense = aux.subtotal + aux.itbis + aux.isc + aux.tip + aux.otherTaxes;
                                               aux.totalDue = aux.totalExpense - aux.isrretention - aux.itbisretention;

                                               this.setState({
                                                   data: aux
                                               });
                                           }}
                            />
                            {this.Validator.showErrors("otherTaxes")}
                        </Col>
                    </Row>
                    <Row>
                        <Col md={3}>
                            <InputSelect
                                id_label={"stageId"}
                                data={this.state.stagesData}
                                label={"Etapa:"}
                                dataDescription={["description"]}
                                dataValue={"stageId"}
                                selectedValue={Number(this.state.data.stageId)}
                                onInputChange={this.onInputChange}/>
                        </Col>
                        <Col md={3}>
                            <InputSelect
                                id_label={"annexIsrretentionTypeId"}
                                data={this.state.annexIsrretentionTypeData}
                                label={"Categoría IR-17:"}
                                dataDescription={["description"]}
                                dataValue={"annexIsrretentionTypeId"}
                                selectedValue={Number(this.state.data.annexIsrretentionTypeId)}
                                onInputChange={this.onInputChange}/>
                        </Col>
                        <Col md={3}>
                            <InputCurrency id_label={"isc"}
                                           label={"Impuesto Selectivo al Consumo:"}
                                           placeholder={""}
                                           value={this.state.data.isc}
                                           onInputChange={(event, maskedvalue, floatValue) => {
                                               let aux = this.state.data;
                                               aux.isc = floatValue;

                                               aux.totalExpense = aux.subtotal + aux.itbis + aux.isc + aux.tip + aux.otherTaxes;
                                               aux.totalDue = aux.totalExpense - aux.isrretention - aux.itbisretention;

                                               this.setState({
                                                   data: aux
                                               });
                                           }}
                            />
                            {this.Validator.showErrors("isc")}
                        </Col>
                        <Col md={3}>
                            <InputCurrency id_label={"tip"}
                                           label={"Propina:"}
                                           placeholder={""}
                                           value={this.state.data.tip}
                                           onInputChange={(event, maskedvalue, floatValue) => {
                                               let aux = this.state.data;
                                               aux.tip = floatValue;

                                               aux.totalExpense = aux.subtotal + aux.itbis + aux.isc + aux.tip + aux.otherTaxes;
                                               aux.totalDue = aux.totalExpense - aux.isrretention - aux.itbisretention;

                                               this.setState({
                                                   data: aux
                                               });
                                           }}
                            />
                            {this.Validator.showErrors("tip")}
                        </Col>
                    </Row>
                    <Row>
                        <Col md={3}>
                            <Label htmlFor={"checks"}>Cheque: </Label>
                            <Select options={this.state.checksData}
                                    id={"checks"}
                                    name={"checks"}
                                    value={this.state.selectedCheck}
                                    isLoading={this.state.checkLoading}
                                    placeholder={"Seleccione cheque"}
                                    isClearable={true}
                                    onChange={this.onCheckSelectChange}
                            />
                        </Col>
                        <Col md={3}>
                            <InputCurrency id_label={"checkAvailable"}
                                           label={"Disponible del Cheque:"}
                                           placeholder={""}
                                           value={this.state.checkAvailable}
                                           readOnly={true}
                            />
                        </Col>
                        <Col md={3}>
                            <InputCurrency id_label={"totalExpense"}
                                           label={"Total de Gastos:"}
                                           placeholder={""}
                                           readOnly={true}
                                           value={this.state.data.totalExpense}
                            />
                        </Col>
                        <Col md={3}>
                            <InputCurrency id_label={"totalDue"}
                                           label={"Total a Pagar:"}
                                           placeholder={""}
                                           readOnly={true}
                                           value={this.state.data.totalDue}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <FormGroup>
                                <Label>Partidas:</Label>
                                {this.state.itemsLoading === false && this.state.loading === false ?
                                    <MultiTable
                                        dataLeft={this.state.itemsData}
                                        dataRight={this.state.assignItems}
                                        columnsRight={columnsRight}
                                        columnsLeft={columnsLeft}
                                        loading={this.state.itemsLoading}
                                        callbackFromParent={this.myCallback}
                                        filterable={true}
                                        defaultAmount={this.state.remainingAmount}
                                    />
                                    : ""}
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <InputTextArea id_label={"comment"} label={"Comentario:"} placeholder={""}
                                           rows={"4"} value={this.state.data.comment}
                                           onInputChange={this.onInputChange}/>
                           {this.Validator.showErrors("comment")}
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <hr/>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={2}>
                            <input type="submit"
                                   disabled={isButtonDisabled}
                                   className={isButtonDisabled ? 'btn btn-block btn-primary disabled' : 'btn btn-block btn-primary'}
                                   value="Guardar"
                                   onClick={this.onSubmit}/>
                        </Col>
                        <Col md={2}>
                            <input type="submit"
                                   disabled={isButtonDisabled}
                                   className={isButtonDisabled ? 'btn btn-block btn-success disabled' : 'btn btn-block btn-success'}
                                   value="Guardar y añadir nuevo"
                                   onClick={(event) => this.onSubmit(event, true)}/>
                        </Col>
                        <Col md={8}>
                            <AnnexValidator 
                                model={this.state.data} 
                                callbackFromParent={this.myValidatorCallback}
                                responseError={this.state.responseError}
                            />
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </div>;
    }
}
CreateAnnex.contextType = CyaContext;
