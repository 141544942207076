const ProducerFieldValidator = {
    name: {
        secondValue: null,
        rules: [
            {
                test: (value) => value.length > 0,
                message: 'Campo en blanco'
            }
        ],
        errors: [],
        valid: false,
        state: ''
    },
    address: {
        secondValue: null,
        rules: [
            {
                test: (value) =>value.length > 0,
                message: 'Campo en blanco'
            }
        ],
        errors: [],
        valid: false,
        state: ''
    },
    rnc: {
        secondValue: 'producerDocument',
        rules: [
            {
                test: (value) =>value.length > 0,
                message: 'Campo en blanco'
            },
            {
                test: (value) => value.length === 12,
                message: 'Faltan caracteres'
            }
        ],
        errors: [],
        valid: false,
        state: ''
    },
};

export default ProducerFieldValidator ;
