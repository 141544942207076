import {ImageModel} from "./ImageModel";

export class ContractAAnnexModel {
    investorId = 0;
    sent = 0;
    imageId = 0;
    image = new ImageModel();
}

export class ContractInversionModel {
    investorId = 0;
    date = new Date().toISOString();
    imageId = 0;
    image = new ImageModel();
    notaryId = 1;
    contractInvestorId = 0;
}

export class ContractPowerRepresentationModel {
    investorId = 0;
    date = new Date().toISOString();
    imageId = 0;
    image = new ImageModel();
    notaryId = 1;
    contractInvestorId = 0;
}


export class ContractWarrantyModel {
    investorId = 0;
    date = new Date().toISOString();
    imageId = 0;
    image = new ImageModel();
    notaryId = 1;
    contractInvestorId = 0;
    financialCertificateAmount = 0;
    financialCertificateNumber = "";
    financialCertificateImageId = 0;
    financialCertificateImage = new ImageModel();
}
