import React from 'react';
import {
    FormGroup,
    Label,
    Input,
    InputGroup
} from 'reactstrap';
import PropTypes from 'prop-types'

export class InputNcfForm extends React.Component {

    render() {
        return <FormGroup>
            <Label htmlFor={this.props.id_label}>{this.props.label}</Label>
            <InputGroup className={"form-inline"}>
                <Input type="select"
                       name={this.props.idSelect}
                       id={this.props.idSelect}
                       onChange={this.props.onInputChange}
                       value={this.props.selectedValue}
                       style={{height:'38px'}}
                >
                    {this.props.data.map((prop, key) => {
                        return <option key={key} value={prop[this.props.dataValue]}> {prop[this.props.dataDescription]} </option>
                    })}
                </Input>
                <Input type={this.props.type}
                       disabled={this.props.selectedValue !== 1}
                       name={this.props.idForm}
                       id={this.props.idForm}
                       onChange={this.props.onInputChange}
                       placeholder={this.props.placeholder}
                       style={{marginLeft: '10px', height:'38px'}}
                       onBlur={this.props.onBlur}
                       value={this.props.formValue} />
            </InputGroup>
        </FormGroup>
    }
}

InputNcfForm.propTypes = {
    label: PropTypes.string,
    idSelect: PropTypes.string,
    idForm: PropTypes.string,
    formValue: PropTypes.string,
    data: PropTypes.array,
    dataValue: PropTypes.string,
    dataDescription: PropTypes.string,
    formType: PropTypes.string,
    placeholder: PropTypes.string,
    onInputChange: PropTypes.func,
    selectedValue: PropTypes.number
};