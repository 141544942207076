import React from 'react';
import PropTypes from "prop-types";
import Modal from "react-modal";
import {Row,Col} from "reactstrap";

const customStyles = {
    content : {
        top                   : '50%',
        left                  : '50%',
        right                 : 'auto',
        bottom                : 'auto',
        marginRight           : '-50%',
        transform             : 'translate(-50%, -50%)',
        width                 : '40%'
    }
};

export class YesOrNoModal extends React.Component {

    constructor(props) {
        super(props);
        this.closeModal = this.closeModal.bind(this);
    }

    closeModal(e){
        e.preventDefault();
        this.props.closeDeleteModal();
    }

    render() {
        Modal.setAppElement('body');
        return <div className="row">
            <Modal
                isOpen={this.props.modalOpen}
                style={customStyles}
                onRequestClose={this.props.closeDeleteModal}
            >

                <Row>
                    <Col md={10}><h3>{this.props.message}</h3></Col>
                    <Col md={2} style={{textAlign: 'right'}}>
                        <button className={"btn btn-danger btn-sm"} style={{textAlign: 'right'}}
                                onClick={this.closeModal}>X
                        </button>
                    </Col>
                </Row>
                <hr style={{marginBottom:"15px"}}/>
               
               <p>{this.props.body}</p>

               {
                  this.props.children && 
                  <div style={{overflowY: "auto", overflowX: "hidden", maxHeight: "500px"}}>
                     {this.props.children}
                  </div>
               }

                <hr style={{marginBottom:"15px"}}/>
                <Row>
                  {
                     this.props.confirmation
                     ? (<div>
                           <Col md="6" >
                              <button className={"btn btn-success"} onClick={(e)=>this.props.confirmation(e,true)} >{this.props.yesText}</button>
                           </Col>
                           <Col md="6" style={{textAlign: 'right'}}>
                              <button className={"btn btn-secondary"} onClick={(e)=>this.props.confirmation(e,false)}>{this.props.noText}</button>
                         </Col>
                    </div>)
                    :
                    (<Col md="6" >
                        <button className={"btn btn-info"} onClick={this.closeModal} >{"Ok"}</button>
                     </Col>)
                  }
                    
                </Row>

            </Modal>
        </div>
    }
}

YesOrNoModal.propTypes = {
    modalOpen: PropTypes.bool,
    closeDeleteModal: PropTypes.func,
    confirmation:PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
    message: PropTypes.string,
    body: PropTypes.string,
    yesText: PropTypes.string,
    noText: PropTypes.string,
    children: PropTypes.element
};

YesOrNoModal.defaultProps = {
    yesText: 'Si',
    noText: 'No',

};
