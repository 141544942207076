import React from "react";
import "react-table/react-table.css";
import {
    Card,
    CardTitle,
    CardBody,
    Row,
    Col,
    Form,
    Label,
    Nav,
    TabPane,
    TabContent,
    Button,
} from "reactstrap";
import { LoadingPage } from "../../components/custom/views/LoadingPage";
import { InputForm } from "../../components/custom/inputs/InputForm";
import { toast } from "react-toastify";
import Datetime from "react-datetime";
import {
    DateFormat,
    ProjectDocTypeCDND,
    ProjectDocTypeCPND,
    ProjectDocTypePUR,
    projectsPage,
} from "../../const/api_consts";
import { ProjectModel } from "../../models/ProjectModel";
import ProjectApiClient from "../../utils/api-client/ProjectApiClient";
import AuthService from "../../utils/AuthService";
import { InputSelect } from "../../components/custom/inputs/InputSelect";
import NotaryApiClient from "../../utils/api-client/NotaryApiClient";
import ProducerApiClient from "../../utils/api-client/ProducerApiClient";
import { CyaContext } from "../../utils/CyaContext";
import CyaHelper from "../../utils/CyaHelper";
import FieldValidator from "../../utils/field-validators/FieldValidator";
import ProjectFieldValidator from "../../utils/field-validators/ProjectFieldValidator";
import { SimpleNavItem } from "../../components/custom/FunctionalComponents";
import { ImageModel } from "../../models/ImageModel";
import { FormGroup } from "@material-ui/core";
import { InputImage } from "../../components/custom/inputs/InputImage";
import { ProjectDocumentModel } from "../../models/ProjectDocumentModel";

export default class CreateProject extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: new ProjectModel(),
            initialData: new ProjectModel(),
            notaryData: [],
            producerData: [],
            loading: true,
            activeTab: "1",
            title: "Crear Proyecto",
            modelCreated: "Proyecto Guardado.",
            modelUpdated: "Proyecto Actualizado.",
            modelError: "Error en Proyecto.",
        };
        this.Auth = new AuthService();
        this.Validator = new FieldValidator(ProjectFieldValidator);
        this.ProjectApiClient = new ProjectApiClient();
        this.NotaryApiClient = new NotaryApiClient();
        this.ProducerApiClient = new ProducerApiClient();

        this.editPromise = this.editPromise.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onDateChange = this.onDateChange.bind(this);
        this.validSaveButton = this.validSaveButton.bind(this);
    }

    componentDidMount() {
        this.NotaryApiClient.GetAll()
            .then((response) => {
                this.setState({ notaryData: response.data });
            })
            .then(() => {
                this.ProducerApiClient.GetAll()
                    .then((response) => {
                        this.setState({ producerData: response.data });
                    })
                    .then(() => {
                        if (
                            this.props.location.state !== undefined &&
                            this.props.location.state?.id
                        ) {
                            this.ProjectApiClient.GetById(
                                this.props.location.state.id
                            ).then((response) => {
                                this.editPromise(response);
                            });
                        } else {
                            this.setState({ loading: false });
                        }
                    });
            });
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if (
            !nextState.loading &&
            CyaHelper.ObjectCompare(nextState.data, this.state.data)
        )
            this.Validator.initValidation(nextState.data);

        return true;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!this.state.loading) {
            let isFormDirty = !CyaHelper.ObjectCompare(
                this.state.initialData,
                this.state.data
            );
            this.context.toggleExitConfirmation(isFormDirty);
        }
    }

    editPromise(response) {
        if (
            response.projectDocument != null &&
            response.projectDocument.length > 0
        ) {
            response.projectDocument.map((x) => {
                if (x.image === null) x.image = new ImageModel();
                return true;
            });
        } else {
            response.projectDocument = [];
            response.projectDocument.push(
                new ProjectDocumentModel(ProjectDocTypePUR),
                new ProjectDocumentModel(ProjectDocTypeCPND),
                new ProjectDocumentModel(ProjectDocTypeCDND)
            );
        }
        this.setState({
            initialData: JSON.parse(JSON.stringify(response)), //copy data variable
            data: response,
            title: "Editar Proyecto ",
            loading: false,
        });
    }

    onInputChange(event, isProjectDocument = false, type = 1, valueName = "") {
        let aux = this.state.data;

        if (isProjectDocument)
            aux.projectDocument.find((x) => x.projectDocumentTypeId === type)[
                valueName
            ] = event.target.value;
        aux[event.target.name] = event.target.value;

        this.setState({ data: aux });
    }

    getValueFromDocument(type, value) {
        let res = "";
        let aux = this.state.data.projectDocument.find(
            (x) => x.projectDocumentTypeId === type
        );
        if (aux !== undefined) {
            res = aux[value];
        } else {
            if (value === "expires" || value === "issuedAt") {
                res = new Date();
            }
            if (value === "image") {
                res = new ImageModel();
            }
        }
        return res;
    }

    onDateChange(moment, dateName, type = -1) {
        let data = this.state.data;
        if (type !== -1) {
            data.projectDocument.find((x) => x.projectDocumentTypeId === type)[
                dateName
            ] = moment.format("YYYY-MM-DD");
        } else {
            data[dateName] = moment.format("YYYY-MM-DD");
        }
        this.setState({ data });
    }

    validSaveButton() {
        return this.Auth.GetPathPermission(this.props.location.pathname, true);
    }

    onSubmit(event) {
        event.preventDefault();
        this.context.toggleExitConfirmation(false);
        this.setState({ loading: true });
        let data = this.state.data;

        data.projectDocument = data.projectDocument.map((images) => {
            if (images.image.fileContent.length === 0) {
                return { ...images, image: null, imageId: null };
            } else {
                return {
                    ...images,
                    image: { ...images.image, imageId: images.imageId },
                };
            }
        });

        if (this.state.data.projectId > 0) {
            this.ProjectApiClient.Update(
                this.state.data.projectId,
                this.state.data
            )
                .then((response) => {
                    this.editPromise(response);
                    toast.success(this.state.modelUpdated);
                })
                .catch((err) => {
                    this.setState({ loading: false });
                    toast.error(this.state.modelError);
                });
        } else {
            this.ProjectApiClient.Create(this.state.data)
                .then((response) => {
                    if (CyaHelper.isResponseOK(response)) {
                        this.setState({ loading: false });
                        toast.error(this.state.modelError);
                    } else {
                        toast.success(this.state.modelCreated);
                        this.props.history.push({ pathname: projectsPage });
                    }
                })
                .catch((err) => {
                    this.setState({ loading: false });
                    toast.error(this.state.modelError);
                });
        }
        window.scrollTo(0, 0);
    }

    toggle = (tab) => {
        if (this.state.activeTab !== tab) {
            this.setState({ activeTab: tab });
            // if (this.state.initialData.mainInvestorId === 0 ){
            //     toast.warn('Asignar un inversionista existente y guardarlo antes de pasar a esta sección.');
            // } else if (this.state.data.investorId === 0) {
            //     toast.warn('Guardar inversionista antes de pasar a esta sección.');
            // } else {
            //     this.setState({activeTab: tab});
            // }
        }
    };

    render() {
        return (
            <div className="auth-wrapper d-flex">
                <div className="container">
                    <Card>
                        <CardTitle className="mb-0 p-3 border-bottom bg-light">
                            <i className="mdi mdi-border-right mr-2" />
                            {this.state.title}
                        </CardTitle>
                        <CardBody>
                            <LoadingPage
                                color={"3e5569"}
                                loading={this.state.loading}
                                type={"spin"}
                            />

                            <Nav tabs className={"pt-0 mt-0"}>
                                <SimpleNavItem
                                    tabName={"General"}
                                    active={this.state.activeTab === "1"}
                                    toggle={() => {
                                        this.toggle("1");
                                    }}
                                />
                            </Nav>

                            <TabContent activeTab={this.state.activeTab}>
                                <TabPane tabId="1">
                                    <Card>
                                        <CardBody>
                                            <CardTitle>
                                                Datos Generales del Proyecto
                                                <hr className="mt-2" />
                                            </CardTitle>

                                            <Row>
                                                <Col md="12">
                                                    <Form>
                                                        <Row>
                                                            <Col md={12}>
                                                                <InputForm
                                                                    id_label={
                                                                        "name"
                                                                    }
                                                                    label={
                                                                        "Nombre:"
                                                                    }
                                                                    placeholder={
                                                                        ""
                                                                    }
                                                                    type={
                                                                        "text"
                                                                    }
                                                                    value={
                                                                        this
                                                                            .state
                                                                            .data
                                                                            .name
                                                                    }
                                                                    onInputChange={
                                                                        this
                                                                            .onInputChange
                                                                    }
                                                                />
                                                                {this.Validator.showErrors(
                                                                    "name"
                                                                )}
                                                            </Col>
                                                        </Row>

                                                        <Row>
                                                            <Col md={6}>
                                                                <InputSelect
                                                                    id_label={
                                                                        "producerId"
                                                                    }
                                                                    data={
                                                                        this
                                                                            .state
                                                                            .producerData
                                                                    }
                                                                    label={
                                                                        "Productora:"
                                                                    }
                                                                    dataDescription={[
                                                                        "name",
                                                                    ]}
                                                                    dataValue={
                                                                        "producerId"
                                                                    }
                                                                    selectedValue={Number(
                                                                        this
                                                                            .state
                                                                            .data
                                                                            .producerId
                                                                    )}
                                                                    onInputChange={
                                                                        this
                                                                            .onInputChange
                                                                    }
                                                                />
                                                            </Col>

                                                            <Col md={3}>
                                                                <Label
                                                                    htmlFor={
                                                                        "date"
                                                                    }
                                                                >
                                                                    Fecha:{" "}
                                                                </Label>
                                                                <Datetime
                                                                    inputProps={{
                                                                        readOnly: true,
                                                                    }}
                                                                    locale={
                                                                        "es-do"
                                                                    }
                                                                    utc={true}
                                                                    timeFormat={
                                                                        false
                                                                    }
                                                                    defaultValue={
                                                                        new Date()
                                                                    }
                                                                    dateFormat={
                                                                        DateFormat
                                                                    }
                                                                    closeOnSelect={
                                                                        true
                                                                    }
                                                                    value={
                                                                        new Date(
                                                                            this.state.data.creationDate
                                                                        )
                                                                    }
                                                                    onChange={(
                                                                        moment
                                                                    ) =>
                                                                        this.onDateChange(
                                                                            moment,
                                                                            "creationDate"
                                                                        )
                                                                    }
                                                                />
                                                            </Col>
                                                            <Col md={3}>
                                                                <InputForm
                                                                    id_label={
                                                                        "movieLength"
                                                                    }
                                                                    label={
                                                                        "Duración(Min):"
                                                                    }
                                                                    placeholder={
                                                                        ""
                                                                    }
                                                                    type={
                                                                        "number"
                                                                    }
                                                                    value={String(
                                                                        this
                                                                            .state
                                                                            .data
                                                                            .movieLength
                                                                    )}
                                                                    onInputChange={
                                                                        this
                                                                            .onInputChange
                                                                    }
                                                                />
                                                            </Col>
                                                        </Row>

                                                        <Row>
                                                            <Col md="6">
                                                                <InputForm
                                                                    id_label={
                                                                        "generalProducer"
                                                                    }
                                                                    label={
                                                                        "Productor General:"
                                                                    }
                                                                    placeholder={
                                                                        ""
                                                                    }
                                                                    type={
                                                                        "text"
                                                                    }
                                                                    value={
                                                                        this
                                                                            .state
                                                                            .data
                                                                            .generalProducer
                                                                    }
                                                                    onInputChange={
                                                                        this
                                                                            .onInputChange
                                                                    }
                                                                />
                                                                {this.Validator.showErrors(
                                                                    "generalProducer"
                                                                )}
                                                            </Col>

                                                            <Col md="6">
                                                                <InputForm
                                                                    id_label={
                                                                        "lineProducer"
                                                                    }
                                                                    label={
                                                                        "Productor de Línea:"
                                                                    }
                                                                    placeholder={
                                                                        ""
                                                                    }
                                                                    type={
                                                                        "text"
                                                                    }
                                                                    value={
                                                                        this
                                                                            .state
                                                                            .data
                                                                            .lineProducer
                                                                    }
                                                                    onInputChange={
                                                                        this
                                                                            .onInputChange
                                                                    }
                                                                />
                                                                {this.Validator.showErrors(
                                                                    "lineProducer"
                                                                )}
                                                            </Col>
                                                        </Row>

                                                        <Row>
                                                            <Col md="6">
                                                                <InputForm
                                                                    id_label={
                                                                        "legalManager"
                                                                    }
                                                                    label={
                                                                        "Gestor Legal:"
                                                                    }
                                                                    placeholder={
                                                                        ""
                                                                    }
                                                                    type={
                                                                        "text"
                                                                    }
                                                                    value={
                                                                        this
                                                                            .state
                                                                            .data
                                                                            .legalManager
                                                                    }
                                                                    onInputChange={
                                                                        this
                                                                            .onInputChange
                                                                    }
                                                                />
                                                                {this.Validator.showErrors(
                                                                    "legalManager"
                                                                )}
                                                            </Col>

                                                            <Col md="6">
                                                                <InputSelect
                                                                    id_label={
                                                                        "notaryId"
                                                                    }
                                                                    data={
                                                                        this
                                                                            .state
                                                                            .notaryData
                                                                    }
                                                                    label={
                                                                        "Notario:"
                                                                    }
                                                                    dataDescription={[
                                                                        "name",
                                                                    ]}
                                                                    dataValue={
                                                                        "notaryId"
                                                                    }
                                                                    selectedValue={Number(
                                                                        this
                                                                            .state
                                                                            .data
                                                                            .notaryId
                                                                    )}
                                                                    onInputChange={
                                                                        this
                                                                            .onInputChange
                                                                    }
                                                                />
                                                            </Col>
                                                        </Row>

                                                        <Row>
                                                            <Col md={6}>
                                                                <Row>
                                                                    <Col
                                                                        md={12}
                                                                    >
                                                                        <small>
                                                                            Fecha
                                                                            de
                                                                            Filmación
                                                                        </small>
                                                                        <hr
                                                                            style={{
                                                                                marginTop:
                                                                                    "0rem",
                                                                            }}
                                                                        />
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col
                                                                        md={12}
                                                                    >
                                                                        <Row>
                                                                            <Col
                                                                                md={
                                                                                    6
                                                                                }
                                                                            >
                                                                                <Label
                                                                                    htmlFor={
                                                                                        "date"
                                                                                    }
                                                                                >
                                                                                    Desde:{" "}
                                                                                </Label>
                                                                                <Datetime
                                                                                    inputProps={{
                                                                                        readOnly: true,
                                                                                    }}
                                                                                    locale={
                                                                                        "es-do"
                                                                                    }
                                                                                    utc={
                                                                                        true
                                                                                    }
                                                                                    timeFormat={
                                                                                        false
                                                                                    }
                                                                                    defaultValue={
                                                                                        new Date()
                                                                                    }
                                                                                    dateFormat={
                                                                                        DateFormat
                                                                                    }
                                                                                    closeOnSelect={
                                                                                        true
                                                                                    }
                                                                                    value={
                                                                                        new Date(
                                                                                            this.state.data.filmingStartDate
                                                                                        )
                                                                                    }
                                                                                    onChange={(
                                                                                        moment
                                                                                    ) =>
                                                                                        this.onDateChange(
                                                                                            moment,
                                                                                            "filmingStartDate"
                                                                                        )
                                                                                    }
                                                                                />
                                                                            </Col>
                                                                            <Col
                                                                                md={
                                                                                    6
                                                                                }
                                                                            >
                                                                                <Label
                                                                                    htmlFor={
                                                                                        "date"
                                                                                    }
                                                                                >
                                                                                    Hasta:{" "}
                                                                                </Label>
                                                                                <Datetime
                                                                                    inputProps={{
                                                                                        readOnly: true,
                                                                                    }}
                                                                                    locale={
                                                                                        "es-do"
                                                                                    }
                                                                                    utc={
                                                                                        true
                                                                                    }
                                                                                    timeFormat={
                                                                                        false
                                                                                    }
                                                                                    defaultValue={
                                                                                        new Date()
                                                                                    }
                                                                                    dateFormat={
                                                                                        DateFormat
                                                                                    }
                                                                                    closeOnSelect={
                                                                                        true
                                                                                    }
                                                                                    value={
                                                                                        new Date(
                                                                                            this.state.data.filmingEndDate
                                                                                        )
                                                                                    }
                                                                                    onChange={(
                                                                                        moment
                                                                                    ) =>
                                                                                        this.onDateChange(
                                                                                            moment,
                                                                                            "filmingEndDate"
                                                                                        )
                                                                                    }
                                                                                />
                                                                            </Col>
                                                                        </Row>
                                                                    </Col>
                                                                </Row>
                                                            </Col>

                                                            <Col md={6}>
                                                                <Row>
                                                                    <Col
                                                                        md={12}
                                                                    >
                                                                        <small>
                                                                            PUR
                                                                        </small>
                                                                        <hr
                                                                            style={{
                                                                                marginTop:
                                                                                    "0rem",
                                                                            }}
                                                                        />
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col
                                                                        md={12}
                                                                    >
                                                                        <Row>
                                                                            <Col md="6">
                                                                                <InputForm
                                                                                    id_label={
                                                                                        "pur"
                                                                                    }
                                                                                    label={
                                                                                        "Número:"
                                                                                    }
                                                                                    placeholder={
                                                                                        ""
                                                                                    }
                                                                                    type={
                                                                                        "text"
                                                                                    }
                                                                                    value={
                                                                                        this
                                                                                            .state
                                                                                            .data
                                                                                            .pur
                                                                                    }
                                                                                    onInputChange={
                                                                                        this
                                                                                            .onInputChange
                                                                                    }
                                                                                />
                                                                                {this.Validator.showErrors(
                                                                                    "pur"
                                                                                )}
                                                                            </Col>
                                                                            <Col md="6">
                                                                                <Label
                                                                                    htmlFor={
                                                                                        "date"
                                                                                    }
                                                                                >
                                                                                    Fecha:{" "}
                                                                                </Label>
                                                                                <Datetime
                                                                                    inputProps={{
                                                                                        readOnly: true,
                                                                                    }}
                                                                                    locale={
                                                                                        "es-do"
                                                                                    }
                                                                                    utc={
                                                                                        true
                                                                                    }
                                                                                    timeFormat={
                                                                                        false
                                                                                    }
                                                                                    defaultValue={
                                                                                        new Date()
                                                                                    }
                                                                                    dateFormat={
                                                                                        DateFormat
                                                                                    }
                                                                                    closeOnSelect={
                                                                                        true
                                                                                    }
                                                                                    value={
                                                                                        new Date(
                                                                                            this.state.data.purdate
                                                                                        )
                                                                                    }
                                                                                    onChange={(
                                                                                        moment
                                                                                    ) =>
                                                                                        this.onDateChange(
                                                                                            moment,
                                                                                            "purdate"
                                                                                        )
                                                                                    }
                                                                                />
                                                                            </Col>
                                                                        </Row>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col md={12}>
                                                                <Row>
                                                                    <Col
                                                                        style={{
                                                                            marginTop:
                                                                                "1rem",
                                                                        }}
                                                                        md={12}
                                                                    >
                                                                        <h5>
                                                                            PUR
                                                                        </h5>
                                                                        <hr
                                                                            style={{
                                                                                marginTop:
                                                                                    "0rem",
                                                                            }}
                                                                        />
                                                                    </Col>
                                                                </Row>

                                                                <Row>
                                                                    <Col
                                                                        md={12}
                                                                    >
                                                                        <Row>
                                                                            <Col md="3">
                                                                                <FormGroup>
                                                                                    <InputForm
                                                                                        id_label={
                                                                                            "PUR"
                                                                                        }
                                                                                        label={
                                                                                            "Número:"
                                                                                        }
                                                                                        placeholder={
                                                                                            ""
                                                                                        }
                                                                                        type={
                                                                                            "text"
                                                                                        }
                                                                                        value={this.getValueFromDocument(
                                                                                            ProjectDocTypePUR,
                                                                                            "value"
                                                                                        )}
                                                                                        onInputChange={(
                                                                                            event
                                                                                        ) =>
                                                                                            this.onInputChange(
                                                                                                event,
                                                                                                true,
                                                                                                ProjectDocTypePUR,
                                                                                                "value"
                                                                                            )
                                                                                        }
                                                                                    />
                                                                                    {this.Validator.showErrors(
                                                                                        "pur"
                                                                                    )}
                                                                                </FormGroup>
                                                                            </Col>
                                                                            <Col
                                                                                md={
                                                                                    2
                                                                                }
                                                                            >
                                                                                <Label
                                                                                    htmlFor={
                                                                                        "purIssuedAt"
                                                                                    }
                                                                                >
                                                                                    Expedición:{" "}
                                                                                </Label>
                                                                                <Datetime
                                                                                    inputProps={{
                                                                                        readOnly: true,
                                                                                    }}
                                                                                    locale={
                                                                                        "es-do"
                                                                                    }
                                                                                    utc={
                                                                                        true
                                                                                    }
                                                                                    timeFormat={
                                                                                        false
                                                                                    }
                                                                                    defaultValue={
                                                                                        new Date()
                                                                                    }
                                                                                    dateFormat={
                                                                                        DateFormat
                                                                                    }
                                                                                    closeOnSelect={
                                                                                        true
                                                                                    }
                                                                                    value={
                                                                                        new Date(
                                                                                            this.getValueFromDocument(
                                                                                                ProjectDocTypePUR,
                                                                                                "issuedAt"
                                                                                            )
                                                                                        )
                                                                                    }
                                                                                    onChange={(
                                                                                        moment
                                                                                    ) =>
                                                                                        this.onDateChange(
                                                                                            moment,
                                                                                            "issuedAt",
                                                                                            ProjectDocTypePUR
                                                                                        )
                                                                                    }
                                                                                />
                                                                            </Col>
                                                                            <Col
                                                                                md={
                                                                                    2
                                                                                }
                                                                            >
                                                                                <Label
                                                                                    htmlFor={
                                                                                        "purExpires"
                                                                                    }
                                                                                >
                                                                                    Vence:{" "}
                                                                                </Label>
                                                                                <Datetime
                                                                                    inputProps={{
                                                                                        readOnly: true,
                                                                                    }}
                                                                                    locale={
                                                                                        "es-do"
                                                                                    }
                                                                                    utc={
                                                                                        true
                                                                                    }
                                                                                    timeFormat={
                                                                                        false
                                                                                    }
                                                                                    defaultValue={
                                                                                        new Date()
                                                                                    }
                                                                                    dateFormat={
                                                                                        DateFormat
                                                                                    }
                                                                                    closeOnSelect={
                                                                                        true
                                                                                    }
                                                                                    value={
                                                                                        new Date(
                                                                                            this.getValueFromDocument(
                                                                                                ProjectDocTypePUR,
                                                                                                "expires"
                                                                                            )
                                                                                        )
                                                                                    }
                                                                                    onChange={(
                                                                                        moment
                                                                                    ) =>
                                                                                        this.onDateChange(
                                                                                            moment,
                                                                                            "expires",
                                                                                            ProjectDocTypePUR
                                                                                        )
                                                                                    }
                                                                                />
                                                                            </Col>
                                                                            <Col
                                                                                md={
                                                                                    5
                                                                                }
                                                                            >
                                                                                {CyaHelper.ImageExists(
                                                                                    this.getValueFromDocument(
                                                                                        ProjectDocTypePUR,
                                                                                        "image"
                                                                                    )
                                                                                ) ? (
                                                                                    <InputImage
                                                                                        id_label={
                                                                                            "purImage"
                                                                                        }
                                                                                        width={
                                                                                            "fit-content"
                                                                                        }
                                                                                        label={
                                                                                            "Copia de PUR:"
                                                                                        }
                                                                                        acceptPDF={
                                                                                            true
                                                                                        }
                                                                                        image={this.getValueFromDocument(
                                                                                            ProjectDocTypePUR,
                                                                                            "image"
                                                                                        )}
                                                                                        hash={
                                                                                            this.getValueFromDocument(
                                                                                                ProjectDocTypePUR,
                                                                                                "image"
                                                                                            )
                                                                                                .hash
                                                                                        }
                                                                                        showViewButton={
                                                                                            true
                                                                                        }
                                                                                        showImageMagnify={
                                                                                            false
                                                                                        }
                                                                                    />
                                                                                ) : (
                                                                                    <div>
                                                                                        <InputImage
                                                                                            id_label={
                                                                                                "purImage"
                                                                                            }
                                                                                            width={
                                                                                                "fit-content"
                                                                                            }
                                                                                            label={
                                                                                                "Copia de PUR:"
                                                                                            }
                                                                                            acceptPDF={
                                                                                                true
                                                                                            }
                                                                                            image={this.getValueFromDocument(
                                                                                                ProjectDocTypePUR,
                                                                                                "image"
                                                                                            )}
                                                                                            showViewButton={
                                                                                                true
                                                                                            }
                                                                                            showImageMagnify={
                                                                                                false
                                                                                            }
                                                                                        />
                                                                                    </div>
                                                                                )}
                                                                            </Col>
                                                                        </Row>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                        </Row>

                                                        <Row>
                                                            <Col md={12}>
                                                                <Row>
                                                                    <Col
                                                                        style={{
                                                                            marginTop:
                                                                                "1rem",
                                                                        }}
                                                                        md={12}
                                                                    >
                                                                        <h5>
                                                                            CPND
                                                                        </h5>
                                                                        <hr
                                                                            style={{
                                                                                marginTop:
                                                                                    "0rem",
                                                                            }}
                                                                        />
                                                                    </Col>
                                                                </Row>

                                                                <Row>
                                                                    <Col
                                                                        md={12}
                                                                    >
                                                                        <Row>
                                                                            <Col md="3">
                                                                                <FormGroup>
                                                                                    <InputForm
                                                                                        id_label={
                                                                                            "CPND"
                                                                                        }
                                                                                        label={
                                                                                            "Número:"
                                                                                        }
                                                                                        placeholder={
                                                                                            ""
                                                                                        }
                                                                                        type={
                                                                                            "text"
                                                                                        }
                                                                                        value={this.getValueFromDocument(
                                                                                            ProjectDocTypeCPND,
                                                                                            "value"
                                                                                        )}
                                                                                        onInputChange={(
                                                                                            event
                                                                                        ) =>
                                                                                            this.onInputChange(
                                                                                                event,
                                                                                                true,
                                                                                                ProjectDocTypeCPND,
                                                                                                "value"
                                                                                            )
                                                                                        }
                                                                                    />
                                                                                    {this.Validator.showErrors(
                                                                                        "cpnd"
                                                                                    )}
                                                                                </FormGroup>
                                                                            </Col>
                                                                            <Col
                                                                                md={
                                                                                    2
                                                                                }
                                                                            >
                                                                                <Label
                                                                                    htmlFor={
                                                                                        "cpndIssuedAt"
                                                                                    }
                                                                                >
                                                                                    Expedición:{" "}
                                                                                </Label>
                                                                                <Datetime
                                                                                    inputProps={{
                                                                                        readOnly: true,
                                                                                    }}
                                                                                    locale={
                                                                                        "es-do"
                                                                                    }
                                                                                    utc={
                                                                                        true
                                                                                    }
                                                                                    timeFormat={
                                                                                        false
                                                                                    }
                                                                                    defaultValue={
                                                                                        new Date()
                                                                                    }
                                                                                    dateFormat={
                                                                                        DateFormat
                                                                                    }
                                                                                    closeOnSelect={
                                                                                        true
                                                                                    }
                                                                                    value={
                                                                                        new Date(
                                                                                            this.getValueFromDocument(
                                                                                                ProjectDocTypeCPND,
                                                                                                "issuedAt"
                                                                                            )
                                                                                        )
                                                                                    }
                                                                                    onChange={(
                                                                                        moment
                                                                                    ) =>
                                                                                        this.onDateChange(
                                                                                            moment,
                                                                                            "issuedAt",
                                                                                            ProjectDocTypeCPND
                                                                                        )
                                                                                    }
                                                                                />
                                                                            </Col>
                                                                            <Col
                                                                                md={
                                                                                    2
                                                                                }
                                                                            >
                                                                                <Label
                                                                                    htmlFor={
                                                                                        "cpndExpires"
                                                                                    }
                                                                                >
                                                                                    Vence:{" "}
                                                                                </Label>
                                                                                <Datetime
                                                                                    inputProps={{
                                                                                        readOnly: true,
                                                                                    }}
                                                                                    locale={
                                                                                        "es-do"
                                                                                    }
                                                                                    utc={
                                                                                        true
                                                                                    }
                                                                                    timeFormat={
                                                                                        false
                                                                                    }
                                                                                    defaultValue={
                                                                                        new Date()
                                                                                    }
                                                                                    dateFormat={
                                                                                        DateFormat
                                                                                    }
                                                                                    closeOnSelect={
                                                                                        true
                                                                                    }
                                                                                    value={
                                                                                        new Date(
                                                                                            this.getValueFromDocument(
                                                                                                ProjectDocTypeCPND,
                                                                                                "expires"
                                                                                            )
                                                                                        )
                                                                                    }
                                                                                    onChange={(
                                                                                        moment
                                                                                    ) =>
                                                                                        this.onDateChange(
                                                                                            moment,
                                                                                            "expires",
                                                                                            ProjectDocTypeCPND
                                                                                        )
                                                                                    }
                                                                                />
                                                                            </Col>
                                                                            <Col
                                                                                md={
                                                                                    5
                                                                                }
                                                                            >
                                                                                {CyaHelper.ImageExists(
                                                                                    this.getValueFromDocument(
                                                                                        ProjectDocTypeCPND,
                                                                                        "image"
                                                                                    )
                                                                                ) ? (
                                                                                    <InputImage
                                                                                        id_label={
                                                                                            "cpndImage"
                                                                                        }
                                                                                        width={
                                                                                            "fit-content"
                                                                                        }
                                                                                        label={
                                                                                            "Copia de CPND:"
                                                                                        }
                                                                                        acceptPDF={
                                                                                            true
                                                                                        }
                                                                                        image={this.getValueFromDocument(
                                                                                            ProjectDocTypeCPND,
                                                                                            "image"
                                                                                        )}
                                                                                        hash={
                                                                                            this.getValueFromDocument(
                                                                                                ProjectDocTypeCPND,
                                                                                                "image"
                                                                                            )
                                                                                                .hash
                                                                                        }
                                                                                        showViewButton={
                                                                                            true
                                                                                        }
                                                                                        showImageMagnify={
                                                                                            false
                                                                                        }
                                                                                    />
                                                                                ) : (
                                                                                    <div>
                                                                                        <InputImage
                                                                                            id_label={
                                                                                                "cpndImage"
                                                                                            }
                                                                                            width={
                                                                                                "fit-content"
                                                                                            }
                                                                                            label={
                                                                                                "Copia de CPND:"
                                                                                            }
                                                                                            acceptPDF={
                                                                                                true
                                                                                            }
                                                                                            image={this.getValueFromDocument(
                                                                                                ProjectDocTypeCPND,
                                                                                                "image"
                                                                                            )}
                                                                                            showViewButton={
                                                                                                true
                                                                                            }
                                                                                            showImageMagnify={
                                                                                                false
                                                                                            }
                                                                                        />
                                                                                    </div>
                                                                                )}
                                                                            </Col>
                                                                        </Row>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                        </Row>

                                                        <Row>
                                                            <Col md={12}>
                                                                <Row>
                                                                    <Col
                                                                        style={{
                                                                            marginTop:
                                                                                "1rem",
                                                                        }}
                                                                        md={12}
                                                                    >
                                                                        <h5>
                                                                            CDND
                                                                        </h5>
                                                                        <hr
                                                                            style={{
                                                                                marginTop:
                                                                                    "0rem",
                                                                            }}
                                                                        />
                                                                    </Col>
                                                                </Row>

                                                                <Row>
                                                                    <Col
                                                                        md={12}
                                                                    >
                                                                        <Row>
                                                                            <Col md="3">
                                                                                <FormGroup>
                                                                                    <InputForm
                                                                                        id_label={
                                                                                            "CDND"
                                                                                        }
                                                                                        label={
                                                                                            "Número:"
                                                                                        }
                                                                                        placeholder={
                                                                                            ""
                                                                                        }
                                                                                        type={
                                                                                            "text"
                                                                                        }
                                                                                        value={this.getValueFromDocument(
                                                                                            ProjectDocTypeCDND,
                                                                                            "value"
                                                                                        )}
                                                                                        onInputChange={(
                                                                                            event
                                                                                        ) =>
                                                                                            this.onInputChange(
                                                                                                event,
                                                                                                true,
                                                                                                ProjectDocTypeCDND,
                                                                                                "value"
                                                                                            )
                                                                                        }
                                                                                    />
                                                                                    {this.Validator.showErrors(
                                                                                        "cpnd"
                                                                                    )}
                                                                                </FormGroup>
                                                                            </Col>
                                                                            <Col
                                                                                md={
                                                                                    2
                                                                                }
                                                                            >
                                                                                <Label
                                                                                    htmlFor={
                                                                                        "cdndIssuedAt"
                                                                                    }
                                                                                >
                                                                                    Expedición:{" "}
                                                                                </Label>
                                                                                <Datetime
                                                                                    inputProps={{
                                                                                        readOnly: true,
                                                                                    }}
                                                                                    locale={
                                                                                        "es-do"
                                                                                    }
                                                                                    utc={
                                                                                        true
                                                                                    }
                                                                                    timeFormat={
                                                                                        false
                                                                                    }
                                                                                    defaultValue={
                                                                                        new Date()
                                                                                    }
                                                                                    dateFormat={
                                                                                        DateFormat
                                                                                    }
                                                                                    closeOnSelect={
                                                                                        true
                                                                                    }
                                                                                    value={
                                                                                        new Date(
                                                                                            this.getValueFromDocument(
                                                                                                ProjectDocTypeCDND,
                                                                                                "issuedAt"
                                                                                            )
                                                                                        )
                                                                                    }
                                                                                    onChange={(
                                                                                        moment
                                                                                    ) =>
                                                                                        this.onDateChange(
                                                                                            moment,
                                                                                            "issuedAt",
                                                                                            ProjectDocTypeCDND
                                                                                        )
                                                                                    }
                                                                                />
                                                                            </Col>
                                                                            <Col
                                                                                md={
                                                                                    2
                                                                                }
                                                                            >
                                                                                <Label
                                                                                    htmlFor={
                                                                                        "cdndExpires"
                                                                                    }
                                                                                >
                                                                                    Vence:{" "}
                                                                                </Label>
                                                                                <Datetime
                                                                                    inputProps={{
                                                                                        readOnly: true,
                                                                                    }}
                                                                                    locale={
                                                                                        "es-do"
                                                                                    }
                                                                                    utc={
                                                                                        true
                                                                                    }
                                                                                    timeFormat={
                                                                                        false
                                                                                    }
                                                                                    defaultValue={
                                                                                        new Date()
                                                                                    }
                                                                                    dateFormat={
                                                                                        DateFormat
                                                                                    }
                                                                                    closeOnSelect={
                                                                                        true
                                                                                    }
                                                                                    value={
                                                                                        new Date(
                                                                                            this.getValueFromDocument(
                                                                                                ProjectDocTypeCDND,
                                                                                                "expires"
                                                                                            )
                                                                                        )
                                                                                    }
                                                                                    onChange={(
                                                                                        moment
                                                                                    ) =>
                                                                                        this.onDateChange(
                                                                                            moment,
                                                                                            "expires",
                                                                                            ProjectDocTypeCDND
                                                                                        )
                                                                                    }
                                                                                />
                                                                            </Col>
                                                                            <Col
                                                                                md={
                                                                                    5
                                                                                }
                                                                            >
                                                                                {CyaHelper.ImageExists(
                                                                                    this.getValueFromDocument(
                                                                                        ProjectDocTypeCDND,
                                                                                        "image"
                                                                                    )
                                                                                ) ? (
                                                                                    <InputImage
                                                                                        id_label={
                                                                                            "cdndImage"
                                                                                        }
                                                                                        width={
                                                                                            "fit-content"
                                                                                        }
                                                                                        label={
                                                                                            "Copia de CDND:"
                                                                                        }
                                                                                        acceptPDF={
                                                                                            true
                                                                                        }
                                                                                        image={this.getValueFromDocument(
                                                                                            ProjectDocTypeCDND,
                                                                                            "image"
                                                                                        )}
                                                                                        hash={
                                                                                            this.getValueFromDocument(
                                                                                                ProjectDocTypeCDND,
                                                                                                "image"
                                                                                            )
                                                                                                .hash
                                                                                        }
                                                                                        showViewButton={
                                                                                            true
                                                                                        }
                                                                                        showImageMagnify={
                                                                                            false
                                                                                        }
                                                                                    />
                                                                                ) : (
                                                                                    <div>
                                                                                        <InputImage
                                                                                            id_label={
                                                                                                "cdndImage"
                                                                                            }
                                                                                            width={
                                                                                                "fit-content"
                                                                                            }
                                                                                            label={
                                                                                                "Copia de CDND:"
                                                                                            }
                                                                                            acceptPDF={
                                                                                                true
                                                                                            }
                                                                                            image={this.getValueFromDocument(
                                                                                                ProjectDocTypeCDND,
                                                                                                "image"
                                                                                            )}
                                                                                            showViewButton={
                                                                                                true
                                                                                            }
                                                                                            showImageMagnify={
                                                                                                false
                                                                                            }
                                                                                        />
                                                                                    </div>
                                                                                )}
                                                                            </Col>
                                                                        </Row>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col md={12}>
                                                                <Row>
                                                                    <Col
                                                                        style={{
                                                                            marginTop:
                                                                                "1rem",
                                                                        }}
                                                                        md={12}
                                                                    >
                                                                        <h5>
                                                                            Solicitante de pago
                                                                        </h5>
                                                                        <hr
                                                                            style={{
                                                                                marginTop:
                                                                                    "0rem",
                                                                            }}
                                                                        />
                                                                    </Col>
                                                                </Row>

                                                                <Row>
                                                                    <Col
                                                                        md={12}
                                                                    >
                                                                        <Row>
                                                                            <Col md="3">
                                                                              <InputForm
                                                                                 id_label={"payRequesterName"}
                                                                                 label={"Nombre:"}
                                                                                 placeholder={""}
                                                                                 type={"text"}
                                                                                 value={this.state.data.payRequesterName ?? ""}
                                                                                 onInputChange={this.onInputChange}
                                                                              />
                                                                              {this.Validator.showErrors("payRequesterName")}
                                                                            </Col>
                                                                            <Col md="3">
                                                                              <InputForm
                                                                                 id_label={"payRequesterEmail"}
                                                                                 label={"Correo:"}
                                                                                 placeholder={""}
                                                                                 type={"email"}
                                                                                 value={this.state.data.payRequesterEmail ?? ""}
                                                                                 onInputChange={this.onInputChange}
                                                                              />
                                                                              {this.Validator.showErrors("payRequesterEmail")}
                                                                            </Col>
                                                                            <Col >
                                                                              <div style={{
                                                                                 display: "flex",
                                                                                 alignItems: "flex-end",
                                                                                 height: "100%",
                                                                                 paddingBottom: "16px"
                                                                              }}>
                                                                                 <Button 
                                                                                    className="btn" 
                                                                                    onClick={() => {}} 
                                                                                    color="dark">
                                                                                    Reenviar enlace
                                                                                 </Button>
                                                                              </div>
                                                                            </Col>
                                                                        </Row>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                    </Form>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={12}>
                                                    <hr />
                                                    <input
                                                        type="submit"
                                                        disabled={
                                                            !this.validSaveButton()
                                                        }
                                                        className={
                                                            !this.validSaveButton()
                                                                ? "btn btn-primary disabled"
                                                                : "btn btn-primary"
                                                        }
                                                        value="Guardar"
                                                        onClick={this.onSubmit}
                                                    />
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </TabPane>
                            </TabContent>
                        </CardBody>
                    </Card>
                </div>
            </div>
        );
    }
}
CreateProject.contextType = CyaContext;
