import Check from "../views/check/Check.jsx";
import CreateCheck from "../views/check/CreateCheck.jsx";
import Annex from "../views/annex/Annex.jsx";
import CreateAnnex from "../views/annex/CreateAnnex.jsx";
import ProjectInvestor from "../views/project-investor/ProjectInvestor.jsx";
import CreateProjectInvestor from "../views/project-investor/CreateProjectInvestor.jsx";
import Item from "../views/item/Item";
import CreateItem from "../views/item/CreateItem";
import Budget from "../views/item/Budget";
import Report from "../views/report/Report";
import TaxReturn from "../views/tax-return/TaxReturn";
import Validation from "../views/validation/Validation";
import Bank from "../views/misc/Bank";
import ImportCrewCast from "../views/misc/ImportCrewCast";
import FilmingCountries from "../views/misc/FilmingCountries";
import AnnexCreditNote from "../views/annex/AnnexCreditNote";
import CreateAnnexCreditNote from "../views/annex/CreateAnnexCreditNote";
import ProjectPerson from "../views/person/ProjectPerson";

import CreateBudget from "../views/budget-cost/create";
import ListingBudget from "../views/budget-cost/listing";
import Dashboard from "../views/home/Dashboard";

let ThemeRoutes = [
   {
      navlabel: true,
      name: "Personal",
      icon: "mdi mdi-dots-horizontal",
   },
   {
      collapse: true,
      path: "/dashboard",
      name: "Principal",
      state: "dashboardsname",
      icon: "mdi mdi-view-dashboard",
      child: [
         {
            path: "/dashboard",
            name: "CYA",
            mini: "B",
            icon: "mdi mdi-view-dashboard",
            component: Dashboard,
         },
         // {
         //     path: "/dashboard",
         //     name: "Booker",
         //     mini: "B",
         //     icon: "mdi mdi-view-dashboard",
         //     component: Dashboard
         // }
      ],
   },

   {
      collapse: false,
      path: "/person",
      name: "Personal",
      state: "personname",
      icon: "fas fa-user-circle",
      component: ProjectPerson,
   },
   {
      collapse: true,
      path: "/checks",
      name: "Cheques",
      state: "chequesname",
      icon: "fas fa-edit",
      child: [
         {
            path: "/checks",
            name: "Listado",
            mini: "B",
            icon: "mdi mdi-format-list-bulleted",
            component: Check,
         },
         {
            path: "/checks/new",
            name: "Crear",
            mini: "B",
            icon: "mdi mdi-plus-circle",
            component: CreateCheck,
         },
      ],
   },
   {
      collapse: true,
      path: "/annexes",
      name: "Anexos",
      state: "anexosname",
      icon: "mdi mdi-file-multiple",
      child: [
         {
            path: "/annexes",
            name: "Listar anexos",
            mini: "B",
            icon: "mdi mdi-format-list-bulleted",
            component: Annex,
         },
         {
            path: "/creditnotes",
            name: "Listar notas de crédito",
            mini: "B",
            icon: "mdi mdi-format-list-bulleted",
            component: AnnexCreditNote,
         },
         {
            path: "/annexes/new",
            name: "Crear anexo",
            mini: "B",
            icon: "mdi mdi-plus-circle",
            component: CreateAnnex,
         },
         {
            path: "/creditnotes/new",
            name: "Crear nota de crédito",
            mini: "B",
            icon: "mdi mdi-plus-circle",
            component: CreateAnnexCreditNote,
         },
      ],
   },
   {
      collapse: true,
      path: "/reports",
      name: "Reportes",
      state: "reportesname",
      icon: "fas fa-clipboard-list",
      child: [
         {
            path: "/reports/audit",
            name: "Auditoria",
            mini: "B",
            icon: "mdi mdi-checkbox-multiple-marked-outline",
            component: Report,
         },
         {
            path: "/reports/personal",
            name: "Personal",
            mini: "B",
            icon: "mdi mdi-account-group-outline",
            component: Report,
         },
         {
            path: "/reports/check",
            name: "Cheques",
            mini: "B",
            icon: "fas fa-edit",
            component: Report,
         },
         {
            path: "/reports/annex",
            name: "Anexos",
            mini: "B",
            icon: "mdi mdi-file-multiple",
            component: Report,
         },
         {
            path: "/reports/investor",
            name: "Inversionistas",
            mini: "B",
            icon: "fas fa-hand-holding-usd",
            component: Report,
         },
         {
            path: "/reports/item",
            name: "Partidas",
            mini: "B",
            icon: "fas fa-file-alt",
            component: Report,
         },
         {
            path: "/reports/validation",
            name: "Validaciones",
            mini: "B",
            icon: "mdi mdi-checkbox-marked-outline",
            component: Report,
         },
      ],
   },
   {
      collapse: true,
      path: "/items",
      name: "Partidas",
      state: "partidasname",
      icon: "fas fa-file-alt",
      child: [
         {
            path: "/items",
            name: "Listado",
            mini: "B",
            icon: "mdi mdi-format-list-bulleted",
            component: Item,
         },
         {
            path: "/items/new",
            name: "Crear",
            mini: "B",
            icon: "mdi mdi-plus-circle",
            component: CreateItem,
         },
         {
            path: "/items/budget",
            name: "Importar",
            mini: "B",
            icon: "mdi mdi-cash-multiple",
            component: Budget,
         },
      ],
   },
   {
      collapse: true,
      path: "/budgetcost",
      name: "Estimación de costos",
      state: "partidasname",
      icon: "fas fa-file-alt",
      child: [
         {
            path: "/budgetcost/items",
            name: "Listado",
            mini: "B",
            icon: "mdi mdi-format-list-bulleted",
            component: ListingBudget,
         },
         {
            path: "/budgetcost/new",
            name: "Crear",
            mini: "B",
            icon: "mdi mdi-plus-circle",
            component: CreateBudget,
         },
      ],
   },
   {
      collapse: true,
      path: "/investors",
      name: "Inversionistas",
      state: "inversionistaname",
      icon: "fas fa-hand-holding-usd",
      child: [
         {
            path: "/investors",
            name: "Listado",
            mini: "B",
            icon: "mdi mdi-format-list-bulleted",
            component: ProjectInvestor,
         },
         {
            path: "/investors/new",
            name: "Crear",
            mini: "B",
            icon: "mdi mdi-plus-circle",
            component: CreateProjectInvestor,
         },
      ],
   },
   {
      collapse: false,
      path: "/taxreturn",
      name: "Impuestos",
      state: "Impuestosname",
      icon: "fas fa-dollar-sign",
      component: TaxReturn,
   },
   {
      collapse: false,
      path: "/validation",
      name: "Validaciones",
      state: "Validacionesname",
      icon: "mdi mdi-checkbox-marked-outline",
      component: Validation,
   },
   {
      collapse: true,
      path: "/misc",
      name: "Misc",
      state: "miscname",
      icon: "mdi mdi-blur",
      child: [
         {
            path: "/misc/banks",
            name: "Bancos",
            mini: "B",
            icon: "mdi mdi-bank",
            component: Bank,
         },
         {
            path: "/misc/importcrewcast",
            name: "Importar Crew & Cast",
            mini: "B",
            icon: "fas fa-users",
            component: ImportCrewCast,
         },
         {
            path: "/misc/filmingcountries",
            name: "Países de Filmación",
            mini: "B",
            icon: "mdi mdi-film",
            component: FilmingCountries,
         },
      ],
   },
   {
      isVisible: false,
      path: "/checks/edit",
      name: "Editar",
      component: CreateCheck,
   },
   {
      isVisible: false,
      path: "/annexes/edit",
      name: "Editar",
      component: CreateAnnex,
   },
   {
      isVisible: false,
      path: "/creditnotes/edit",
      name: "Editar",
      component: CreateAnnexCreditNote,
   },
   {
      isVisible: false,
      path: "/investors/edit",
      name: "Editar",
      component: CreateProjectInvestor,
   },
   {
      isVisible: false,
      path: "/items/edit",
      name: "Editar",
      component: CreateItem,
   },
   // {
   //     collapse: false,
   //     path: "/projectdocuments",
   //     name: "Documentos",
   //     state: "projectdocumentsname",
   //     icon: "mdi mdi-film",
   //     component: ProjectDocuments
   // },

   //Dejar este de ultimo
   {
      path: "/",
      pathTo: "/dashboard",
      name: "Dashboard",
      redirect: true,
   },
];
export default ThemeRoutes;
