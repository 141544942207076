import React from "react";
import PropTypes from "prop-types";

export class CheckValidator extends React.Component {

    constructor(props) {
        super(props);
        this.isValid = [];
        this.checkDatesOfInvestorDeposit = this.checkDatesOfInvestorDeposit.bind(this);
        this.checkSumOfInvestorNewAmount = this.checkSumOfInvestorNewAmount.bind(this);
        this.checkOnlyInvestorForCheckTypeDeposit = this.checkOnlyInvestorForCheckTypeDeposit.bind(this);
        this.finalsChecksAndDepositsSameInvestor = this.finalsChecksAndDepositsSameInvestor.bind(this);
        this.checkIfThereIsInvestor = this.checkIfThereIsInvestor.bind(this);
        this.investorOverdraftWarning = this.investorOverdraftWarning.bind(this);
        this.finalsChecksAndDepositsOneInvestor = this.finalsChecksAndDepositsOneInvestor.bind(this);
        this.checkInvestorZero = this.checkInvestorZero.bind(this);
        this.checkInvestorBlocked = this.checkInvestorBlocked.bind(this);
        this.checkIfExistsCheckNumberd = this.checkIfExistsCheckNumberd.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.props.callbackFromParent(this.isValid.every(x => x));
    }

    //1- La fecha no puede ser anterior a la fecha de deposito del inversionista.
    checkDatesOfInvestorDeposit() {
        if (this.props.model.investorCheck.length > 0 && !this.props.model.pettyCash) {
            return this.props.model.investorCheck.find(x => new Date(this.props.model.date) < new Date(x.depositDate)) === undefined;
        }
        return true;
    }

    //2- La suma del monto de los inversionistas debe se igual al monto utilizado del cheque. aplica para cheque final
    checkSumOfInvestorNewAmount() {
        if (this.props.model.investorCheck.length > 0 && Number(this.props.model.checkTypeId) === 1) {
            let sum = this.props.model.investorCheck.reduce(function (prev, cur) {
                return prev + cur.amount;
            }, 0);
            return sum === this.props.model.usedAmount;
        }
        return true;
    }

    //3- Cheques de abono no pueden ser divididos entre diferentes inversionistas
    checkOnlyInvestorForCheckTypeDeposit() {
        if (Number(this.props.model.checkTypeId) === 2) {
            return this.props.model.investorCheck.length === 1;
        }
        return true;
    }

    //4- Cheques finales y sus abonos deben de todos pertenecer al mismo inversionista.
    finalsChecksAndDepositsSameInvestor() {
        if (this.props.model.investorCheck.length === 1 && this.props.model.children.length > 0 && this.props.model.checkTypeId === 1) {
            let finalCheckInvestor = this.props.model.investorCheck[0].investorId;
            return this.props.model.children.every(x => x.investorId === finalCheckInvestor);
        }
        return true;
    }

    //5- Cheques finales con abonos no pueden tener multiples inversionistas.
    finalsChecksAndDepositsOneInvestor() {
        if (this.props.model.checkTypeId === 1 && this.props.model.children.length > 0)
            return this.props.model.investorCheck.length === 1;
        return true;
    }

    //6- Permitir inversionistas sobregirados (solo administradores y que presente un warning).
    investorOverdraftWarning() {
        if (this.props.model.pettyCash)
            return true;
        return this.props.model.investorCheck.find(x => x.amount > x.amountAvailable) === undefined;
    }

    // 7- Todos los inversionistas deben tener un monto mayor a cero
    checkInvestorZero() {
        if (this.props.model.pettyCash)
            return true;
        return this.props.model.investorCheck.every(x => x.amount > 0);
    }

    // 8- debe tener inversionista cualquier tipo de cheque, exceptuando que seas caja chica.
    checkIfThereIsInvestor() {
        if (this.props.model.pettyCash)
            return true;
        return !(this.props.model.investorCheck.length === 0 && this.props.model.pettyCash === false);
    }

    // 9- Si al menos un inversionista esta bloqueado, no permitir salvar
    checkInvestorBlocked() {
        return !this.props.model.investorBlocked;
    }

    // 10- Si existe un numero de cheque con el mismo bamco
    checkIfExistsCheckNumberd() {
        return !this.props.model.ifExistsCheckNumber;
    }

    showResponseError = () => {
        return (
            this.props.responseError && 
            <li className={"list-group-item list-group-item-danger"}>
                {`ERROR: ${this.props.responseError.data}`}
            </li>)
    }

    render() {
        this.isValid = [];
        this.isValid.push(this.checkDatesOfInvestorDeposit());
        this.isValid.push(this.checkSumOfInvestorNewAmount());
        this.isValid.push(this.checkOnlyInvestorForCheckTypeDeposit());
        this.isValid.push(this.finalsChecksAndDepositsSameInvestor());
        this.isValid.push(this.checkIfThereIsInvestor());
        this.isValid.push(this.finalsChecksAndDepositsOneInvestor());
        this.isValid.push(this.checkInvestorZero());
        this.isValid.push(this.checkInvestorBlocked());
        this.isValid.push(this.checkIfExistsCheckNumberd());
        let x = 0;

        return <ul className={"list-group"}>
            {this.isValid[x++] ? "" :
                <li className={"list-group-item list-group-item-danger"}>ERROR: La fecha del cheque no puede ser
                    anterior que la fecha de depósito de cualquiera de los inversionistas</li>}
            {this.isValid[x++] ? "" :
                <li className={"list-group-item list-group-item-danger"}>ERROR: La suma del monto de los inversionistas
                    debe se igual al monto utilizado del cheque.</li>}
            {this.isValid[x++] ? "" :
                <li className={"list-group-item list-group-item-danger"}>ERROR: Cheques de abono no pueden ser divididos
                    entre diferentes inversionistas.</li>}
            {this.isValid[x++] ? "" :
                <li className={"list-group-item list-group-item-danger"}>ERROR: Cheques finales y sus abonos deben de
                    todos pertenecer al mismo inversionista.</li>}
            {this.isValid[x++] ? "" :
                <li className={"list-group-item list-group-item-danger"}>ERROR: Seleccionar al menos un
                    inversionista.</li>}
            {this.isValid[x++] ? "" :
                <li className={"list-group-item list-group-item-danger"}>ERROR: Cheques finales con abonos no pueden
                    tener multiples inversionistas.</li>}
            {this.isValid[x++] ? "" :
                <li className={"list-group-item list-group-item-danger"}>ERROR: Todos los inversionistas deben tener
                    un monto mayor que cero.</li>}
            {this.isValid[x++] ? "" :
                <li className={"list-group-item list-group-item-danger"}>ERROR: Al menos un inversionista del cheque se
                    encuentra bloqueado, no se puede modificar el cheque</li>}
            {this.isValid[x] ? "" :
                <li className={"list-group-item list-group-item-danger"}>ERROR: Existe un numero de cheque con el mismo
                    banco.</li>}
            {this.investorOverdraftWarning() ? "" :
                <li className={"list-group-item list-group-item-warning"}>AVISO: No queda monto suficiente en al menos
                    un inversionista seleccionado.</li>}
            { this.showResponseError() }
        </ul>
    }
}

CheckValidator.propTypes = {
    model: PropTypes.object,
    callbackFromParent: PropTypes.func
};
