const ProjectFieldValidator = {
   name: {
      secondValue: null,
      rules: [
         {
            test: (value) => value.length > 0,
            message: "Campo en blanco",
         },
      ],
      errors: [],
      valid: false,
      state: "",
   },
   pur: {
      secondValue: null,
      rules: [
         {
            test: (value) => value.length > 0,
            message: "Campo en blanco",
         },
      ],
      errors: [],
      valid: false,
      state: "",
   },
   generalProducer: {
      secondValue: null,
      rules: [
         {
            test: (value) => value && value.length > 0,
            message: "Campo en blanco",
         },
      ],
      errors: [],
      valid: false,
      state: "",
   },
   lineProducer: {
      secondValue: null,
      rules: [
         {
            test: (value) => value && value.length > 0,
            message: "Campo en blanco",
         },
      ],
      errors: [],
      valid: false,
      state: "",
   },
   legalManager: {
      secondValue: null,
      rules: [
         {
            test: (value) => value.length > 0,
            message: "Campo en blanco",
         },
      ],
      errors: [],
      valid: false,
      state: "",
   },
   payRequesterName: {
      secondValue: null,
      rules: [],
      errors: [],
      valid: false,
      state: "",
   },
   payRequesterEmail: {
      secondValue: null,
      rules: [
         {
            test: (value) => {
               const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
               return emailRegex.test(String(value).toLowerCase());
            },
            message: "Email con formato incorrecto",
         },
      ],
      errors: [],
      valid: false,
      state: "",
   },
};

export default ProjectFieldValidator;
