import React from 'react';
import Moment from "moment";
import {DateFormat, SuccessResponse} from "../../const/api_consts";
import CyaHelper from "../../utils/CyaHelper";
import {CyaTableLayout} from "../../components/custom/tables/CyaTableLayout";
import CheckApiClient from "../../utils/api-client/CheckApiClient";
import Modal from "react-modal";
import {Col, Row, CustomInput, FormGroup} from "reactstrap";
import {toast} from "react-toastify";
import 'react-confirm-alert/src/react-confirm-alert.css'
import ItemApiClient from "../../utils/api-client/ItemApiClient";
import Select from 'react-select';
import ProjectInvestorApiClient from "../../utils/api-client/ProjectInvestorApiClient";
import AuthService from "../../utils/AuthService";

export default class Check extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            loading: true,
            loadingInvestor: false,
            selected: false,
            selectedRowId: -1,
            selectedMultiple: [],
            selectedMultipleData: [],
            itemData: [],
            investorData: [],
            title: "Cheques",
            modalMultipleActions: false,
            modalMassDelete: false,
            massItemCheckbox: false,
            massInvestorCheckbox: false,
            selectedItem: 0,
            selectedInvestor: 0,
            selectedRowData: {},
            deleteText: "Borrar",
            responseValidateInvestorOd: {resultCode: -1},
        };

        this.GetProps = this.GetProps.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.closeMassDeleteModal = this.closeMassDeleteModal.bind(this);
        this.closeModalAndOpenDeleteModal = this.closeModalAndOpenDeleteModal.bind(this);
        this.massiveDelete = this.massiveDelete.bind(this);
        this.onCheckboxChange = this.onCheckboxChange.bind(this);
        this.onItemChange = this.onItemChange.bind(this);
        this.onInvestorChange = this.onInvestorChange.bind(this);
        this.saveChangesMass = this.saveChangesMass.bind(this);
        this.validateButtonMassSave = this.validateButtonMassSave.bind(this);
        this.CheckApiClient = new CheckApiClient();
        this.ItemApiClient = new ItemApiClient();
        this.InvestorApiClient = new ProjectInvestorApiClient();
        this.Auth = new AuthService();
    }

    componentDidMount() {

        if (this.props.location.state !== undefined && this.props.location.state?.id) {
            this.CheckApiClient.GetByAnnexId(this.props.location.state.id).then(response =>
                this.setState({
                    data: response.data,
                    loading: false,
                    title: "Cheques del Anexo " + this.props.location.state.name
                }));
        } else {
            this.CheckApiClient.GetAll().then(response => this.setState({data: response.data, loading: false}));
        }

        this.ItemApiClient.GetListForAnnexes().then(response => {
            let items = response.data.map(x => {
                return {
                    value: x.itemId,
                    label: "[" + x.code + "] " + x.name,
                    parentItemId: x.parentItemId
                }
            });
            this.setState({itemData: items, loading: false});
        });

        this.setState({loadingInvestor: true});
        this.InvestorApiClient.GetListForChecks().then(response => {
            let investors = response.data.map(x => {
                return {
                    value: x.investorId,
                    label: x.name,
                }
            });
            this.setState({investorData: investors, loadingInvestor: false})
        });
    }

    GetProps(state, rowInfo) {
        if (rowInfo && rowInfo.row) {
            return {
                onClick: () => {
                    this.setState({
                        selected: rowInfo.index,
                        selectedRowId: rowInfo.row.checkId,
                        selectedRowData: this.state.data.find(x => x.checkId === rowInfo.row.checkId)
                    });
                },
                onDoubleClick: () => {
                    if (this.state.selected === rowInfo.index) {
                        this.props.history.push({
                            pathname: '/checks/edit',
                            state: {id: rowInfo.row.checkId}
                        });
                    }
                },
                style: {
                    background: rowInfo.index === this.state.selected ? '#00AFEC' : rowInfo.index % 2 === 0 ? '#DDDDDD' : '#FFFFFF',
                    color: rowInfo.index === this.state.selected ? 'white' : 'black'
                }
            }
        }
        return {};
    }

    selectedMultiple = (selectedMultiple) => {
        const self = this;
        let list = [];
        for (let key in selectedMultiple)
            if (selectedMultiple.hasOwnProperty(key) && selectedMultiple[key] === true) {
                list.push(Number(key));
            }
        let selectedMultipleData = [];
        list.map(function (name, index) {
            selectedMultipleData.push(self.state.data.find(x => x.checkId === Number(name)));
            return true;
        });
        this.setState({
            selectedMultiple: list,
            selectedMultipleData: selectedMultipleData,
            deleteText: (list.length > 0) ? "Borrar Seleccionados" : "Borrar",
        });
    };

    deleteCallback = () => {
        if (this.state.selectedMultiple.length > 0 || this.state.selectedRowId > 0) {
            this.setState({modalMassDelete: true});
        }
    };

    viewCallback = () => {
        if (this.state.selectedRowId >= 0) {
            this.props.history.push({
                pathname: '/checks/edit',
                state: {id: this.state.selectedRowId}
            });
        }
    };

    viewAnnexesCallback = () => {
        if (this.state.selectedRowId >= 0) {
            let check = this.state.data.find(x => x.checkId === this.state.selectedRowId);
            this.props.history.push({
                pathname: '/annexes',
                state: {id: this.state.selectedRowId, name: check.number + " - " + check.bankDescription}
            });
        }
    };

    multipleActionsCallback = () => {
        this.setState({modalMultipleActions: true});
    };

    closeModal() {
        if (this.state.modalMultipleActions)
            this.setState({modalMultipleActions: false});
    }

    closeModalAndOpenDeleteModal() {
        if (this.state.modalMultipleActions)
            this.setState({modalMultipleActions: false, modalMassDelete: true});
    }

    closeMassDeleteModal() {
        if (this.state.modalMassDelete)
            this.setState({modalMassDelete: false});
    }

    massiveDelete() {
        this.setState({loading: true});
        if (this.state.selectedMultiple.length > 0) {
            this.CheckApiClient.MassDelete(this.state.selectedMultiple)
                .then((response) => {
                    if (response !== SuccessResponse) {
                        toast.error("Error eliminado cheques.");
                    } else {
                        this.props.history.push({pathname: '/refresh/checks/'});
                        toast.success("Cheques eliminados.");
                    }
                }).catch(() => {
                toast.warn("Error eliminado cheques.");
            });
        } else {
            if (this.state.selectedRowId > 0) {
                this.CheckApiClient.Delete(this.state.selectedRowId).then((response) => {
                    if (response !== SuccessResponse) {
                        toast.error("Error eliminado cheque.");
                    } else {
                        this.props.history.push({pathname: '/refresh/checks/'});
                        toast.success("Cheque eliminado.");
                    }
                }).catch(() => {
                    toast.warn("Error eliminado cheque.");
                });
            }
        }
        this.setState({loading: false});
        this.closeMassDeleteModal();
    }

    onCheckboxChange(event) {
        if (event.target.name === "massItemCheckbox")
            this.setState({massItemCheckbox: event.target.checked});
        if (event.target.name === "massInvestorCheckbox")
            this.setState({massInvestorCheckbox: event.target.checked});
    }

    onItemChange(item) {
        if (this.state.selectedItem !== item) {
            this.setState({selectedItem: item});
        }
    }

    onInvestorChange(item) {
        if (this.state.selectedInvestor !== item) {
            this.setState({selectedInvestor: item});
        }
        this.setState({loadingInvestor: true});
        this.CheckApiClient.ValidateInvestorOd(item.value, this.state.selectedMultiple)
            .then((response) => {
                this.setState({responseValidateInvestorOd: response, loadingInvestor: false});

            });
    }

    saveChangesMass() {
        let sendValues = [];
        if (this.state.massItemCheckbox) {
            if (this.state.selectedItem===0 || this.state.selectedItem ===null){
                sendValues.push({name: "Item", value: 0 });
            } else if (this.state.selectedItem.value !==null){
                sendValues.push({name: "Item", value: this.state.selectedItem.value ? this.state.selectedItem.value:0 });
            }

        }
        if (this.state.massInvestorCheckbox && this.state.responseValidateInvestorOd.resultCode === 0) {
            sendValues.push({name: "ProjectInvestor", value: this.state.selectedInvestor.value});
        }

        if (sendValues.length > 0) {
            this.setState({loading: true});
            this.CheckApiClient.MassChange(sendValues, this.state.selectedMultiple)
                .then((response) => {
                    if (response !== SuccessResponse) {
                        toast.error("Error modificando cheques.");
                    } else {
                        this.props.history.push({pathname: '/refresh/checks/'});
                        toast.success("Cheques modificandos.");
                    }
                }).catch(() => {
                toast.error("Error modificando cheques.");
            });
            this.setState({loading: false});
            this.closeModal();
        }
    }

    validateButtonMassSave() {
        let valInvestor = false;
        if (this.state.massInvestorCheckbox) {
            valInvestor = (this.state.responseValidateInvestorOd.resultCode === 1 || this.state.responseValidateInvestorOd.resultCode === 2);
        }
        return !(this.state.massItemCheckbox || valInvestor);
    }

    render() {
        const {data} = this.state;
        const self = this;
        let columns = [
            {Header: "CheckId", accessor: "checkId", show: false},
            {Header: "No.", accessor: "number", width: 100},
            {Header: "Dirigido", accessor: "orderOf", minWidth: 250},
            {Header: "Concepto", accessor: "concept", width: 300},
            {
                Header: "Fecha ", id: "date", style: {textAlign: 'center'}, accessor: d => {
                    return Moment(d.date).local().format(DateFormat)
                }, width: 100
            },
            {Header: "Banco", accessor: "bankDescription", width: 200},
            {Header: "Tipo", accessor: "checkTypeDescription", width: 70},
            {Header: "Inversionista", accessor: "investors", width: 250},
            {Header: "Anexos", accessor: "annexes", width: 60},
            {
                Header: "Img", id: "img", style: {textAlign: 'center'}, accessor: "",
                Cell: props => {
                    return (
                        <input
                            type="checkbox"
                            className="checkbox"
                            readOnly={true}
                            checked={props.original.image === true}
                        />
                    );
                }, width: 50
            },
            {
                Header: "C.C.", id: "cc", style: {textAlign: 'center'}, accessor: "",
                Cell: props => {
                    return (
                        <input
                            type="checkbox"
                            className="checkbox"
                            readOnly={true}
                            checked={props.original.pettyCash === true}
                        />
                    );
                }, width: 50
            },
            {
                Header: "Monto",
                accessor: "amount",
                style: {textAlign: 'right'},
                Cell: props => CyaHelper.ToCurrency(props.value),
                width: 150
            },
            {
                Header: "Monto Usado",
                accessor: "usedAmount",
                style: {textAlign: 'right'},
                Cell: props => CyaHelper.ToCurrency(props.value),
                width: 150
            },
            {Header: "Comentario", accessor: "comment", width: 300}
        ];
        const customStyles = {
            content: {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)'
            }
        };
        Modal.setAppElement('body');

        return <div>
            <Modal
                isOpen={this.state.modalMassDelete}
                onRequestClose={this.closeMassDeleteModal}
                style={customStyles}
            >
                <Row>
                    <Col md={10}><h4>Confirmación</h4></Col>
                    <Col md={2} style={{textAlign: 'right'}}>
                        <button className={"btn btn-danger"} style={{textAlign: 'right'}}
                                onClick={this.closeMassDeleteModal}>X
                        </button>
                    </Col>
                </Row>
                <hr/>
                <h5>¿Desea eliminar los siguientes cheques? </h5>
                <ul>
                    {self.state.selectedMultiple.length > 0 ?
                        self.state.selectedMultiple.map(function (name, index) {
                            let check = self.state.data.find(x => x.checkId === Number(name));
                            return <li
                                key={index}>{check.number + ' - ' + check.orderOf + ' - ' + check.bankDescription + ' - ' + CyaHelper.ToCurrency(check.amount)}</li>;
                        })
                        : ""}

                    {self.state.selectedRowId > 0 && self.state.selectedMultiple.length === 0 ?
                        <li key={0}>{this.state.selectedRowData.number + ' - ' + this.state.selectedRowData.orderOf + ' - ' + this.state.selectedRowData.bankDescription + ' - ' + CyaHelper.ToCurrency(this.state.selectedRowData.amount)}</li>
                        : ""}
                </ul>
                <Row>
                    <Col md={12}>
                        <button className={"btn btn-danger float-right"} onClick={this.massiveDelete}>Eliminar
                        </button>
                    </Col>
                </Row>

            </Modal>

            <Modal
                isOpen={this.state.modalMultipleActions}
                onRequestClose={this.closeModal}
                style={customStyles}
            >
                <Row>
                    <Col md={10}><h4>Acciones Masivas</h4></Col>
                    <Col md={2} style={{textAlign: 'right'}}>
                        <button className={"btn btn-danger"} style={{textAlign: 'right'}} onClick={this.closeModal}>X
                        </button>
                    </Col>
                </Row>
                <hr/>
                <h5>Seleccione que propiedades desea cambiar de los cheques elegidos: </h5>
                <br/>
                <Row>
                    <Col md={4}>
                        <CustomInput type="checkbox"
                                     id="massInvestorCheckbox"
                                     name="massInvestorCheckbox"
                                     label="Inversionista "
                                     checked={this.state.massInvestorCheckbox}
                                     onChange={this.onCheckboxChange}
                        />
                    </Col>
                    <Col md={8}>
                        <FormGroup>
                            <Select
                                options={this.state.investorData}
                                id={"investorId"}
                                name={"investorId"}
                                isDisabled={!this.state.massInvestorCheckbox}
                                value={this.state.selectedInvestor}
                                isLoading={this.state.loadingInvestor}
                                placeholder={"Seleccione Inversionista"}
                                onChange={this.onInvestorChange}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md={4}>
                    </Col>
                    <Col md={8}>
                        {this.state.responseValidateInvestorOd.resultCode === 0 ? <small style={{color: "blue"}}
                                                                                         className={"float-left"}>{this.state.responseValidateInvestorOd.message}</small> : ""}
                        {this.state.responseValidateInvestorOd.resultCode === 1 ? <small style={{color: "red"}}
                                                                                         className={"float-left"}>{this.state.responseValidateInvestorOd.message}</small> : ""}
                        {this.state.responseValidateInvestorOd.resultCode === 2 ? <small style={{color: "yellow"}}
                                                                                         className={"float-left"}>{this.state.responseValidateInvestorOd.message}</small> : ""}
                    </Col>
                </Row>
                <Row>
                    <Col md={4}>
                        <CustomInput type="checkbox"
                                     id="massItemCheckbox"
                                     name="massItemCheckbox"
                                     label="Partidas "
                                     checked={this.state.massItemCheckbox}
                                     onChange={this.onCheckboxChange}
                        />
                    </Col>
                    <Col md={8}>
                        <FormGroup>
                            <Select
                                options={this.state.itemData}
                                id={"itemId"}
                                name={"itemId"}
                                isDisabled={!this.state.massItemCheckbox}
                                value={this.state.selectedItem}
                                isClearable={true}
                                isLoading={this.state.loading}
                                placeholder={"Seleccione Partida"}
                                onChange={this.onItemChange}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <hr/>
                <Row>
                    <Col md={8}>
                        <h5 className={'float-left'}>
                            Sumatoria Importes: {CyaHelper.ToCurrency(self.state.selectedMultipleData.reduce(function (prev, cur) {
                            return prev + cur.usedAmount;
                        }, 0))}
                        </h5>
                    </Col>
                    <Col md={4}>
                        <button
                            disabled={(this.validateButtonMassSave())}
                            className={(this.validateButtonMassSave()) ? 'btn btn-primary float-right disabled' : 'btn btn-primary float-right'}
                            onClick={this.saveChangesMass}
                        >Salvar cambios
                        </button>
                    </Col>
                </Row>
            </Modal>
            <CyaTableLayout selectionCheckbox={true}
                            title={this.state.title}
                            columns={columns}
                            data={data}
                            loading={this.state.loading}
                            trProps={this.GetProps}
                            selectedMultiple={this.selectedMultiple}
                            enableButtons={this.state.selectedRowId >= 0}
                            showDeleteButton={this.Auth.GetPathPermission(this.props.location.pathname+"/delete",false)}
                            deleteCallback={this.deleteCallback}
                            viewCallback={this.viewCallback}
                            modelType={"check"}
                            viewAnnexesCallback={this.viewAnnexesCallback}
                            multipleActionsButton={true}
                            multipleActionsCallback={this.multipleActionsCallback}
                            deleteText={this.state.deleteText}
            />
        </div>;

    }
}
