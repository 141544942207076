import React from 'react';

class Footer extends React.Component {
  render() {
    return (
      <footer className="footer text-center">
      </footer>
    );
  }
}
export default Footer;
