import React from 'react';
import "react-table/react-table.css";
import 'react-confirm-alert/src/react-confirm-alert.css'
import TaxReturnApiClient from "../../utils/api-client/TaxReturnApiClient";
import {Card, CardBody, CardTitle, Col, Label, Row, Tooltip} from "reactstrap";
import {LoadingPage} from "../../components/custom/views/LoadingPage";
import Datetime from "react-datetime";
import pdfImage from "../../assets/images/button-icons/pdf.png";
import xlsxImage from "../../assets/images/button-icons/xlsx.png";
import downloadImage from "../../assets/images/button-icons/download.png";
import uploadImage from "../../assets/images/button-icons/upload.png";
import deleteImage from "../../assets/images/button-icons/delete.png";
import CyaHelper from "../../utils/CyaHelper";
import md5 from "md5";

require('moment/locale/es-do');

export default class TaxReturn extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            taxReturnTypes: [],
            title: "Declaraciones de Impuestos",
            tooltipOpen: [],
            loading: true
        };
        this.date = "";
        this.taxReturnTypeId = 0;
        this.taxReturnDocumentTypeId = 0;
        this.fileSelector = this.buildFileSelector();
        this.toggle = this.toggle.bind(this);
        this.upload = this.upload.bind(this);
        this.download = this.download.bind(this);
        this.delete = this.delete.bind(this);
        this.onDateChange = this.onDateChange.bind(this);
        this.TaxReturnApiClient = new TaxReturnApiClient();
        this.TaxReturnApiClient.GetTaxReturnTypes()
            .then(response => this.setState({taxReturnTypes: response.data, loading: false}));
    }

    toggle(e) {
        let aux = this.state.tooltipOpen;
        aux[e] = aux[e] === undefined ? true : !aux[e];
        this.setState({
            tooltipOpen: aux
        });
    }

    onDateChange(moment) {
        this.date = moment.format("YYYY-MM-DD");
        this.setState({loading: true});
        this.TaxReturnApiClient.GetByDate(this.date)
            .then(response => this.setState({data: response.data, loading: false}));
    }

    buildFileSelector(){
        const domain = this;
        const fileSelector = document.createElement('input');
        fileSelector.setAttribute('type', 'file');
        fileSelector.onchange = function () {
            if(this.files.length > 0){
                CyaHelper.GetBase64FromFileInput(this.files[0]).then(
                    data => domain.onFileSuccessCallback(data.split(',').pop(), this.files[0].name)
                );
            }
        };
        return fileSelector;
    }

    onFileSuccessCallback(fileContent, filename){
        this.setState({loading: true});
        let dto = {
            taxReturnId: 0,
            taxReturnTypeId: this.taxReturnTypeId,
            taxReturnDocumentTypeId: this.taxReturnDocumentTypeId,
            date: this.date,
            fileContent: fileContent,
            filename: filename,
            hash: md5(fileContent)
        };
        let document = this.state.data.find(x => x.taxReturnTypeId === this.taxReturnTypeId
            && x.taxReturnDocumentTypeId === this.taxReturnDocumentTypeId);
        if(document !== undefined){
            dto.taxReturnId = document.taxReturnId;
            this.TaxReturnApiClient.Update(document.taxReturnId, dto).then(() => this.TaxReturnApiClient.GetByDate(this.date).then(response => this.setState({data: response.data, loading: false})));
        }
        else{
            this.TaxReturnApiClient.Create(dto).then(() => this.TaxReturnApiClient.GetByDate(this.date).then(response => this.setState({data: response.data, loading: false})));
        }
    }

    upload(e, taxReturnTypeId, taxReturnDocumentTypeId, filter = '.pdf,.xlsx')
    {
        e.preventDefault();
        this.taxReturnTypeId = taxReturnTypeId;
        this.taxReturnDocumentTypeId = taxReturnDocumentTypeId;
        this.fileSelector.setAttribute('accept', filter);
        this.fileSelector.value = null;
        this.fileSelector.click();
    }

    download(e, taxReturnTypeId, taxReturnDocumentTypeId)
    {
        e.preventDefault();
        this.taxReturnTypeId = taxReturnTypeId;
        this.taxReturnDocumentTypeId = taxReturnDocumentTypeId;
        this.setState({loading: true});
        let document = this.state.data.find(x => x.taxReturnTypeId === this.taxReturnTypeId
            && x.taxReturnDocumentTypeId === this.taxReturnDocumentTypeId);
        CyaHelper.DownloadFile('data:application/octet-stream;base64,' + document.fileContent, document.filename);
        this.setState({loading: false});
    }

    delete(e, taxReturnTypeId, taxReturnDocumentTypeId)
    {
        e.preventDefault();
        this.taxReturnTypeId = taxReturnTypeId;
        this.taxReturnDocumentTypeId = taxReturnDocumentTypeId;
        this.setState({loading: true});
        let taxReturnId = this.state.data.find(x => x.taxReturnTypeId === this.taxReturnTypeId
            && x.taxReturnDocumentTypeId === this.taxReturnDocumentTypeId).taxReturnId;
        this.TaxReturnApiClient.Delete(taxReturnId)
            .then(() => this.TaxReturnApiClient.GetByDate(this.date).then(response => this.setState({data: response.data, loading: false}))
        );
    }

    render() {
        let buttons = [];
        if(this.date.length > 0)
        {
            this.state.taxReturnTypes.map((taxReturnType, index) =>
                buttons.push(<Col className="col-xxl-4" key={index}>
                        <Card style={{minWidth: "500px"}}>
                            <CardTitle className="mb-0 p-3 border-bottom bg-light">
                                {taxReturnType.description}
                            </CardTitle>
                            <CardBody>
                                <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                                    {taxReturnType.taxReturnDocumentTypes.map((taxReturnDocumentType, ind) => {
                                        let imageSource;
                                        let notExists = this.state.data.find(x => x.taxReturnTypeId === taxReturnType.taxReturnTypeId
                                        && x.taxReturnDocumentTypeId === taxReturnDocumentType.taxReturnDocumentTypeId) === undefined;

                                        if (taxReturnDocumentType.description === "Excel") {
                                            imageSource = xlsxImage;
                                        } else {
                                            imageSource = pdfImage;
                                        }

                                        return <table key={ind*100} style={{marginLeft:10, marginRight:10}}>
                                            <tbody>
                                                <tr style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                                                    <td colSpan={3}>
                                                        <img src={imageSource} alt={taxReturnDocumentType.description} style={{background: notExists ? "transparent" : "#5CB85C"}}/>
                                                    </td>
                                                </tr>
                                                <tr style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                                                    <td>
                                                        <button className="btn btn-primary" id={"C" + taxReturnType.taxReturnTypeId.toString() + taxReturnDocumentType.taxReturnDocumentTypeId.toString()} onClick={(e) => this.upload(e, taxReturnType.taxReturnTypeId, taxReturnDocumentType.taxReturnDocumentTypeId)}>
                                                            <img src={uploadImage} alt="Cargar"/>
                                                        </button>
                                                        <Tooltip placement="top"
                                                                 isOpen={this.state.tooltipOpen["C" + taxReturnType.taxReturnTypeId.toString() + taxReturnDocumentType.taxReturnDocumentTypeId.toString()]}
                                                                 target={"C" + taxReturnType.taxReturnTypeId.toString() + taxReturnDocumentType.taxReturnDocumentTypeId.toString()}
                                                                 toggle={() => this.toggle("C" + taxReturnType.taxReturnTypeId.toString() + taxReturnDocumentType.taxReturnDocumentTypeId.toString())}
                                                        >
                                                            Cargar
                                                        </Tooltip>
                                                    </td>
                                                    <td>
                                                        <button className="btn btn-success" id={"D" + taxReturnType.taxReturnTypeId.toString() + taxReturnDocumentType.taxReturnDocumentTypeId.toString()} onClick={(e) => this.download(e, taxReturnType.taxReturnTypeId, taxReturnDocumentType.taxReturnDocumentTypeId)} disabled={notExists}>
                                                            <img src={downloadImage} alt="Descargar"/>
                                                        </button>
                                                        <Tooltip placement="top"
                                                                 isOpen={this.state.tooltipOpen["D" + taxReturnType.taxReturnTypeId.toString() + taxReturnDocumentType.taxReturnDocumentTypeId.toString()]}
                                                                 target={"D" + taxReturnType.taxReturnTypeId.toString() + taxReturnDocumentType.taxReturnDocumentTypeId.toString()}
                                                                 toggle={() => this.toggle("D" + taxReturnType.taxReturnTypeId.toString() + taxReturnDocumentType.taxReturnDocumentTypeId.toString())}
                                                        >
                                                            Descargar
                                                        </Tooltip>
                                                    </td>
                                                    <td>
                                                        <button className="btn btn-danger" id={"E" + taxReturnType.taxReturnTypeId.toString() + taxReturnDocumentType.taxReturnDocumentTypeId.toString()} onClick={(e) => this.delete(e, taxReturnType.taxReturnTypeId, taxReturnDocumentType.taxReturnDocumentTypeId)} disabled={notExists}>
                                                            <img src={deleteImage} alt="Eliminar"/>
                                                        </button>
                                                        <Tooltip placement="top"
                                                                 isOpen={this.state.tooltipOpen["E" + taxReturnType.taxReturnTypeId.toString() + taxReturnDocumentType.taxReturnDocumentTypeId.toString()]}
                                                                 target={"E" + taxReturnType.taxReturnTypeId.toString() + taxReturnDocumentType.taxReturnDocumentTypeId.toString()}
                                                                 toggle={() => this.toggle("E" + taxReturnType.taxReturnTypeId.toString() + taxReturnDocumentType.taxReturnDocumentTypeId.toString())}
                                                        >
                                                            Eliminar
                                                        </Tooltip>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    })}
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                ));
        }
        return <div>
            <Card>
                <CardTitle className="mb-0 p-3 border-bottom bg-light">
                    <i className="fas fa-dollar-sign mr-2"/>{this.state.title}
                </CardTitle>
                <CardBody>
                    <LoadingPage color={"3e5569"} loading={this.state.loading} type={"spin"}/>
                    <Row>
                        <Col md={3}>
                            <Label htmlFor={"date"}>Seleccione fecha:</Label>
                            <Datetime locale={"es-do"}
                                      inputProps={{readOnly: true}}
                                      utc={true}
                                      timeFormat={false}
                                      dateFormat={"MMMM, YYYY"}
                                      closeOnSelect={true}
                                      onChange={this.onDateChange}/>
                        </Col>
                    </Row>
                    <Row style={{paddingTop: 30}}>
                        {buttons.map(button => button)}
                    </Row>
                </CardBody>
            </Card>
        </div>;
    }
}
