import React from 'react';
import "react-table/react-table.css";
import 'react-confirm-alert/src/react-confirm-alert.css'
import {Card, CardBody, CardTitle, Col, Row,Tooltip} from "reactstrap";
import {LoadingPage} from "../../components/custom/views/LoadingPage";
import {SimpleTable} from "../../components/custom/tables/SimpleTable";
import AuthService from "../../utils/AuthService";
import Modal from "react-modal";
import {toast} from "react-toastify";
import {ValidationModel} from "../../models/ValidationModel";
import ProjectInvestorApiClient from "../../utils/api-client/ProjectInvestorApiClient";
import AnnexApiClient from "../../utils/api-client/AnnexApiClient";
import {ValidationComponent} from "../../components/custom/validation/ValidationComponent";
import ValidationApiClient from "../../utils/api-client/ValidationApiClient";
import {YesOrNoModal} from "../../components/custom/views/YesOrNoModal";
import CyaHelper from "../../utils/CyaHelper";
import {CyaContext} from "../../utils/CyaContext";
require('moment/locale/es-do');

export default class Validation extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            investors: [],
            validations: [],
            initialData: [],
            stageOptions: [],
            validationSelected: null,
            selected: null,
            selectedRowId: -1,
            selectedRowData: {},
            modalDelete: false,
            modalInvestors: false,
            modelCreated: "Validación Agregada",
            modelUpdated: "Validación Actualizada",
            modelDeleted: "Validación Eliminada",
            modelError: "Error en Validación",
            modalTitle: 'Crear Validación',
            title: "Validaciones",
            loading: true,
            addTooltip: false,
            modalConfirmationState: false
        };

        this.Auth = new AuthService();
        this.ValidationApiClient = new ValidationApiClient();
        this.InvestorApiClient = new ProjectInvestorApiClient();
        this.AnnexApiClient = new AnnexApiClient();

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!this.state.loading){
            let isFormDirty = !CyaHelper.ObjectCompare(this.state.initialData, this.state.data);
            this.context.toggleExitConfirmation(isFormDirty);
        }
    }

    componentDidMount(){
        this.ValidationApiClient.GetAll().then(response => {
            let validations = response.data.reverse();
            this.setState({
                validations:validations,
                initialData: JSON.parse(JSON.stringify(validations))
            });
        }).then(()=>{
            this.InvestorApiClient.GetAll().then(response => {this.setState({investors: response.data, loading: false,}); });
        });

        this.AnnexApiClient.GetStages().then(response => 
         {
            const dataFormatted = response.data.map(item => ({...item, value: item.stageId, label: item.description}))
            this.setState({stageOptions: dataFormatted})
         }
         )
    }

    validSaveButton = ()=>{
        return this.Auth.GetPathPermission(this.props.location.pathname, true);
    };

    tProps = (state, rowInfo) =>{
        if (rowInfo && rowInfo.row) {
            return {
                onClick: () => {
                    this.setState({
                        selected: rowInfo.index,
                        selectedRowId: rowInfo.row.investorId,
                        selectedRowData: this.state.investors.find(x => x.investorId === rowInfo.row.investorId)
                    });
                },
                onDoubleClick: () => {
                    this.addInvestorToValidation();
                },
                style: {
                    background: rowInfo.index === this.state.selected ? '#00AFEC' : rowInfo.index % 2 === 0 ? '#DDDDDD' : '#FFFFFF',
                    color: rowInfo.index === this.state.selected ? 'white' : 'black'
                }
            }
        }
        return {};
    };

    onInputChange = (event) => {
        let aux = this.state.data;
        aux[event.target.name] = event.target.value;
        this.setState({data: aux});
    };

    onDateChange = (validationSelected,moment) => {
        let aux = this.state.validations;
        aux.map( x =>{
            if (x.validationNumber === validationSelected.validationNumber){
                x.date=moment.format("YYYY-MM-DD");
            }
            return true;
        });
        this.setState({validations:aux});

    };

    updateValidation =(validationObject)=>{
        this.setState({loading: true});
        this.ValidationApiClient.Update(validationObject.validationId, validationObject)
            .then(response => {
                if (response.response!==undefined && response.response.data==="Error"){
                    toast.error(this.state.modelError);
                }
                this.setState({loading: false});
            })
            .catch(() => {
                this.setState({loading: false});
                toast.error(this.state.modelError);
            });
    };

    addNewValidation = (e,confirm=false, value=false)=>{
        e.preventDefault();

        if (this.state.validations.length > 0){
            if (!confirm){
                this.stateConfirmationModal(true);
                return;
            }
            if (confirm ){
                if (value ){
                    let aux = this.state.validations;
                    let newValidation=new ValidationModel();
                    newValidation.validationNumber=aux.length+1;
                    if (!aux[0].closed){
                        aux[0].closed=true;
                        this.updateValidation(aux[0]);
                    }

                    aux.unshift(newValidation);
                    this.setState({validations:aux,initialData: JSON.parse(JSON.stringify(aux))});
                }

                this.stateConfirmationModal(false);


            }
        } else {
            let aux = this.state.validations;
            let newValidation=new ValidationModel();
            newValidation.validationNumber=aux.length+1;
            aux.unshift(newValidation);
            this.setState({validations:aux});
        }

    };

    closeInvestorsModal =()=>{
        if (this.state.modalInvestors)
            this.setState({modalInvestors: false,validationSelected: null,selected: null, selectedRowId: -1, selectedRowData: {}});
    };

    openInvestorsModal = (event,validationSelected) => {
        event.preventDefault();

        if (!this.state.modalInvestors)
            this.setState({modalInvestors: true,validationSelected: validationSelected});
    };

    toggle=()=> {
        this.setState({
            addTooltip: !this.state.addTooltip
        });
    };

    onBlockedChange = (validationNumber)=>{
        let aux = this.state.validations;
        aux.map( x =>{
            if (x.validationNumber ===validationNumber){
                x.closed=!x.closed;
            }
            return true;
        });
        this.setState({validations:aux});

    };

    onSubmitClick = (validationSelected) =>{
        this.setState({loading:true});
        if (validationSelected.validationId > 0) {
            this.ValidationApiClient.Update(validationSelected.validationId, validationSelected)
                .then(response => {
                    if (response.response!==undefined && response.response.data==="Error"){
                        toast.error(this.state.modelError);
                    }else {
                        toast.success(this.state.modelUpdated);
                    }
                    this.setState({initialData: JSON.parse(JSON.stringify(this.state.validations))});
                    this.setState({loading: false});
                })
                .catch(() => {
                    this.setState({loading: false});
                    toast.error(this.state.modelError);
                });
        }
        else {
            this.ValidationApiClient.Create(validationSelected)
                .then(response => {
                    if (response.response!==undefined && response.response.data==="Error"){
                        this.setState({loading: false});
                        toast.error(this.state.modelError);
                    }else {
                        toast.success(this.state.modelCreated);

                        this.ValidationApiClient.GetAll().then(response => {
                            let validations = response.data.reverse();
                            this.setState({
                                validations:validations,
                                initialData: JSON.parse(JSON.stringify(validations))
                            });
                        }).then(() => {
                            this.InvestorApiClient.GetAll().then(response => {
                                this.setState({investors: response.data, loading: false});
                            });
                        });
                    }
                }).catch(() => {
                    this.setState({loading: false});
                    toast.error(this.state.modelError);
                });
        }
        window.scrollTo(0, 0);

    };

    addInvestorToValidation=()=>{

        let self=this;
        let validationNumber = this.state.validationSelected.validationNumber;
        let aux = this.state.validations;
        aux.map(function (obj) {
            if (obj.validationNumber===validationNumber){
                if (obj.investor==null){
                    obj.investor=[];
                }
                obj.investor.push(self.state.selectedRowData);
            }
            return true;
        });
        this.setState({validations:aux});
        // this.closeInvestorsModal();
    };

    addInvestorToValidationEvent = (event) =>{
        event.preventDefault();
        this.addInvestorToValidation();
    };

    deleteValidationConfirmation = (event,validationSelected) =>{
        event.preventDefault();
        if (!this.state.modalDelete)
            this.setState({modalDelete: true,validationSelected: validationSelected});
    };

    deleteValidation = (event) => {
        event.preventDefault();

        if (this.state.validationSelected.validationId>0){
            this.setState({loading:true});
            this.ValidationApiClient.Delete(this.state.validationSelected.validationId)
                .then(response => {
                    if (response.response!==undefined && response.response.data==="Error"){
                        this.setState({loading: false});
                        toast.error(this.state.modelError);
                    }else {
                        toast.success(this.state.modelDeleted);

                        this.ValidationApiClient.GetAll().then(response => {
                            let validations = response.data.reverse();
                            this.setState({
                                validations:validations,
                                initialData: JSON.parse(JSON.stringify(validations))
                            });
                        }).then(()=>{
                            this.InvestorApiClient.GetAll().then(response => { this.setState({investors: response.data, loading: false}); });
                        });
                    }

                })
                .catch(() => {
                    this.setState({loading: false});
                    toast.error(this.state.modelError);
                });
        }else {
            let self = this;
            let aux = this.state.validations;
            let indexOfElementToDelete = -1;
            aux.map(function (obj,index) {
                if (obj.validationNumber===self.state.validationSelected.validationNumber){
                    indexOfElementToDelete=index;
                }
                return true;
            });
            if (indexOfElementToDelete !== -1) aux.splice(indexOfElementToDelete,1);
            if (aux.length>0){
                aux[0].closed=false;
            }
            this.setState({validations:aux});
        }
        this.closeDeleteModal();

    };

    closeDeleteModal=()=>{
        if (this.state.modalDelete)
            this.setState({modalDelete: false,validationSelected: null});
    };

    deleteInvestorFromValidation = (validationSelected,investorIndex) =>{
        let aux = this.state.validations;
        aux.map(validation =>{
            if (validation.validationNumber===validationSelected.validationNumber){
                validation.investor.splice(investorIndex,1);
            }
            return true;
        });
        this.setState({validations:aux});
    };

    stateConfirmationModal = (modalConfirmationState) => {
        this.setState({modalConfirmationState});
    };

    render() {
        let self = this;
        let investors = [
            {Header: "InvestorId", accessor: "investorId", show: false},
            {Header: "RNC", accessor: "rnc", width: 150},
            {Header: "Nombre", accessor: "name"},
        ];

        let auxArray=[];
        this.state.validations.map(x => {
            if (x.investor != null && x.investor.length > 0) {
                x.investor.map(investorsValidation => {
                    if (auxArray.find(z => z.investorId === investorsValidation.investorId) === undefined) {
                        auxArray.push(investorsValidation);
                    }
                    return true;
                });
            }
            return true;
        });

        let arrayInvestorsForModal=[];
        if (auxArray.length>0 && this.state.investors.length>0){
            arrayInvestorsForModal = (this.state.investors.filter( (x) => !(auxArray.find(y => y.investorId === x.investorId)!==undefined)) );
        } else {
            arrayInvestorsForModal = this.state.investors.length>0?this.state.investors.filter(()=>{return true;}):[];
        }

        const customStyles2 = {
            content: {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)',
                width: '40%'
            }
        };
        Modal.setAppElement('body');

        return <div>

            <YesOrNoModal modalOpen={this.state.modalConfirmationState}
                          closeDeleteModal={()=>this.stateConfirmationModal(false)}
                          message={'Agregar una nueva validación'}
                          body={'Al agregar una nueva validación, las previas se cerrarán y no podrán ser abiertas a menos que no elimine la procedente a la misma. ¿Desea proceder?'}
                          confirmation={(e,value)=>this.addNewValidation(e, true,value)}
            />

            <Modal
                isOpen={this.state.modalDelete}
                onRequestClose={this.closeDeleteModal}
                style={customStyles2}
            >
                <Row>
                    <Col md={10}><h4>¿Desea eliminar la validación {this.state.validationSelected != null ?this.state.validationSelected.validationNumber:""} ?</h4></Col>
                    <Col md={2} style={{textAlign: 'right'}}>
                        <button className={"btn btn-danger btn-sm"} style={{textAlign: 'right'}}
                                onClick={this.closeDeleteModal}>X
                        </button>
                    </Col>
                </Row>
                <hr style={{marginBottom:"15px"}}/>

                <Row>
                    <Col md="6" >
                        <button className={"btn btn-danger"} onClick={this.deleteValidation} >Eliminar</button>
                    </Col>
                    <Col md="6" style={{textAlign: 'right'}}>
                        <button className={"btn btn-secondary"} onClick={this.closeDeleteModal}>Cancelar</button>
                    </Col>

                </Row>

            </Modal>

            {/*MODAL INVERSIONISTAS*/}

            <Modal
                isOpen={this.state.modalInvestors}
                onRequestClose={this.closeInvestorsModal}
                style={customStyles2}
            >
                <Row>
                    <Col md={10}><h4>Inversionistas</h4></Col>
                    <Col md={2} style={{textAlign: 'right'}}>
                        <button className={"btn btn-danger"} style={{textAlign: 'right'}}
                                onClick={this.closeInvestorsModal}>X
                        </button>
                    </Col>
                </Row>

                <hr style={{marginBottom:"0px"}}/>

                <Row>
                    <Col md="12">
                        <br/>
                        <SimpleTable
                            data={arrayInvestorsForModal}
                            columns={investors}
                            trProps={this.tProps}
                            loading={this.state.loading} defaultPageSize={3}
                        />

                        <hr/>

                        <Row>
                            <Col md="12" style={{textAlign: 'center'}}>
                              <i className="fas fa-info mr-1 text-info"/>
                              <label className="text-info">Hacer doble click para seleccionar inversionista</label>
                            </Col>
                        </Row>
                    </Col>
                </Row>

            </Modal>

            {/*FIN DE LA MODAL*/}

            <Card>
                <CardTitle className="mb-0 p-3 border-bottom bg-light">
                    <i className="mdi mdi-checkbox-marked-outline mr-2"/>{this.state.title}
                </CardTitle>
                <CardBody>
                    <LoadingPage color={"3e5569"} loading={this.state.loading} type={"spin"}/>
                    <Row>
                        <div id={"addTooltip"}>
                            <Col md="6" >
                                <input type="submit"
                                       disabled={!(this.validSaveButton() && (this.state.validations.length>0?this.state.validations[0].validationId>0:true))}
                                       className={ (!(this.validSaveButton() && (this.state.validations.length>0?this.state.validations[0].validationId>0:true)) ? 'btn btn-primary disabled' : 'btn btn-primary ')}
                                       value="Añadir"
                                       onClick={this.addNewValidation}
                                />
                            </Col>
                        </div>

                        <Tooltip placement="right"
                                 isOpen={this.state.addTooltip}
                                 target={"addTooltip"}
                                 toggle={this.toggle}
                        >
                            {(this.state.validations.length>0? (this.state.validations[0].validationId>0 ? "Añadir": "Debe guardar antes de añadir otra validación"):"Añadir")}
                        </Tooltip>
                    </Row>

                    <hr/>

                    <Row>
                        {(this.state.validations.length>0)?
                            this.state.validations.map(function (obj, index) {
                                return (
                                    <ValidationComponent 
                                       data={obj} key={obj.validationNumber}
                                       stageOptions={self.state.stageOptions}
                                       onBlockedChange={self.onBlockedChange}
                                       onSubmitClick={self.onSubmitClick}
                                       validSaveButton={self.validSaveButton}
                                       firstOne={index===0}
                                       onDateChange={self.onDateChange}
                                       deleteValidation={self.deleteValidationConfirmation}
                                       openInvestorsModal={self.openInvestorsModal}
                                       deleteInvestorFromValidation={self.deleteInvestorFromValidation}
                                    />
                                )
                            })

                            :""}

                    </Row>
                    <hr style={{marginTop:'0px'}}/>

                </CardBody>
            </Card>
        </div>;
    }
}
Validation.contextType = CyaContext;
