import React from 'react';
import Moment from 'moment';
import {DateFormat} from "../../const/api_consts";
import CyaHelper from '../../utils/CyaHelper';
import "react-table/react-table.css";
import {CyaTableLayout} from "../../components/custom/tables/CyaTableLayout";
import AnnexApiClient from "../../utils/api-client/AnnexApiClient";
import Modal from "react-modal";
import {Col, Row, CustomInput, FormGroup} from "reactstrap";
import {toast} from "react-toastify";
import 'react-confirm-alert/src/react-confirm-alert.css'
import Select from 'react-select';
import ItemApiClient from "../../utils/api-client/ItemApiClient";
import {SuccessResponse} from "../../const/api_consts";
import {InputSelect} from "../../components/custom/inputs/InputSelect";
import AuthService from "../../utils/AuthService";
import CheckApiClient from "../../utils/api-client/CheckApiClient";

export default class Annex extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            loading: true,
            selected: false,
            selectedRowId: -1,
            selectedMultiple: [],
            selectedMultipleData: [],
            itemData: [],
            stageData: [],
            typeIrData: [],
            title: "Anexos",
            modalMultipleActions: false,
            modalMassDelete: false,
            massItemCheckbox: false,
            massStepCheckbox: false,
            massTypeIR17Checkbox: false,
            selectedTypeIr: 0,
            selectedStageId: 0,
            selectedItem: 0,
            selectedRowData: {},
            deleteText: "Borrar",
            isAnnexesCheck:false,
            checkSelectedData:null
        };
        this.GetProps = this.GetProps.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.closeMassDeleteModal = this.closeMassDeleteModal.bind(this);
        this.closeModalAndOpenDeleteModal = this.closeModalAndOpenDeleteModal.bind(this);
        this.massiveDelete = this.massiveDelete.bind(this);
        this.onCheckboxChange = this.onCheckboxChange.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.onItemChange = this.onItemChange.bind(this);
        this.saveChangesMass = this.saveChangesMass.bind(this);
        this.validateButtonMassSave = this.validateButtonMassSave.bind(this);

        this.Auth = new AuthService();
        this.AnnexApiClient = new AnnexApiClient();
        this.CheckApiClient = new CheckApiClient();
        this.ItemApiClient = new ItemApiClient();
        if (this.props.location.state !== undefined && this.props.location.state?.id) {
            this.AnnexApiClient.GetByCheckId(this.props.location.state.id).then(response =>{
                    this.setState({
                        data: response.data,
                        loading: false,
                        title: "Anexos del Cheque " + this.props.location.state.name,
                        isAnnexesCheck:true
                    });
            }).then(()=>{
                    this.CheckApiClient.GetCheckInfoById(this.props.location.state.id).then(response=>{
                        this.setState({checkSelectedData:response});
                    });
            });
        } else {
            this.AnnexApiClient.GetAll().then(response => this.setState({data: response.data, loading: false}));
        }

    }

    componentDidMount() {
        this.ItemApiClient.GetListForAnnexes().then(response => {
            let items = response.data.map(x => {
                return {
                    value: x.itemId,
                    label: "[" + x.code + "] " + x.name,
                    parentItemId: x.parentItemId
                }
            });
            this.setState({itemData: items, loading: false});
        });
        this.AnnexApiClient.GetStages().then(response => this.setState({stageData: response.data}));
        this.AnnexApiClient.GetIsrRetentionTypes().then(response => this.setState({typeIrData: response.data}));
    }

    GetProps(state, rowInfo) {
        if (rowInfo && rowInfo.row) {
            return {
                onClick: () => {
                    this.setState({
                        selected: rowInfo.index,
                        selectedRowId: rowInfo.row.annexId,
                        selectedRowData: this.state.data.find(x => x.annexId === rowInfo.row.annexId)
                    });
                },
                onDoubleClick: () => {
                    if (this.state.selected === rowInfo.index) {
                        this.props.history.push({
                            pathname: '/annexes/edit',
                            state: {id: rowInfo.row.annexId}
                        });
                    }
                },
                style: {
                    background: rowInfo.index === this.state.selected ? '#00AFEC' : rowInfo.index % 2 === 0 ? '#DDDDDD' : '#FFFFFF',
                    color: rowInfo.index === this.state.selected ? 'white' : 'black'
                }
            }
        }
        return {};
    }

    selectedMultiple = (selectedMultiple) => {
        let list = [];
        let self = this;
        for (let key in selectedMultiple) {
            if (selectedMultiple.hasOwnProperty(key) && selectedMultiple[key] === true)
                list.push(Number(key));
        }
        let selectedMultipleData = [];
        list.map(function (name, index) {
            selectedMultipleData.push(self.state.data.find(x => x.annexId === Number(name)));
            return true;
        });
        this.setState({
            selectedMultiple: list,
            selectedMultipleData: selectedMultipleData,
            deleteText: (list.length > 0) ? "Borrar Seleccionados" : "Borrar",
        });
    };


    deleteCallback = () => {
        if (this.state.selectedMultiple.length > 0 || this.state.selectedRowId > 0) {
            this.setState({modalMassDelete: true});
        }
    };

    viewCallback = () => {
        if (this.state.selectedRowId >= 0) {
            this.props.history.push({
                pathname: '/annexes/edit',
                state: {id: this.state.selectedRowId}
            });
        }
    };

    viewChecksCallback = () => {
        if (this.state.selectedRowId >= 0) {
            let annex = this.state.data.find(x => x.annexId === this.state.selectedRowId);
            this.props.history.push({
                pathname: '/checks',
                state: {id: this.state.selectedRowId, name: annex.name + " con NCF " + annex.ncf}
            });
        }
    };

    multipleActionsCallback = () => {
        this.setState({modalMultipleActions: true});
    };

    closeModal() {
        if (this.state.modalMultipleActions)
            this.setState({modalMultipleActions: false});
    }

    closeModalAndOpenDeleteModal() {
        if (this.state.modalMultipleActions)
            this.setState({modalMultipleActions: false, modalMassDelete: true});
    }

    closeMassDeleteModal() {
        if (this.state.modalMassDelete)
            this.setState({modalMassDelete: false});
    }

    massiveDelete() {
        this.setState({loading: true});
        if (this.state.selectedMultiple.length > 0) {
            this.AnnexApiClient.MassDelete(this.state.selectedMultiple)
                .then((response) => {
                    if (response !== SuccessResponse) {
                        toast.error("Error eliminado anexos.");
                    } else {
                        this.props.history.push({pathname: '/refresh/annexes/'});
                        toast.success("Anexos eliminados.");
                    }
                }).catch(() => {
                toast.error("Error eliminado anexos.");
            });
        } else {
            if (this.state.selectedRowId > 0) {
                this.AnnexApiClient.Delete(this.state.selectedRowId).then((response) => {
                    if (response !== SuccessResponse) {
                        toast.error("Error eliminado anexos.");
                    } else {
                        this.props.history.push({pathname: '/refresh/annexes/'});
                        toast.success("Anexos eliminados.");
                    }
                }).catch(() => {
                    toast.error("Error eliminado anexos.");
                });
            }
            this.setState({loading: false});
            this.closeMassDeleteModal();
        }
    }

    onItemChange(item) {
        if (this.state.selectedItem !== item) {
            this.setState({selectedItem: item});
        }
    }

    onCheckboxChange(event) {
        if (event.target.name === "massItemCheckbox") {
            this.setState({massItemCheckbox: event.target.checked});
        }
        if (event.target.name === "massStepCheckbox") {
            this.setState({massStepCheckbox: event.target.checked});
        }
        if (event.target.name === "massTypeIR17Checkbox") {
            this.setState({massTypeIR17Checkbox: event.target.checked});
        }
    }

    onInputChange(event) {
        if (event.target.name === "stageId") {
            this.setState({selectedStageId: event.target.value});
        }
        if (event.target.name === "annexIsrretentionTypeId") {
            this.setState({selectedTypeIr: event.target.value});
        }
    }

    saveChangesMass() {
        let sendValues = [];
        if (this.state.massItemCheckbox) {
            sendValues.push({name: "Item", value: this.state.selectedItem.value});
        }
        if (this.state.massStepCheckbox) {
            sendValues.push({name: "Stage", value: this.state.selectedStageId});
        }
        if (this.state.massTypeIR17Checkbox) {
            sendValues.push({name: "Ir17", value: this.state.selectedTypeIr});
        }
        if (sendValues.length > 0) {
            this.setState({loading: true});
            this.AnnexApiClient.MassChange(sendValues, this.state.selectedMultiple)
                .then((response) => {
                    if (response.response !== undefined) {
                        if (response.response.status > 400) {
                            toast.error("Error modificando anexos.");
                        }
                    } else {
                        this.props.history.push({pathname: '/refresh/annexes/'});
                        toast.success("Anexos modificados.");
                    }
                }).catch((response) => {
                toast.warn("Error modificando anexos.");
            });
            this.setState({loading: false});
            this.closeModal();
        }
    }

    validateButtonMassSave() {
        let valItem = true;
        if (this.state.massItemCheckbox)
            valItem = !(this.state.selectedItem !== 0);
        return (!this.state.massStepCheckbox && !this.state.massTypeIR17Checkbox && valItem  )
    }



    render() {

        const {data} = this.state;
        const self = this;
        let columns = [
            {Header: "AnnexID", accessor: "annexId", show: false},
            {Header: "NCF", accessor: "ncf", width: 190},
            {Header: "ID", accessor: "id", width: 150},
            {Header: "Nombre", accessor: "name", minWidth: 250},
            {
                Header: "Fecha ", id: "date", style: {textAlign: 'center'}, accessor: d => {
                    return Moment(d.date).local().format(DateFormat)
                }, width: 100
            },
            {Header: "Cheque", accessor: "checkBankNumber", width: 200},
            {Header: "Partidas", accessor: "items", width: 200},
            {Header: "Etapa", accessor: "stageDescription", width: 120},
            {Header: "Tipo", accessor: "annexTypeDescription", width: 120},
            {Header: "IR17 Cat", accessor: "annexIsrretentionTypeDescription", width: 150},
            {Header: "Img", accessor: "images", style: {textAlign: 'right'}, width: 50},
            {
                Header: "Subtotal",
                accessor: "subtotal",
                style: {textAlign: 'right'},
                Cell: props => CyaHelper.ToCurrency(props.value),
                width: 150
            },
            {
                Header: "ITBIS",
                accessor: "itbis",
                style: {textAlign: 'right'},
                Cell: props => CyaHelper.ToCurrency(props.value),
                width: 150
            },
            {
                Header: "% ITBIS",
                accessor: "itbispercentage",
                style: {textAlign: 'right'},
                Cell: props => CyaHelper.ToPercent(props.value),
                width: 70
            },
            {
                Header: "ITBIS Ret",
                accessor: "itbisretention",
                style: {textAlign: 'right'},
                Cell: props => CyaHelper.ToCurrency(props.value),
                width: 150
            },
            {
                Header: "% ITBIS Ret",
                accessor: "itbisretentionPercentage",
                style: {textAlign: 'right'},
                Cell: props => CyaHelper.ToPercent(props.value),
                width: 70
            },
            {
                Header: "ISR",
                accessor: "isrretention",
                style: {textAlign: 'right'},
                Cell: props => CyaHelper.ToCurrency(props.value),
                width: 150
            },
            {
                Header: "% ISR ",
                accessor: "isrretentionPercentage",
                style: {textAlign: 'right'},
                Cell: props => CyaHelper.ToPercent(props.value),
                width: 70
            },
            {
                Header: "ISC",
                accessor: "isc",
                style: {textAlign: 'right'},
                Cell: props => CyaHelper.ToCurrency(props.value),
                width: 150
            },
            {
                Header: "Propina",
                accessor: "tip",
                style: {textAlign: 'right'},
                Cell: props => CyaHelper.ToCurrency(props.value),
                width: 150
            },
            {
                Header: "Otros Impuestos",
                accessor: "otherTaxes",
                style: {textAlign: 'right'},
                Cell: props => CyaHelper.ToCurrency(props.value),
                width: 150
            },
            {
                Header: "Tot.Gastos",
                accessor: "totalExpense",
                style: {textAlign: 'right'},
                Cell: props => CyaHelper.ToCurrency(props.value),
                width: 150
            },
            {
                Header: "Tot.Pagar",
                accessor: "totalDue",
                style: {textAlign: 'right'},
                Cell: props => CyaHelper.ToCurrency(props.value),
                width: 150
            },
            {Header: "Comentario", accessor: "comment", width: 300}
        ];
        const customStyles = {
            content: {
                top: '50%',
                left: '50%',
                right: '50%',
                bottom: '50',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)'
            }
        };
        Modal.setAppElement('body');

        return <div>
            <Modal
                isOpen={this.state.modalMassDelete}
                onRequestClose={this.closeMassDeleteModal}
                style={customStyles}
            >
                <Row>
                    <Col md={10}><h4>Confirmación</h4></Col>
                    <Col md={2} style={{textAlign: 'right'}}>
                        <button className={"btn btn-danger"} style={{textAlign: 'right'}}
                                onClick={this.closeMassDeleteModal}>X
                        </button>
                    </Col>
                </Row>
                <hr/>
                <h5>¿Desea eliminar los siguientes anexos? </h5>
                <ul>
                    {self.state.selectedMultiple.length > 0 ?
                        self.state.selectedMultiple.map(function (name, index) {
                            let annex = self.state.data.find(x => x.annexId === Number(name));
                            return <li key={index}>{annex.ncf + ' - ' + annex.name}</li>;
                        })
                        : ""}
                    {self.state.selectedRowId > 0 && self.state.selectedMultiple.length === 0 ?
                        <li key={0}>{this.state.selectedRowData.ncf + ' - ' + this.state.selectedRowData.name}</li>
                        : ""}
                </ul>
                <Row>
                    <Col md={12}>
                        <button className={"btn btn-danger float-right"} onClick={this.massiveDelete}>Eliminar
                        </button>
                    </Col>
                </Row>

            </Modal>

            <Modal
                isOpen={this.state.modalMultipleActions}
                onRequestClose={this.closeModal}
                style={customStyles}
            >
                <Row>
                    <Col md={10}><h4>Acciones Masivas</h4></Col>
                    <Col md={2} style={{textAlign: 'right'}}>
                        <button className={"btn btn-danger"} style={{textAlign: 'right'}} onClick={this.closeModal}>X
                        </button>
                    </Col>
                </Row>
                <hr/>
                <h5>Seleccione que propiedades desea cambiar de los anexos elegidos: </h5>
                <br/>
                <Row>
                    <Col md={4}>
                        <CustomInput type="checkbox"
                                     id="massItemCheckbox"
                                     name="massItemCheckbox"
                                     label="Partidas "
                                     checked={this.state.massItemCheckbox}
                                     onChange={this.onCheckboxChange}
                        />
                    </Col>
                    <Col md={8}>
                        <FormGroup>
                            <Select
                                options={this.state.itemData}
                                id={"itemId"}
                                name={"itemId"}
                                isDisabled={!this.state.massItemCheckbox}
                                value={this.state.selectedItem}
                                isLoading={this.state.loading}
                                placeholder={"Seleccione Partida"}
                                onChange={this.onItemChange}
                            />
                        </FormGroup>
                    </Col>
                </Row>

                <Row>
                    <Col md={4}>
                        <CustomInput type="checkbox"
                                     id="massStepCheckbox"
                                     name="massStepCheckbox"
                                     label="Etapa"
                                     checked={this.state.massStepCheckbox}
                                     onChange={this.onCheckboxChange}
                        />
                    </Col>
                    <Col md={8}>
                        <InputSelect
                            id_label={"stageId"}
                            data={this.state.stageData}
                            label={""}
                            showLabel={false}
                            dataDescription={["description"]}
                            dataValue={"stageId"}
                            showInput={this.state.massStepCheckbox}
                            selectedValue={Number(this.state.selectedStageId)}
                            onInputChange={this.onInputChange}
                        />
                    </Col>
                </Row>

                <Row>
                    <Col md={4}>
                        <CustomInput type="checkbox"
                                     id="massTypeIR17Checkbox"
                                     name="massTypeIR17Checkbox"
                                     label="Categoría IR-17"
                                     checked={this.state.massTypeIR17Checkbox}
                                     onChange={this.onCheckboxChange}
                        />
                    </Col>
                    <Col md={8}>
                        <InputSelect
                            id_label={"annexIsrretentionTypeId"}
                            data={this.state.typeIrData}
                            label={""}
                            showLabel={false}
                            dataDescription={["description"]}
                            dataValue={"annexIsrretentionTypeId"}
                            showInput={this.state.massTypeIR17Checkbox}
                            selectedValue={Number(this.state.selectedTypeIr)}
                            onInputChange={this.onInputChange}
                        />
                    </Col>
                </Row>
                <hr/>
                <Row>
                    <Col md={8}>
                        <h5 className={'float-left'}>
                            Sumatoria
                            Subtotales: {CyaHelper.ToCurrency(self.state.selectedMultipleData.reduce(function (prev, cur) {
                            return prev + cur.subtotal;
                        }, 0))}
                        </h5>
                    </Col>
                    <Col md={4}>
                        <button
                            disabled={(this.validateButtonMassSave())}
                            className={(this.validateButtonMassSave()) ? 'btn btn-primary float-right disabled' : 'btn btn-primary float-right'}
                            onClick={this.saveChangesMass}
                        >Salvar cambios
                        </button>
                    </Col>
                </Row>
            </Modal>
            <CyaTableLayout title={this.state.title}
                            columns={columns}
                            data={data}
                            loading={this.state.loading}
                            trProps={this.GetProps}
                            selectionCheckbox={true}
                            selectedMultiple={this.selectedMultiple}
                            enableButtons={this.state.selectedRowId >= 0}
                            showDeleteButton={this.Auth.GetPathPermission(this.props.location.pathname+"/delete",false)}
                            deleteCallback={this.deleteCallback}
                            viewCallback={this.viewCallback}
                            viewChecksCallback={this.viewChecksCallback}
                            modelType={"annex"}
                            multipleActionsButton={true}
                            multipleActionsCallback={this.multipleActionsCallback}
                            deleteText={this.state.deleteText}
                            checkSelectedData={this.state.checkSelectedData}
            />
        </div>;
    }
}
