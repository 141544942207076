import React from "react";
import ReactTable from "react-table";
import PropTypes from "prop-types";

export class SimpleTable extends React.Component {
   render() {
      return (
         <ReactTable
            style={{ width: "100%", ...this.props.style }}
            nextText={"Siguiente"}
            previousText={"Anterior"}
            loadingText={"Cargando..."}
            loading={this.props.loading}
            noDataText={this.props.noDataText}
            pageText={"Página"}
            ofText={"de"}
            rowsText={"filas"}
            data={this.props.data}
            columns={this.props.columns}
            defaultPageSize={this.props.defaultPageSize}
            pageSizeOptions={[5, 10, 50]}
            showPaginationTop={this.props.showPaginationTop}
            defaultFilterMethod={(filter, row, column) => {
               const id = filter.pivotId || filter.id;
               return row[id] !== undefined
                  ? String(row[id].toString().toLowerCase()).includes(
                       filter.value.toLowerCase()
                    )
                  : true;
            }}
            showPageJump={false}
            showPageSizeOptions={false}
            filterable={this.props.filterable}
            className="-striped -highlight"
            getTrProps={this.props.trProps}
         />
      );
   }
}

SimpleTable.propTypes = {
   data: PropTypes.array,
   columns: PropTypes.array,
   loading: PropTypes.bool,
   filterable: PropTypes.bool,
   showPaginationTop: PropTypes.bool,
   defaultPageSize: PropTypes.number,
   trProps: PropTypes.func,
   noDataText: PropTypes.string,
   style: PropTypes.object,
};

SimpleTable.defaultProps = {
   data: [],
   columns: [],
   filterable: false,
   showPaginationTop: false,
   noDataText: "No se encontraron resultados",
   style: {},
};
