import React from 'react';
import Moment from 'moment';
import {DateFormat} from "../../const/api_consts";
import CyaHelper from '../../utils/CyaHelper';
import "react-table/react-table.css";
import {CyaTableLayout} from "../../components/custom/tables/CyaTableLayout";
import {toast} from "react-toastify";
import 'react-confirm-alert/src/react-confirm-alert.css'
import AuthService from "../../utils/AuthService";
import {confirmAlert} from "react-confirm-alert";
import AnnexCreditNoteApiClient from "../../utils/api-client/AnnexCreditNoteApiClient";

export default class AnnexCreditNote extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            loading: true,
            selectedRowId: -1,
            selected: -1
        };
        this.GetProps = this.GetProps.bind(this);
        this.Auth = new AuthService();
        this.AnnexCreditNoteApiClient = new AnnexCreditNoteApiClient();
        this.AnnexCreditNoteApiClient.GetAll().then(response => this.setState({data: response.data, loading: false}));
    }

    GetProps(state, rowInfo) {
        if (rowInfo && rowInfo.row) {
            return {
                onClick: () => {
                    this.setState({
                        selected: rowInfo.index,
                        selectedRowId: rowInfo.row.annexCreditNoteId
                    });
                },
                onDoubleClick: () => {
                    if (this.state.selected === rowInfo.index) {
                        this.props.history.push({
                            pathname: '/creditnotes/edit',
                            state: {id: rowInfo.row.annexCreditNoteId}
                        });
                    }
                },
                style: {
                    background: rowInfo.index === this.state.selected ? '#00AFEC' : rowInfo.index%2 === 0 ? '#DDDDDD' : '#FFFFFF',
                    color: rowInfo.index === this.state.selected ? 'white' : 'black'
                }
            }
        }
        return {};
    }

    viewCallback = () => {
        if (this.state.selectedRowId >= 0) {
            this.props.history.push({
                pathname: '/creditnotes/edit',
                state: {id: this.state.selectedRowId}
            });
        }
    };

    deleteCallback = () => {
        let creditNote = this.state.data.find(x => x.annexCreditNoteId === this.state.selectedRowId);
        confirmAlert({
            customUI: ({onClose}) => {
                return (
                    <div className='react-confirm-alert-body'>
                        <h1>Confirmación</h1>
                        <p>¿Está seguro de borrar esta nota de crédito?</p>
                        <h6>Nombre. : {creditNote.name }</h6>
                        <h6>NCF : {creditNote.ncf }</h6>
                        <h6>NCF Padre: {creditNote.annexNcf }</h6>
                        <div className={"react-confirm-alert-button-group"}>
                            <button onClick={() => {
                                if (this.state.selectedRowId >= 0) {
                                    this.AnnexCreditNoteApiClient.Delete(this.state.selectedRowId).then((response) => {
                                        if (response.response!==undefined){
                                            if (response.response.status === 400) {
                                                toast.error("Error eliminando nota de crédito.");
                                                this.setState({loading: false});
                                            }
                                            if (response.response.status === 200) {
                                                this.setState({loading: true});
                                                this.AnnexCreditNoteApiClient.GetAll().then(response => this.setState({data: response.data, loading: false}));
                                                toast.success("Nota de crédito  eliminada.");
                                            }
                                        }else {
                                            this.setState({loading: true});
                                            this.AnnexCreditNoteApiClient.GetAll().then(response => this.setState({data: response.data, loading: false}));
                                            toast.success("Nota de crédito eliminada.");
                                        }

                                    });
                                }
                                onClose();
                            }}
                            >Si
                            </button>
                            <button  onClick={onClose}>No</button>
                        </div>
                    </div>
                )
            },
        });
    };

    render() {
        const {data} = this.state;
        let columns = [
            {Header: "AnnexCreditNoteID", accessor: "annexCreditNoteId", show: false},
            {Header: "NCF", accessor: "ncf", width: 190},
            {Header: "NCF Padre", accessor: "annexNcf", width: 190},
            {Header: "Nombre Padre", accessor: "annexName", minWidth: 250},
            {
                Header: "Fecha ", id: "date", style: {textAlign: 'center'}, accessor: d => {
                    return Moment(d.date).local().format(DateFormat)
                }, width: 100
            },
            {Header: "Img", accessor: "images", style: {textAlign: 'right'}, width: 50},
            {
                Header: "Subtotal",
                accessor: "subtotal",
                style: {textAlign: 'right'},
                Cell: props => CyaHelper.ToCurrency(props.value),
                width: 150
            },
            {
                Header: "ITBIS",
                accessor: "itbis",
                style: {textAlign: 'right'},
                Cell: props => CyaHelper.ToCurrency(props.value),
                width: 150
            },
            {
                Header: "% ITBIS",
                accessor: "itbispercentage",
                style: {textAlign: 'right'},
                Cell: props => CyaHelper.ToPercent(props.value),
                width: 70
            },
            {
                Header: "ITBIS Ret",
                accessor: "itbisretention",
                style: {textAlign: 'right'},
                Cell: props => CyaHelper.ToCurrency(props.value),
                width: 150
            },
            {
                Header: "% ITBIS Ret",
                accessor: "itbisretentionPercentage",
                style: {textAlign: 'right'},
                Cell: props => CyaHelper.ToPercent(props.value),
                width: 70
            },
            {
                Header: "ISR",
                accessor: "isrretention",
                style: {textAlign: 'right'},
                Cell: props => CyaHelper.ToCurrency(props.value),
                width: 150
            },
            {
                Header: "% ISR ",
                accessor: "isrretentionPercentage",
                style: {textAlign: 'right'},
                Cell: props => CyaHelper.ToPercent(props.value),
                width: 70
            },
            {
                Header: "Tot.Gastos",
                accessor: "totalExpense",
                style: {textAlign: 'right'},
                Cell: props => CyaHelper.ToCurrency(props.value),
                width: 150
            },
            {
                Header: "Tot.Pagar",
                accessor: "totalDue",
                style: {textAlign: 'right'},
                Cell: props => CyaHelper.ToCurrency(props.value),
                width: 150
            },
            {Header: "Comentario", accessor: "comment", width: 300}
        ];
        return <CyaTableLayout title={"Notas de crédito"}
                               columns={columns}
                               data={data}
                               loading={this.state.loading}
                               trProps={this.GetProps}
                               deleteCallback={this.deleteCallback}
                               enableButtons={this.state.selectedRowId >= 0}
                               viewCallback={this.viewCallback}
                               showDeleteButton={this.Auth.GetPathPermission(this.props.location.pathname + "/delete", false)}
        />;
    }
}
