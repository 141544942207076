import React from "react/index";
import readXlsxFile from "read-excel-file/index";
import {LoadingPage} from "../../components/custom/views/LoadingPage";
import {SimpleTable} from "../../components/custom/tables/SimpleTable";
import {Card, CardBody, CardTitle, Col, FormGroup, Input, InputGroup, Row} from "reactstrap";
import Select from 'react-select';
import {toast} from "react-toastify";
import CrewCastApiClient from "../../utils/api-client/CrewCastApiClient";

export default class ImportCrewCast extends React.Component  {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            crewCastTypes: [],
            selectedCrewCast: 0,
            loading: false,
            fileName: "Importar Excel",
            errorMsg: "",
        };
        this.CrewCastApiClient = new CrewCastApiClient();
    }

    componentDidMount(){
        this.setState({loading:true});
        this.CrewCastApiClient.Types().then((response) => {
            const crewCastTypes = response.data.map(x => {x.value = x.crewCastTypeId;x.label = x.description;return x;});
            this.setState({crewCastTypes, selectedCrewCast: crewCastTypes[0],loading: false});
        });
    }

    fileChange = (event)=> {
        const crewCastTypeId =this.state.selectedCrewCast.crewCastTypeId;
        let file = event.target.files[0];
        if (file !== undefined) {
            this.setState({fileName: file.name, loading: true});
            readXlsxFile(event.target.files[0]).then((rows) => {
                let data = [];
                rows.map(function (obj, index) {
                    if (String(obj[0]).trim().length > 0 && String(obj[0]).trim() != "Nombre" && String(obj[0]).trim() != "REPORTE DE TECNICOS" && String(obj[0]).trim() != "REPORTE DE ACTORES") {
                        data.push({
                            name: String(obj[0]).trim(),
                            position: String(obj[1]).trim(),
                            id: String(obj[2]).trim(),
                            nationality: String(obj[3]).trim(),
                            crewCastTypeId : crewCastTypeId,
                        });
                    }
                    return true;
                });
                this.setState({data,loading: false});
            })
        }
    };

    saveCrewCast = (event) => {
        event.preventDefault();
        this.setState({loading: true});
        this.CrewCastApiClient.AddCrewCast({Data:this.state.data}).then((response)=>{
            console.log(response);
            toast.success("Importado correctamente");
            this.setState({loading: false});
            this.setState({data:[],fileName: "Importar Excel",});
        });
    };

    onSelectChange =(selectedCrewCast) => {
        this.setState({selectedCrewCast: selectedCrewCast});
    };

    render() {
        const columns = [
            {Header: "Nombre", accessor: "name"},
            {Header: "Cargo", accessor: "position"},
            {Header: "ID", accessor: "id"},
            {Header: "Nacionalidad", accessor: "nationality"}
        ];

        return (
            <div>
                <LoadingPage color={"3e5569"} loading={this.state.loading} type={"spin"}/>
                <Card>
                    <CardTitle className="bg-light border-bottom p-3 mb-0">
                        <i className="fas fa-users mr-2"/>Importaci&oacute;n de Crew y Cast
                    </CardTitle>
                    <CardBody>
                        <Row>
                            <Col md={6}>
                                <InputGroup>
                                    <div className="custom-file">
                                        <Input type="file"
                                               className="custom-file-input"
                                               name={"file"}
                                               id={"file"}
                                               onChange={this.fileChange}
                                            // accept={this.props.accept}
                                        />
                                        <label style={{overflow: "hidden"}} className="custom-file-label"
                                               htmlFor={"file"}>{this.state.fileName}</label>
                                    </div>
                                </InputGroup>
                            </Col>
                            <Col md={2}>
                                <FormGroup>
                                    <Select options={this.state.crewCastTypes}
                                            id={"crewCastTypeID"}
                                            name={"crewCastTypeID"}
                                            value={this.state.selectedCrewCast}
                                            isLoading={this.state.loading}
                                            placeholder={"Seleccione Tipo"}
                                            onChange={this.onSelectChange}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={3}>
                                <button disabled={!(this.state.data.length > 0 && this.state.selectedCrewCast.crewCastTypeId > 0)}
                                        className={!(this.state.data.length > 0 && this.state.selectedCrewCast.crewCastTypeId > 0) ? 'btn btn-primary disabled' : 'btn btn-primary '}
                                        onClick={this.saveCrewCast}
                                >Importar
                                </button>
                            </Col>


                        </Row>
                        <Row>
                            <Col md={6}>
                                {this.state.errorMsg !== "" ? <small style={{color: "red"}} className={"float-left"}>{this.state.errorMsg}</small> : ""}
                            </Col>
                        </Row>

                        <hr/>
                        <Row>
                            <Col md={12}>
                                <SimpleTable data={this.state.data}
                                             columns={columns}
                                             loading={this.state.loading}
                                             defaultPageSize={10}
                                />
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </div>
        );
    }


}
