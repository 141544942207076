const UserFieldValidator = {
    name: {
        secondValue: null,
        rules: [
            {
                test: (value) => value.length > 0,
                message: 'Campo en blanco'
            }
        ],
        errors: [],
        valid: false,
        state: ''
    },
    password: {
        secondValue: null,
        rules: [
            {
                test: (value) => value.length >= 5,
                message: 'Contraseña no puede ser menor a 5 caracteres',
            }
        ],
        errors: [],
        valid: false,
        state: ''
    },
    username: {
        secondValue: null,
        rules: [
            {
                test: (value) => value.length >= 4,
                message: 'Usuario debe contener al menos 4 caracteres',
            },
            {
                test: /^[A-Za-z0-9]+$/,
                message: 'Sólo se permiten valores alfanuméricos',
            },
        ],
        errors: [],
        valid: false,
        state: ''
    },
    email: {
        secondValue: null,
        rules: [
            {
                test: (value) => value.length > 0,
                message: 'Campo en blanco'
            },
            {
                test: (value) => {
                    let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    return re.test(String(value).toLowerCase());
                },
                message: 'Correo no es válido.'
            }
        ],
        errors: [],
        valid: false,
        state: ''
    },

};

export default UserFieldValidator ;
