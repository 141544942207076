import React from 'react';
import "react-table/react-table.css";
import {
    Card,
    CardTitle,
    CardBody,
    Row,
    Col,
    Form, Nav, NavItem, NavLink, Label, TabPane, TabContent,
} from 'reactstrap';
import {LoadingPage} from "../../components/custom/views/LoadingPage";
import {InputForm} from "../../components/custom/inputs/InputForm";
import {toast} from "react-toastify";
import AuthService from "../../utils/AuthService";
import {UserModel} from "../../models/UserModel";
import UserApiClient from "../../utils/api-client/UserApiClient";
import {InputSelect} from "../../components/custom/inputs/InputSelect";
import FieldValidator from "../../utils/field-validators/FieldValidator";
import UserFieldValidator from "../../utils/field-validators/UserFieldValidator";
import CyaHelper from "../../utils/CyaHelper";
import {CyaContext} from "../../utils/CyaContext";
import classnames from 'classnames';
import {SimpleTable} from "../../components/custom/tables/SimpleTable";
import ProjectApiClient from "../../utils/api-client/ProjectApiClient";
import ProjectsModal from "./ProjectsModal";
import md5 from 'md5';
import ChangePassword from "./ChangePassword";

export default class CreateUser extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: new UserModel(),
            initialData: new UserModel(),
            rolesData: [],
            userProjectsData: [],
            projectsData: [],
            projectsModal: false,
            changePasswordModal: false,
            selectedRow: null,
            loading: true,
            activeTab: '1',
            title: "Crear Usuario",
            modelCreated: "Usuario Guardado.",
            modelUpdated: "Usuario Actualizado.",
            modelError: "Error en Usuario."
        };
        this.Auth = new AuthService();
        this.UserApiClient = new UserApiClient();
        this.ProjectApiClient = new ProjectApiClient();
        this.Validator = new FieldValidator(UserFieldValidator);

        this.editPromise = this.editPromise.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.validSaveButton = this.validSaveButton.bind(this);
        this.toggle = this.toggle.bind(this);
        this.setProjectsModalOpen = this.setProjectsModalOpen.bind(this);
        this.setSelectedProject = this.setSelectedProject.bind(this);
        this.userProjectsDataTrProps = this.userProjectsDataTrProps.bind(this);
        this.deleteProjectFromList = this.deleteProjectFromList.bind(this);
        this.columns = [{Header: "Proyecto", accessor: "projectName"}];
    }

    componentDidMount() {
        this.UserApiClient.Roles().then(response =>{
            this.setState({rolesData:response.data});
        }).then(()=>{
            this.ProjectApiClient.GetAll().then(res => this.setState({projectsData: res.data}));
            if (this.props.location.state !== undefined && this.props.location.state?.id) {
                this.UserApiClient.GetUserProjects(this.props.location.state.id).then(res=> this.setState({userProjectsData:res.data}));
                this.UserApiClient.GetById(this.props.location.state.id).then(response => {
                    this.editPromise(response);
                });
            } else {
                this.setState({loading: false});
            }
        });

    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if (!nextState.loading && CyaHelper.ObjectCompare(nextState.data, this.state.data))
            this.Validator.initValidation(nextState.data);

        return true;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!this.state.loading){
            let isFormDirty = !CyaHelper.ObjectCompare(this.state.initialData, this.state.data);
            this.context.toggleExitConfirmation(isFormDirty);
        }
    }

    editPromise(response) {
        this.setState({
            initialData: JSON.parse(JSON.stringify(response)), //copy data variable
            data: response,
            title: "Editar Usuario",
            loading: false
        });
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({activeTab: tab});
        }
    }

    onInputChange(event) {
        let aux = this.state.data;
        aux[event.target.name] = event.target.value;
        this.setState({data: aux});
    }

    validSaveButton(){
        return (this.Auth.GetPathPermission(this.props.location.pathname, true) && this.Validator.validForm());
    }

    onSubmit(event) {
        event.preventDefault();
        this.setState({loading: true});

        const userData = {...this.state.data};
        userData.password = md5(userData.password);

        if (this.state.data.userId > 0) {
            this.UserApiClient.Update(this.state.data.userId, userData)
                .then(response => {
                    if (response.response !== undefined && response.response.data === "Error") {
                        this.setState({loading: false});
                        toast.error(this.state.modelError);
                    } else {
                        this.editPromise(response);
                        toast.success(this.state.modelUpdated);
                    }
                })
                .catch(() => {
                    this.setState({loading: false});
                    toast.error(this.state.modelError);
                });

            this.UserApiClient.SaveUserProjects(this.state.userProjectsData, this.state.data.userId).then(response => {
                if (response.response !== undefined && response.response.status >= 400) {
                    toast.error('Error actualizando los proyectos del usuario');
                }else{
                    toast.success('Proyectos del usuario actualizados');
                }
            });
        }
        else {
            this.UserApiClient.Create(userData)
                .then(response => {
                    if (response.response !== undefined && response.response.data === "Error") {
                        this.setState({loading: false});
                        toast.error(this.state.modelError);
                    }else {
                        this.editPromise(response);
                        toast.success(this.state.modelUpdated);
                    }
                })
                .catch(() => {
                    this.setState({loading: false});
                    toast.error(this.state.modelError);
                });
        }
        window.scrollTo(0, 0);
    }

    setProjectsModalOpen (value) {
        this.setState({projectsModal: value});
    }

    setSelectedProject (selectedProject) {
        let userProjectsData = [...this.state.userProjectsData];
        userProjectsData.push({
            projectId: selectedProject.projectId,
            userId: this.state.data.userId,
            projectName: selectedProject.name,
        });
        this.setState({userProjectsData});
    }

    deleteProjectFromList (){
        this.setState(prevState => ({ selectedRow: null, userProjectsData: prevState.userProjectsData.filter((x, index) => index !== this.state.selectedRow ) }));
    }

    userProjectsDataTrProps(state, rowInfo){
        if (rowInfo && rowInfo.row) {
            return {
                onClick: () => {
                    this.setState({selectedRow: rowInfo.index});
                },
                style: {
                    background: rowInfo.index === this.state.selectedRow ? '#00AFEC' : rowInfo.index % 2 === 0 ? '#DDDDDD' : '#FFFFFF',
                    color: rowInfo.index === this.state.selectedRow ? 'white' : 'black'
                }
            }
        }
        return {};
    }

    render() {
        return <div className="auth-wrapper d-flex">
            <ProjectsModal isOpen={this.state.projectsModal} setModalOpen={this.setProjectsModalOpen} setSelectedProject={this.setSelectedProject} projectsData={this.state.projectsData.filter(p => !this.state.userProjectsData.find(x=> x.projectId === p.projectId))} />
            <ChangePassword isOpen={this.state.changePasswordModal} setModalOpen={(value) => this.setState({changePasswordModal: value})} UserApiClient={this.UserApiClient} userId={this.state.data.userId} isAdmin={this.Auth.IsAdmin()} oldRealPassword={this.state.data.password}/>
            <div className="container">
                <Card>
                    <CardTitle className="mb-0 p-3 border-bottom bg-light"><i
                        className="mdi mdi-border-right mr-2"/>{this.state.title}
                    </CardTitle>
                    <CardBody>
                        <LoadingPage color={"3e5569"} loading={this.state.loading} type={"spin"}/>
                        <Nav tabs>
                            <NavItem>
                                <NavLink className={classnames({active: this.state.activeTab === '1'})} onClick={() => {this.toggle('1');}}>
                                    <Label>General</Label>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink className={classnames({active: this.state.activeTab === '2'})} onClick={() => {this.toggle('2');}}>
                                    <Label>Proyectos</Label>
                                </NavLink>
                            </NavItem>
                        </Nav>

                        <TabContent activeTab={this.state.activeTab}>
                            <TabPane tabId="1">
                                <Row>
                                    <Col md="12">
                                        <Card body>
                                        <Form>
                                            <Row>
                                                <Col md={this.state.data.userId>0?6:12}>
                                                    <InputForm
                                                        id_label={"name"}
                                                        label={"Nombre:"}
                                                        placeholder={""}
                                                        type={"text"}
                                                        value={this.state.data.name}
                                                        onInputChange={this.onInputChange}
                                                    />
                                                    {this.Validator.showErrors("name")}
                                                </Col>
                                                {this.state.data.userId>0?
                                                    <Col md={6}>
                                                        <InputForm
                                                            id_label={"username"}
                                                            label={"Usuario:"}
                                                            placeholder={""}
                                                            type={"text"}
                                                            value={this.state.data.username}
                                                            onInputChange={this.onInputChange}
                                                        />
                                                        {this.Validator.showErrors("username")}
                                                    </Col>
                                                    :''
                                                }

                                            </Row>
                                            {this.state.data.userId > 0 ?
                                                ''
                                                :
                                                <Row>
                                                    <Col md={6}>
                                                        <InputForm
                                                            id_label={"username"}
                                                            label={"Usuario:"}
                                                            placeholder={""}
                                                            type={"text"}
                                                            value={this.state.data.username}
                                                            onInputChange={this.onInputChange}
                                                        />
                                                        {this.Validator.showErrors("username")}
                                                    </Col>
                                                    <Col md={6}>
                                                        <InputForm
                                                            id_label={"password"}
                                                            label={"Contraseña:"}
                                                            placeholder={""}
                                                            type={"password"}
                                                            value={this.state.data.password}
                                                            onInputChange={this.onInputChange}
                                                        />
                                                        {this.Validator.showErrors("password")}
                                                    </Col>
                                                </Row>
                                            }
                                            <Row>
                                                <Col md="6">
                                                    <InputForm
                                                        id_label={"email"}
                                                        label={"Correo:"}
                                                        placeholder={""}
                                                        type={"text"}
                                                        value={this.state.data.email}
                                                        onInputChange={this.onInputChange}
                                                    />
                                                    {this.Validator.showErrors("email")}
                                                </Col>
                                                <Col md="6">
                                                    <InputSelect
                                                        id_label={"rolId"}
                                                        data={this.state.rolesData}
                                                        label={"Rol:"}
                                                        dataDescription={["description"]}
                                                        dataValue={"rolId"}
                                                        selectedValue={Number(this.state.data.rolId)}
                                                        onInputChange={this.onInputChange}/>

                                                </Col>
                                            </Row>
                                        </Form>
                                        </Card>
                                    </Col>
                                </Row>
                            </TabPane>
                            <TabPane tabId="2">
                                <Row>
                                    <Col md="12">
                                        <Card body>
                                            {this.state.data.userId === 0 ?
                                                <h3 style={{textAlign: 'center'}}>Debe guardar el usuario antes de asignarle un proyecto.</h3>
                                                : (
                                                    <div>
                                                        <SimpleTable
                                                            data={this.state.userProjectsData}
                                                            columns={this.columns}
                                                            trProps={this.userProjectsDataTrProps}
                                                            defaultPageSize={10}
                                                        />
                                                        <br/>
                                                        <Row>
                                                            <Col md="6">
                                                                <input type="submit"
                                                                       className='btn btn-success'
                                                                       value="Agregar"
                                                                       onClick={() => this.setProjectsModalOpen(true)}
                                                                />
                                                                {' '}
                                                            </Col>
                                                            <Col md="6" style={{textAlign: 'right'}}>
                                                                <input type="submit"
                                                                       disabled={this.state.selectedRow == null}
                                                                       className={this.state.selectedRow == null ? 'btn btn-danger disabled' : 'btn btn-danger'}
                                                                       value="Eliminar"
                                                                       onClick={this.deleteProjectFromList}
                                                                />
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                )
                                            }
                                        </Card>
                                    </Col>
                                </Row>
                            </TabPane>
                        </TabContent>

                        <Row>
                            <Col md={12}>

                                <input type="submit"
                                       disabled={!(this.validSaveButton())}
                                       className={!(this.validSaveButton()) ? 'btn btn-primary disabled' : 'btn btn-primary'}
                                       value="Guardar"
                                       onClick={this.onSubmit}/>
                                {' '}
                                {this.state.data.userId > 0 && (
                                    <input
                                        className={'btn btn-warning'}
                                        value="Cambiar contraseña"
                                        onClick={() => this.setState({changePasswordModal: true})}
                                    />
                                )}
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </div>
        </div>
    }
}
CreateUser.contextType = CyaContext;
