import React from 'react';
import {
    FormGroup,
    Label,
    Input,
} from 'reactstrap';
import PropTypes from 'prop-types'

export class InputTextArea extends React.Component {

    render() {

        return <FormGroup>
            <Label htmlFor={this.props.id_label}>{this.props.label}</Label>
            <Input type="textarea" rows={this.props.rows} name={this.props.id_label} id={this.props.id_label} placeholder={this.props.placeholder} value={this.props.value} onChange={this.props.onInputChange}/>
        </FormGroup>

    }
}

InputTextArea.propTypes = {
    label: PropTypes.string,
    id_label: PropTypes.string,
    value: PropTypes.string,
    placeholder: PropTypes.string,
    rows: PropTypes.string,
    onInputChange: PropTypes.func,
};
