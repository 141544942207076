export class UserModel {
    userId = 0;
    username = "";
    password = "";
    name = "";
    email = "";
    active = true;
    rolId = 1;
    deleted = false;
}






