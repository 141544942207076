import {ImageModel} from "./ImageModel";

export class ProducerDocumentModel {
    constructor(type = 1){
        this.producerDocumentTypeId = type;
    }

    producerDocumentTypeId = 1;
    value = "";
    issuedAt = new Date();
    expires = new Date();
    imageId = 0;
    image = new ImageModel();
}
