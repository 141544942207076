import {
    Label,
    FormGroup
} from 'reactstrap';
import Datetime from "react-datetime";
import {DateFormat} from "../../../const/api_consts";
import React from "react";
import PropTypes from "prop-types";

require('moment/locale/es-do');

export class DateFilter extends React.Component {
    render() {
        return <FormGroup>
                <Label style={{fontSize: '20px'}}>{this.props.label}:</Label>
                <Datetime   id={this.props.filterId}
                            name={this.props.filterId}
                            locale={"es-do"}
                            inputProps={{readOnly: true, style:{backgroundColor: "white"}}}
                            utc={true}
                            timeFormat={false}
                            dateFormat={DateFormat}
                            closeOnSelect={true}
                            value={this.props.value === undefined ? null : this.props.value}
                            onChange={this.props.onInputChange}
                />
            </FormGroup>
    }
}

DateFilter.propTypes = {
    filterId: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.object,
    onInputChange: PropTypes.func
};