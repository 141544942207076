import React from 'react';
import "react-table/react-table.css";
import ProjectApiClient from "../../utils/api-client/ProjectApiClient";
import {CyaTableLayout} from "../../components/custom/tables/CyaTableLayout";
import {Row, Col,} from 'reactstrap';
import Modal from "react-modal";
import {preDashboardUrl, projectsPage, SuccessResponse} from "../../const/api_consts";
import {toast} from "react-toastify";
import AuthService from "../../utils/AuthService";
import {CyaContext} from "../../utils/CyaContext";

export default class Project extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            selected: null,
            loading: true,
            selectedRowId: -1,
            projectName: "",
            producerName: "",
            selectedRowData: {},
            modalDelete:false,
        };
        this.GetProps = this.GetProps.bind(this);
        this.closeDeleteModal = this.closeDeleteModal.bind(this);
        this.deleteModel = this.deleteModel.bind(this);
        this.Auth = new AuthService();
        this.ProjectApiClient = new ProjectApiClient();

    }

    componentDidMount(){
        const {nameid, role} = this.Auth.GetProfile();

        // Solo buscar por user id al rol de Cliente. < todo: cambiar a futuro si no se necesita que solo sea a usuarios tipo clientes.
      //   if (parseInt(role) === 4) {
      //       this.ProjectApiClient.GetProjectsByUserId(nameid)
      //           .then(response => this.setState({data: response.data, loading: false}));
      //   }else{
            this.ProjectApiClient.GetAll().then(response => this.setState({data: response.data, loading: false}));
      //   }
    }

    SelectProject(projectId, projectName, producerName) {
        if (this.state.selected != null) {
            this.ProjectApiClient.Open(projectId)
                .then(() => {
                    this.ProjectApiClient.SetLocalProject(projectId, projectName, producerName);
                    this.props.history.push('/dashboard');
                })
                .catch(err => {
                    alert(err);
                });
        }
    }

    GetProps(state, rowInfo) {
        if (rowInfo && rowInfo.row) {
            return {
                onClick: () => {
                    this.setState({
                        selected: rowInfo.index,
                        selectedRowId: rowInfo.row.projectId,
                        projectName: rowInfo.row.name,
                        producerName: rowInfo.row.producerName,
                        selectedRowData: this.state.data.find(x => x.projectId === rowInfo.row.projectId)
                    });
                },
                onDoubleClick: () => {
                    if (Number(this.state.selected) === Number(rowInfo.index)) {
                        this.SelectProject(Number(rowInfo.row.projectId), rowInfo.row.name, rowInfo.row.producerName);
                    }
                },
                style: {
                    background: rowInfo.index === this.state.selected ? '#00AFEC' : 'white',
                    color: rowInfo.index === this.state.selected ? 'white' : 'black'
                }
            }
        }
        return {};
    }

    viewCallback = () => {
        if (this.state.selectedRowId >= 0) {
            this.SelectProject(this.state.selectedRowId, this.state.projectName, this.state.producerName);
        }
    };
    multipleActionsCallback = () => {
        if (this.state.selectedRowId !== -1){
            this.props.history.push({
                pathname: projectsPage+'/edit',
                state: {id: this.state.selectedRowId}
            });
        }
    };

    closeDeleteModal() {
        if (this.state.modalDelete)
            this.setState({modalDelete: false});
    }

    deleteCallback = () => {
        if (this.state.selectedRowId > 0) {
            this.setState({modalDelete: true});
        }
    };

    deleteModel(){
        this.setState({loading: true});
        if (this.state.selectedRowId > 0) {
            this.ProjectApiClient.Delete(this.state.selectedRowId).then((response) => {
                if (response !== SuccessResponse) {
                    toast.error("Error eliminado proyecto.");
                } else {
                    this.props.history.push({pathname: '/refresh'+preDashboardUrl+'/projects'});
                    toast.success("Proyecto eliminado.");
                }
            }).catch(() => {
                toast.warn("Error eliminado proyecto.");
            });
        }
        this.setState({loading: false});
        this.closeDeleteModal();
    }

    render() {
        let self = this;
        let columns = [
            {Header: "ID", accessor: "projectId", show: false},
            {Header: "Nombre", accessor: "name"},
            {Header: "Productora", accessor: "producerName"},
            {Header: "Productor General", accessor: "generalProducer"},
            {Header: "Productor de Linea", accessor: "lineProducer"}
        ];

        const customStyles = {
            content: {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)'
            }
        };
        Modal.setAppElement('body');

        return <div className="auth-wrapper d-flex">
            <Modal
                isOpen={this.state.modalDelete}
                onRequestClose={this.closeDeleteModal}
                style={customStyles}
            >
                <Row>
                    <Col md={10}><h4>Confirmación</h4></Col>
                    <Col md={2} style={{textAlign: 'right'}}>
                        <button className={"btn btn-danger"} style={{textAlign: 'right'}}
                                onClick={this.closeDeleteModal}>X
                        </button>
                    </Col>
                </Row>
                <hr/>
                <h5>¿Desea eliminar el siguiente proyecto? </h5>
                <ul>
                    {self.state.selectedRowId > 0 ?
                        <li key={0}>{this.state.selectedRowData.name }</li>
                        : ""}
                </ul>
                <Row>
                    <Col md={12}>
                        <button className={"btn btn-danger float-right"} onClick={this.deleteModel}>Eliminar
                        </button>
                    </Col>
                </Row>

            </Modal>
            <div className="container">
                <div>
                    <CyaTableLayout
                        title={"Proyectos"}
                        data={this.state.data}
                        columns={columns}
                        loading={this.state.loading}
                        trProps={this.GetProps}
                        enableButtons={this.state.selectedRowId >= 0}
                        viewCallback={this.viewCallback}
                        multipleActionsButton={true}
                        multipleActionsCallback={this.multipleActionsCallback}
                        showDeleteButton={this.Auth.GetPathPermission(this.props.location.pathname+"/delete",false)}
                        deleteCallback={this.deleteCallback}
                        massButtonText={"Ver"}
                        viewButtonText={"Abrir"}
                        enableMassButtonDefault={true}
                    />

        </div>
        </div>
        </div>
    }
}
Project.contextType = CyaContext;
