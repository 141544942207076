const PaymentMethodFieldValidator = {
    name: {
        secondValue: null,
        rules: [
            {
                test: (value) => value.length > 0,
                message: 'Campo en blanco'
            }
        ],
        errors: [],
        valid: false,
        state: ''
    },
    description: {
        secondValue: null,
        rules: [
            {
                test: (value) => value.length > 0,
                message: 'Campo en blanco'
            }
        ],
        errors: [],
        valid: false,
        state: ''
    },

};
export default PaymentMethodFieldValidator;
