import React, { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import { CyaTableLayout } from "../../components/custom/tables/CyaTableLayout";
import Modal from "react-modal";
import { Header } from "./components/Header";

export const PayRequest = () => {
   const [openDetailModal, setOpenDetailModal] = useState(false);

   const toggleDetailModal = () => setOpenDetailModal((old) => !old);

   return (
      <div
         style={{
            width: "100%",
         }}
      >
         <Modal isOpen={openDetailModal} onRequestClose={toggleDetailModal}>
            <div
               style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  height: "100%",
               }}
            >
               <Row>
                  <Col md={10}>
                     <h4>Detalles</h4>
                  </Col>
                  <Col md={2} style={{ textAlign: "right" }}>
                     <button
                        className={"btn btn-danger"}
                        style={{ textAlign: "right" }}
                        onClick={toggleDetailModal}
                     >
                        X
                     </button>
                  </Col>
               </Row>

               <div style={{ width: "100%", height: "100%" }}></div>

               <Row>
                  <Col md={12}>
                     <button
                        className={"btn btn-info float-right"}
                        onClick={() => {}}
                     >
                        Guardar
                     </button>
                  </Col>
               </Row>
            </div>
         </Modal>

         <Header />

         <CyaTableLayout
            title={"Solicitudes de Pago"}
            columns={[
               {
                  Header: "PayRequestId",
                  accessor: "payRequestId",
                  show: false,
               },
               {
                  Header: "Tipo Documento",
                  accessor: "idTypeDescription",
                  width: 190,
               },
               { Header: "Documento", accessor: "id", width: 150 },
               { Header: "Nombre", accessor: "name", minWidth: 250 },
               {
                  Header: "Etapa",
                  accessor: "stageDescription",
                  minWidth: 250,
               },
               { Header: "Fecha", accessor: "date", minWidth: 250 },
               { Header: "Subtotal", accessor: "subtotal", minWidth: 250 },
               { Header: "Comentario", accessor: "comment", minWidth: 250 },
            ]}
            data={[
               {
                  payRequestId: 1,
                  idTypeDescription: "Factura",
                  id: "102030-4050",
                  name: "Text Name",
                  stageDescription: "Producción",
                  date: "20203-02-01",
                  subtotal: "$ 10,000.00",
                  comment: "Compra articulos varios",
               },
            ]}
            loading={false}
            // trProps={() => {}}
            //  selectionCheckbox={true}
            //  selectedMultiple={this.selectedMultiple}
            //  enableButtons={this.state.selectedRowId >= 0}
            enableButtons={true}
            showDeleteButton={true}
            deleteCallback={() => {}}
            viewCallback={toggleDetailModal}
            // viewChecksCallback={() => {}}
            // modelType={"annex"}
            multipleActionsButton={false}
            multipleActionsCallback={() => {}}
            deleteText={"Eliminar"}
            //  checkSelectedData={}
         />
      </div>
   );
};
