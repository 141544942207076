import React from 'react';
import PropTypes from 'prop-types'
import {InputFileUpload} from "./InputFileUpload";
import Compress from 'compress.js';
import ReactImageMagnify from 'react-image-magnify';
import ImagePreview from '../../../assets/images/imagePreview.jpg';
import md5 from 'md5';
import {Col, Label, Row, ModalHeader, Modal, ModalBody} from 'reactstrap';
import CyAHelper from "../../../utils/CyaHelper";
import {ImageModel} from "../../../models/ImageModel";
import {Document, Page} from "react-pdf/dist/entry.webpack";
import {toast} from "react-toastify";

export class InputImage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            largeSrc: '',
            largeWidth: 0,
            largeHeight: 0,
            smallSrc: ImagePreview,
            filename: "Cargar archivo",
            isNew: true,
            numPages: null,
            pageNumber: 1,
            uniqueInputKey: 1,
            rotate:8,
        };
    }

    componentDidMount() {
        if (this.props.image.hash.length > 0) {
            const self = this;

            if (CyAHelper.GetFileExtension(this.props.image.filename) !== 'pdf') {
                let img = new Image();
                img.src = 'data:image/png;base64, ' + this.props.image.fileContent;
                self.setState({
                    isNew: false,
                    filename: self.props.image.filename
                });
                img.onload = function () {
                    let imgWidth = img.naturalWidth, imgHeight = img.naturalHeight;
                    self.setState({
                        largeSrc: 'data:image/png;base64, ' + self.props.image.fileContent,
                        largeWidth: imgWidth,
                        largeHeight: imgHeight,
                        smallSrc: 'data:image/png;base64, ' + self.props.image.fileContent
                    });
                };
            } else {
                self.setState({
                    isNew: false,
                    filename: self.props.image.filename,
                    largeSrc: 'data:application/pdf;base64,' + self.props.image.fileContent,
                    largeWidth: 1,
                    largeHeight: 1,
                    smallSrc: 'data:application/pdf;base64,' + self.props.image.fileContent
                });
            }
        }
    }

    onDocumentLoadSuccess = ({numPages}) => {
        this.setState({numPages});
    };

    onDownloadClick = () => {
        if (this.props.image.filename !== "") {
            if (CyAHelper.GetFileExtension(this.props.image.filename) !== 'pdf') {
                CyAHelper.DownloadFile('data:application/octet-stream;base64,' + this.props.image.fileContent, this.props.image.filename);
            } else {
                CyAHelper.DownloadFile(this.state.largeSrc, this.props.image.filename);
            }
        }
    };

    onViewClick = () => {
        this.setState({modal: true});
    };

    closeModal = () => {
        this.setState({modal: false});
    };

    toggleModal = () => {
        this.setState({modal: !this.state.modal});
    };

    functionThatResetsTheFileInput = () => {
        let randomString = Math.random().toString(36);
        this.setState({
            uniqueInputKey: randomString
        });
    };

    changePage = (e, action) => {
        e.preventDefault();
        let pageNumber = this.state.pageNumber;
        switch (action) {
            case 'back': {
                pageNumber = pageNumber > 1 ? (pageNumber - 1) : pageNumber;
                break;
            }
            case 'next': {
                pageNumber = pageNumber < this.state.numPages ? (pageNumber + 1) : pageNumber;
                break;
            }
            default: {
                pageNumber = 1;
                break;
            }
        }
        this.setState({pageNumber: pageNumber})
    };

    onFileInputChange = (e) => {
        e.preventDefault();
        this.props.loadingCallback(true);
        let files = [...e.target.files];

        if (files.length === 0) return;

        if (CyAHelper.IsFileImage(files[0])) {
            new Compress().compress(files, {
                size: 1,
                quality: .5
            }).then((results) => {
                const compressImg = results[0];
                this.props.image.imageId = 0;
                this.props.image.fileContent = compressImg.data;
                this.props.image.hash = md5(compressImg.data);

                this.props.image.filename = compressImg.alt;
                this.props.callback(this.state.isNew, results);

                this.setState({
                    isNew: false,
                    largeSrc: 'data:image/png;base64, ' + compressImg.data,
                    largeWidth: compressImg.endWidthInPx,
                    largeHeight: compressImg.endHeightInPx,
                    smallSrc: 'data:image/png;base64, ' + compressImg.data,
                    filename: compressImg.alt
                });
                this.props.loadingCallback(false);
            });
        } else if (CyAHelper.IsFilePDF(files[0]) && this.props.acceptPDF) {
            CyAHelper.GetBase64FromFileInput(files[0])
                .then(
                    (data) => {
                        this.props.image.imageId = 0;
                        this.props.image.fileContent = data.split(',')[1];
                        this.props.image.hash = md5(data.split(',')[1]);
                        this.props.image.filename = files[0].name;
                        this.props.callback(this.state.isNew);

                        this.setState({
                            isNew: false,
                            largeSrc: 'data:application/pdf;base64,' + data.split(',')[1],
                            largeWidth: 1,
                            largeHeight: 1,
                            smallSrc: 'data:application/pdf;base64,' + data.split(',')[1],
                            filename: files[0].name,
                            pageNumber: 1
                        });
                        this.props.loadingCallback(false);
                    }
                )
                .catch((error) => {
                    console.log(error);
                })
        } else {
            toast.error('ERROR. Solo se aceptan imágenes ' + (this.props.acceptPDF ? 'o PDF.' : '.'));
            this.props.loadingCallback(false);
        }

    };

    onPrintClick = () => {
        if (this.props.image.filename !== "") {
            let html = "";
            if (CyAHelper.GetFileExtension(this.props.image.filename) !== 'pdf') {
                html += "<html><body><img src='data:image/png;base64," + this.props.image.fileContent + "' /></body></html>";
            } else {
                html += '<embed width=100% height=100% type="application/pdf" src="data:application/pdf;base64,' + this.props.image.fileContent + '"> </embed>';
            }
            const printWindow = window.open('', '_blank');
            printWindow.document.write(html);
            printWindow.document.close();
            printWindow.onload = function () {
                printWindow.print();
            };
        }
    };



    rotateImage = () => {
        const {smallSrc, rotate} = this.state
        this.resetOrientation(smallSrc, rotate, (resetBase64Image) => {
            this.props.rotatedImage(resetBase64Image, this.props.image)
            this.setState({
                smallSrc: resetBase64Image,
                largeSrc: resetBase64Image,
                rotate: 8
            })
        })
    }


    resetOrientation(srcBase64, srcOrientation, callback) {
        let img = new Image();
        img.onload = function() {
            var width = img.width,
                height = img.height,
                canvas = document.createElement('canvas'),
                ctx = canvas.getContext("2d");

            if (4 < srcOrientation && srcOrientation < 9) {
                canvas.width = height;
                canvas.height = width;
            } else {
                canvas.width = width;
                canvas.height = height;
            }

            switch (srcOrientation) {
                case 2: ctx.transform(-1, 0, 0, 1, width, 0); break;
                case 3: ctx.transform(-1, 0, 0, -1, width, height ); break;
                case 4: ctx.transform(1, 0, 0, -1, 0, height ); break;
                case 5: ctx.transform(0, 1, 1, 0, 0, 0); break;
                case 6: ctx.transform(0, 1, -1, 0, height , 0); break;
                case 7: ctx.transform(0, -1, -1, 0, height , width); break;
                case 8: ctx.transform(0, -1, 1, 0, 0, width); break;
                default: break;
            }
            ctx.drawImage(img, 0, 0);
            callback(canvas.toDataURL());
        };
        img.src = srcBase64;
    }

    render() {
        let selectedStyle = {
            boxShadow: "0 0 5px rgba(81, 203, 238, 1)",
            margin: "5px 1px 3px 0px",
            border: "2px solid rgba(81, 203, 238, 1)"
        };
        const {numPages, pageNumber} = this.state;
        let unselectedStyle = {
            borderWidth: "0px"
        };

        return <div style={{
            height: this.props.showImageMagnify ? this.props.height : "",
            width: this.props.showImageMagnify ? this.props.width : "",
            padding: this.props.addPadding ? '5px' : "0px"
        }}>
            {this.props.label === "" ? "" : <Label>{this.props.label}</Label>}
            <InputFileUpload id_label={"image"}
                             onDownloadClick={this.onDownloadClick}
                             onPrintClick={this.onPrintClick}
                             rotateImage={this.rotateImage}
                             onInputChange={this.onFileInputChange} onViewClick={this.onViewClick}
                             filename={this.state.filename}
                             imageContent={this.state.largeSrc}
                             label={""}
                             accept={(this.props.acceptPDF ? ' .pdf, ' : '') + ".jpg, .png, .jpeg, .jpeg|images/*"}
                             multiple={this.props.multiple}
                             key={this.state.uniqueInputKey}
                             showMailButton={this.props.showMailButton}
                             showWhatsappButton={this.props.showWhatsappButton}
                             showViewButton={(this.props.showViewButton && this.state.largeWidth > 0) || CyAHelper.GetFileExtension(this.state.filename) === 'pdf'}
            />

            {this.props.showImageMagnify ?
                (CyAHelper.GetFileExtension(this.state.filename) !== 'pdf' ?
                    <ReactImageMagnify
                        style={this.props.id_label === this.props.selectedId ? selectedStyle : unselectedStyle}
                        {...{
                            smallImage: {
                                alt: this.state.filename,
                                isFluidWidth: true,
                                src: this.state.smallSrc,
                            },
                            largeImage: {
                                src: this.state.largeSrc,
                                width: this.state.largeWidth,
                                height: this.state.largeHeight
                            },
                            enlargedImagePosition: "beside",
                            enlargedImageContainerStyle: {zIndex: 10,}
                        }}/> : '') : ""}

            <Modal isOpen={this.state.modal} toggle={this.toggleModal} className={'modal-lg'}>
                <ModalHeader toggle={this.toggleModal}>Imagen</ModalHeader>
                <ModalBody>
                    <div style={{marginLeft: "auto", marginRight: "auto", width: this.props.width, padding: "5px"}}>
                        {
                            CyAHelper.GetFileExtension(this.state.filename) === 'pdf' ?
                                <div>
                                    <Document
                                        file={this.state.largeSrc}
                                        onLoadSuccess={this.onDocumentLoadSuccess}
                                    >
                                        <Page pageNumber={pageNumber}/>
                                    </Document>
                                    <Row>
                                        <Col md={6}>
                                            <button
                                                className={'btn btn-success ' + (pageNumber === 1 ? 'disable' : ' ')}
                                                disabled={pageNumber === 1}
                                                onClick={(event) => this.changePage(event, 'back')}>Atrás
                                            </button>
                                        </Col>
                                        <Col md={6} style={{textAlign: 'right'}}>
                                            <button
                                                className={'btn btn-success ' + (pageNumber === numPages ? 'disable' : ' ')}
                                                disabled={pageNumber === numPages}
                                                onClick={(event) => this.changePage(event, 'next')}>Siguiente
                                            </button>
                                        </Col>
                                    </Row>

                                    <p>Página {pageNumber} de {numPages}</p>
                                </div>
                                :
                                <ReactImageMagnify
                                    style={this.props.id_label === this.props.selectedId ? selectedStyle : unselectedStyle}
                                    {...{
                                        smallImage: {
                                            alt: this.state.filename,
                                            isFluidWidth: true,
                                            src: this.state.smallSrc,
                                        },
                                        largeImage: {
                                            src: this.state.largeSrc,
                                            width: this.state.largeWidth,
                                            height: this.state.largeHeight,
                                        },
                                        enlargedImagePosition: "over",
                                        enlargedImageContainerStyle: {zIndex: 10,}
                                    }}/>
                        }
                    </div>
                </ModalBody>
            </Modal>
        </div>
    }
}

InputImage.propTypes = {
    label: PropTypes.string,
    id_label: PropTypes.string,
    image: PropTypes.object,
    height: PropTypes.string,
    width: PropTypes.string,
    callback: PropTypes.func,
    selectedId: PropTypes.string,
    showMailButton: PropTypes.bool,
    showWhatsappButton: PropTypes.bool,
    showViewButton: PropTypes.bool,
    showImageMagnify: PropTypes.bool,
    multiple: PropTypes.bool,
    acceptPDF: PropTypes.bool,
    addPadding: PropTypes.bool,
    loadingCallback: PropTypes.func,
};

InputImage.defaultProps = {
    showImageMagnify: true,
    showMailButton: false,
    showWhatsappButton: false,
    showViewButton: false,
    label: "",
    id_label: "",
    image: new ImageModel(),
    height: "auto",
    width: "auto",
    selectedId: "",
    callback: function () {
    },
    multiple: false,
    acceptPDF: false,
    addPadding: false,
    loadingCallback: function () {
    },
};
