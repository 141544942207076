import React from "react";
import ReactTable from "react-table";
import PropTypes from 'prop-types'

export class CyaTableNestedRow extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            expanded: {}
        };
    }

    HideHeaders = () => {
        return {style: { display: 'none' }}
    };

    render() {
        return <ReactTable
                nextText={"Siguiente"}
                previousText={"Anterior"}
                loadingText={"Cargando..."}
                loading={this.props.loading}
                noDataText={"No se encontraron resultados"}
                pageText={"Página"}
                ofText={"de"}
                rowsText={"filas"}
                data={this.props.data}
                columns={this.props.columns}
                className="-striped -highlight"
                onExpandedChange={(expanded) => {
                    this.setState({expanded});
                }}
                showPaginationBottom={false}
                expanded={this.state.expanded}
                SubComponent={this.props.subComponent}
                pageSize={this.props.data.length}
                defaultSortDesc={false}
                getTheadThProps={this.HideHeaders}
                getTrProps={this.props.trProps}
                sorted={[{ // the sorting model for the table
                    id: 'code',
                    desc: false
                }]}
            />
    }
}

CyaTableNestedRow.propTypes = {
    data: PropTypes.array,
    columns: PropTypes.array,
    subComponent: PropTypes.func,
    nestedLevel: PropTypes.number,
    loading: PropTypes.bool,
    trProps: PropTypes.func
};