const AnnexFieldValidator = {
    ncf: {
        secondValue: null,
        rules: [
            {
                test: (value) => value.length > 0,
                message: 'Campo en blanco'
            },
            {
                test: (value) => {
                    let isNumber = !Number.isNaN(Number(value.substr(1)));
                    let firstCheck = value.length === 19 && value.startsWith("A");
                    let secondCheck = value.length === 11 && value.startsWith("B");
                    let thirdCheck = value.length === 13 && value.startsWith("E");
                    return isNumber && (firstCheck || secondCheck || thirdCheck);
                },
                message: 'Formato incorrecto'
            }
        ],
        errors: [],
        valid: false,
        state: ''
    },
    id: {
        secondValue: "idTypeId",
        rules: [
            {
                test: (value) => value.length > 0,
                message: 'Campo en blanco'
            },
            {
                test: (value, idTypeId) => {
                    let firstCheck = value.length === 12 && Number(idTypeId) === 1;
                    let secondCheck = value.length === 13 && Number(idTypeId) === 2;
                    let thirdCheck = Number(idTypeId) === 3;

                    return (firstCheck || secondCheck || thirdCheck);
                },
                message: 'Formato incorrecto'
            }
        ],
        errors: [],
        valid: false,
        state: ''
    },
    name: {
        secondValue: null,
        rules: [
            {
                test: (value) => value.length > 0,
                message: 'Campo en blanco'
            },
            {
               test: (value) => value.length <= 150,
               message: 'Cantidad máxima de carácteres excedidos'
            }
        ],
        errors: [],
        valid: false,
        state: ''
    },
    subtotal: {
        secondValue: null,
        rules: [
            {
                test: (value) => value > 0,
                message: 'Subtotal no puede ser cero'
            }
        ],
        errors: [],
        valid: false,
        state: 0
    },
    comment: {
      secondValue: null,
      rules: [
          
          {
             test: (value) => value.length <= 250,
             message: 'Cantidad máxima de carácteres excedidos'
          }
      ],
      errors: [],
      valid: true,
      state: ''
  },
};

export default AnnexFieldValidator;