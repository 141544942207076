import React, {useState} from "react";
import Modal from "react-modal";
import {Col, Row} from "reactstrap";
import {InputForm} from "../../components/custom/inputs/InputForm";
import {ModalStyle} from "../../const/api_consts";
import {toast} from "react-toastify";
import CyaHelper from "../../utils/CyaHelper";
import md5 from 'md5';

export default function ChangePassword({isOpen, setModalOpen, UserApiClient, userId, isAdmin, oldRealPassword}) {
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');

    const cleanFields = () => {
        setOldPassword('');
        setNewPassword('');
        setConfirmNewPassword('');
    }

    const onSubmit = () => {
        if (newPassword !== confirmNewPassword) {
            toast.error('Nueva contraseña y confirmar contraseña deben ser iguales');
            return null
        }

        if (newPassword.length < 5){
            toast.error('Nueva contraseña no puede ser menor a 5 caracteres');
            return null
        }

        const oldPass = !isAdmin ? md5(oldPassword) : oldRealPassword;

        UserApiClient.ChangePassword(oldPass, md5(newPassword), userId).then(res => {
            if (CyaHelper.isResponseOK(res)) {
                toast.success('Contraseña cambiada con éxito');
                setModalOpen(false)
                cleanFields()
            } else {
                toast.error('Error cambiando contraseña, verificar la contraseña anterior.');
            }
        })
    }

    return (
        <Modal {...{isOpen, onRequestClose: () => setModalOpen(false), style: ModalStyle}}>
            <Row>
                <Col md={10}><h4>Cambiar Contraseña</h4></Col>
                <Col md={2} style={{textAlign: 'right'}}>
                    <button className={"btn btn-danger"} style={{textAlign: 'right'}} onClick={() => setModalOpen(false)}>X</button>
                </Col>
            </Row>
            {!isAdmin && (
                <Row>
                    <Col md={6}>
                        <InputForm
                            label={"Contraseña anterior: "}
                            value={oldPassword}
                            type='password'
                            onInputChange={(event) => setOldPassword(event.target.value)}
                        />
                    </Col>
                </Row>
            )}

            <Row>
                <Col md={6}>
                    <InputForm
                        label={"Contraseña nueva: "}
                        value={newPassword}
                        type='password'
                        onInputChange={(event) => setNewPassword(event.target.value)}
                    />
                </Col>
                <Col md={6}>
                    <InputForm
                        label={"Confirmar contraseña nueva: "}
                        value={confirmNewPassword}
                        type='password'
                        onInputChange={(event) => setConfirmNewPassword(event.target.value)}
                    />
                </Col>
            </Row>
            <Row>
                <Col md={12}>
                    <h6>Contraseña no puede ser menor a 5 caracteres</h6>
                </Col>
            </Row>

            <Row>
                <Col md={6}>
                    <input
                        className='btn btn-primary'
                        value="Cambiar"
                        onClick={onSubmit}
                    />
                </Col>
            </Row>
        </Modal>
    )
}