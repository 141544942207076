export class ItemModel {
    code = "";
    name = "";
    subtotal = 0;
    itbis = 0;
    total = 0;
    parentItemId = 0;
    itemDetail = [];
    isGrandson = false
}






