import React from 'react';
import PerfectScrollbar from "react-perfect-scrollbar";
import {Row,FormGroup, Col, Card, CardBody,InputGroup,Input,InputGroupAddon, CardTitle, NavLink, Label, NavItem,ModalHeader,ModalBody,Modal,Button,ModalFooter} from "reactstrap";
import classnames from 'classnames';
import CreatableSelect from "react-select/lib/Creatable";
import {InputSelect} from "./inputs/InputSelect";
import CyAHelper from "../../utils/CyaHelper";
import {toast} from "react-toastify";

function ExpiredSingleSirecine(props) {
    return (
        <div className="d-flex flex-row comment-row mt-0">
            <div className="comment-text w-100">
                <h6 className="font-medium">{props.name}</h6>
                <span className="mb-3 d-block">{props.comment}</span>
                <div className="comment-footer">
                    <span className="text-muted float-right">{props.date}</span>
                    <span className={"badge badge-" + props.badgeColor}>{props.badge}</span>
                    <span className="action-icons">
              <i className="ti-pencil-alt"/>
            </span>
                </div>
            </div>
        </div>
    );

}

export function ExpiredSirecine(props) {
    return (
        <Card className={"card-hover"}>
            <CardBody>
                <div className="d-md-flex align-items-center">
                    <div>
                        <CardTitle>Sirecine Expirados</CardTitle>
                        {/*<CardSubtitle>Expirados</CardSubtitle>*/}
                    </div>
                </div>
                <div className="comment-widgets scrollable" style={{height: '258px'}}>
                    <PerfectScrollbar>
                        {props.data.map((x, i) => {
                            return (
                                <ExpiredSingleSirecine
                                    key={i}
                                    badge="Expirado"
                                    badgeColor="danger"
                                    name={x.name}
                                    comment={"Sirecine Expirado el día: " + x.date}
                                    date=""
                                />
                            );
                        })}

                    </PerfectScrollbar>
                </div>
            </CardBody>
        </Card>
    );
}

export function SimpleNavItem(props) {
    return (
        <NavItem>
            <NavLink className={classnames({active: props.active})} onClick={props.toggle}>
                <Label>{props.tabName}</Label>
            </NavLink>
        </NavItem>
    );
}

export function SimplePersonProfileCard(props) {
    return (
        <Card className="card-hover" style={{minHeight:'200px'}} >
            <CardBody className="text-center pl-1 pr-1">
                <div className="profile-pic mb-2 mt-2">
                    <h5 className="mt-1 mb-0">{props.data.personName}</h5>
                    <div>
                        <small>{props.data.idNumber}</small>
                    </div>
                    <div>
                        <small>{props.data.character}</small>
                    </div>

                </div>
                {!props.noLabel?
                    <div className="badge badge-pill badge-light font-12">{props.data.positionName}</div>
                    :''}


            </CardBody>
            <div className="p-1 border-top mt-0 mb-0">
                <Row className="text-center">
                    <Col md={12} className="border-right">
                        <a href="" className="link d-flex align-items-center justify-content-center font-medium"
                           onClick={props.onClick}><i
                            className="mdi mdi-message font-20 mr-1"> </i>Ver</a>
                    </Col>
                </Row>
            </div>
        </Card>
    );
}

export function ConfirmModal({modal,title,body,confirmation}) {
    return (
        <Modal isOpen={modal} className={'modal-md'} centered>
            <ModalHeader>{title}</ModalHeader>
            <ModalBody>
                {body}
                <hr/>
                <Row>
                    <Col md={6} >
                        <Button color="success" size={'xl'} onClick={() => confirmation(true)}>Si</Button>
                    </Col>
                    <Col md={6} style={{textAlign: 'right'}}>
                        <Button color="secondary" size={'xl'} onClick={() => confirmation(false)}>No</Button>
                    </Col>
                </Row>
            </ModalBody>
        </Modal>
    );
}

export const FileInput = ({onFileChange,fileName,fileContent})=>{
    const onDownload = () => CyAHelper.DownloadFile('data:application/octet-stream;base64,' + fileContent, fileName);
    const onChange = (e) =>{
        let files = [...e.target.files];
        if (CyAHelper.IsFileDOC(files[0])){
            CyAHelper.GetBase64FromFileInput(files[0]).then((data) => onFileChange(data.split(',')[1],files[0].name) ).catch((error) => {console.log(error);})
        } else {
            toast.error("ERROR. Solo se acepta archivos Word.");
        }

    };
    return(<div>
        <FormGroup>
            <Label> Archivo: </Label>
            <InputGroup>
                <div className="custom-file">
                    <Input type="file" className="custom-file-input" accept={".docx, .doc"} onChange={onChange}/>
                    <Label style={{overflow: "hidden"}} className="custom-file-label" >{fileName===""?"Cargar archivo":fileName}</Label>
                </div>
                    {fileName.length>0? <InputGroupAddon addonType="append"><Button className="btn" onClick={onDownload} color="info"><i className="fa fa-download"/></Button></InputGroupAddon> : ''}
            </InputGroup>
        </FormGroup>
        </div>
    );
};

export const ModalContractPerson =({modal,toggleModal,personal,onChange,data,submit,personContractTypes,deleteCallback,onFileChange,})=>{
    personal.map(x=>{x.label=x.name;x.value=x.personId;return x;});
  return <Modal isOpen={modal} toggle={()=>toggleModal("cancel")} className={'modal-lg'}>
      <ModalHeader toggle={()=>toggleModal("cancel")}>Contrato Personal</ModalHeader>
      <ModalBody>
          <Row>
              <Col md={12}>
                  <FormGroup>
                      <Label htmlFor={"name"}>Personal: </Label>
                      <CreatableSelect
                          value={personal.find(x=>x.personId===data.personId)}
                          options={personal}
                          id={"name"}
                          name={"name"}
                          placeholder={"Seleccionar Personal"}
                          onChange={(e)=>onChange(e,"personal")}/>
                  </FormGroup>
              </Col>
          </Row>
          <Row>
              <Col md={12}>
                  <InputSelect
                      id_label={"contractId"}
                      data={personContractTypes}
                      label={"Tipo de personal:"}
                      dataDescription={["name"]}
                      dataValue={"contractId"}
                      selectedValue={Number(data.contractId)}
                      onInputChange={(e)=>onChange(e,"personalType")}/>
              </Col>
          </Row>
          <Row>
              <Col md={12}>
                  <FileInput fileContent={data.file} fileName={data.name} onFileChange={onFileChange}/>
              </Col>
          </Row>

      </ModalBody>
      <ModalFooter>
          {data.contractPersonId===0?
              <Button disabled={!(data.personId!==0)} color={"success"} onClick={submit}> Agregar</Button>
              :
              <Button color={"danger"} onClick={()=>deleteCallback("person",data.contractPersonId)}> Borrar</Button>}

      </ModalFooter>
  </Modal>
};

export const ModalInvestorPerson =({modal,toggleModal,investors,data,onChange,submit,investorContractTypes,deleteCallback,onFileChange})=>{
    investors.map(x=>{x.label=x.name;x.value=x.investorId;return x;});
    return <Modal isOpen={modal} toggle={()=>toggleModal("cancel")} className={'modal-lg'}>
        <ModalHeader toggle={()=>toggleModal("cancel")}>Contrato Inversionista</ModalHeader>
        <ModalBody>
            <Row>
                <Col md={12}>
                    <FormGroup>
                        <Label>Inversionista: </Label>
                        <CreatableSelect
                            value={investors.find(x=>x.investorId===data.investorId)}
                            options={investors}
                            id={"name"}
                            name={"name"}
                            placeholder={"Seleccionar Inversionista"}
                            onChange={(e)=>onChange(e,"investor")}/>
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col md={12}>
                    <InputSelect
                        id_label={"contractId"}
                        data={investorContractTypes}
                        label={"Tipo de Contrato:"}
                        dataDescription={["name"]}
                        dataValue={"contractId"}
                        selectedValue={Number(data.contractId)}
                        onInputChange={(e)=>onChange(e,"investorContractType")}/>
                </Col>
            </Row>
            <Row>
                <Col md={12}>
                    <FileInput fileContent={data.file} fileName={data.name} onFileChange={onFileChange}/>
                </Col>
            </Row>
        </ModalBody>
        <ModalFooter>
            {data.contractInvestorId===0?
            <Button disabled={!(data.investorId!==0)} color={"success"} onClick={submit}> Agregar</Button>
            :
            <Button color={"danger"} onClick={()=>deleteCallback("investor",data.contractInvestorId)}> Borrar</Button>}
        </ModalFooter>
    </Modal>
};
