import React from 'react';
import {Card, CardTitle, CardBody, Row, Col, Button} from 'reactstrap';
import {LoadingPage} from "../../components/custom/views/LoadingPage";
import {SimpleTable} from "../../components/custom/tables/SimpleTable";
import PersonApiClient from "../../utils/api-client/PersonApiClient";
import CyaHelper from "../../utils/CyaHelper";
import {ConfirmModal, ModalContractPerson, ModalInvestorPerson} from "../../components/custom/FunctionalComponents";
import InvestorApiClient from "../../utils/api-client/InvestorApiClient";
import {ContractPersonModel} from "../../models/ContractPersonModel";
import {ContractInvestorModel} from "../../models/ContractInvestorModel";
import {toast} from "react-toastify";
import HiredPersonApiClient from "../../utils/api-client/HiredPersonApiClient";
import ProjectInvestorApiClient from "../../utils/api-client/ProjectInvestorApiClient";

export class Contracts extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            personModal: false,
            personalContracts:[],
            personContractTypes:[],
            personal:[],
            personalContract: new ContractPersonModel(),

            investorModal: false,
            investorContracts:[],
            investorContractTypes:[],
            investors:[],
            investorContract: new ContractInvestorModel(),

            deleteModal:false,
            contractToDelete:{type:"",id:""},
        };
        this.PersonApiClient = new PersonApiClient();
        this.InvestorApiClient = new InvestorApiClient();
        this.HiredPersonApiClient = new HiredPersonApiClient();
        this.ProjectInvestorApiClient = new ProjectInvestorApiClient();
    }

    componentDidMount(){
        this.setState({loading:true});
        CyaHelper.GetAllRequests([
            this.PersonApiClient.GetContracts(),
            this.InvestorApiClient.GetContracts(),
            this.PersonApiClient.GetAll(),
            this.InvestorApiClient.GetAll(),
            this.PersonApiClient.GetContractTypes(),
            this.InvestorApiClient.GetContractTypes(),
        ]).then(responseArr=>{
            if (CyaHelper.isResponseOK(responseArr[0])){this.setState({personalContracts:responseArr[0].data});}

            if (CyaHelper.isResponseOK(responseArr[1])){this.setState({investorContracts:responseArr[1].data});}

            if (CyaHelper.isResponseOK(responseArr[2])){this.setState({personal:responseArr[2].data});}

            if (CyaHelper.isResponseOK(responseArr[3])){this.setState({investors:responseArr[3].data});}

            if (CyaHelper.isResponseOK(responseArr[4])){this.setState({personContractTypes:responseArr[4].data});}

            if (CyaHelper.isResponseOK(responseArr[5])){this.setState({investorContractTypes:responseArr[5].data});}

        }).finally(()=>this.setState({loading:false}));
    }

    togglePersonModal = (type="") =>{
        if (type === "cancel") this.setState({personalContract: new ContractPersonModel()});
        this.setState({personModal:!this.state.personModal});
    };

    toggleInvestorModal = (type="") =>{
        if (type === "cancel") this.setState({investorContract: new ContractInvestorModel()});
        this.setState({investorModal:!this.state.investorModal});
    };

    //PersonContract
    submitPersonContract = () =>{
        this.setState({loading:true});
        const personalContract = this.state.personalContract;
        this.PersonApiClient.SaveContract(personalContract).then(response=>{
            if(CyaHelper.isResponseOK(response)){
                let personalContracts = [...this.state.personalContracts];
                personalContracts.push(response);
                this.togglePersonModal();
                toast.success("Contrato Eliminado");
                this.setState({personalContract: new ContractPersonModel(),personalContracts});
            }

        }).finally(()=>this.setState({loading:false}));
    };

    personModalOnChange = (e,type="") =>{
        switch (type) {
            case "personalType":
                const contractId=e.target.value;
                this.setState(prevState => ({ personalContract: { ...prevState.personalContract, contractId } }));
                break;
            case "personal":
                this.setState(prevState => ({ personalContract: { ...prevState.personalContract, personId: e.personId } }));
                break;
            default:
                break;
        }

    };

    //InvestorContract
    investorModalOnChange = (e,type="") =>{
        switch (type) {
            case "investorContractType":
                const contractId=e.target.value;
                this.setState(prevState => ({ investorContract: { ...prevState.investorContract, contractId } }));
                break;
            case "investor":
                this.setState(prevState => ({ investorContract: { ...prevState.investorContract, investorId: e.investorId } }));
                break;
            default:
                break;
        }
    };

    submitInvestorContract = () => {
        this.setState({loading:true});
        const investorContract = this.state.investorContract;
        this.InvestorApiClient.SaveContract(investorContract).then(response=>{
            if(CyaHelper.isResponseOK(response)){
                let investorContracts =[...this.state.investorContracts] ;
                investorContracts.push(response);
                this.toggleInvestorModal();
                this.setState({investorContract: new ContractInvestorModel(),investorContracts});
            }

        }).finally(()=>this.setState({loading:false}));
    };

    onTableClick=(type,id)=>{
        if (type === "person") {
            this.PersonApiClient.GetContract(id).then(contract => {
                if ("contractId" in contract){
                    this.setState({personalContract: contract});
                    this.togglePersonModal();
                }
            });
        }

        if (type === "investors") {
            this.InvestorApiClient.GetContract(id).then(contract => {
                if ("contractId" in contract){
                    this.setState({investorContract: contract});
                    this.toggleInvestorModal();
                }
            });
        }
    };

    confirmDelete = (deleteConfirmation)=>{
        if (deleteConfirmation){this.deleteCallback(0,0,true);}
        else {this.setState({deleteModal:false});}
    };

    deleteCallback = (type,id,deleteConfirmation=false) =>{
        //todo: until we fix the deletec contract on webapi
        toast.warning("Contrato No Eliminado");
        return;

        // if (deleteConfirmation){
        //     this.setState({loading:true});
        //     if (this.state.contractToDelete.type==="person"){
        //         this.HiredPersonApiClient.DeleteContract(this.state.contractToDelete.id).then(response=>{
        //             if(CyaHelper.isResponseOK(response)){
        //                 let personalContracts = [...this.state.personalContracts];
        //                 personalContracts = personalContracts.filter(x => x.contractPersonId !== this.state.contractToDelete.id);
        //                 this.setState({personalContract: new ContractPersonModel(),personalContracts});
        //                 this.togglePersonModal();
        //                 toast.success("Contrato Eliminado");
        //             }else {
        //                 this.setState({personalContract: new ContractPersonModel()});
        //                 this.togglePersonModal();
        //                 toast.error("Error eliminando contrato.");
        //             }
        //         }).finally(()=>this.setState({loading:false,deleteModal:false,contractToDelete:{type:"",id:""}}));
        //     }
        //     if (this.state.contractToDelete.type==="investor"){
        //         this.ProjectInvestorApiClient.DeleteContract(this.state.contractToDelete.id).then(response=>{
        //             if(CyaHelper.isResponseOK(response)){
        //                 let investorContracts = [...this.state.investorContracts];
        //                 investorContracts = investorContracts.filter(x => x.contractInvestorId !== this.state.contractToDelete.id);
        //                 this.setState({investorContract: new ContractInvestorModel(),investorContracts});
        //                 this.toggleInvestorModal();
        //                 toast.success("Contrato Eliminado");
        //             }else {
        //                 this.setState({investorContract: new ContractInvestorModel()});
        //                 this.toggleInvestorModal();
        //                 toast.error("Error eliminando contrato.");
        //             }
        //         }).finally(()=>this.setState({loading:false,deleteModal:false,contractToDelete:{type:"",id:""}}));
        //     }
        // }
        // else {
        //     this.setState({deleteModal:true,contractToDelete:{type,id}});
        // }
    };

    onFileChange = (key,content,filename) =>{
        this.setState(prevState => ({[key]: {...prevState[key], name: filename, file: content}}));
    };

    render() {
        const onTableClick = this.onTableClick;
        const personalColumns = [
            {Header: "Id", accessor: "personId", show: false},
            {Header: "Personal", accessor: "personName"},
            {Header: "Tipo", accessor: "contractName", minWidth:60},
            {Header: "Contrato", accessor: "name"},
            {Header: "Acciones", accessor: "contractPersonId", minWidth:40, Cell:(props)=><div style={{textAlign:"center"}}><Button color={"primary"} onClick={()=>onTableClick("person",props.value)} size={"sm"}>Ver</Button></div>},
        ];
        const investorsColumns = [
            {Header: "Id", accessor: "personId", show: false},
            {Header: "Inversionista", accessor: "investorName"},
            {Header: "Tipo", accessor: "contractName", minWidth:80},
            {Header: "Contrato", accessor: "name"},
            {Header: "Acciones", accessor: "contractInvestorId", minWidth:40, Cell:(props)=><div style={{textAlign:"center"}}><Button color={"primary"} onClick={()=>onTableClick("investors",props.value)} size={"sm"}>Ver</Button></div>},
        ];

        return (<div>
            <ModalContractPerson toggleModal={this.togglePersonModal} modal={this.state.personModal}
                                 personal={this.state.personal} data={this.state.personalContract} onChange={this.personModalOnChange}
                                 submit={this.submitPersonContract} personContractTypes={this.state.personContractTypes} deleteCallback={this.deleteCallback}
                                 onFileChange={(content,filename)=>this.onFileChange("personalContract",content,filename)}
            />
            <ModalInvestorPerson toggleModal={this.toggleInvestorModal} modal={this.state.investorModal}
                                 investors={this.state.investors} data={this.state.investorContract} onChange={this.investorModalOnChange}
                                 submit={this.submitInvestorContract} investorContractTypes={this.state.investorContractTypes} deleteCallback={this.deleteCallback}
                                 onFileChange={(content,filename)=>this.onFileChange("investorContract",content,filename)}
            />
            <ConfirmModal modal={this.state.deleteModal} title={"Desea eliminar este contrato?"} confirmation={this.confirmDelete} />

            <Card>
                <CardTitle className="mb-0 p-3 border-bottom bg-light">
                    <i className="fas fa-file mr-2"/>Contratos
                </CardTitle>
                <CardBody>
                    <LoadingPage color={"3e5569"} loading={this.state.loading} type={"spin"}/>

                    <Row>
                        <Col md="6">
                            <h3>Contratos de Personal</h3>
                            <Row>
                                <Col md="6">
                                    <input type="submit" className={('btn btn-primary')} value="Añadir Nuevo"
                                           onClick={this.togglePersonModal}/>
                                </Col>
                            </Row>
                            <hr/>
                            <Row>
                                <Col md={12}>
                                    <SimpleTable loading={this.state.loading} data={this.state.personalContracts} defaultPageSize={10}
                                                 columns={personalColumns}/>
                                </Col>
                            </Row>

                        </Col>
                        <Col md="6">
                            <h3>Contratos de Inversionistas</h3>
                            <Row>
                                <Col md="6">
                                    <input type="submit" className={('btn btn-primary')} value="Añadir Nuevo"
                                           onClick={this.toggleInvestorModal}/>
                                </Col>
                            </Row>
                            <hr/>
                            <Row>
                                <Col md={12}>
                                    <SimpleTable loading={this.state.loading} data={this.state.investorContracts} defaultPageSize={10}
                                                 columns={investorsColumns} />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <hr/>

                </CardBody>
            </Card>
        </div>);
    }
}
