import React from 'react';
import {
    Card,
    CardBody,
    CardTitle,
    Row,
    Col,
    Label,
    Form,
    CustomInput,
    Nav,
    TabPane,
    TabContent,
    CardSubtitle, FormGroup
} from 'reactstrap';
import {InvestorModel} from "../../models/InvestorModel";
import ProjectInvestorApiClient from "../../utils/api-client/ProjectInvestorApiClient";
import FieldValidator from "../../utils/field-validators/FieldValidator";
import ProjectInvestorFieldValidator from "../../utils/field-validators/ProjectInvestorFieldValidator";
import {LoadingPage} from "../../components/custom/views/LoadingPage";
import {InputForm} from "../../components/custom/inputs/InputForm";
import {InputCurrency} from "../../components/custom/inputs/InputCurrency";
import Datetime from "react-datetime";
import {DateFormat} from "../../const/api_consts";
import {InputImage} from "../../components/custom/inputs/InputImage";
import {ImageModel} from "../../models/ImageModel";
import MaskedInput from "react-text-mask";
import {toast} from "react-toastify";
import AuthService from "../../utils/AuthService";
import {CyaContext} from "../../utils/CyaContext";
import CyaHelper from "../../utils/CyaHelper";
import {SimpleNavItem} from "../../components/custom/FunctionalComponents";
import NotaryApiClient from "../../utils/api-client/NotaryApiClient";
import {InputSelect} from "../../components/custom/inputs/InputSelect";
import {
    ContractAAnnexModel,
    ContractInversionModel,
    ContractPowerRepresentationModel,
    ContractWarrantyModel
} from "../../models/InvestorContracts";
import CreatableSelect from "react-select/lib/Creatable";
import InvestorApiClient from "../../utils/api-client/InvestorApiClient";
import moment from "moment";
import Compress from "compress.js";
import md5 from "md5";

export default class CreateProjectInvestor extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            collectionName: '',
            collectionImage: '',
            data: new InvestorModel(),
            initialData: new InvestorModel(),
            contractAAnnex: new ContractAAnnexModel(),
            contractInversion: new ContractInversionModel(),
            contractPowerRepresentation: new ContractPowerRepresentationModel(),
            contractWarranty: new ContractWarrantyModel(),
            loading: true,
            notaryData: [],
            title: "Crear Inversionista",
            modelCreated: "Inversionista Guardado.",
            modelUpdated: "Inversionista Actualizado.",
            modelError: "Error Salvando.",
            activeTab: '1',
            contractsData: [{name: 'Contrato Predeterminado', contractInvestorId: 0, contractId: 0,}],
            contractIdInversion: 3,
            contractIdWarranty: 4,
            contractIdPowerRepresentation: 5,
            existingInvestors: [],
            responseError: null,
        };
        this.Auth = new AuthService();
        this.ProjectInvestorApiClient = new ProjectInvestorApiClient();
        this.InvestorApiClient = new InvestorApiClient();
        this.NotaryApiClient = new NotaryApiClient();
        this.Validator = new FieldValidator(ProjectInvestorFieldValidator);

    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if (!nextState.loading && CyaHelper.ObjectCompare(nextState.data, this.state.data))
            this.Validator.initValidation(nextState.data);

        return true;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!this.state.loading) {
            let isFormDirty = !CyaHelper.ObjectCompare(this.state.initialData, this.state.data);
            this.context.toggleExitConfirmation(isFormDirty);
        }
    }

    componentDidMount() {
            this.setState({loading: true});
            this.NotaryApiClient.GetAll().then(response => {
                const notaryData = response.data.map(x => {
                    x.value = x.notaryId;
                    x.label = x.name;
                    return x;
                });
    
                this.setState({notaryData});
                this.InvestorApiClient.GetAll().then(res => {
                    if (CyaHelper.isResponseOK(res)) {
                        this.setState({
                            existingInvestors: res.data.map(x => {
                                x.label = x.name;
                                x.value = x.investorId;
                                return x;
                            })
                        });
                    }
                });
    
            }).then(() => {
                if (this.props.location.state !== undefined && this.props.location.state?.id) {
                    this.ProjectInvestorApiClient.GetById(this.props.location.state.id).then(response => {
                        this.editPromise(response);
                    }).then(() => {
                        CyaHelper.GetAllRequests([
                            this.ProjectInvestorApiClient.GetContractAAnnex({investorId: this.state.data.investorId}),
                            this.ProjectInvestorApiClient.GetContractInversion({investorId: this.state.data.investorId}),
                            this.ProjectInvestorApiClient.GetContractPowerRepresentation({investorId: this.state.data.investorId}),
                            this.ProjectInvestorApiClient.GetContractWarranty({investorId: this.state.data.investorId}),
                            this.ProjectInvestorApiClient.GetInvestorContracts({investorId: this.state.data.investorId}),
    
                        ]).then(responseArr => {
                            if (CyaHelper.isResponseOK(responseArr[0])) {
                                if (responseArr[0].image === null) responseArr[0].image = new ImageModel();
                                this.setState({contractAAnnex: responseArr[0]});
                            }
                            if (CyaHelper.isResponseOK(responseArr[1])) {
                                if (responseArr[1].image === null) responseArr[1].image = new ImageModel();
                                this.setState({contractInversion: responseArr[1]});
                            }
                            if (CyaHelper.isResponseOK(responseArr[2])) {
                                if (responseArr[2].image === null) responseArr[2].image = new ImageModel();
                                this.setState({contractPowerRepresentation: responseArr[2]});
                            }
                            if (CyaHelper.isResponseOK(responseArr[3])) {
                                if (responseArr[3].image === null) responseArr[3].image = new ImageModel();
                                if (responseArr[3].financialCertificateImage === null) responseArr[3].financialCertificateImage = new ImageModel();
                                this.setState({contractPowerRepresentation: responseArr[3]});
                            }
    
                            if (CyaHelper.isResponseOK(responseArr[4])) {
                                let contractsData = this.state.contractsData
                                    .concat(responseArr[4].data);
                                this.setState({contractsData});
                            } else {
                                toast.warn('Error cargando contratos de Documentos.');
                            }
    
                            
                        }).finally(() =>this.setState({loading: false}) );
                    });
                } else {
                    this.setState({loading: false});
                }
            });
    }

    onInputChange = (event, key = 'data') => {
        let name = event.target.name
        let value = event.target.value
        if (name === 'rnc' && value.length >= 20) return
        let aux = this.state[key];
        aux[event.target.name] = event.target.value;
        this.setState({[key]: aux});
    };

    handleCurrencyChange = (event, maskedvalue, floatvalue, key = 'data') => {
        let aux = this.state[key];
        if (event.target.name === "checkAmount") {
            aux["amount"] = floatvalue;
        }
        aux[event.target.name] = floatvalue;
        this.setState({[key]: aux});
    };

    editPromise = (response) => {
        if (response.image === null)
            response.image = new ImageModel();
        this.setState({
            initialData: JSON.parse(JSON.stringify(response)), //copy data variable
            data: response,
            title: "Editar Inversionista " + response.name
        });
    };

    onDateChange = (moment, dateName) => {
        let aux = this.state.data;
        aux[dateName] = moment.format("YYYY-MM-DD");
        this.setState({data: aux});
    };

    onDateChangeContract = (moment, dateName, key) => {
        let aux = this.state[key];
        aux[dateName] = moment.format("YYYY-MM-DD");
        this.setState({[key]: aux});
    };

    onInputSelectChange = (event, key) => {
        let aux = this.state[key];
        aux[event.target.name] = Number(event.target.value);
        this.setState({[key]: aux});
    };

    onCheckboxChange = (event, key) => {
        let aux = this.state[key];

        aux[event.target.name] = event.target.name === "sent" ? (event.target.checked ? 1 : 0) : event.target.checked;
        this.setState({[key]: aux});
        console.log(this.state[key]);
    };

    toggle = (tab) => {
        if (this.state.activeTab !== tab) {
            if (this.state.initialData.mainInvestorId === 0) {
                toast.warn('Asignar un inversionista existente y guardarlo antes de pasar a esta sección.');
            } else if (this.state.data.investorId === 0) {
                toast.warn('Guardar inversionista antes de pasar a esta sección.');
            } else {
                this.setState({activeTab: tab});
            }
        }
    };

    loadingImageCallback = (loading) => {
        if (this.state.loading !== loading)
            this.setState({loading});
    };

    onSubmitContract = (event, key) => {
        event.preventDefault();
        let data = this.state[key];
        if (data.investorId === 0) {
            data.investorId = this.state.data.investorId;
        }
        this.setState({loading: true});
        switch (key) {
            case 'contractAAnnex':
                this.ProjectInvestorApiClient.SaveContractAAnnex(data).then(response => {
                    this.setState({loading: false});
                    if (response.hasOwnProperty('investorId'))
                        toast.success('Anexo A Guardado');
                    else
                        toast.error('Error al guardar Anexo A');
                });
                break;
            case 'contractInversion':
                this.ProjectInvestorApiClient.SaveContractInversion(data).then(response => {
                    this.setState({loading: false});
                    if (response.hasOwnProperty('investorId'))
                        toast.success('Contrato de Inversión Guardado');
                    else
                        toast.error('Error al guardar Contrato de Inversión');
                });
                break;
            case 'contractPowerRepresentation':
                this.ProjectInvestorApiClient.SaveContractPowerRepresentation(data).then(response => {
                    this.setState({loading: false});
                    if (response.hasOwnProperty('investorId'))
                        toast.success('Contrato Poder de Representación Guardado');
                    else
                        toast.error('Error al guardar Contrato Poder de Representación');
                });
                break;
            case 'contractWarranty':
                this.ProjectInvestorApiClient.SaveContractWarranty(data).then(response => {
                    this.setState({loading: false});
                    if (response.hasOwnProperty('investorId'))
                        toast.success('Contrato de Garantía Guardado');
                    else
                        toast.error('Error al guardar Contrato de Garantía');
                });
                break;
            default:
                this.setState({loading: false});
                toast.warn('No existe este tipo de contrato.');
                break;
        }
    };

    onMainInvestorChange = (e) => {
        this.setState(prevState => ({data: {...prevState.data, mainInvestorId: e.investorId}}));
    };

    onSubmit = (event) => {
        event.preventDefault();
        this.setState({loading: true});
        
        var data = this.state.data;
        if (data.image.fileContent === ""){
            data.imageId = null;
        }
        
        if (this.state.data.investorId > 0) {
            this.ProjectInvestorApiClient.Update(this.state.data.investorId, this.state.data)
                .then(response => {
                    this.editPromise(response);
                    toast.success(this.state.modelUpdated);
                })
                .catch(this.handleResponseError)
                .finally(() => this.setState({loading: false}));
        } else {
            this.ProjectInvestorApiClient.Create(this.state.data)
                .then(response => {
                    toast.success(this.state.modelCreated);
                    this.props.history.push({pathname: '/refresh/investors/new'});
                })
                .catch(this.handleResponseError)
                .finally(() => this.setState({loading: false}));
        }
        window.scrollTo(0, 0);
    };

    dataURLtoFile = (dataurl, filename) => {
        let arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, {type: mime});
    }

    rotatedImage = (base64) => {
        const {[this.state.collectionName]: dataUpdated, collectionName, collectionImage} = this.state
        let fileName = moment().format("YYYY-MM-DD-hh-mm-ss-") + dataUpdated.imageId + '.jpg'
        let imgFile = this.dataURLtoFile(base64, fileName)
        new Compress().compress([imgFile], {
            size: 1,
            quality: .5
        }).then((results) => {
            const compressImg = results[0];
            dataUpdated[collectionImage]["fileContent"] = compressImg.data
            dataUpdated[collectionImage]["hash"] = md5(compressImg.data)
            dataUpdated[collectionImage]["filename"] = fileName
            dataUpdated[collectionImage]["imageId"] = 0
            dataUpdated["imageId"] = 0
            this.setState({[collectionName]: dataUpdated});
        });
    }

    handleResponseError = error => {
        toast.error(this.state.modelError);
        this.setState({responseError: error})
    }

    render() {
        const SubmitContractButton = (props) => {
            return (<input type="submit" disabled={!(this.Auth.GetPathPermission(this.props.location.pathname, true))}
                           className={!(this.Auth.GetPathPermission(this.props.location.pathname, true)) ? 'btn btn-primary disabled' : 'btn btn-primary'}
                           value="Guardar" onClick={e => this.onSubmitContract(e, props.name)}/>);
        };

        return <div>
            <Card>
                <CardTitle className="bg-light border-bottom p-3 mb-0">
                    <i className="fas fa-hand-holding-usd mr-2"/>{this.state.title}
                </CardTitle>
                <CardBody className={'pt-2'}>
                    <LoadingPage color={"3e5569"} loading={this.state.loading} type={"spin"}/>

                    <Nav tabs className={'pt-0 mt-0'}>
                        <SimpleNavItem tabName={'General'} active={this.state.activeTab === '1'} toggle={() => {
                            this.toggle('1')
                        }}/>
                        <SimpleNavItem tabName={'Inversión'} active={this.state.activeTab === '2'} toggle={() => {
                            this.toggle('2')
                        }}/>
                        <SimpleNavItem tabName={'Garantía'} active={this.state.activeTab === '3'} toggle={() => {
                            this.toggle('3')
                        }}/>
                        <SimpleNavItem tabName={'Poder de Representación'} active={this.state.activeTab === '4'}
                                       toggle={() => {
                                           this.toggle('4')
                                       }}/>
                        <SimpleNavItem tabName={'Anexo A'} active={this.state.activeTab === '5'} toggle={() => {
                            this.toggle('5')
                        }}/>
                    </Nav>

                    <TabContent activeTab={this.state.activeTab}>
                        <TabPane tabId="1">
                            <Card>
                                <CardBody>
                                    <CardTitle>Datos del Inversionista</CardTitle>
                                    <CardSubtitle>Datos Generales</CardSubtitle>
                                    <hr/>
                                    <Row>
                                        <Col md="4">
                                            {CyaHelper.ImageExists(this.state.data.image) ?
                                                <InputImage id_label={"imageId"} label={"Imagen:"}
                                                            rotatedImage={(base64, image) => {
                                                                this.setState({collectionName: 'data',collectionImage:'image'},()=>{
                                                                    this.rotatedImage(base64, image);
                                                                })
                                                            }}

                                                            loadingCallback={this.loadingImageCallback}
                                                            image={this.state.data.image}/>
                                                :
                                                <div><InputImage id_label={"imageId"} label={"Imagen:"}
                                                                 rotatedImage={(base64, image) => {
                                                                     this.setState({collectionName: 'data',collectionImage:'image'},()=>{
                                                                         this.rotatedImage(base64, image);
                                                                     })
                                                                 }}
                                                                 loadingCallback={this.loadingImageCallback}
                                                                 image={this.state.data.image}/>
                                                </div>
                                            }
                                        </Col>

                                        <Col md="8">
                                            <Form>
                                                <Row>
                                                    <Col md={12}>
                                                        <FormGroup>
                                                            <Label htmlFor={"name"}>Inversionistas Existentes: </Label>
                                                            <CreatableSelect
                                                                value={this.state.existingInvestors.find(x => x.investorId === this.state.data.mainInvestorId)}
                                                                options={this.state.existingInvestors}
                                                                id={"name"}
                                                                name={"name"}
                                                                placeholder={"Seleccionar Inversionista"}
                                                                onChange={this.onMainInvestorChange}
                                                            />
                                                        </FormGroup>
                                                        {this.Validator.showErrors("mainInvestorId")}
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md="10">
                                                        <InputForm
                                                            id_label={"name"}
                                                            label={"Nombre:"}
                                                            placeholder={""}
                                                            type={"text"}
                                                            value={this.state.data.name}
                                                            onInputChange={this.onInputChange}
                                                        />
                                                        {this.Validator.showErrors("name")}
                                                    </Col>

                                                    <Col md="2">
                                                        <br/><br/>
                                                        <CustomInput
                                                            type="checkbox"
                                                            id="blocked"
                                                            name="blocked"
                                                            label="Bloqueado"
                                                            checked={this.state.data.blocked}
                                                            onChange={this.onCheckboxChange}
                                                        />
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col md="6">
                                                        <Label>{"RNC: "}</Label>
                                                        <MaskedInput className="form-control"
                                                                     showMask={true}
                                                                     mask={[/\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/]}
                                                                     type={"text"}
                                                                     name={"rnc"}
                                                                     id={"rnc"}
                                                                     onChange={this.onInputChange}
                                                                     guide={false}
                                                                     value={this.state.data.rnc}/>
                                                        {this.Validator.showErrors("rnc")}
                                                    </Col>

                                                    <Col md="6">
                                                        <InputForm
                                                            id_label={"checkNumber"}
                                                            label={"No. Cheque:"}
                                                            type={"text"}
                                                            value={this.state.data.checkNumber}
                                                            onInputChange={this.onInputChange}
                                                        />
                                                        {this.Validator.showErrors("checkNumber")}
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col md="4">
                                                        <Label htmlFor={"date"}>Fecha Cheque: </Label>
                                                        <Datetime
                                                            inputProps={{
                                                                readOnly: true,
                                                                style: {backgroundColor: "transparent"}
                                                            }}
                                                            utc={true}
                                                            locale={"es-do"}
                                                            timeFormat={false}
                                                            defaultValue={new Date(this.state.data.checkDate)}
                                                            dateFormat={DateFormat}
                                                            closeOnSelect={true}
                                                            value={new Date(this.state.data.checkDate)}
                                                            onChange={(moment) => this.onDateChange(moment, 'checkDate')}
                                                        />
                                                    </Col>
                                                    <Col md="4">
                                                        <Label htmlFor={"date"}>Fecha para Validación: </Label>
                                                        <Datetime
                                                            inputProps={{
                                                                readOnly: true,
                                                                style: {backgroundColor: "transparent"}
                                                            }}
                                                            utc={true}
                                                            locale={"es-do"}
                                                            timeFormat={false}
                                                            defaultValue={new Date(this.state.data.depositDate)}
                                                            dateFormat={DateFormat}
                                                            closeOnSelect={true}
                                                            value={new Date(this.state.data.depositDate)}
                                                            onChange={(moment) => this.onDateChange(moment, 'depositDate')}
                                                        />
                                                    </Col>
                                                    <Col md="4">
                                                        <Label htmlFor={"date"}>Fin Año Fiscal: </Label>
                                                        <Datetime
                                                            inputProps={{
                                                                readOnly: true,
                                                                style: {backgroundColor: "transparent"}
                                                            }}
                                                            utc={true}
                                                            locale={"es-do"}
                                                            timeFormat={false}
                                                            defaultValue={new Date(this.state.data.fiscalYearEnd)}
                                                            dateFormat={"YYYY"}
                                                            closeOnSelect={true}
                                                            // value={new Date(this.state.data.fiscalYearEnd)}
                                                            onChange={(moment) => this.onDateChange(moment, 'fiscalYearEnd')}
                                                        />
                                                    </Col>
                                                </Row>
                                                <br/>

                                                <Row>
                                                    <Col md="6">
                                                        <InputCurrency
                                                            id_label={"checkAmount"}
                                                            label={"Importe:"}
                                                            placeholder={""}
                                                            value={this.state.data.checkAmount}
                                                            onInputChange={this.handleCurrencyChange}
                                                        />
                                                        {this.Validator.showErrors("checkAmount")}
                                                    </Col>

                                                    <Col md="6">
                                                        <InputCurrency
                                                            id_label={"amount"}
                                                            label={"Importe Utilizado:"}
                                                            placeholder={""}
                                                            value={this.state.data.amount}
                                                            onInputChange={this.handleCurrencyChange}
                                                        />
                                                        {this.Validator.showErrors("amount")}
                                                    </Col>
                                                </Row>


                                                <hr/>
                                                <Row>
                                                    <Col md={2}>
                                                        <input type="submit"
                                                               disabled={!(this.Validator.validForm() && this.Auth.GetPathPermission(this.props.location.pathname, true))}
                                                               className={!(this.Validator.validForm() && this.Auth.GetPathPermission(this.props.location.pathname, true)) ? 'btn btn-primary disabled' : 'btn btn-primary'}
                                                               value="Guardar"
                                                               onClick={this.onSubmit}/>
                                                    </Col>
                                                    <Col >
                                                        {this.state.responseError && 
                                                                <span className="error">
                                                                    * {this.state.responseError.data}<br/>
                                                                </span>
                                                        }
                                                    </Col>
                                                </Row>
                                            </Form>
                                        </Col>

                                    </Row>
                                </CardBody>
                            </Card>
                        </TabPane>
                    </TabContent>

                    <TabContent activeTab={this.state.activeTab}>
                        <TabPane tabId="2">
                            <Card>
                                <CardBody>

                                    <CardTitle>Contrato de Inversión</CardTitle>
                                    <CardSubtitle>Datos del contrato</CardSubtitle>

                                    <hr/>

                                    <Row>
                                        <Col md={6}>
                                            <Label htmlFor={"date"}>Fecha Contrato: </Label>
                                            <Datetime
                                                inputProps={{readOnly: true, style: {backgroundColor: "transparent"}}}
                                                utc={true}
                                                locale={"es-do"}
                                                timeFormat={false}
                                                defaultValue={new Date(this.state.contractInversion.date)}
                                                closeOnSelect={true}
                                                value={new Date(this.state.contractInversion.date)}
                                                onChange={(moment) => this.onDateChangeContract(moment, 'date', 'contractInversion')}
                                            />
                                        </Col>
                                        <Col md={6}>
                                            <InputSelect id_label={"contractInvestorId"}
                                                         data={this.state.contractsData.filter(x => x.contractId === 0 || x.contractId === this.state.contractIdInversion)}
                                                         label={"Formato Contrato:"}
                                                         dataDescription={["name"]}
                                                         dataValue={"contractInvestorId"}
                                                         selectedValue={Number(this.state.contractInversion.contractInvestorId)}
                                                         onInputChange={e => this.onInputSelectChange(e, 'contractInversion')}
                                            />
                                        </Col>

                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <InputSelect id_label={"notaryId"}
                                                         data={this.state.notaryData}
                                                         label={"Notario:"}
                                                         dataDescription={["name"]}
                                                         dataValue={"notaryId"}
                                                         selectedValue={Number(this.state.contractInversion.notaryId)}
                                                         onInputChange={(event) => this.onInputSelectChange(event, 'contractInversion')}
                                            />
                                        </Col>
                                        <Col md={6}>
                                            {CyaHelper.ImageExists(this.state.contractInversion.image) ?
                                                <InputImage id_label={"imageId"} label={"Copia de Contrato:"}
                                                            rotatedImage={(base64, image) => {
                                                                this.setState({collectionName: 'contractInversion',collectionImage:'image'},()=>{
                                                                    this.rotatedImage(base64, image);
                                                                })
                                                            }}
                                                            width={"fit-content"}
                                                            image={this.state.contractInversion.image}
                                                            loadingCallback={this.loadingImageCallback}
                                                            showViewButton={true} showImageMagnify={false}
                                                            acceptPDF={true}/>
                                                :
                                                <div>
                                                    <InputImage id_label={"imageId"} label={"Copia de Contrato:"}
                                                                rotatedImage={(base64, image) => {
                                                                    this.setState({collectionName: 'contractInversion',collectionImage:'image'},()=>{
                                                                        this.rotatedImage(base64, image);
                                                                    })
                                                                }}
                                                                image={this.state.contractInversion.image}
                                                                loadingCallback={this.loadingImageCallback}
                                                                showViewButton={true} showImageMagnify={false}
                                                                acceptPDF={true}/>
                                                </div>
                                            }
                                        </Col>
                                    </Row>
                                    <hr/>
                                    <Row>
                                        <Col md={3}>
                                            <SubmitContractButton name={'contractInversion'}/>
                                        </Col>
                                    </Row>

                                </CardBody>
                            </Card>
                        </TabPane>
                    </TabContent>

                    <TabContent activeTab={this.state.activeTab}>
                        <TabPane tabId="3">
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col md={12}>

                                            <CardTitle>Contrato de Garantía</CardTitle>
                                            <CardSubtitle>Datos del contrato</CardSubtitle>

                                            <hr/>

                                            <Row>
                                                <Col md={6}>
                                                    <Label htmlFor={"date"}>Fecha Contrato: </Label>
                                                    <Datetime
                                                        inputProps={{
                                                            readOnly: true,
                                                            style: {backgroundColor: "transparent"}
                                                        }}
                                                        utc={true}
                                                        locale={"es-do"}
                                                        timeFormat={false}
                                                        defaultValue={new Date(this.state.contractWarranty.date)}
                                                        closeOnSelect={true}
                                                        value={new Date(this.state.contractWarranty.date)}
                                                        onChange={(moment) => this.onDateChangeContract(moment, 'date', 'contractWarranty')}
                                                    />
                                                </Col>
                                                <Col md={6}>
                                                    <InputSelect id_label={"contractInvestorId"}
                                                                 data={this.state.contractsData.filter(x => x.contractId === 0 || x.contractId === this.state.contractIdWarranty)}
                                                                 label={"Formato Contrato:"}
                                                                 dataDescription={["name"]}
                                                                 dataValue={"contractInvestorId"}
                                                                 selectedValue={Number(this.state.contractWarranty.contractInvestorId)}
                                                                 onInputChange={e => this.onInputSelectChange(e, 'contractWarranty')}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={6}>
                                                    <InputSelect id_label={"notaryId"}
                                                                 data={this.state.notaryData}
                                                                 label={"Notario:"}
                                                                 dataDescription={["name"]}
                                                                 dataValue={"notaryId"}
                                                                 selectedValue={Number(this.state.contractWarranty.notaryId)}
                                                                 onInputChange={(event) => this.onInputSelectChange(event, 'contractWarranty')}
                                                    />
                                                </Col>
                                                <Col md={6}>
                                                    {CyaHelper.ImageExists(this.state.contractWarranty.image) ?
                                                        <InputImage id_label={"imageId"} label={"Copia de Contrato:"}
                                                                    rotatedImage={(base64, image) => {
                                                                        this.setState({collectionName: 'contractWarranty', collectionImage:'image'},()=>{
                                                                            this.rotatedImage(base64, image);
                                                                        })
                                                                    }}
                                                                    width={"fit-content"}
                                                                    image={this.state.contractWarranty.image}
                                                                    showViewButton={true}
                                                                    loadingCallback={this.loadingImageCallback}
                                                                    showImageMagnify={false} acceptPDF={true}/>
                                                        :
                                                        <div><InputImage id_label={"imageId"}
                                                                         rotatedImage={(base64, image) => {
                                                                             this.setState({collectionName: 'contractWarranty',collectionImage:'image'},()=>{
                                                                                 this.rotatedImage(base64, image);
                                                                             })
                                                                         }}
                                                                         label={"Copia de Contrato:"}
                                                                         image={this.state.contractWarranty.image}
                                                                         showViewButton={true}
                                                                         loadingCallback={this.loadingImageCallback}
                                                                         showImageMagnify={false} acceptPDF={true}/>
                                                        </div>
                                                    }

                                                </Col>
                                            </Row>

                                            <CardSubtitle className={'pt-2'}>Certificado Financiero</CardSubtitle>
                                            <hr/>
                                            <Row>
                                                <Col md={6}>
                                                    <InputForm
                                                        id_label={"financialCertificateNumber"}
                                                        label={"Número:"}
                                                        placeholder={""}
                                                        type={"text"}
                                                        value={this.state.contractWarranty.financialCertificateNumber}
                                                        onInputChange={e => this.onInputChange(e, 'contractWarranty')}
                                                    />
                                                </Col>

                                                <Col md={6}>
                                                    <InputCurrency
                                                        id_label={"financialCertificateAmount"}
                                                        label={"Monto:"}
                                                        placeholder={""}
                                                        value={this.state.contractWarranty.financialCertificateAmount}
                                                        onInputChange={(e, mv, ft) => this.handleCurrencyChange(e, mv, ft, 'contractWarranty')}
                                                    />

                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={12}>
                                                    {this.state.contractWarranty.financialCertificateImage.hash.length > 0 ?
                                                        <InputImage id_label={"imageId"} label={"Copia de Contrato:"}
                                                                    rotatedImage={(base64, image) => {
                                                                        this.setState({collectionName: 'contractWarranty', collectionImage:'financialCertificateImage'},()=>{
                                                                            this.rotatedImage(base64, image);
                                                                        })
                                                                    }}
                                                                    width={"fit-content"}
                                                                    image={this.state.contractWarranty.financialCertificateImage}
                                                                    showViewButton={true}
                                                                    loadingCallback={this.loadingImageCallback}
                                                                    showImageMagnify={false} acceptPDF={true}/>
                                                        :
                                                        <div><InputImage id_label={"imageId"}
                                                                         rotatedImage={(base64, image) => {
                                                                             this.setState({collectionName: 'contractWarranty', collectionImage:'financialCertificateImage'},()=>{
                                                                                 this.rotatedImage(base64, image);
                                                                             })
                                                                         }}
                                                                         label={"Copia de Contrato:"}
                                                                         image={this.state.contractWarranty.financialCertificateImage}
                                                                         showViewButton={true}
                                                                         loadingCallback={this.loadingImageCallback}
                                                                         showImageMagnify={false} acceptPDF={true}/>
                                                        </div>
                                                    }
                                                </Col>
                                            </Row>

                                            <hr/>
                                            <Row>
                                                <Col md={3}>
                                                    <SubmitContractButton name={'contractWarranty'}/>
                                                </Col>
                                            </Row>

                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </TabPane>
                    </TabContent>

                    <TabContent activeTab={this.state.activeTab}>
                        <TabPane tabId="4">
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col md={12}>
                                            <CardTitle>Poder de Representación</CardTitle>
                                            <CardSubtitle>Datos del contrato</CardSubtitle>

                                            <hr/>

                                            <Row>
                                                <Col md={6}>
                                                    <Label htmlFor={"date"}>Fecha Contrato: </Label>
                                                    <Datetime
                                                        inputProps={{
                                                            readOnly: true,
                                                            style: {backgroundColor: "transparent"}
                                                        }}
                                                        utc={true}
                                                        locale={"es-do"}
                                                        timeFormat={false}
                                                        defaultValue={new Date(this.state.contractPowerRepresentation.date)}
                                                        closeOnSelect={true}
                                                        value={new Date(this.state.contractPowerRepresentation.date)}
                                                        onChange={(moment) => this.onDateChangeContract(moment, 'date', 'contractPowerRepresentation')}
                                                    />
                                                </Col>
                                                <Col md={6}>
                                                    <InputSelect id_label={"contractInvestorId"}
                                                                 data={this.state.contractsData.filter(x => x.contractId === 0 || x.contractId === this.state.contractIdPowerRepresentation)}
                                                                 label={"Formato Contrato:"}
                                                                 dataDescription={["name"]}
                                                                 dataValue={"contractInvestorId"}
                                                                 selectedValue={Number(this.state.contractPowerRepresentation.contractInvestorId)}
                                                                 onInputChange={e => this.onInputSelectChange(e, 'contractPowerRepresentation')}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={6}>
                                                    <InputSelect id_label={"notaryId"}
                                                                 data={this.state.notaryData}
                                                                 label={"Notario:"}
                                                                 dataDescription={["name"]}
                                                                 dataValue={"notaryId"}
                                                                 selectedValue={Number(this.state.contractPowerRepresentation.notaryId)}
                                                                 onInputChange={(event) => this.onInputSelectChange(event, 'contractPowerRepresentation')}
                                                    />
                                                </Col>

                                                <Col md={6}>
                                                    {CyaHelper.ImageExists(this.state.contractPowerRepresentation.image) ?
                                                        <InputImage id_label={"imageId"} label={"Copia de Contrato:"}
                                                                    rotatedImage={(base64, image) => {
                                                                        this.setState({collectionName: 'contractPowerRepresentation',collectionImage:'image'},()=>{
                                                                            this.rotatedImage(base64, image);
                                                                        })
                                                                    }}
                                                                    width={"fit-content"}
                                                                    image={this.state.contractPowerRepresentation.image}
                                                                    showViewButton={true}
                                                                    loadingCallback={this.loadingImageCallback}
                                                                    showImageMagnify={false} acceptPDF={true}/>
                                                        :
                                                        <div><InputImage id_label={"imageId"}
                                                                         rotatedImage={(base64, image) => {
                                                                             this.setState({collectionName: 'contractPowerRepresentation',collectionImage:'image'},()=>{
                                                                                 this.rotatedImage(base64, image);
                                                                             })
                                                                         }}
                                                                         label={"Copia de Contrato:"}
                                                                         image={this.state.contractPowerRepresentation.image}
                                                                         showViewButton={true}
                                                                         loadingCallback={this.loadingImageCallback}
                                                                         showImageMagnify={false} acceptPDF={true}/>
                                                        </div>
                                                    }

                                                </Col>
                                            </Row>

                                            <hr/>
                                            <Row>
                                                <Col md={3}>
                                                    <SubmitContractButton name={'contractPowerRepresentation'}/>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </TabPane>
                    </TabContent>

                    <TabContent activeTab={this.state.activeTab}>
                        <TabPane tabId="5">
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col md={12}>
                                            <CardTitle>Anexo A</CardTitle>
                                            <CardSubtitle>Datos del contrato</CardSubtitle>
                                            <hr/>
                                            <Row>

                                                <Col md={8}>
                                                    {CyaHelper.ImageExists(this.state.contractAAnnex.image) ?
                                                        // {this.state.contractAAnnex.image.hash.length > 0 ?
                                                        <InputImage id_label={"imageId"} label={"Copia de Contrato:"}
                                                                    rotatedImage={(base64, image) => {
                                                                        this.setState({collectionName: 'contractAAnnex',collectionImage:'image'},()=>{
                                                                            this.rotatedImage(base64, image);
                                                                        })
                                                                    }}
                                                                    width={"fit-content"}
                                                                    image={this.state.contractAAnnex.image}
                                                                    showViewButton={true}
                                                                    loadingCallback={this.loadingImageCallback}
                                                                    showImageMagnify={false} acceptPDF={true}/>
                                                        :
                                                        <div><InputImage id_label={"imageId"}
                                                                         rotatedImage={(base64, image) => {
                                                                             this.setState({collectionName: 'contractAAnnex',collectionImage:'image'},()=>{
                                                                                 this.rotatedImage(base64, image);
                                                                             })
                                                                         }}
                                                                         label={"Copia de Contrato:"}
                                                                         image={this.state.contractAAnnex.image}
                                                                         showViewButton={true}
                                                                         loadingCallback={this.loadingImageCallback}
                                                                         showImageMagnify={false} acceptPDF={true}/>
                                                        </div>
                                                    }

                                                </Col>
                                                <Col md={2}>
                                                    <br/><br/>
                                                    <CustomInput
                                                        type="checkbox"
                                                        id="sent"
                                                        name="sent"
                                                        label="Enviado"
                                                        checked={this.state.contractAAnnex.sent}
                                                        onChange={e => this.onCheckboxChange(e, 'contractAAnnex')}
                                                    />
                                                </Col>
                                            </Row>

                                            <hr/>
                                            <Row>
                                                <Col md={3}>
                                                    <SubmitContractButton name={'contractAAnnex'}/>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </TabPane>
                    </TabContent>

                </CardBody>
            </Card>
        </div>;
    }
}

CreateProjectInvestor.contextType = CyaContext;
