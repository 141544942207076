import CyaApiClient from "./CyaApiClient";

export default class ItemApiClient extends CyaApiClient {

    constructor(){
        super("Item");
        this.GetListForAnnexes = this.GetListForAnnexes.bind(this);
        this.GetListForItems = this.GetListForItems.bind(this);
    }

    GetListForAnnexes() {
        return this.MakeCall('get', this.ControllerName + '/listForAnnexes');
    }

    GetListForItems() {
        return this.MakeCall('get', this.ControllerName + '/listForItems');
    }

    SaveBudget(objects) {
        return this.MakeCall('post', this.ControllerName + '/saveBudget',{data: objects});
    }
}