import React from 'react';
import {
    FormGroup,
    Label,
    Input, FormFeedback,
} from 'reactstrap';
import PropTypes from 'prop-types'

export class InputForm extends React.Component {

    render() {

        return <FormGroup>
            <Label htmlFor={this.props.id_label}>{this.props.label}</Label>
            <Input type={this.props.type} readOnly={this.props.readOnly} invalid={this.props.invalid} onBlur={this.props.onBlur} name={this.props.id_label} id={this.props.id_label} onChange={this.props.onInputChange} placeholder={this.props.placeholder} value={this.props.value} />
            {this.props.invalid?
                <FormFeedback>{this.props.invalidMessage}</FormFeedback>
                : ''}
        </FormGroup>
    }
}

InputForm.propTypes = {
    label: PropTypes.string,
    id_label: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    placeholder: PropTypes.string,
    type: PropTypes.string,
    onInputChange: PropTypes.func,
    onBlur: PropTypes.func,
    invalid: PropTypes.bool,
    invalidMessage: PropTypes.string,
    readOnly: PropTypes.bool
};
InputForm.defaultProps = {
    invalid: false,
    readOnly: false,
    invalidMessage: '',
};
