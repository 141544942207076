import React from 'react';
import {
    FormGroup,
    Label,
    Input,
    InputGroup
} from 'reactstrap';
import PropTypes from 'prop-types'
import MaskedInput from 'react-text-mask'

export class InputSelectForm extends React.Component {

    render() {
        let mask = this.props.mask.length > 0 ? this.props.mask : false;
        return <FormGroup>
            <Label>{this.props.label}</Label>
            <InputGroup className={"form-inline"}>
                <Input type="select"
                       name={this.props.idSelect}
                       id={this.props.idSelect}
                       onChange={this.props.onInputChange}
                       value={this.props.selectedValue}
                       style={{height:'38px'}}
                >
                    {this.props.data.map((prop, key) => {
                        return <option key={key} value={prop[this.props.dataValue]}> {prop[this.props.dataDescription]} </option>
                    })}
                </Input>
                <MaskedInput className="form-control"
                             showMask={true}
                             mask={mask}
                             style={{marginLeft: '10px', height:'38px'}}
                             type={this.props.formType}
                             name={this.props.idForm}
                             id={this.props.idForm}
                             onChange={this.props.onInputChange}
                             onBlur={this.props.onBlur}
                             guide={false}
                             placeholder={this.props.placeholder}
                             value={this.props.formValue} />
            </InputGroup>
        </FormGroup>
    }
}

InputSelectForm.propTypes = {
    label: PropTypes.string,
    idSelect: PropTypes.string,
    idForm: PropTypes.string,
    formValue: PropTypes.string,
    data: PropTypes.array,
    dataValue: PropTypes.string,
    dataDescription: PropTypes.string,
    formType: PropTypes.string,
    placeholder: PropTypes.string,
    onInputChange: PropTypes.func,
    selectedValue: PropTypes.number,
    mask: PropTypes.array,
    onBlur: PropTypes.func
};
