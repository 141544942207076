import React, { useEffect, useState } from "react";
import {
   DropdownItem,
   DropdownMenu,
   DropdownToggle,
   Nav,
   Navbar,
   NavbarBrand,
   UncontrolledDropdown,
} from "reactstrap";
import logolighticon from "../../../assets/images/logo-light-icon.png";
import logolighttext from "../../../assets/images/logo-light-text.png";
import profilephoto from "../../../assets/images/users/1.jpg";
import AuthService from "../../../utils/AuthService";

export const Header = () => {
   const [profile, setProfile] = useState({ name: "", email: "" });
   const authService = new AuthService();

   useEffect(() => {
      const token = authService.GetProfile();
      if (token) {
         const { given_name: name, email } = token;
         setProfile({ name, email });
      }
   }, [authService]);

   return (
      <Navbar className={"top-navbar navbar-light bg-primary p-1"} expand="md">
         <div className="navbar-header" id="logobg" style={{ width: "auto" }}>
            <NavbarBrand href={"#"}>
               <b className="logo-icon">
                  <img
                     src={logolighticon}
                     alt="homepage"
                     className="light-logo"
                  />
               </b>
               <span className="logo-text">
                  <img
                     src={logolighttext}
                     className="light-logo"
                     alt="homepage"
                  />
               </span>
            </NavbarBrand>
         </div>

         <Nav className="ml-auto float-right" navbar>
            {/*--------------------------------------------------------------------------------*/}
            {/* Start Profile Dropdown                                                         */}
            {/*--------------------------------------------------------------------------------*/}
            <UncontrolledDropdown nav inNavbar>
               <DropdownToggle nav caret className="pro-pic">
                  <img
                     src={profilephoto}
                     alt="user"
                     className="rounded-circle"
                     width="31"
                  />
               </DropdownToggle>
               <DropdownMenu right className="user-dd">
                  <span className="with-arrow">
                     <span className="bg-primary" />
                  </span>
                  <div className="d-flex no-block align-items-center p-3 bg-primary text-white mb-2">
                     <div className="">
                        <img
                           src={profilephoto}
                           alt="user"
                           className="rounded-circle"
                           width="60"
                        />
                     </div>
                     <div className="ml-2">
                        <h4 className="mb-0">{profile.name}</h4>
                        <p className=" mb-0">{profile.email}</p>
                     </div>
                  </div>

                  <DropdownItem href="#" onClick={authService.Logout}>
                     <i className="fa fa-power-off mr-1 ml-1" /> Salir
                  </DropdownItem>
               </DropdownMenu>
            </UncontrolledDropdown>
            {/*--------------------------------------------------------------------------------*/}
            {/* End Profile Dropdown                                                           */}
            {/*--------------------------------------------------------------------------------*/}
         </Nav>
      </Navbar>
   );
};
