import React from 'react';
import {Row, Col} from 'reactstrap';
import DashboardApiClient from "../../utils/api-client/DashboardApiClient";
import PieChart from "../../components/custom/charts/PieChart";
import {ProjectSummary} from "../../components/custom/charts/ProjectSummary";
import {ProjectSummaryModel} from "../../models/ProjectSummaryModel";
import {LoadingPage} from "../../components/custom/views/LoadingPage";
import CategoryExecutionTable from "../../components/custom/tables/CategoryExecutionTable";
import AnnexStageDistribution from "../../components/custom/charts/AnnexStageDistribution";

class Dashboard extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            loading: true,
            stages: [],
            annexStage: [],
            investorTable: [],
            itemTable: [],
            summary: new ProjectSummaryModel()
        };
        this.DashboardApiClient = new DashboardApiClient();
    }

    componentDidMount(){
        this.DashboardApiClient.GetStagesCompletion().then(
            response => this.setState({
                stages: response.data.map(x=>{
                    return [x.description, x.executed];
                })
            })
        );
        /*this.DashboardApiClient.GetAnnexIsrTypes().then(
            response => this.setState({
                annexIsrType: response.data
            })
        );*/
        this.DashboardApiClient.GetAnnexStage().then(
            response => this.setState({
                annexStage: response
            })
        );
        this.DashboardApiClient.GetInvestorTable().then(
            response => this.setState({
                investorTable: response.data
            })
        );
        this.DashboardApiClient.GetItemTable().then(
            response => this.setState({
                itemTable: response.data
            })
        );
        this.DashboardApiClient.GetProjectSummary().then(
            response => this.setState({
                summary: response,
                loading: false
            })
        );
    }

    render() {
        return (
            <div>
                <LoadingPage color={"3e5569"} loading={this.state.loading} type={"spin"}/>
                <Row>
                    <Col md="12">
                        <ProjectSummary data={this.state.summary} />
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <AnnexStageDistribution data={this.state.annexStage} />
                        {/*<AnnexIsrTypeDistribution data={this.state.annexIsrType} />*/}
                    </Col>
                    <Col md="6">
                        <PieChart data={this.state.stages} height={"339px"}
                                  title={"Distribución por etapas"}
                                  subtitle={"Porcentaje de ejecución de gastos por etapas"}/>
                    </Col>
                </Row>
                <Row>
                    <Col md="12">
                        <CategoryExecutionTable data={this.state.investorTable}
                                                title={"Inversionistas"}
                                                subtitle={"Importes y porcentaje de ejecución por inversionistas"}/>
                    </Col>
                </Row>
                <Row>
                    <Col md="12">
                        <CategoryExecutionTable data={this.state.itemTable}
                                                title={"Partidas"}
                                                subtitle={"Montos y porcentaje de ejecución por partidas"}/>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default Dashboard;
