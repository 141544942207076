export class AnnexCreditNoteModel {
    annexCreditNoteId = 0;
    annexId = 0;
    ncf = "";
    date = new Date();
    subtotal = 0;
    itbis = 0;
    itbispercentage = 18;
    itbisretention = 0;
    itbisretentionPercentage = 0;
    isrretention = 0;
    isrretentionPercentage = 0;
    totalExpense = 0;
    totalDue = 0;
    comment = "";
    annexCreditNoteImage = [];
}