const InvestorFieldValidator = {
    name: {
        secondValue: null,
        rules: [
            {
                test: (value) => value.length > 0,
                message: 'Campo en blanco'
            },
            {
               test: (value) =>value.length <= 150,
               message: 'Cantidad máxima de carácteres excedidos'
            }
        ],
        errors: [],
        valid: false,
        state: ''
    },
    address: {
        secondValue: null,
        rules: [
            {
                test: (value) =>value.length > 0,
                message: 'Campo en blanco'
            },
            {
               test: (value) =>value.length <= 250,
               message: 'Cantidad máxima de carácteres excedidos '
            }
        ],
        errors: [],
        valid: false,
        state: ''
    },
    rnc: {
        secondValue: 'investorDocument',
        rules: [
            {
                test: (value) =>value.length > 0,
                message: 'Campo en blanco'
            },
            {
                test: (value) => value.length === 12,
                message: 'Faltan caracteres'
            }
        ],
        errors: [],
        valid: false,
        state: ''
    },
};

export default InvestorFieldValidator ;
