export const GET_DASHBOARD_DATA_REQUEST = "GET_DASHBOARD_DATA_REQUEST";
export const GET_DASHBOARD_DATA_SUCCESS = "GET_DASHBOARD_DATA_SUCCESS";
export const GET_DASHBOARD_DATA_ERROR = "GET_DASHBOARD_DATA_ERROR";

export function dashboardRequest() {
    return {
        type: GET_DASHBOARD_DATA_REQUEST
    }
}

export function dashboardSuccess(payload) {
    return {
        type: GET_DASHBOARD_DATA_SUCCESS,
        payload
    }
}

export function dashboardError(error) {
    return {
        type: GET_DASHBOARD_DATA_ERROR,
        error
    }
}

export function getDashboard() {
    return async function (dispatch) {
        dispatch(dashboardRequest());
        try {
            const payload = []
            dispatch(dashboardSuccess(payload));
        } catch (error) {
            dispatch(dashboardError(error.message));
        }
    }
}
