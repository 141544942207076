import React from "react";
import "react-table/react-table.css";
import {
   Card,
   CardBody,
   CardTitle,
   Row,
   Col,
   InputGroup,
   Input,
} from "reactstrap";
import readXlsxFile from "read-excel-file";
import { SimpleTable } from "../../components/custom/tables/SimpleTable";
import CyaHelper from "../../utils/CyaHelper";
import ItemApiClient from "../../utils/api-client/ItemApiClient";
import { SuccessResponse } from "../../const/api_consts";
import { toast } from "react-toastify";

export default class Budget extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         data: [],
         sendData: [],
         loading: false,
         fileName: "Importar Excel",
         errorMsg: "",
      };
      this.fileChange = this.fileChange.bind(this);
      this.fixArray = this.fixArray.bind(this);
      this.saveBudget = this.saveBudget.bind(this);
      this.ItemApiClient = new ItemApiClient();
   }

   fileChange(event) {
      let file = event.target.files[0];
      if (file !== undefined) {
         this.setState({ fileName: file.name, loading: true, errorMsg: [] });
         readXlsxFile(event.target.files[0]).then((rows) => {
            let data = [];
            rows.forEach(function (obj, index) {
               if (index > 0) {
                  data.push({
                     indicator: Number(String(obj[0]).trim()),
                     code: String(obj[1]).trim(),
                     name: String(obj[2]).trim(),
                     subtotal: obj[3],
                     itbis: obj[4],
                     total: obj[5],
                     parentCode: null,
                     isGrandson: obj[0] === 3,
                  });
               }
            });

            this.fixArray(data);
            this.setState({ loading: false });
         });
      }
   }

   fixArray(data) {
      let newArray = [];
      let fatherIndicator = {};
      let parent = {};
      let grandSon = {};
      let lastOneIndicator = 0;
      let errorMsg = [];
      let rowT = 0;
      for (let i = 0; i < data.length; i++) {
         rowT = i + 2;
         //Comprobacion de celdas vacias
         if (
            !data[i]["indicator"] ||
            !data[i]["name"] ||
            !data[i]["code"] ||
            !(data[i]["subtotal"] || data[i]["subtotal"] === 0) ||
            !(data[i]["itbis"] || data[i]["itbis"] === 0) ||
            !(data[i]["total"] || data[i]["subtotal"] === 0)
         ) {
            if (data[i]["indicator"] !== 4 || data[i]["name"] === null) {
               errorMsg.push("Celda vacia en la fila: " + rowT);
            }
         }

         if (data[i]["indicator"] === 1) {
            if (lastOneIndicator !== 1) {
               data[i]["parentCode"] = "";
               fatherIndicator = data[i];
            } else {
               errorMsg.push(
                  "Error en el orden de los indicadores. Existe dos filas con indicadores 1. Fila: " +
                     rowT
               );
            }
         }
         if (data[i]["indicator"] === 2) {
            if (
               fatherIndicator.indicator === 1 &&
               (lastOneIndicator === 1 ||
                  lastOneIndicator === 3 ||
                  lastOneIndicator === 4)
            ) {
               data[i]["parentCode"] = fatherIndicator.code;
               parent = data[i];
            } else {
               errorMsg.push(
                  "Error en el orden de los indicadores. El anterior no puede ser indicador 2. Fila: " +
                     rowT
               );
            }
         }
         if (data[i]["indicator"] === 3) {
            if (
               parent.indicator === 2 &&
               (lastOneIndicator === 2 ||
                  lastOneIndicator === 3 ||
                  lastOneIndicator === 4)
            ) {
               data[i]["parentCode"] = parent.code;
               grandSon = data[i];
            } else {
               errorMsg.push(
                  "Error en el orden de los indicadores. El anterior no puede ser indicador 1. Fila: " +
                     rowT
               );
            }
         }
         if (data[i]["indicator"] === 4) {
            if (lastOneIndicator === 3 || lastOneIndicator === 4) {
               if (grandSon["itemDetail"] === undefined)
                  grandSon["itemDetail"] = [];
               grandSon["itemDetail"].push({ description: data[i]["name"] });
            } else {
               errorMsg.push(
                  "Error en el orden de los indicadores. El anterior debe ser indicador 3 o 4. Fila: " +
                     rowT
               );
            }
         }
         if (data[i]["indicator"] !== 4) {
            //Comprobacion subtotal+itbis = total
            let total = CyaHelper.ToFinancial(data[i]["total"]);
            let subtotal = CyaHelper.ToFinancial(
               data[i]["subtotal"] + data[i]["itbis"]
            );
            if (total !== subtotal) {
               let diferencia = Math.abs(total - subtotal);
               errorMsg.push(
                  "Error en la suma del Subtotal e ITBIS contra el Total. Diferencia de " +
                     diferencia +
                     ". Fila: " +
                     rowT
               );
            }
         }

         lastOneIndicator = data[i]["indicator"];

         if (data[i]["indicator"] !== 4) newArray.push(data[i]);
      }

      //Comprobacion de sumatorias por padres, hijos, nietos.
      for (let i = 0; i < newArray.length; i++) {
         if (newArray[i].indicator === 1) {
            let sonCodes = newArray.filter(
               ({ parentCode }) => parentCode === newArray[i].code
            );
            let sumSons = sonCodes.reduce(function (prev, cur) {
               return prev + cur.total;
            }, 0);
            sumSons = CyaHelper.ToFinancial(sumSons);
            const grandMotherTotal = CyaHelper.ToFinancial(newArray[i].total);
            if (sumSons !== grandMotherTotal) {
               errorMsg.push(
                  "La sumatoria de las partidas hijas no es igual al total de la partida abuela. Partida: " +
                     newArray[i].name
               );
            }

            for (let j = 0; j < sonCodes.length; j++) {
               let sumGrandson = newArray
                  .filter(({ parentCode }) => parentCode === sonCodes[j].code)
                  .reduce(function (prev, cur) {
                     return prev + cur.total;
                  }, 0);
               sumGrandson = CyaHelper.ToFinancial(sumGrandson);
               const motherTotal = CyaHelper.ToFinancial(sonCodes[j].total);

               if (sumGrandson !== motherTotal) {
                  errorMsg.push(
                     "La sumatoria de las partidas nietas no es igual al total de la partida madre. Partida: " +
                        sonCodes[j].name
                  );
               }
            }
         }
      }

      if (errorMsg.length === 0)
         this.setState({ data: newArray, sendData: newArray, errorMsg: [] });
      else this.setState({ data: [], errorMsg: errorMsg });
   }

   saveBudget() {
      // console.log(this.state.sendData);
      this.ItemApiClient.SaveBudget(this.state.sendData).then((response) => {
         if (response !== SuccessResponse) {
            toast.error("Error importando presupuesto.");
         } else {
            this.props.history.push({ pathname: "/refresh/items/" });
            toast.success("Presupuesto importado correctamente.");
         }
      });
   }

   render() {
      let columns = [
         {
            Header: "Indicador",
            accessor: "indicator",
            width: 150,
            show: false,
         },
         { Header: "Código", width: 150, accessor: "code" },
         { Header: "Nombre", accessor: "name" },
         {
            Header: "Subtotal",
            accessor: "subtotal",
            style: { textAlign: "right" },
            Cell: (props) => CyaHelper.ToCurrency(props.value),
         },
         {
            Header: "ITBIS",
            accessor: "itbis",
            style: { textAlign: "right" },
            Cell: (props) => CyaHelper.ToCurrency(props.value),
         },
         {
            Header: "Total",
            accessor: "total",
            style: { textAlign: "right" },
            Cell: (props) => CyaHelper.ToCurrency(props.value),
         },
         { Header: "parentCode", accessor: "parentCode", show: false },
      ];
      return (
         <div>
            <Card>
               <CardTitle className="bg-light border-bottom p-3 mb-0">
                  <i className="mdi mdi-cash-multiple mr-2" />
                  Importación de Presupuesto
               </CardTitle>
               <CardBody>
                  <Row>
                     <Col md={6}>
                        <InputGroup>
                           <div className="custom-file">
                              <Input
                                 type="file"
                                 className="custom-file-input"
                                 name={"file"}
                                 id={"file"}
                                 onChange={this.fileChange}
                                 // accept={this.props.accept}
                              />
                              <label
                                 style={{ overflow: "hidden" }}
                                 className="custom-file-label"
                                 htmlFor={"file"}
                              >
                                 {this.state.fileName}
                              </label>
                           </div>
                        </InputGroup>
                     </Col>
                     <Col md={3}>
                        <button
                           disabled={!(this.state.data.length > 0)}
                           className={
                              !(this.state.data.length > 0)
                                 ? "btn btn-primary disabled"
                                 : "btn btn-primary "
                           }
                           onClick={this.saveBudget}
                        >
                           Importar
                        </button>
                     </Col>
                  </Row>
                  <Row>
                     <Col md={6}>
                        {this.state.errorMsg.length > 0
                           ? this.state.errorMsg.map((e, index) => {
                                return (
                                   <Row key={index}>
                                      <small
                                         style={{ color: "red" }}
                                         className={"float-left"}
                                      >
                                         {e}
                                      </small>
                                   </Row>
                                );
                             })
                           : ""}
                     </Col>
                  </Row>
                  <hr />
                  <Row>
                     <Col md={12}>
                        <SimpleTable
                           data={this.state.data}
                           columns={columns}
                           loading={this.state.loading}
                           defaultPageSize={10}
                        />
                     </Col>
                  </Row>
               </CardBody>
            </Card>
         </div>
      );
   }
}
