import CyaApiClient from "./CyaApiClient";

export default class PersonApiClient extends CyaApiClient {

    constructor(){
        super("Person");
        this.Options = this.Options.bind(this);
        this.Positions = this.Positions.bind(this);
    }

    Options() {
        return this.MakeCall('get', this.ControllerName + '/options');
    }
    Positions() {
        return this.MakeCall('get', this.ControllerName + '/positions');
    }
    GetContracts() {
        return this.MakeCall('get', this.ControllerName + '/contracts');
    }
    GetContractTypes() {
        return this.MakeCall('get', this.ControllerName + '/getContractTypes');
    }
    GetContract(id) {
        return this.MakeCall('get', this.ControllerName + '/getContract',{id: id});
    }
    SaveContract(data) {
        return this.MakeCall('post', this.ControllerName + '/saveContract', data);
    }

}
