import md5 from "md5/md5";
import axios from "axios";
var diff = require('deep-diff')

export default class CyaHelper {

    static ToFinancial(x) {
        return Number.parseFloat(Number.parseFloat(x).toFixed(2));
    }

    static ToPercent(number) {
        return (number * 100).toFixed(2) + '%'
    }

    static ToCurrency(number) {
        return "RD$ " + (number).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    }

    static ObjectMap(object, mapFn) {
        return Object.keys(object).reduce(function(result, key) {
            result[key] = mapFn(object[key]);
            return result;
        }, {})
    }

    static Guid() {
        function s4() {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
        }
        return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
    }

    static DownloadFile(data, filename) {
        let link = document.createElement('a');
        link.href = data;
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        // delete link;
    };

    static CleanStringToFloat(number){
        return parseFloat(number.replace(/[^\d.-]/g, ''));
    }

    static ArrayMove(arr, old_index, new_index) {
        if (new_index >= arr.length) {
            let k = new_index - arr.length + 1;
            while (k--) {
                arr.push(undefined);
            }
        }
        arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    };

    static ColorGradient(firstColor, lastColor, percent){

        function processHEX(hex) {
            let r = hex.substr(1, 2);
            let g = hex.substr(3, 2);
            let b = hex.substr(5, 2);
            // return our clean values
            return [
                parseInt(r, 16),
                parseInt(g, 16),
                parseInt(b, 16)
            ]
        }

        function pad(n, width, z) {
            z = z || '0';
            n = n + '';
            return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
        }

        let val1RGB = processHEX(firstColor);
        let val2RGB = processHEX(lastColor);
        let stepsPerc = percent;

        // diffs between two values
        let valClampRGB = [
            val2RGB[0] - val1RGB[0],
            val2RGB[1] - val1RGB[1],
            val2RGB[2] - val1RGB[2]
        ];

        let i = 0;

        let clampedR = (valClampRGB[0] > 0)
            ? pad((Math.round(valClampRGB[0] / 100 * (stepsPerc * (i + 1)))).toString(16), 2)
            : pad((Math.round((val1RGB[0] + (valClampRGB[0]) / 100 * (stepsPerc * (i + 1))))).toString(16), 2);

        let clampedG = (valClampRGB[1] > 0)
            ? pad((Math.round(valClampRGB[1] / 100 * (stepsPerc * (i + 1)))).toString(16), 2)
            : pad((Math.round((val1RGB[1] + (valClampRGB[1]) / 100 * (stepsPerc * (i + 1))))).toString(16), 2);

        let clampedB = (valClampRGB[2] > 0)
            ? pad((Math.round(valClampRGB[2] / 100 * (stepsPerc * (i + 1)))).toString(16), 2)
            : pad((Math.round((val1RGB[2] + (valClampRGB[2]) / 100 * (stepsPerc * (i + 1))))).toString(16), 2);

        return [
            '#',
            clampedR,
            clampedG,
            clampedB
        ].join('');
    }

    static ImageExists(image) {
        return !!(image && image !== null && image.hash.length > 0);
    }

    static GetBase64FromFileInput(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }

    static GetFileExtension(filename) {
        return filename.slice((filename.lastIndexOf(".") - 1 >>> 0) + 2);
    }

    static IsNumber(number) {
        return !Number.isNaN(Number(number));
    }

    // Only for Cya Models
    static ObjectCompare(obj1, obj2) {
        // console.log(obj1)
        // console.log(obj2)
        const difference = diff(obj1, obj2);

        // console.log(difference)

        let differencesNotImportant  = [];
        if (difference){
            // console.log({obj1, obj2});
            // console.log({difference});

            // Almacenando las diferencias que no son importantes
            difference.forEach( diff => {
                if (
                    (diff.path !==undefined && diff.path.includes('imageGuid')) || (diff.path !==undefined && diff.path.includes('ifExistsCheckNumber')) ||
                    (diff.rhs !== undefined && diff.lhs === undefined) ||
                    (this.IsNumber(diff.rhs) && diff.rhs == diff.lhs) // Contempla si el primer es un numero y comparando con dos = por si uno es string y el otro numero
                ){
                    differencesNotImportant.push(diff.path[diff.path.length - 1])
                }
            });
            const isSameObject = differencesNotImportant.length === difference.length;
            // console.log({isSameObject: isSameObject, differencesNotImportant})

            return isSameObject;
        }
        return true;
    };

    static GetArrayImagesInBase64(arrayImages){
        let newArray=[];
        arrayImages.map(x => {
            let image={};
            image.imageGuid = this.Guid();
            image.imageId = 0;
            image.fileContent = x.data;
            image.hash = md5(x.data);
            image.filename = x.alt;
            newArray.push(image);
            return x;
        });

        return newArray;
    }

    static GetAllRequests(requestArray){
        return axios.all(requestArray);
    }

    static IsFileImage(file) {
        const acceptedImageTypes = ['image/jpg', 'image/jpeg', 'image/png'];
        return file && acceptedImageTypes.includes(file['type'])
    }

    static IsFilePDF(file) {
        const acceptedImageTypes = ['application/pdf'];
        return file && acceptedImageTypes.includes(file['type'])
    }

    static IsFileDOC(file) {
        const acceptedImageTypes = ['application/vnd.openxmlformats-officedocument.wordprocessingml.document','application/msword'];
        return file && acceptedImageTypes.includes(file['type'])
    }

    static isResponseOK(response){
        if (response.response===undefined){
            return true;
        }else if (response.response.status>=400){
            return false;
        }
    }

    static formatDateYYYYMMDD(dt){
        const date = new Date(dt);
        return (date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate());
    }

    static GetFilteredStringArrayBySearch(array,searchString){
        let currentList = [];
        // Variable to hold the filtered list before putting into state
        let newList = [];

        // If the search bar isn't empty
        if (searchString !== "") {
            // Assign the original list to currentList
            currentList = array;

            // Use .filter() to determine which items should be displayed
            // based on the search terms
            newList = currentList.filter(item => {
                // change current item to lowercase
                const lc = item.toLowerCase();
                // change search term to lowercase
                const filter = searchString.toLowerCase();
                // check to see if the current list item includes the search term
                // If it does, it will be added to newList. Using lowercase eliminates
                // issues with capitalization in search terms and search content
                return lc.includes(filter);
            });
        } else {
            // If the search bar is empty, set newList to original task list
            newList = array;
        }
        // Set the filtered state based on what our rules added to newList
        return newList;
    }

}
