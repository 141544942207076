import React, {useState, useMemo, useEffect, useRef} from 'react';
import "react-table/react-table.css";
import {CyaTableLayout} from "../../components/custom/tables/CyaTableLayout";
import CyaHelper from "../../utils/CyaHelper";
import ItemApiClient from "../../utils/api-client/ItemApiClient";
import {SuccessResponse} from "../../const/api_consts";
import {toast} from "react-toastify";
import AuthService from "../../utils/AuthService";
import BudgetModal from "../../components/custom/modals/BudgetModal";
import {ItemModel} from "../../models/ItemModel";
import {SimpleTable} from "../../components/custom/tables/SimpleTable";
import {Card, CardBody, CardTitle} from "reactstrap";

export default function Listing(props) {

    const [data, setData] = useState([
        {
            amountAvailable: 960002, //principal, todo estara en esta parte
            annexes: 0,
            code: "a1000",
            itbis: 10800,
            itemId: 822,
            items: [
                {
                    code: "a1001",
                    name: "Argumento y Guion",
                    items: [ // estos son los que se veran los ejemplos
                        {
                            amountAvailable: 199279.71,
                            annexes: 5,
                            code: "a1001-01",
                            itbis: 0,
                            itemId: 824,
                            items: [],
                            name: "Idea original-Eduardo Matres",
                            parentItemId: 823,
                            subtotal: 200002,
                            total: 200002
                        },
                        {
                            amountAvailable: 199279.71,
                            annexes: 5,
                            code: "a1001-02",
                            itbis: 1,
                            itemId: 825,
                            items: [],
                            name: "Idea original-Eduardo Matres",
                            parentItemId: 824,
                            subtotal: 200003,
                            total: 200003
                        }
                    ],
                }
            ],
            name: "HISTORIA Y ESCRITURA",
            parentItemId: 0,
            subtotal: 960002,
            total: 970802,
        },
    ])
    const [tablaData, setTablaData] = useState([])
    const [loading, setLoading] = useState(true)
    const [selectedRowId, setSelectedRowId] = useState(-1)
    const [selected0, setSelected0] = useState(-1)
    const [selected1, setSelected1] = useState(-1)
    const [selected2, setSelected2] = useState(-1)


    const [modalToggle, setModalToggle] = useState(false)


    let {current: apiClient} = useRef(new ItemApiClient())
    let {current: Auth} = useRef(new AuthService())


    useEffect(() => {
        let table=[];
         data.map(x => {
            x['kind'] = 'father'
            table.push(x)
            x.items.map(y => {
                y['kind'] = 'children'
                table.push(y)
                y.items.map(z => {
                    z['kind'] = 'grandchild'
                    table.push(z)
                    return z
                })
                return y
            })
            return x
        })

        setTablaData(table)
        setLoading(false)

    }, [])


    const GetProps0 = (state, rowInfo) => {
        if (rowInfo && rowInfo.row) {
            return {
                onClick: (e) => {
                    setSelected0(rowInfo.index)
                    setSelected1(-1)
                    setSelected2(-1)
                    setSelectedRowId(rowInfo.row.itemId)
                },
                onDoubleClick: (e) => {
                    setModalToggle(true)

                    // if (selected0 === rowInfo.index) {
                    //     props.history.push({
                    //         pathname: '/items/edit',
                    //         state: {id: rowInfo.row.itemId}
                    //     });
                    // }
                },
                style: {
                    background: rowInfo.row.itemId === selectedRowId ? '#00AFEC' : (rowInfo.index % 2 === 0 ? '#DDDDDD' : '#FFFFFF'),
                    color: rowInfo.row.itemId === selectedRowId ? 'white' : 'black'
                }
            }
        }
        return {};
    }

    let columns = [
        {Header: "ItemId", accessor: "itemId", show: false},
        {Header: "Código", id: "code", style: {fontWeight: "bold"}, accessor: "code", width: 150},
        {Header: "Nombre", accessor: "name", style: {fontWeight: "bold"}, minWidth: 300},
        {
            Header: "Subtotal",
            accessor: "subtotal",
            style: {textAlign: 'right', fontWeight: "bold"},
            // Cell: props => CyaHelper.ToCurrency(props.value),
            width: 150
        },
        {
            Header: "ITBIS",
            accessor: "itbis",
            style: {textAlign: 'right', fontWeight: "bold"},
            // Cell: props => CyaHelper.ToCurrency(props.value),
            width: 150
        },
        {
            Header: "Total",
            accessor: "total",
            style: {textAlign: 'right', fontWeight: "bold"},
            // Cell: props => CyaHelper.ToCurrency(props.value),
            width: 150
        }
    ];


    const renderColumns = useMemo(() => {
        return [
            {
                id: 'period',
                label: 'Periodo',
            },
            {
                id: 'amount',
                label: 'Monto',
            },
            {
                id: 'commentary',
                label: 'Comentario',
            },
        ];
    }, [])


    const renderRows = useMemo(() => {
        return [
            {period: 1, amount: 1324171354, commentary: ''},
            {period: 1, amount: 1403500365, commentary: ''},
            {period: 1, amount: 60483973, commentary: ''},
        ];
    }, [])

    const onChangeBudget = () => {

    }

    return (
        <Card>
            <CardTitle className="mb-0 p-3 border-bottom bg-light"><i
                className="mdi mdi-format-list-bulleted mr-2"/>Estimacion de costos</CardTitle>
            <CardBody>
                <BudgetModal
                    toggle={() => setModalToggle(!modalToggle)}
                    isOpen={modalToggle}
                    columns={renderColumns}
                    onChangeEvent={onChangeBudget}
                    rows={renderRows}

                />

                <SimpleTable
                    data={tablaData}
                    columns={columns}
                    loading={loading}
                    filterable={true}
                    showPaginationTop={true}
                    trProps={GetProps0}
                    defaultPageSize={10}/>

            </CardBody>
        </Card>
    )
}
