const LoginFieldValidator = {
    password: {
        secondValue: null,
        rules: [
            {
                test: (value) => value.length >= 5,
                message: 'Contraseña no puede ser menor a 5 caracteres',
            }
        ],
        errors: [],
        valid: false,
        state: ''
    },
    username: {
        secondValue: null,
        rules: [
            {
                test: (value) => value.length >= 4,
                message: 'Usuario debe contener al menos 4 caracteres',
            },
            {
                test: /^[A-Za-z0-9]+$/,
                message: 'Sólo se permiten valores alfanuméricos',
            },
        ],
        errors: [],
        valid: false,
        state: ''
    }
};

export default LoginFieldValidator;