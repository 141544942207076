import { ImageModel } from "./ImageModel";

export class ProjectDocumentModel {
    constructor(type = 1) {
        this.projectDocumentTypeId = type;
    }

    projectDocumentTypeId = 1;
    value = "";
    issuedAt = new Date();
    expires = new Date();
    imageId = 0;
    image = new ImageModel();
}
