import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter'
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import 'bootstrap/dist/css/bootstrap.css';
import {FormGroup, Input, FormFeedback, Button} from 'reactstrap';
import CurrencyInput from "react-currency-input";
import {CurrencyPrecision, CurrencyPrefix} from "../../../const/api_consts";
import PerfectScrollbar from "react-perfect-scrollbar";


export default function BudgetTable({columns, onChangeEvent, rows, }) {
    const classes = useStyles();

    return (
        <div>
            <TableContainer className={classes.container}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell
                                    className={classes.headCenter}
                                    key={column.id}
                                    align={column.align}
                                    style={{minWidth: column.minWidth}}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>

                    <TableBody className={classes.tableBody}>

                        {rows.map((row, index) => {
                            return (
                                <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                                    <TableCell align="center">
                                        {row.period}
                                    </TableCell>

                                    <TableCell align="center">
                                        <FormGroup className={classes.formGroup}>
                                            <CurrencyInput
                                                readOnly={false}
                                                className={`form-control ${classes.input}`}
                                                prefix={CurrencyPrefix}
                                                precision={CurrencyPrecision}
                                                value={row.amount}
                                                placeholder={'valor'}
                                                allowNegative={true}
                                                onChangeEvent={onChangeEvent}/>

                                        </FormGroup>
                                    </TableCell>

                                    <TableCell align="center">
                                        <FormGroup className={classes.formGroup}>
                                            <Input className={classes.input} value={row.commentary} invalid={true}/>
                                            <FormFeedback className={classes.formFeedback} valid={false}> Sweet! that
                                                name is available</FormFeedback>
                                        </FormGroup>

                                    </TableCell>
                                </TableRow>

                            );
                        })}

                    </TableBody>

                    <TableFooter>
                        <Button  style={{margin:15}} size={'sm'} color="primary">Agrrgar nuevo</Button>
                    </TableFooter>
                </Table>
            </TableContainer>
        </div>
    );
}

const useStyles = makeStyles({
    root: {
        width: '100%',
    },
    container: {
        maxHeight: 475,
    },
    tableBody: {
        minWidth: 1080,
    },

    center: {
        display: 'flex',
        textAlign: 'right',
        justifyContent: 'flex-end',

    },
    headCenter: {
        // display: 'flex',
        textAlign: 'center',
        justifyContent: 'flex-start',

    },

    input: {
        width: '100%',
        minWidth: 200,
    },

    formGroup: {
        display: 'flex',
        flexDirection: 'column',
    },

    formCurrency: {
        position: 'absolute'
    },

    formFeedback: {
        top: '100%',
        textAlign: 'left',
        position: 'absolute'
    },
    textTransparent: {
        color: 'transparent',
    }

});
