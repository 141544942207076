import {ImageModel} from "./ImageModel";

export class CheckModel {
    number="";
    orderOf="";
    concept="";
    bankId = 1;
    date= new Date().toISOString();
    checkTypeId = 1;
    amount=0;
    usedAmount=0;
    imageId;
    image = new ImageModel();
    investorCheck = [];
    parentCheckId;
    comment="";
    pettyCash = false;
    itemId;
    children=[];
    // Para validaciones
    investorBlocked=false;
    ifExistsCheckNumber = false;
}