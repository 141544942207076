import React from 'react';
import {
    FormGroup,
    Label,

} from 'reactstrap';
import PropTypes from 'prop-types'
import CurrencyInput from "react-currency-input";
import {CurrencyPrecision, CurrencyPrefix} from "../../../const/api_consts";

export class InputCurrency extends React.Component {

    render() {
        return <FormGroup>
            <Label htmlFor={this.props.id_label}>{this.props.label}</Label>
            <CurrencyInput
                readOnly={this.props.readOnly}
                className="form-control"
                prefix={CurrencyPrefix}
                precision={CurrencyPrecision}
                id={this.props.id_label}
                name={this.props.id_label}
                value={this.props.value}
                placeholder={this.props.placeholder}
                allowNegative={true}
                onChangeEvent={this.props.onInputChange}/>
        </FormGroup>
    }
}

InputCurrency.propTypes = {
    label: PropTypes.string,
    id_label: PropTypes.string,
    readOnly: PropTypes.bool,
    value: PropTypes.number,
    placeholder: PropTypes.string,
    onInputChange: PropTypes.func
};
