import React from "react";
import PropTypes from "prop-types";
import {DateFormat} from "../../const/api_consts";
import moment from "moment/moment";
import CyaHelper from "../CyaHelper";

require('moment/locale/es-do');

export class AnnexValidator extends React.Component {

    constructor(props) {
        super(props);
        this.isValid = [];
        this.compareAnnexDateVsCheckDate = this.compareAnnexDateVsCheckDate.bind(this);
        this.totalDueAvailableOnCheck = this.totalDueAvailableOnCheck.bind(this);
        // this.retentionsAvailableOnInvestor = this.retentionsAvailableOnInvestor.bind(this);
        this.checkSubtotalDivisionIntoItems = this.checkSubtotalDivisionIntoItems.bind(this);
        this.checkOverdueItems = this.checkOverdueItems.bind(this);
        this.checkItemZero = this.checkItemZero.bind(this);
        this.checkInvestorBlocked = this.checkInvestorBlocked.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.props.callbackFromParent(this.isValid.every(x => x));
    }

    // 1- La fecha no puede ser anterior a la fecha del cheque.
    compareAnnexDateVsCheckDate() {
        if(Number(this.props.model.checkId) > 0){
            let checkDate = new Date(this.props.model.checkDate);
            let annexDate = new Date(this.props.model.date);
            checkDate.setUTCHours(0,0,0,0);
            annexDate.setUTCHours(0,0,0,0);
            return checkDate <= annexDate;
        }
        return true;
    }

    // 2- El "Total a pagar" debe de verificarse con el monto disponible en el cheque.
    totalDueAvailableOnCheck() {
        if(Number(this.props.model.checkId) > 0)
            return CyaHelper.ToFinancial(this.props.model.totalDue) <= CyaHelper.ToFinancial(this.props.model.checkAvailable);
        return true;
    }

    // 3- Las retenciones debe de verificarse con el monto disponible en el inversionista seleccionado. (Warning)
    // retentionsAvailableOnInvestor() {
    //     if(Number(this.props.model.checkId) > 0)
    //         return this.props.model.totalExpense - this.props.model.totalDue <= this.props.model.investorAvailable;
    //     return true;
    // }

    // 5- Un anexo puede estar en una o mas partidas sin importar si esta(s) han llegado a su límite (partidas sobregiradas) (Warning)
    checkSubtotalDivisionIntoItems(itemsSum) {
        return CyaHelper.ToFinancial(itemsSum) === CyaHelper.ToFinancial(this.props.model.subtotal);
    }

    // 6- Todas las partidas deben tener un monto mayor a cero
    checkItemZero() {
        return this.props.model.annexItem.every(x => x.amount > 0);
    }

    // 7- Un anexo puede estar en una o mas partidas sin importar si esta(s) han llegado a su límite (partidas sobregiradas)
    checkOverdueItems() {
        return this.props.model.annexItem.find(x => x.amount > x.availableAmount) === undefined;
    }

    // 8- Si al menos un inversionista esta bloqueado, no permitir salvar
    checkInvestorBlocked() {
        return !this.props.model.investorBlocked;
    }

    showResponseError = () => {
        return (
            this.props.responseError && 
            <li className={"list-group-item list-group-item-danger"}>
                {`ERROR: ${this.props.responseError.data}`}
            </li>)
    }

    render() {
        this.isValid = [];
        let annexDate = moment(this.props.model.date).format(DateFormat);
        let checkDate = moment(this.props.model.checkDate).format(DateFormat);
        let itemsSum = this.props.model.annexItem.length === 0 ? 0 :
        this.props.model.annexItem.reduce(function(prev, cur) {
            return prev + cur.amount;
        }, 0);

        this.isValid.push(this.compareAnnexDateVsCheckDate());
        //this.isValid.push(this.totalDueAvailableOnCheck());
        this.isValid.push(this.checkSubtotalDivisionIntoItems(itemsSum));
        this.isValid.push(this.checkItemZero());
        this.isValid.push(this.checkInvestorBlocked());
        let x = 0;

        return <ul className={"list-group"}>
            {this.isValid[x++] ? "" :
                <li className={"list-group-item list-group-item-danger"}>ERROR: La fecha del anexo ({annexDate}) no
                    puede ser anterior a la del cheque ({checkDate})</li>}
            {this.totalDueAvailableOnCheck() ? "" :
                <li className={"list-group-item list-group-item-warning"}>AVISO: No queda monto suficiente en el cheque
                    ({CyaHelper.ToCurrency(this.props.model.checkAvailable)}) para cubrir este anexo
                    ({CyaHelper.ToCurrency(this.props.model.totalDue)})</li>}
            {this.isValid[x++] ? "" :
                <li className={"list-group-item list-group-item-danger"}>ERROR: La sumatoria del monto dividido en las
                    partidas ({CyaHelper.ToCurrency(itemsSum)}) debe ser igual al subtotal del anexo
                    ({CyaHelper.ToCurrency(this.props.model.subtotal)})</li>}
            {this.isValid[x++] ? "" :
                <li className={"list-group-item list-group-item-danger"}>ERROR: Las partidas seleccionadas deben tener
                    un monto mayor a cero</li>}
            {this.isValid[x] ? "" :
                <li className={"list-group-item list-group-item-danger"}>ERROR: Al menos un inversionista del cheque se
                    encuentra bloqueado, no se puede modificar el anexo</li>}
            {this.checkOverdueItems() ? "" :
                <li className={"list-group-item list-group-item-warning"}>AVISO: No queda monto suficiente en al menos
                    una partida seleccionada</li>}
            {/*{this.retentionsAvailableOnInvestor() ? "" :*/}
                {/*<li className={"list-group-item list-group-item-warning"}>AVISO: No queda monto suficiente en el*/}
                    {/*inversionista ({CyaHelper.ToCurrency(this.props.model.investorAvailable)}) para cubrir las*/}
                    {/*retenciones de este anexo*/}
                    {/*({CyaHelper.ToCurrency((this.props.model.totalExpense - this.props.model.totalDue))})</li>}*/}
            { this.showResponseError() }
        </ul>;
    }
}

AnnexValidator.propTypes = {
    model: PropTypes.object,
    callbackFromParent: PropTypes.func
};