import React from 'react';
import {Card, CardTitle, CardBody, Row, Col, Label} from 'reactstrap';
import AnnexApiClient from "../../utils/api-client/AnnexApiClient";
import {InputForm} from "../../components/custom/inputs/InputForm";
import {InputTextArea} from "../../components/custom/inputs/InputTextArea";
import {InputTaxForm} from "../../components/custom/inputs/InputTaxForm";
import {LoadingPage} from "../../components/custom/views/LoadingPage";
import Datetime from "react-datetime";
import {DateFormat} from "../../const/api_consts";
import {InputCurrency} from "../../components/custom/inputs/InputCurrency";
import {InputImageCarousel} from "../../components/custom/inputs/InputImageCarousel";
import CyaHelper from "../../utils/CyaHelper";
import FieldValidator from "../../utils/field-validators/FieldValidator";
import {toast} from "react-toastify";
import AuthService from "../../utils/AuthService";
import {CyaContext} from "../../utils/CyaContext";
import AnnexCreditNoteApiClient from "../../utils/api-client/AnnexCreditNoteApiClient";
import {AnnexCreditNoteModel} from "../../models/AnnexCreditNoteModel";
import AnnexCreditNoteFieldValidator from "../../utils/field-validators/AnnexCreditNoteFieldValidator";
import Select from "react-select";

require('moment/locale/es-do');

export default class CreateAnnexCreditNote extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            data: new AnnexCreditNoteModel(),
            initialData: new AnnexCreditNoteModel(),
            loading: true,
            annexLoading: true,
            annexData: [],
            title: "Crear Nota de Crédito",
            selectedAnnex: {
                label: "",
                value: 0,
                model: {id: "", name: "", totalDue: 0}
            },
            annexCreditNoteImages: [],
            modelCreated: "Nota de crédito guardada.",
            modelUpdated: "Nota de crédito actualizada.",
            modelError: "Error Salvando.",
            existsNfcAndId: null,
            loadingNfcAndId: false,
        };
        this.onEdit = false;
        this.Validator = new FieldValidator(AnnexCreditNoteFieldValidator);
        this.Auth = new AuthService();
        this.AnnexApiClient = new AnnexApiClient();
        this.AnnexCreditNoteApiClient = new AnnexCreditNoteApiClient();
    }

    componentDidMount(){
        this.AnnexApiClient.GetListForCreditNotes().then(response => {
            this.setState({
                annexLoading: false,
                annexData: response.data.map(x => {
                    return {
                        label: x.ncf,
                        value: x.annexId,
                        model: x
                    };
                })

            });
        }).then(() => {
            if (this.props.location.state !== undefined && this.props.location.state?.id) {
                this.AnnexCreditNoteApiClient.GetById(this.props.location.state.id).then(response => {
                    this.editPromise(response);
                });
            } else {
                this.setState({
                    loading: false
                });
            }
        });
    }


    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if (!nextState.loading
            && CyaHelper.ObjectCompare(nextState.data, this.state.data)
            && !this.onEdit)
        {
            this.Validator.initValidation(nextState.data);
        }
        return true;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!this.state.loading) {
            let isFormDirty = !CyaHelper.ObjectCompare(this.state.initialData, this.state.data);
            this.context.toggleExitConfirmation(isFormDirty);
        }
        const {annexId} = prevState.data;
        if (annexId !== this.state.data.annexId) {
            this.checkNfcAndId()
        }
    }

    editPromise = (response) =>{
        this.onEdit = true;
        const self = this;

        if (response.comment === null)
            response.comment = "";

        let annexIndex = self.state.annexData.findIndex(x => x.value === response.annexId);
        let annex = annexIndex >= 0 ?
            self.state.annexData[annexIndex] :
            {
                label: "",
                value: 0,
                model: {id: "", name: "", totalDue: 0}
            };

        response.annexCreditNoteImage.sort((a, b) => a.sequence - b.sequence);

        self.setState({
            initialData: JSON.parse(JSON.stringify(response)), //copy data variable
            data: response,
            annexCreditNoteImages: response.annexCreditNoteImage,
            selectedAnnex: annex,
            title: "Editar Note de Crédito " + response.ncf,
            loading: false
        });
        this.onEdit = false;
    };

    calculateValues = () => {
        let aux = this.state.data;

        aux.itbis = (aux.itbispercentage / 100) * aux.subtotal;
        aux.isrretention = (aux.isrretentionPercentage / 100) * aux.subtotal;
        aux.itbisretention = (aux.itbisretentionPercentage / 100) * aux.itbis;

        aux.totalExpense = aux.subtotal + aux.itbis;
        aux.totalDue = aux.totalExpense - aux.isrretention - aux.itbisretention;

        this.setState({
            data: aux
        });
    };

    onDateChange = (moment) => {
        let aux = this.state.data;
        aux.date = moment.format("YYYY-MM-DD");
        this.setState({
            data: aux
        });
    };

    onCurrencyChange = (event, maskedvalue, floatvalue) => {
        let aux = this.state.data;
        aux[event.target.name] = floatvalue;
        this.calculateValues();
    };

    onInputChange = (event) => {
        let aux = this.state.data;
        aux[event.target.name] = event.target.type === "checkbox" ? event.target.checked : event.target.value;
        this.setState({
            data: aux
        });
    };

    onTaxInputChange = (event) => {
        let aux = this.state.data;
        aux[event.target.name] = CyaHelper.CleanStringToFloat(event.target.value);
        aux.totalExpense = aux.subtotal + aux.itbis;
        aux.totalDue = aux.totalExpense - aux.isrretention - aux.itbisretention;
        this.setState({
            data: aux
        });
    };

    onImageChange = (images) => {
        let aux = this.state.data;
        aux.annexCreditNoteImage = images;
        this.setState({
            data: aux
        });
    };

    onAnnexSelectChange = (annex) => {
        if (typeof(annex) !== "undefined") {
            annex = annex || {
                label: "",
                value: 0,
                model: {id: "", name: "", totalDue: 0}
            };

            let aux = this.state.data;
            aux.annexId = annex.value;
            aux.itbispercentage = annex.model.itbispercentage * 100;
            aux.itbisretentionPercentage = annex.model.itbisretentionPercentage * 100;
            aux.isrretentionPercentage = annex.model.isrretentionPercentage * 100;
            this.setState({
                data: aux,
                selectedAnnex: annex
            });
        }
    };

    onSubmit = (event,addNew=false) => {
        event.preventDefault();
        this.setState({
            loading: true
        });

        if (this.state.data.annexCreditNoteId > 0) {
            this.AnnexCreditNoteApiClient.Update(this.state.data.annexCreditNoteId, this.state.data)
                .then(response => {
                    this.editPromise(response);
                    toast.success(this.state.modelUpdated);
                    this.context.toggleExitConfirmation(false);
                    if (addNew)
                        this.props.history.push({pathname: '/refresh/creditnotes/new'});
                })
                .catch(() => {
                    this.setState({loading: false});
                    toast.error(this.state.modelError);
                });
        }
        else {
            this.AnnexCreditNoteApiClient.Create(this.state.data)
                .then(response => {
                    toast.success(this.state.modelCreated);
                    this.context.toggleExitConfirmation(false);
                    if (addNew)
                        this.props.history.push({pathname: '/refresh/creditnotes/new'});
                    else
                        this.editPromise(response);
                })
                .catch(() => {
                    this.setState({loading: false});
                    toast.error(this.state.modelError);
                });
        }
        window.scrollTo(0, 0);
    };

    checkNfcAndId = ()=>{
        const {annexId,ncf} =this.state.data;
        let isNumber = !Number.isNaN(Number(ncf.substr(1)));
        let nfcFirstCheck = ncf.length === 19 && ncf.startsWith("A");
        let nfcSecondCheck = ncf.length === 11 && ncf.startsWith("B");
        let thirdCheck = ncf.length === 13 && ncf.startsWith("E");
        const validNfc= isNumber && (nfcFirstCheck || nfcSecondCheck || thirdCheck);
        if (annexId>0 && validNfc) {
            this.setState({loadingNfcAndId:true});
            this.AnnexCreditNoteApiClient.ExistsAnnexIdAndRnc(annexId,ncf).then(x=>
                {
                    this.setState({existsNfcAndId:x,loadingNfcAndId:false});
                }
            );
        }
    };


    disabledSubmit = () => {
        const {location} = this.props.history
        if (location.pathname === '/creditnotes/edit') {
            return !((this.state.existsNfcAndId != null && this.state.existsNfcAndId) && this.Validator.validForm() && this.Auth.GetPathPermission(this.props.location.pathname, true))
        }
        if (location.pathname === '/creditnotes/new') {
            return !((this.state.existsNfcAndId != null && !this.state.existsNfcAndId) && this.Validator.validForm() && this.Auth.GetPathPermission(this.props.location.pathname, true))
        }
    }

    ValidatorNfcAndId = () => {
        const {location} = this.props.history
        if (location.pathname === '/creditnotes/edit') {
            return (this.state.existsNfcAndId!=null && !this.state.existsNfcAndId && !this.state.loadingNfcAndId)
        }
        if (location.pathname === '/creditnotes/new') {
            return (this.state.existsNfcAndId!=null && this.state.existsNfcAndId && !this.state.loadingNfcAndId)
        }
    }


    render() {
        return <div>
            <Card>
                <CardTitle className="mb-0 p-3 border-bottom bg-light"><i
                    className="mdi mdi-file-multiple mr-2"/>{this.state.title}</CardTitle>
                <CardBody>
                    <LoadingPage color={"3e5569"} loading={this.state.loading} type={"spin"}/>
                    <Row>
                        <Col md={12}>
                            {this.state.annexCreditNoteImages.length > 0 ?
                                <InputImageCarousel annexImageModels={this.state.annexCreditNoteImages} onImageChange={this.onImageChange}/>
                                : <div><InputImageCarousel annexImageModels={this.state.annexCreditNoteImages} onImageChange={this.onImageChange}/></div>}
                            <hr/>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={3}>
                            <InputForm id_label={"ncf"} label={"NCF:"} placeholder={""} onBlur={this.checkNfcAndId}
                                       type={"text"} value={this.state.data.ncf} onInputChange={this.onInputChange}/>
                            {this.Validator.showErrors("ncf")}
                            {this.ValidatorNfcAndId() && <span className={"error"}>Ya existe una nota de credito con este NFC y RNC</span>}
                            {this.state.loadingNfcAndId && <span className={'success'}>Validando Anexo y NFC...</span>}
                        </Col>
                        <Col md={3}>
                            <Label htmlFor={"date"}>Fecha: </Label>
                            <Datetime locale={"es-do"}
                                      inputProps={{readOnly: true, style:{backgroundColor: "transparent"}}}
                                      style={{background:'transparent'}}
                                      utc={true}
                                      timeFormat={false}
                                      defaultValue={new Date(this.state.data.date)}
                                      dateFormat={DateFormat}
                                      closeOnSelect={true}
                                      value={new Date(this.state.data.date)}
                                      onChange={this.onDateChange}/>
                        </Col>
                        <Col md={3}>
                            <InputCurrency id_label={"subtotal"}
                                           label={"Subtotal:"}
                                           placeholder={""}
                                           value={this.state.data.subtotal}
                                           onInputChange={this.onCurrencyChange}
                            />
                            {this.Validator.showErrors("subtotal")}
                        </Col>
                        <Col md={3}>
                            <InputTaxForm
                                label={"ITBIS:"}
                                amountId={"itbis"}
                                percentId={"itbispercentage"}
                                amountValue={String(this.state.data.itbis)}
                                percentValue={this.state.data.itbispercentage}
                                onInputChange={this.onTaxInputChange}
                                onPercentInputChange={(values) => {
                                    if(!this.onEdit){
                                        const {floatValue} = values;
                                        let aux = this.state.data;

                                        aux.itbispercentage = floatValue;
                                        aux.itbis = (aux.itbispercentage / 100) * aux.subtotal;
                                        aux.itbisretention = (aux.itbisretentionPercentage / 100) * aux.itbis;

                                        aux.totalExpense = aux.subtotal + aux.itbis;
                                        aux.totalDue = aux.totalExpense - aux.isrretention - aux.itbisretention;

                                        this.setState({
                                            data: aux
                                        });
                                    }
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={3}>
                            <Label htmlFor={"annex"}> Anexo Padre NCF: </Label>
                            <Select options={this.state.annexData}
                                    id={"annex"}
                                    name={"annex"}
                                    value={this.state.selectedAnnex}
                                    isLoading={this.state.annexLoading}
                                    placeholder={"Seleccione anexo"}
                                    onBlur={this.checkNfcAndId}
                                    onChange={this.onAnnexSelectChange}
                            />
                            {this.Validator.showErrors("annexId")}
                        </Col>
                        <Col md={3}>
                            <InputForm id_label={"id"}
                                       label={" Anexo Padre ID:"}
                                       type={"text"}
                                       placeholder={""}
                                       value={this.state.selectedAnnex.model.id}
                                       readOnly={true}
                            />
                        </Col>
                        <Col md={3}>
                            <InputTaxForm
                                label={"ISR Retenido:"}
                                amountId={"isrretention"}
                                percentId={"isrretentionPercentage"}
                                amountValue={String(this.state.data.isrretention)}
                                percentValue={this.state.data.isrretentionPercentage}
                                onInputChange={this.onTaxInputChange}
                                onPercentInputChange={(values) => {
                                    if(!this.onEdit) {
                                        const {floatValue} = values;
                                        let aux = this.state.data;

                                        aux.isrretentionPercentage = floatValue;
                                        aux.isrretention = (aux.isrretentionPercentage / 100) * aux.subtotal;

                                        aux.totalExpense = aux.subtotal + aux.itbis;
                                        aux.totalDue = aux.totalExpense - aux.isrretention - aux.itbisretention;

                                        this.setState({
                                            data: aux
                                        });
                                    }
                                }}
                            />
                        </Col>
                        <Col md={3}>
                            <InputTaxForm
                                label={"ITBIS Retenido:"}
                                amountId={"itbisretention"}
                                percentId={"itbisretentionPercentage"}
                                amountValue={String(this.state.data.itbisretention)}
                                percentValue={this.state.data.itbisretentionPercentage}
                                onInputChange={this.onTaxInputChange}
                                onPercentInputChange={(values) => {
                                    if(!this.onEdit) {
                                        const {floatValue} = values;
                                        let aux = this.state.data;

                                        aux.itbisretentionPercentage = floatValue;
                                        aux.itbisretention = (aux.itbisretentionPercentage / 100) * aux.itbis;

                                        aux.totalExpense = aux.subtotal + aux.itbis;
                                        aux.totalDue = aux.totalExpense - aux.isrretention - aux.itbisretention;

                                        this.setState({
                                            data: aux
                                        });
                                    }
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={3}>
                            <InputForm id_label={"name"}
                                       label={" Anexo Padre Nombre:"}
                                       placeholder={""}
                                       readOnly={true}
                                       type={"text"}
                                       value={this.state.selectedAnnex.model.name}/>
                        </Col>
                        <Col md={3}>
                            <InputCurrency id_label={"annexTotalDue"}
                                           label={" Anexo Padre Total a pagar:"}
                                           placeholder={""}
                                           readOnly={true}
                                           value={this.state.selectedAnnex.model.totalDue}
                            />
                        </Col>
                        <Col md={3}>
                            <InputCurrency id_label={"totalExpense"}
                                           label={"Total de Gastos:"}
                                           placeholder={""}
                                           readOnly={true}
                                           value={this.state.data.totalExpense}
                            />
                        </Col>
                        <Col md={3}>
                            <InputCurrency id_label={"totalDue"}
                                           label={"Total a Pagar:"}
                                           placeholder={""}
                                           readOnly={true}
                                           value={this.state.data.totalDue}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <InputTextArea id_label={"comment"} label={"Comentario:"} placeholder={""}
                                           rows={"4"} value={this.state.data.comment}
                                           onInputChange={this.onInputChange}/>
                        </Col>
                        {this.Validator.showErrors("comment")}
                    </Row>
                    <Row>
                        <Col md={12}>
                            <hr/>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={2}>
                            <input type="submit"
                                   disabled={this.disabledSubmit()}
                                   className={this.disabledSubmit() ? 'btn btn-block btn-primary disabled' : 'btn btn-block btn-primary'}
                                   value="Guardar"
                                   onClick={this.onSubmit}/>
                        </Col>
                        <Col md={2}>
                            <input type="submit"
                                   disabled={this.disabledSubmit()}
                                   className={this.disabledSubmit() ? 'btn btn-block btn-success disabled' : 'btn btn-block btn-success'}
                                   value="Guardar y añadir nuevo"
                                   onClick={(event) => this.onSubmit(event,true)}/>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </div>;
    }
}
CreateAnnexCreditNote.contextType = CyaContext;
