import React from "react";
import {
    Card,
    CardBody,
    CardTitle,
    CardSubtitle,
    Col,
    Row
} from 'reactstrap';
import { Line } from 'react-chartjs-2';
import Statistics from "../../dashboard-components/statistics/statistics";
import PropTypes from "prop-types";
import CyaHelper from "../../../utils/CyaHelper";

export class ProjectSummary extends React.Component {
    render() {
        //Line chart
        let lineData = {
            labels: this.props.data.transactionChart.length > 0 ? this.props.data.transactionChart.map(x => x.date) : [],
            datasets: [{
                label: 'Cheques',
                borderWidth: 1,
                backgroundColor: 'rgba(79,195,247,.1)',
                borderColor: 'rgb(79,195,247)',
                pointBorderColor: 'rgb(79,195,247)',
                pointBackgroundColor: 'rgb(79,195,247)',
                data: this.props.data.transactionChart.length > 0 ? this.props.data.transactionChart.map(x => x.checks) : []
            },{
                label: 'Gastos',
                borderWidth: 1,
                backgroundColor: 'rgba(251,99,64,.1)',
                borderColor: 'rgb(251,99,64)',
                pointBorderColor: 'rgb(251,99,64)',
                pointBackgroundColor: 'rgb(251,99,64)',
                data: this.props.data.transactionChart.length > 0 ? this.props.data.transactionChart.map(x => x.expenses) : []
            },{
                label: 'Inversiones',
                borderWidth: 1,
                backgroundColor: 'rgba(45,206,137,.1)',
                borderColor: 'rgb(45,206,137)',
                pointBorderColor: 'rgb(45,206,137)',
                pointBackgroundColor: 'rgb(45,206,137)',
                data: this.props.data.transactionChart.length > 0 ? this.props.data.transactionChart.map(x => x.investors) : []
            },{
                label: 'Validaciones',
                borderWidth: 1,
                backgroundColor: 'rgba(41,98,255,.1)',
                borderColor: 'rgb(41,98,255)',
                pointBorderColor: 'rgb(41,98,255)',
                pointBackgroundColor: 'rgb(41,98,255)',
                data: this.props.data.transactionChart.length > 0 ? this.props.data.transactionChart.map(x => x.validations) : []
            }]
        };

        return (
            <Card>
                <CardBody>
                    <div className="d-md-flex align-items-center">
                        <div>
                            <CardTitle>Visión General</CardTitle>
                            <CardSubtitle>Datos actuales y progreso semanal del desarrollo del proyecto</CardSubtitle>
                        </div>
                        <div className="ml-auto d-flex no-block align-items-center">
                            <ul className="list-inline font-12 dl mr-3 mb-0">
                                <li className="border-0 p-0 text-cyan list-inline-item">
                                    <i className="fa fa-circle"/> Cheques
                                </li>
                                <li className="border-0 p-0 text-orange list-inline-item">
                                    <i className="fa fa-circle"/> Gastos
                                </li>
                                <li className="border-0 p-0 text-success list-inline-item">
                                    <i className="fa fa-circle"/> Inversiones
                                </li>
                                <li className="border-0 p-0 text-info list-inline-item">
                                    <i className="fa fa-circle"/> Validaciones
                                </li>
                            </ul>
                        </div>
                    </div>
                    <Row>
                        <Col lg="4">
                            <h1 className="mb-0 mt-4">{CyaHelper.ToCurrency(this.props.data.budget)}</h1>
                            <h6 className="font-light text-muted">Presupuesto</h6>
                            <h3 className="mt-4 mb-0">{this.props.data.investorQuantity}</h3>
                            <h6 className="font-light text-muted">Inversionistas</h6>
                            <h3 className="mt-4 mb-0">{this.props.data.checksQuantity}</h3>
                            <h6 className="font-light text-muted">Cheques</h6>
                        </Col>
                        <Col lg="8">
                            <div className="campaign ct-charts">
                                <div className="chart-wrapper" style={{ width: '100%', margin: '0 auto', height: 250 }}>
                                    <Line data={lineData}
                                          options={{
                                              tooltips: {
                                                  callbacks: {
                                                      label: function(tooltipItem, data) {
                                                          return CyaHelper.ToCurrency(tooltipItem.yLabel);
                                                      }
                                                  }
                                              },
                                              maintainAspectRatio: false,
                                              legend: {
                                                  display: false,
                                                  labels: {
                                                      fontFamily: "Nunito Sans",
                                                      callback: function(label, index, labels) {
                                                          return CyaHelper.ToCurrency(label);
                                                      }
                                                  }
                                              },
                                              scales: {
                                                  yAxes: [{
                                                      gridLines: {
                                                          display: true
                                                      },
                                                      ticks: {
                                                          fontFamily: "Nunito Sans",
                                                          callback: function(label, index, labels) {
                                                              return CyaHelper.ToCurrency(label);
                                                          }
                                                      }
                                                  }],
                                                  xAxes: [{
                                                      gridLines: {
                                                          display: false
                                                      },
                                                      ticks: {
                                                          fontFamily: "Nunito Sans",
                                                          callback: function(label, index, labels) {
                                                              return label.substr(0,10);
                                                          }
                                                      }
                                                  }]
                                              }
                                          }} />
                                </div>
                            </div>
                        </Col>
                    </Row>
                </CardBody>
                <CardBody className="border-top">
                    <Row className="mb-0">
                        <Col lg="3" md="6">
                            <Statistics
                                textColor="cyan"
                                icon="fas fa-edit"
                                title="Cheques"
                                subtitle={CyaHelper.ToCurrency(this.props.data.checks)}
                            />
                        </Col>
                        <Col lg="3" md="6">
                            <Statistics
                                textColor="orange"
                                icon="mdi mdi-file-multiple"
                                title="Gastos"
                                subtitle={CyaHelper.ToCurrency(this.props.data.expenses)}
                            />
                        </Col>
                        <Col lg="3" md="6">
                            <Statistics
                                textColor="success"
                                icon="fas fa-hand-holding-usd"
                                title="Inversionistas"
                                subtitle={CyaHelper.ToCurrency(this.props.data.investors)}
                            />
                        </Col>
                        <Col lg="3" md="6">
                            <Statistics
                                textColor="info"
                                icon="mdi mdi-checkbox-marked-outline"
                                title="Validaciones"
                                subtitle={CyaHelper.ToCurrency(this.props.data.validations)}
                            />
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        );
    }
}

ProjectSummary.propTypes = {
    data: PropTypes.object
};
