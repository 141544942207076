import React from 'react';
import "react-table/react-table.css";
import {Card, CardTitle, CardBody, Row, Col, Form,} from 'reactstrap';
import {LoadingPage} from "../../components/custom/views/LoadingPage";
import {InputForm} from "../../components/custom/inputs/InputForm";
import {toast} from "react-toastify";
import AuthService from "../../utils/AuthService";
import FieldValidator from "../../utils/field-validators/FieldValidator";
import CyaHelper from "../../utils/CyaHelper";
import {CyaContext} from "../../utils/CyaContext";
import {NotaryModel} from "../../models/NotaryModel";
import NotaryApiClient from "../../utils/api-client/NotaryApiClient";
import NotaryFieldValidator from "../../utils/field-validators/NotaryFieldValidator";

export default class CreateNotary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: new NotaryModel(),
            initialData: new NotaryModel(),
            loading: true,
            title: "Crear Notario",
            modelCreated: "Notario Guardado.",
            modelUpdated: "Notario Actualizado.",
            modelError: "Error en Notario."
        };
        this.Auth = new AuthService();
        this.NotaryApiClient = new NotaryApiClient();
        this.Validator = new FieldValidator(NotaryFieldValidator);

        this.editPromise = this.editPromise.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.validSaveButton = this.validSaveButton.bind(this);
    }

    componentDidMount() {
        if (this.props.location.state !== undefined && this.props.location.state?.id) {
            this.NotaryApiClient.GetById(this.props.location.state.id).then(response => {
                this.editPromise(response);
            });
        } else {
            this.setState({loading: false});
        }
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if (!nextState.loading && CyaHelper.ObjectCompare(nextState.data, this.state.data))
            this.Validator.initValidation(nextState.data);

        return true;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!this.state.loading){
            let isFormDirty = !CyaHelper.ObjectCompare(this.state.initialData, this.state.data);
            this.context.toggleExitConfirmation(isFormDirty);
        }
    }

    editPromise(response) {
        this.setState({
            initialData: JSON.parse(JSON.stringify(response)), //copy data variable
            data: response,
            title: "Editar notario",
            loading: false
        });
    }


    onInputChange(event) {
        let aux = this.state.data;
        aux[event.target.name] = event.target.value;
        this.setState({data: aux});
    }

    validSaveButton(){
        return (this.Auth.GetPathPermission(this.props.location.pathname, true) && this.Validator.validForm());
    }

    onSubmit(event) {
        event.preventDefault();
        this.setState({loading: true});
        if (this.state.data.notaryId > 0) {
            this.NotaryApiClient.Update(this.state.data.notaryId, this.state.data)
                .then(response => {
                    if (response.response !== undefined && response.response.data === "Error") {
                        this.setState({loading: false});
                        toast.error(this.state.modelError);
                    } else {
                        this.editPromise(response);
                        toast.success(this.state.modelUpdated);
                    }
                })
                .catch(() => {
                    this.setState({loading: false});
                    toast.error(this.state.modelError);
                });
        }
        else {
            this.NotaryApiClient.Create(this.state.data)
                .then(response => {
                    if (response.response !== undefined && response.response.data === "Error") {
                        this.setState({loading: false});
                        toast.error(this.state.modelError);
                    }else {
                        this.editPromise(response);
                        toast.success(this.state.modelUpdated);
                    }
                })
                .catch(() => {
                    this.setState({loading: false});
                    toast.error(this.state.modelError);
                });
        }
        window.scrollTo(0, 0);
    }

    render() {
        return <div className="auth-wrapper d-flex">
            <div className="container">
                <Card>
                    <CardTitle className="mb-0 p-3 border-bottom bg-light"><i
                        className="mdi mdi-border-right mr-2"/>{this.state.title}
                    </CardTitle>
                    <CardBody>
                        <LoadingPage color={"3e5569"} loading={this.state.loading} type={"spin"}/>
                        <Row>
                            <Col md="12">
                                <Form>
                                    <Row>
                                        <Col md={12}>
                                            <InputForm
                                                id_label={"name"}
                                                label={"Nombre:"}
                                                placeholder={""}
                                                type={"text"}
                                                value={this.state.data.name}
                                                onInputChange={this.onInputChange}
                                            />
                                            {this.Validator.showErrors("name")}
                                        </Col>

                                    </Row>

                                    <Row>
                                        <Col md="6">
                                            <InputForm
                                                id_label={"number"}
                                                label={"Número:"}
                                                placeholder={""}
                                                type={"text"}
                                                value={this.state.data.number}
                                                onInputChange={this.onInputChange}
                                            />
                                            {this.Validator.showErrors("number")}
                                        </Col>
                                        <Col md="6">
                                            <InputForm
                                                id_label={"id"}
                                                label={"ID:"}
                                                placeholder={""}
                                                type={"text"}
                                                value={this.state.data.id}
                                                onInputChange={this.onInputChange}
                                            />

                                        </Col>
                                    </Row>

                                    <Row>

                                        <Col md={12}>
                                            <InputForm
                                                id_label={"address"}
                                                label={"Dirección:"}
                                                placeholder={""}
                                                type={"text"}
                                                value={this.state.data.address}
                                                onInputChange={this.onInputChange}
                                            />

                                        </Col>
                                    </Row>
                                </Form>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <hr/>
                                <input type="submit"
                                       disabled={!(this.validSaveButton())}
                                       className={!(this.validSaveButton()) ? 'btn btn-primary disabled' : 'btn btn-primary'}
                                       value="Guardar"
                                       onClick={this.onSubmit}/>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </div>
        </div>
    }
}
CreateNotary.contextType = CyaContext;
