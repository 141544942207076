import React from "react";
import {Card, CardBody, Col, Progress, Row} from 'reactstrap';
import img1 from 'assets/images/background/active-bg.png';
import PropTypes from "prop-types";

export default class AnnexStageDistribution extends React.Component {
	render() {

		return this.props.data.length > 0 ? <Card className="card-hover">
			<CardBody style={{background: `url(${img1}) no-repeat top center`}}>
				<div className="pt-3 text-center">
					<i className="mdi mdi-file-chart display-4 text-success d-block"/>
					<span
						className="display-4 d-block font-medium">{this.props.data[0] + this.props.data[1] + this.props.data[2]}</span>
					<span>Total de anexos</span>
					<Progress multi className="mt-3">
						<Progress bar color="purple" value={this.props.data[0]}/>
						<Progress bar color="cyan" value={this.props.data[1]}/>
						<Progress bar color="orange" value={this.props.data[2]}/>
					</Progress>
					<Row className="mt-4 mb-3">
						<Col xs="4" md="12" lg="4" className="border-right text-left">
							<h4 className="mb-0 font-medium">{this.props.data[0]}</h4>Pre-Producción
						</Col>
						<Col xs="4" md="12" lg="4" className="border-right text-md-left">
							<h4 className="mb-0 font-medium">{this.props.data[1]}</h4>Producción
						</Col>
						<Col xs="4" md="12" lg="4" className="text-right text-md-left">
							<h4 className="mb-0 font-medium">{this.props.data[2]}</h4>Post-Producción
						</Col>
					</Row>
				</div>
			</CardBody>
		</Card> : null;
	}
}

AnnexStageDistribution.propTypes = {
    data: PropTypes.array
};