import {ImageModel} from "./ImageModel";

export class InvestorModel{
    investorId = 0;
    mainInvestorId = 0;
    name="";
    rnc="";
    checkNumber="";
    checkDate= new Date().toISOString();
    depositDate= new Date().toISOString();
    fiscalYearEnd= new Date().toISOString();
    amount=0;
    checkAmount=0;
    validationId=0;
    imageId;
    image = new ImageModel();
    blocked = false;
}
