const ProjectInvestorFieldValidator = {
    mainInvestorId: {
        secondValue: null,
        rules: [
            {
                test: (value) => value > 0,
                message: 'Seleccionar un Inversionista'
            }
        ],
        errors: [],
        valid: false,
        state: ''
    },
    name: {
        secondValue: null,
        rules: [
            {
                test: (value) => value.length > 0,
                message: 'Campo en blanco'
            },
            {
               test: (value) => value.length <= 150,
               message: 'Cantidad máxima de carácteres excedidos'
            }
        ],
        errors: [],
        valid: false,
        state: ''
    },
    rnc: {
        secondValue: null,
        rules: [
            {
                test: (value) => value.length > 0,
                message: 'Campo en blanco'
            },
            {
               test: (value) => value.length <= 20,
               message: 'Cantidad máxima de carácteres excedidos'
            }
        ],
        errors: [],
        valid: false,
        state: ''
    },
    checkNumber: {
        secondValue: null,
        rules: [
            {
                test: (value) => value.length > 0,
                message: 'Campo en blanco'
            },
            {
               test: (value) => value.length <= 10,
               message: 'Cantidad máxima de carácteres excedidos'
            }
        ],
        errors: [],
        valid: false,
        state: ''
    },
    checkAmount: {
        secondValue: null,
        rules: [
            {
                test: (value) => value > 0,
                message: 'Importe no puede ser cero'
            }
        ],
        errors: [],
        valid: false,
        state: 0
    },
    amount: {
        secondValue: "checkAmount",
        rules: [
            {
                test: (value) => value >0,
                message: 'Importe utilizado no puede ser cero'
            },
            {
                test: (value, checkAmount) => checkAmount >= value,
                message: 'Importe utilizado debe ser menor o igual que el Importe'
            }
        ],
        errors: [],
        valid: false,
        state: 0
    },
};

export default ProjectInvestorFieldValidator;
