import React from 'react';

import {
    Card,
    CardBody,
    CardTitle,
    CardSubtitle,
    Table
} from 'reactstrap';
import ProgressBar from "../charts/ProgressBar";
import PropTypes from "prop-types";
import CyaHelper from "../../../utils/CyaHelper";

export default class CategoryExecutionTable extends React.Component {

    render() {
        return <div>
            <Card>
                <CardBody>
                    <CardTitle>{this.props.title}</CardTitle>
                    <CardSubtitle>{this.props.subtitle}</CardSubtitle>
                    <div className="mt-3">
                        <Table className="mb-3" bordered responsive>
                            <thead>
                                <tr className="bg-light">
                                    <th>Nombre</th>
                                    <th>Monto</th>
                                    <th>Ejecutado</th>
                                    <th>% Ejecutado</th>
                                </tr>
                            </thead>
                            <tbody>
                            {this.props.data !== undefined ?
                                this.props.data.map((x, index) => {
                                return <tr key={index}>
                                    <td className="no-wrap">
                                        <h4>{x.name}</h4>
                                        <h5 className="text-muted">{x.subname}</h5>
                                    </td>
                                    <td>
                                        <h5 className="text-muted">{CyaHelper.ToCurrency(x.total)}</h5>
                                    </td>
                                    <td>
                                        <h5 className="text-muted">{CyaHelper.ToCurrency(x.executed)}</h5>
                                    </td>
                                    <td>
                                        <ProgressBar percent={x.executedPercent}/>
                                    </td>
                                </tr>
                            }) : ""}
                            </tbody>
                        </Table>
                    </div>
                </CardBody>
            </Card>
        </div>
    }
}

CategoryExecutionTable.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    data: PropTypes.array
};

CategoryExecutionTable.defaultProps = {
    title: "",
    subtitle: "",
    data: [],
};