import CyaApiClient from "./CyaApiClient";

export default class PayRequesterApiClient extends CyaApiClient {
   constructor() {
      super("payrequester");
   }

   Validate(link_code) {
      return this.MakeCall(
         "get",
         this.ControllerName + `/validate?linkCode=${link_code}`
      );
   }

   Authenticate(dto) {
      return this.MakeCall("post", this.ControllerName + `/authenticate`, dto);
   }
}
