import React from "react";
import "react-table/react-table.css";
import "react-confirm-alert/src/react-confirm-alert.css";
import {
   Row,
   Col,
   Modal,
   ModalHeader,
   ModalBody,
   Label,
   InputGroup,
   Input,
} from "reactstrap";
import { LoadingPage } from "../../components/custom/views/LoadingPage";
import { toast } from "react-toastify";
import { CyaTableLayout } from "../../components/custom/tables/CyaTableLayout";
import AuthService from "../../utils/AuthService";
import { InputForm } from "../../components/custom/inputs/InputForm";
import { DateFormat, SuccessResponse } from "../../const/api_consts";
import Datetime from "react-datetime";
import { YesOrNoModal } from "../../components/custom/views/YesOrNoModal";
import { TaxRecordModel } from "../../models/TaxRecordModel";
import TaxRecordApiClient from "../../utils/api-client/TaxRecordApiClient";
import moment from "moment";
import readXlsxFile from "read-excel-file/index";
import MaskedInput from "react-text-mask";
const momentMsDate = require("moment-msdate");

export default class TaxRecord extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         data: new TaxRecordModel(),
         loading: true,
         taxRecordList: [],
         selected: null,
         selectedRowRnc: "",
         selectedRowData: {},
         confirmToggle: false,
         modalOpened: false,
         isNew: false,
         modalText: "Crear nuevo",
         modelCreated: "Registro Guardado",
         modelUpdated: "Registro Actualizado",
         modelError: "Error en Registro",
         fileName: "Seleccione un archivo",
         openWarningModal: false,
         errors: [],
         massSaveData: [],
      };
      this.TaxRecordApiClient = new TaxRecordApiClient();
      this.Auth = new AuthService();
      this.rncValidator = /^(\d{3}-\d{7}-\d{1})$|^(\d{1}-\d{2}-\d{5}-\d{1})$/;
   }

   componentDidMount() {
      this.TaxRecordApiClient.GetAll()
         .then((response) => {
            this.setState({ taxRecordList: response.data, loading: false });
         })
         .catch(() => {
            this.setState({ loading: false });
            toast.error(this.state.modelError);
         });
   }

   addCallback = () => {
      this.setState({ isNew: true });
      this.toggleModal();
   };

   deleteCallback = () => {
      this.setState({ confirmToggle: true });
   };

   deleteTaxRecord = (e, confirm) => {
      e.preventDefault();
      if (confirm) {
         this.setState({ loading: true });
         if (this.state.selectedRowRnc.length > 0) {
            this.TaxRecordApiClient.Delete(this.state.selectedRowRnc)
               .then((response) => {
                  if (response !== SuccessResponse) {
                     toast.error("Error eliminado registro.");
                  } else {
                     this.props.history.push({
                        pathname: "taxrecord",
                     });
                     toast.success("Registro eliminado.");
                  }
                  this.setState({ loading: false });
                  this.toggleConfirmation();
               })
               .catch(() => {
                  toast.warn("Error eliminado registros");
               });
         }
      } else {
         this.toggleConfirmation();
      }
   };

   viewCallback = () => {
      this.toggleModal();
      this.setState({ data: Object.assign({}, this.state.selectedRowData) });
   };

   GetTrProps = (state, rowInfo) => {
      if (rowInfo && rowInfo.row) {
         return {
            onClick: () => {
               this.setState({
                  selected: rowInfo.index,
                  selectedRowRnc: rowInfo.row.rnc,
                  selectedRowData: this.state.taxRecordList.find(
                     (x) => x.rnc === rowInfo.row.rnc
                  ),
               });
            },
            onDoubleClick: () => {
               this.toggleModal();
               this.setState({
                  data: Object.assign({}, this.state.selectedRowData),
               });
            },
            style: {
               background:
                  rowInfo.index === this.state.selected
                     ? "#00AFEC"
                     : rowInfo.index % 2 === 0
                     ? "#DDDDDD"
                     : "#FFFFFF",
               color: rowInfo.index === this.state.selected ? "white" : "black",
            },
         };
      }
      return {};
   };

   onInputChange = (event) => {
      let data = this.state.data;
      data[event.target.name] = event.target.value;
      this.setState({ data });
   };

   onDateChange = (moment) => {
      let data = this.state.data;
      data.expirationDate = moment.format("YYYY-MM-DD");
      this.setState({
         data,
      });
   };

   toggleModal = (clean = false) => {
      this.setState({ modalOpened: !this.state.modalOpened });
      if (this.state.modalOpened || clean) {
         this.cleanDataAndTable();
      }
   };

   toggleWarningModal = () => {
      this.setState({ openWarningModal: !this.state.openWarningModal });
   };

   toggleConfirmation = () => {
      this.setState({ confirmToggle: !this.state.confirmToggle });
      if (this.state.confirmToggle) {
         this.cleanDataAndTable();
      }
   };

   cleanDataAndTable = () => {
      this.setState({
         data: new TaxRecordModel(),
         selected: null,
         selectedRowRnc: "",
         selectedRowData: {},
         isNew: false,
         fileName: "Seleccione un archivo",
         massSaveData: [],
      });
   };

   validSaveButton = () => {
      return (
         this.Auth.GetPathPermission(this.props.location.pathname, true) &&
         this.state.data.rnc.length > 0 &&
         this.state.data.number.length > 0 &&
         moment(this.state.data.expirationDate).diff(moment(), "days") > 0
      );
   };

   onMassSubmit = (event) => {
      event.preventDefault();

      this.setState({ loading: true });
      const data = this.state.massSaveData.length;

      if (!data > 0) return;

      this.TaxRecordApiClient.MassSave(this.state.massSaveData)
         .then((response) => {
            if (response !== "Success") {
               this.setState({ loading: false });
               toast.error(this.state.modelError);
               return;
            }
            toast.success(this.state.modelUpdated);
            this.TaxRecordApiClient.GetAll()
               .then((res) => {
                  this.setState({
                     taxRecordList: res.data,
                     loading: false,
                  });

                  this.toggleModal();
               })
               .catch(() => {
                  this.setState({ loading: false });
                  toast.error(this.state.modelError);
               });
         })
         .catch(() => {
            this.setState({ loading: false });
            toast.error(this.state.modelError);
         });
   };

   onSubmit = (event) => {
      event.preventDefault();

      this.setState({ loading: true });

      this.TaxRecordApiClient.Create(this.state.data)
         .then((response) => {
            if (
               response.response !== undefined &&
               response.response.data === "Error"
            ) {
               this.setState({ loading: false });
               toast.error(this.state.modelError);
            } else {
               toast.success(this.state.modelUpdated);
               this.toggleModal(true);
            }
         })
         .then(() => {
            this.TaxRecordApiClient.GetAll()
               .then((response) => {
                  this.setState({
                     taxRecordList: response.data,
                     loading: false,
                  });
               })
               .catch(() => {
                  this.setState({ loading: false });
                  toast.error(this.state.modelError);
               });
         });

      window.scrollTo(0, 0);
   };

   validateExcelFile = async (file) => {
      if (!file) return null;

      try {
         this.setState({ loading: true });
         let errors = [];
         let rows = await readXlsxFile(file);

         if (rows[0][3] !== "cya") {
            this.setState({ loading: false });
            return {
               data: [],
               errors: [
                  {
                     row: "Documento inválido",
                     message: [
                        "Utilizar documento oficial para realizar esta operación",
                     ],
                  },
               ],
            };
         }

         const chunk = rows.slice(2);

         const data = chunk.map((obj, index) => {
            const rnc = String(obj[0]).trim();
            const number = obj[1] != null && String(obj[1]).trim();
            const expirationDate =
               typeof obj[2] === "number"
                  ? momentMsDate.fromOADate(obj[2])
                  : moment(obj[2]);

            const rncValid = this.rncValidator.test(rnc);
            const numberValid = number != null && number.length > 0;
            const expirationDateValid =
               expirationDate.diff(moment(), "days") > 0;

            let errorMessages = [];
            if (!rncValid) {
               errorMessages.push(
                  "Rnc con formato incorrecto, colocar un RNC o Cédula válidas"
               );
            }
            if (!numberValid) {
               errorMessages.push(
                  "Número de registro con formato incorrecto, celda no puede estar en blanco"
               );
            }
            if (!expirationDateValid) {
               errorMessages.push(
                  expirationDate.isValid()
                     ? "Fecha de vencimiento no puede ser menor a la fecha actual"
                     : "Fecha de vencimiento Inválida, formato adecuado: YYYY-MM-DD"
               );
            }

            if (errorMessages.length > 0) {
               errors.push({
                  row: index + 3,
                  message: errorMessages,
               });
            }

            return {
               rnc,
               number,
               expirationDate: expirationDate.format("YYYY-MM-DD"),
            };
         });

         this.setState({ loading: false });

         return { data, errors };
      } catch (error) {
         console.error(error);
         this.setState({ loading: false });
      }
   };

   fileChange = (event) => {
      const file = event.target.files[0];

      if (file !== undefined) {
         this.validateExcelFile(file).then((response) => {
            if (response.errors.length > 0) {
               this.setState({ errors: response.errors });
               this.toggleWarningModal();
               this.toggleModal();
               return;
            }

            if (response.data.length > 0) {
               this.setState({
                  massSaveData: response.data,
                  fileName: file.name,
               });
            }
         });
      }
   };

   render() {
      const taxRecordColumns = [
         {
            Header: "RNC",
            accessor: "rnc",
            style: { textAlign: "center" },
         },
         {
            Header: "Número",
            accessor: "number",
            style: { textAlign: "center" },
         },
         {
            Header: "Fecha de Vencimiento",
            accessor: "expirationDate",
            style: { textAlign: "center" },
            Cell: (props) =>
               props.value ? moment(props.value).format(DateFormat) : "",
         },
      ];
      return (
         <div>
            <YesOrNoModal
               modalOpen={this.state.confirmToggle}
               closeDeleteModal={this.toggleConfirmation}
               message={"¿Desea Eliminar el siguiente Registro?"}
               body={this.state.selectedRowData.number}
               confirmation={(e, value) => this.deleteTaxRecord(e, value)}
            />

            <YesOrNoModal
               modalOpen={this.state.openWarningModal}
               closeDeleteModal={this.toggleWarningModal}
               message={"Error importando archivo excel"}
               confirmation={false}
            >
               <div>
                  {this.state.errors.map((item, index) => (
                     <Row key={index}>
                        <Col>
                           <label className="text-danger">
                              {item.row > 0 ? "Fila " + item.row : item.row}
                           </label>
                           <ul>
                              {item.message.map((message, i) => (
                                 <li key={"R" + index + "-" + i}>{message}</li>
                              ))}
                           </ul>
                        </Col>
                     </Row>
                  ))}
               </div>
            </YesOrNoModal>
            <LoadingPage
               color={"3e5569"}
               loading={this.state.loading}
               type={"spin"}
            />
            <Modal
               isOpen={this.state.modalOpened}
               toggle={this.toggleModal}
               size="lg"
            >
               <ModalHeader toggle={this.toggleModal}>
                  {this.state.isNew ? "Guardar" : "Editar"} Registro Fiscal
               </ModalHeader>
               <ModalBody>
                  <Row>
                     <Col>
                        <label htmlFor={"rnc"}>{"RNC:"}</label>
                        <MaskedInput
                           className="form-control"
                           showMask={true}
                           mask={(value) => {
                              return value.length < 13
                                 ? [
                                      /\d/,
                                      "-",
                                      /\d/,
                                      /\d/,
                                      "-",
                                      /\d/,
                                      /\d/,
                                      /\d/,
                                      /\d/,
                                      /\d/,
                                      "-",
                                      /\d/,
                                   ]
                                 : [
                                      /\d/,
                                      /\d/,
                                      /\d/,
                                      "-",
                                      /\d/,
                                      /\d/,
                                      /\d/,
                                      /\d/,
                                      /\d/,
                                      /\d/,
                                      /\d/,
                                      "-",
                                      /\d/,
                                   ];
                           }}
                           name={"rnc"}
                           id={"rnc"}
                           onChange={this.onInputChange}
                           placeholder="RNC o Cédula"
                           guide={false}
                           value={this.state.data.rnc}
                        />
                     </Col>

                     <Col>
                        <InputForm
                           id_label={"number"}
                           label={"Número:"}
                           placeholder={"Número"}
                           type={"text"}
                           value={this.state.data.number}
                           onInputChange={this.onInputChange}
                        />
                     </Col>

                     <Col>
                        <Label htmlFor={"date"}>Fecha de Vencimiento: </Label>
                        <Datetime
                           locale={"es-do"}
                           inputProps={{
                              readOnly: true,
                              style: { backgroundColor: "transparent" },
                           }}
                           utc={true}
                           timeFormat={false}
                           dateFormat={DateFormat}
                           closeOnSelect={true}
                           onChange={this.onDateChange}
                           value={moment(this.state.data.expirationDate).format(
                              DateFormat
                           )}
                           renderInput={(props) => {
                              return (
                                 <input
                                    {...props}
                                    value={
                                       this.state.data.expirationDate
                                          ? props.value
                                          : "Fecha de Vencimiento"
                                    }
                                 />
                              );
                           }}
                        />
                     </Col>
                  </Row>
                  {this.state.isNew && (
                     <div>
                        <hr />
                        <Row>
                           <Col md={12}>
                              <label htmlFor={"file"}>
                                 {"Importar registros fiscales por Excel:"}
                              </label>
                           </Col>
                        </Row>
                        <Row>
                           <Col md={9}>
                              <InputGroup>
                                 <div className="custom-file">
                                    <Input
                                       type="file"
                                       className="custom-file-input"
                                       name={"file"}
                                       id={"file"}
                                       onChange={this.fileChange}
                                       accept={
                                          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                       }
                                    />
                                    <label
                                       style={{ overflow: "hidden" }}
                                       className="custom-file-label"
                                       htmlFor={"file"}
                                    >
                                       {this.state.fileName}
                                    </label>
                                 </div>
                              </InputGroup>
                           </Col>
                           <Col>
                              <a
                                 className={"btn btn-info"}
                                 href={this.TaxRecordApiClient.DownloadTemplate()}
                              >
                                 Descargar Plantilla
                              </a>
                           </Col>
                        </Row>
                     </div>
                  )}

                  <hr />
                  <Row>
                     <Col md={6}>
                        <input
                           type="submit"
                           disabled={!this.validSaveButton()}
                           className={
                              !this.validSaveButton()
                                 ? "btn btn-success disabled"
                                 : "btn btn-success"
                           }
                           value="Guardar"
                           onClick={this.onSubmit}
                        />
                     </Col>
                     {this.state.isNew && (
                        <Col className="d-flex justify-content-end" md={6}>
                           <input
                              type="submit"
                              disabled={!this.state.massSaveData.length > 0}
                              className={
                                 this.state.massSaveData.length > 0
                                    ? "btn btn-success"
                                    : "btn btn-success disabled"
                              }
                              value="Guardar Masivamente"
                              onClick={this.onMassSubmit}
                           />
                        </Col>
                     )}
                  </Row>
               </ModalBody>
            </Modal>
            <CyaTableLayout
               title={"Importación de registros fiscales para suplidores"}
               data={this.state.taxRecordList}
               columns={taxRecordColumns}
               loading={this.state.loading}
               trProps={this.GetTrProps}
               enableButtons={this.state.selectedRowRnc.length > 0}
               viewCallback={this.viewCallback}
               viewButtonText={"Editar"}
               massButtonText={"Anadir"}
               defaultPageSize={7}
               enableMassButtonDefault={true}
               multipleActionsButton={true}
               multipleActionsCallback={this.addCallback}
               showDeleteButton={this.Auth.GetPathPermission(
                  this.props.location.pathname + "/delete",
                  false
               )}
               deleteCallback={this.deleteCallback}
            />
         </div>
      );
   }
}
