import React from "react";
import ReactDOM from "react-dom";
import "./assets/scss/style.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Blanklayout from "./layouts/blanklayout";
import Fulllayout from "./layouts/fulllayout";
import { loginPage, preDashboardUrl } from "./const/api_consts";
import DashboardReducer from "../src/redux/reducers";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { PayRequestLayout } from "./layouts/PayRequestLayout";

const store = createStore(DashboardReducer, applyMiddleware(thunk));
/*
if (process.env.NODE_ENV || process.env.NODE_ENV !== "development") {
    Sentry.init({
        dsn: "https://65d8abdea7cf4c709fc03f85dc0a37da@o468681.ingest.sentry.io/5496916",
        integrations: [new Integrations.BrowserTracing()],
        tracesSampleRate: 1.0,
    });
}*/

ReactDOM.render(
   <Provider store={store}>
      <Router>
         <Switch>
            <Route
               path={loginPage}
               key="Authentication"
               component={Blanklayout}
            />
            <Route
               path={preDashboardUrl}
               key="Projects"
               component={Blanklayout}
            />
            <Route
               path="/payrequest"
               key="PayRequest"
               component={PayRequestLayout}
            />
            <Route path="/" key="Dashboard" component={Fulllayout} />
         </Switch>
      </Router>
   </Provider>,
   document.getElementById("root")
);
