import React from 'react';
import "react-table/react-table.css";
import Moment from "moment";
import {DateFormat} from "../../const/api_consts";
import CyaHelper from "../../utils/CyaHelper";
import {CyaTableLayout} from "../../components/custom/tables/CyaTableLayout";
import ProjectInvestorApiClient from "../../utils/api-client/ProjectInvestorApiClient";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'
import {toast} from "react-toastify";
import AuthService from "../../utils/AuthService"; // Import css

export default class ProjectInvestor extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            loading: true,
            selectedRowId: -1,
            selected: -1
        };
        this.InvestorApiClient = new ProjectInvestorApiClient();
        this.Auth = new AuthService();

    }

    componentDidMount(){
        this.InvestorApiClient.GetAll().then(response => this.setState({data: response.data, loading: false}));
    }

    GetProps = (state, rowInfo)=> {
        if (rowInfo && rowInfo.row) {
            return {
                onClick: (e) => {
                    this.setState({
                        selected: rowInfo.index,
                        selectedRowId: rowInfo.row.investorId
                    });
                },
                onDoubleClick: (e) => {
                    if (this.state.selected === rowInfo.index) {
                        this.props.history.push({
                            pathname: '/investors/edit',
                            state: {id: rowInfo.row.investorId}
                        });
                    }
                },
                style: {
                    background: rowInfo.index === this.state.selected ? '#00AFEC' : rowInfo.index%2 === 0 ? '#DDDDDD' : '#FFFFFF',
                    color: rowInfo.index === this.state.selected ? 'white' : 'black'
                }
            }
        }
        return {};
    }

    viewCallback = () => {
        if (this.state.selectedRowId >= 0) {
            this.props.history.push({
                pathname: '/investors/edit',
                state: {id: this.state.selectedRowId}
            });
        }
    };

    deleteCallback = () => {
        let investor = this.state.data.find(x => x.investorId === this.state.selectedRowId);
        confirmAlert({
            customUI: ({onClose}) => {
                return (
                    <div className='react-confirm-alert-body'>
                        <h1>Confirmación</h1>
                        <p>¿Está seguro de borrar este inversionista?</p>
                        <h6>Nombre. : {investor.name }</h6>
                        <h6>RNC : {investor.rnc }</h6>
                        <div className={"react-confirm-alert-button-group"}>
                            <button onClick={() => {
                                if (this.state.selectedRowId >= 0) {
                                    this.InvestorApiClient.Delete(this.state.selectedRowId).then((response) => {
                                        if (response.response!==undefined){
                                            if (response.response.status === 400) {
                                                toast.error("Error eliminado Inversionista.");
                                                this.setState({loading: false});
                                            }
                                            if (response.response.status === 200) {
                                                this.setState({loading: true});
                                                this.InvestorApiClient.GetAll().then(response => this.setState({data: response.data, loading: false}));
                                                toast.success("Inversionista eliminado.");
                                            }
                                        }else {
                                            this.setState({loading: true});
                                            this.InvestorApiClient.GetAll().then(response => this.setState({data: response.data, loading: false}));
                                            toast.success("Inversionista eliminado.");
                                        }

                                    });
                                }
                                onClose();
                            }}
                            >Si
                            </button>
                            <button  onClick={onClose}>No</button>
                        </div>
                    </div>
                )
            },
        });
    };

    render() {
        const {data} = this.state;
        let columns = [
            {Header: "InvestorId", accessor: "investorId", show: false},
            {Header: "Nombre", accessor: "name", minWidth: 250},
            {Header: "RNC", accessor: "rnc", width: 120},
            {
                Header: "Fin Año Fiscal ", style: {textAlign: 'center'}, id: "fiscalYearEnd",
                accessor: d => {
                    return d.fiscalYearEnd?Moment(d.fiscalYearEnd).local().format("YYYY"):''
                }, width: 100
            },
            {
                Header: "Bloqueado", id: "block", style: {textAlign: 'center'}, accessor: "",
                Cell: props => {
                    return (
                        <input
                            type="checkbox"
                            className="checkbox"
                            readOnly={true}
                            checked={props.original.blocked === true}
                        />
                    );
                }, width: 100
            },
            {
                Header: "Img", id: "img", style: {textAlign: 'center'}, accessor: "",
                Cell: props => {
                    return (
                        <input
                            type="checkbox"
                            className="checkbox"
                            readOnly={true}
                            checked={props.original.image === true}
                        />
                    );
                }, width: 100
            },
            {Header: "Cheques", accessor: "checks", style: {textAlign: 'right'}, width: 80},
            {Header: "Validación", accessor: "validationValidationNumber", style: {textAlign: 'right'}, width: 50},
            {Header: "No. Cheque", accessor: "checkNumber", width: 100},
            {
                Header: "Fecha Cheque", id: "checkDate", style: {textAlign: 'center'}, accessor: d => {
                    return Moment(d.checkDate).local().format(DateFormat)
                }, width: 120
            },
            {
                Header: "Fecha para Validación", id: "depositDate", style: {textAlign: 'center'}, accessor: d => {
                    return Moment(d.depositDate).local().format(DateFormat)
                }, width: 120
            },
            {
                Header: "Monto",
                accessor: "checkAmount",
                style: {textAlign: 'right'},
                Cell: props => CyaHelper.ToCurrency(props.value),
                width: 150
            },
            {
                Header: "Monto Usado",
                accessor: "amount",
                style: {textAlign: 'right'},
                Cell: props => CyaHelper.ToCurrency(props.value),
                width: 150
            }
        ];
        return <CyaTableLayout title={"Inversionistas"} columns={columns} data={data}
                               loading={this.state.loading}
                               trProps={this.GetProps}
                               deleteCallback={this.deleteCallback}
                               enableButtons={this.state.selectedRowId >= 0}
                               viewCallback={this.viewCallback}
                               showDeleteButton={this.Auth.GetPathPermission(this.props.location.pathname+"/delete",false)}
        />;
    }
}
