import axios from 'axios';
import {ApiURL} from "../../const/api_consts";
import AuthService from "../AuthService";

export default class CyaReadApiClient extends AuthService {

    constructor(controllerName) {
        super();
        this.ControllerName = controllerName;
        this.AxiosCaller = axios.create({
            baseURL: ApiURL + "/",
            timeout: 60000,
            headers: {Authorization: 'Bearer ' + this.GetToken()}
        });
    }

    async MakeCall(method, url, data = null) {
        let response=null;
        let dataGet=null;
        let dataPost=null;
        switch(method)
        {
            case "post":
                dataPost = data;
                break;
            case "put":
                dataGet = data.id != null ? {id: data.id} : {[Object.keys(data)[0]] : Object.values(data)[0]} ;
                dataPost = data.dto;
                break;
            default:
                dataGet = data;
                break;
        }
        await this.AxiosCaller({
            method: method,
            url: url,
            data: dataPost,
            params: dataGet
        }).then(res => {response = res.data})
            .catch(error => {
                response = error;
                if(typeof(error.response) !== "undefined" && typeof(error.response.status) !== "undefined"){
                    this.CheckStatus(error.response.status);
                }
                return Promise.reject(error.response)
            });
        return response;
    }

    CheckStatus(status) {
        if (status === 401){
            this.Logout();
            window.location.reload();
        }
    }
}
