import { ApiURL } from "../../const/api_consts";
import CyaApiClient from "./CyaApiClient";

export default class TaxRecordApiClient extends CyaApiClient {
   constructor() {
      super("TaxRecord");
   }

   DownloadTemplate() {
      return `${ApiURL}/${this.ControllerName}/downloadTemplate`;
   }

   MassSave(taxRecordCollection) {
      return this.MakeCall("post", this.ControllerName + "/massSave", {
         data: taxRecordCollection,
      });
   }

   Update(rnc, dto) {
      return this.MakeCall("put", this.ControllerName, { rnc, dto });
   }

   Delete(rnc) {
      return this.MakeCall("delete", this.ControllerName, { rnc });
   }
}
