import React from 'react';
import "react-table/react-table.css";
import {CyaTableLayout} from "../../components/custom/tables/CyaTableLayout";
import AuthService from "../../utils/AuthService";
// import ValidationApiClient from "../../utils/ApiClient/ValidationApiClient";
import ProducerApiClient from "../../utils/api-client/ProducerApiClient";
import {preDashboardUrl, SuccessResponse} from "../../const/api_consts";
import {toast} from "react-toastify";
import {Row, Col,} from 'reactstrap';
import Modal from "react-modal";


export default class Producer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            selected: null,
            loading: true,
            selectedRowId: -1,
            selectedRowData: {},
            modalDelete:false,
        };
        this.Auth = new AuthService();
        this.ProducerApiClient = new ProducerApiClient();

        this.tProps = this.tProps.bind(this);
        this.deleteModel = this.deleteModel.bind(this);
        this.closeDeleteModal = this.closeDeleteModal.bind(this);

    }

    componentDidMount(){
        this.ProducerApiClient.GetAll().then(response => {
            this.setState({data: response.data, loading: false});
            }
        );
    }

    viewCallback = () => {
        if (this.state.selectedRowId >= 0) {
            this.props.history.push({
                pathname: preDashboardUrl+'/producers/edit',
                state: {id: this.state.selectedRowId}
            });
        }
    };

    deleteCallback = () => {
        if (this.state.selectedRowId > 0) {
            this.setState({modalDelete: true});
        }
    };

    closeDeleteModal() {
        if (this.state.modalDelete)
            this.setState({modalDelete: false});
    }

    deleteModel(){
        this.setState({loading: true});
        if (this.state.selectedRowId > 0) {
            this.ProducerApiClient.Delete(this.state.selectedRowId).then((response) => {
                if (response !== SuccessResponse) {
                    toast.error("Error eliminando productora.");
                } else {
                    this.props.history.push({pathname: '/refresh'+preDashboardUrl+'/producers'});
                    toast.success("Productora eliminada.");
                }
            }).catch(() => {
                toast.warn("Error eliminando productora.");
            });
        }
        this.setState({loading: false});
        this.closeDeleteModal();
    }

    tProps(state, rowInfo) {
        if (rowInfo && rowInfo.row) {
            return {
                onClick: () => {
                    this.setState({
                        selected: rowInfo.index,
                        selectedRowId: rowInfo.row.producerId,
                        selectedRowData: this.state.data.find(x => x.producerId === rowInfo.row.producerId)
                    });
                },
                onDoubleClick: () => {
                    if (this.state.selected === rowInfo.index) {
                        this.props.history.push({
                            pathname: preDashboardUrl+'/producers/edit',
                            state: {id: rowInfo.row.producerId}
                        });
                    }
                },
                style: {
                    background: rowInfo.index === this.state.selected ? '#00AFEC' : rowInfo.index % 2 === 0 ? '#DDDDDD' : '#FFFFFF',
                    color: rowInfo.index === this.state.selected ? 'white' : 'black'
                }
            }
        }
        return {};
    }

    render() {
        let self = this;
        let columns = [
            {Header: "ID", accessor: "producerId", show: false},
            {Header: "Nombre", accessor: "name", width: 300},
            {Header: "RNC", accessor: "rnc", width: 190},
            {Header: "Dirección", accessor: "address", width: 400},
            {Header: "Ciudad", accessor: "cityName"},
        ];
        const customStyles = {
            content: {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)'
            }
        };
        Modal.setAppElement('body');

        return <div className="auth-wrapper d-flex">
            <Modal
                isOpen={this.state.modalDelete}
                onRequestClose={this.closeDeleteModal}
                style={customStyles}
            >
                <Row>
                    <Col md={10}><h4>Confirmación</h4></Col>
                    <Col md={2} style={{textAlign: 'right'}}>
                        <button className={"btn btn-danger"} style={{textAlign: 'right'}}
                                onClick={this.closeDeleteModal}>X
                        </button>
                    </Col>
                </Row>
                <hr/>
                <h5>¿Desea eliminar la siguiente productora? </h5>
                <ul>
                    {self.state.selectedRowId > 0 ?
                        <li key={0}>{' (' +this.state.selectedRowData.rnc +') ' + this.state.selectedRowData.name }</li>
                        : ""}
                </ul>
                <Row>
                    <Col md={12}>
                        <button className={"btn btn-danger float-right"} onClick={this.deleteModel}>Eliminar
                        </button>
                    </Col>
                </Row>

            </Modal>
            <div className="container">
                <div>
                    <CyaTableLayout
                        title={"Productoras"}
                        data={this.state.data}
                        columns={columns}
                        loading={this.state.loading}
                        trProps={this.tProps}
                        viewCallback={this.viewCallback}
                        deleteCallback={this.deleteCallback}
                        enableButtons={this.state.selectedRowId >= 0}
                        showDeleteButton={this.Auth.GetPathPermission(this.props.location.pathname+"/delete",false)}
                    />

                </div>
            </div>
        </div>
    }
}
