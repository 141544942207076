import React from "react";

export const NotFound = () => {
   return (
      <div
         style={{
            display: "grid",
            placeItems: "center",
            color: "white",
         }}
      >
         <h2>404 not found</h2>
      </div>
   );
};
