import { ProducerDocumentModel } from "./ProducerDocumentModel";
import {
   ProducerDocTypeACP,
   ProducerDocTypeCommRegister,
   ProducerDocTypeDGII,
   ProducerDocTypeRNC,
} from "../const/api_consts";

export class ProducerModel {
   constructor() {
      this.producerDocument.push(
         new ProducerDocumentModel(ProducerDocTypeRNC),
         new ProducerDocumentModel(ProducerDocTypeACP),
         new ProducerDocumentModel(ProducerDocTypeCommRegister),
         new ProducerDocumentModel(ProducerDocTypeDGII)
      );
   }
   producerId = 0;
   name = "";
   rnc = "";
   address = "";
   cityId = 1;
   taxRecordNumber = "";
   taxRecordExpirationDate = "";
   producerDocument = [];
   producerRepresentative = [];
}
