import React from 'react';
import {
    FormGroup,
    Label,
    Input,
    InputGroup,
    Button,
    InputGroupAddon
} from 'reactstrap';
import PropTypes from 'prop-types'
import {EmailShareButton, WhatsappShareButton} from "react-share";

export class InputFileUpload extends React.Component {
    render() {
        return <FormGroup>
            {this.props.label === "" ? "" : <Label> {this.props.label}</Label>}

            <InputGroup>
                <div className="custom-file" >
                    <Input type="file" className="custom-file-input"
                           name={this.props.id_label}
                           id={this.props.id_label}
                           onChange={this.props.onInputChange}
                           accept={this.props.accept}
                           multiple={this.props.multiple}/>
                    <label style={{overflow: "hidden"}} className="custom-file-label" htmlFor={this.props.id_label}>{this.props.filename}</label>
                </div>
                <InputGroupAddon addonType="append">
                    <Button className="btn" onClick={this.props.onDownloadClick} color="info"><i
                        className="fa fa-download"/></Button>{' '}
                    <Button className="btn" onClick={this.props.onPrintClick} color="dark"><i
                        className="fa fa-print"/></Button>{' '}
                    <Button className="btn" onClick={this.props.rotateImage} color="primary"><i
                        className="fa fa-redo-alt"/></Button>{' '}
                    {this.props.showViewButton?
                        <Button className="btn" onClick={this.props.onViewClick} color="light"><i
                            className="fa fa-eye"/></Button>
                        :""}
                    {this.props.showMailButton?
                    <EmailShareButton
                        url={this.props.imageContent}
                        // type= {'image/png'}
                        subject={"subject"}
                        body="Enviado desde CyA WebApp"
                        className="btn btn-warning"
                        >
                        <i className="fa fa-envelope"/>
                    </EmailShareButton>
                    :""}
                    {/*<Button className="btn" onClick={() => this.props.onClick('mail')} color="warning"><i*/}
                        {/*className="fa fa-envelope"/></Button>{' '}*/}
                    {this.props.showWhatsappButton?
                    <WhatsappShareButton
                        url={"https://www.google.com"}
                        title={"Title Whaatsapp"}
                        pic={this.props.filename}
                        className="btn btn-success"
                        onClick={() => this.props.onClick('whatsapp')}
                    >
                        <i className="fab fa-whatsapp"/>
                    </WhatsappShareButton>
                    :""}
                    {/*<Button className="btn" onClick={() => this.props.onClick('whatsapp')} color="success"><i*/}
                        {/*className="fab fa-whatsapp"/>*/}

                    {/*</Button>{' '}*/}
                </InputGroupAddon>
            </InputGroup>
        </FormGroup>
    }
}

InputFileUpload.propTypes = {
    onInputChange: PropTypes.func,
    onViewClick: PropTypes.func,
    onDownloadClick: PropTypes.func,
    label: PropTypes.string,
    id_label: PropTypes.string,
    filename: PropTypes.string,
    imageContent: PropTypes.string,
    accept: PropTypes.string,
    showMailButton: PropTypes.bool,
    showWhatsappButton: PropTypes.bool,
    showViewButton: PropTypes.bool,
    multiple: PropTypes.bool,
    onPrintClick: PropTypes.func,
};
InputFileUpload.defaultProps = {
    multiple: false,
};
