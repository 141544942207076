const ItemFieldValidator = {
    code: {
        secondValue: null,
        rules: [
            {
                test: (value) => value.length > 0,
                message: 'Campo en blanco'
            }
        ],
        errors: [],
        valid: false,
        state: ''
    },
    name: {
        secondValue: null,
        rules: [
            {
                test: (value) => value.length > 0,
                message: 'Campo en blanco'
            }
        ],
        errors: [],
        valid: false,
        state: ''
    },
    subtotal: {
        secondValue: "isGrandson",
        rules: [
            {
                test: (value, isGrandson) => isGrandson ? value > 0 : true,
                message: 'Subtotal no puede ser cero'
            },
        ],
        errors: [],
        valid: true,
        state: 0
    }
};

export default ItemFieldValidator;