import React from 'react';
import {Route, Switch, Redirect, Prompt} from 'react-router-dom';
import Header from '../components/header/header.jsx';
import Sidebar from '../components/sidebar/sidebar.jsx';
import Footer from '../components/footer/footer.jsx';
// import Customizer from '../components/customizer/customizer';
import ThemeRoutes from '../routes/routing.jsx';
import AuthService from "../utils/AuthService";
import {ToastContainer} from 'react-toastify';
import {dashboardPage, loginPage, preDashboardUrl, projectsPage, UnsavedChangesMsg} from "../const/api_consts";
import {CyaContext} from "../utils/CyaContext";
import ProjectApiClient from "../utils/api-client/ProjectApiClient";

const Refresh = ({path = '/'}) => (
    <Route
        path={path}
        component={({history, location, match}) => {
            history.replace({
                ...location,
                pathname: location.pathname.substring(match.path.length)
            });
            return null;
        }}
    />
);

export default class Fulllayout extends React.Component {
    constructor(props) {
        super(props);
        this.updateDimensions = this.updateDimensions.bind(this);
        this.toggleExitConfirmation = (confirmExit) => {
            // if (this.state.context.confirmExit!==confirmExit){
            //     this.setState(prevState => ({ context: { ...prevState.context, confirmExit: confirmExit } }));
            // }
        };
        this.ProjectApiClient = new ProjectApiClient();

        this.state = {
            isOpen: false,
            width: window.innerWidth,
            settings: [{
                theme: 'light',
                layout: 'horizontal',
                dir: 'ltr',
                sidebartype: 'full',
                sidebarpos: 'fixed',
                headerpos: 'fixed',
                boxed: 'full',
                navbarbg: 'skin1',
                sidebarbg: 'skin6',
                logobg: 'skin1',
                viewBtnChangeProject: true,
            }],
            context: {
                confirmExit: false,
                toggleExitConfirmation: this.toggleExitConfirmation,
                authService: new AuthService(),
                projectId: this.ProjectApiClient.GetProject().projectId,
            },
        };
        this.Auth = new AuthService();

        this.beforeUnLoad = this.beforeUnLoad.bind(this);
        this.props.history.listen((location, action) => {
            if (window.innerWidth < 767 &&
                document.getElementById('main-wrapper').className.indexOf("show-sidebar") !== -1) {
                document.getElementById('main-wrapper').classList.toggle("show-sidebar");
            }
        });

    }

    /*--------------------------------------------------------------------------------*/
    /*Life Cycle Hook, Applies when loading or resizing App                           */

    /*--------------------------------------------------------------------------------*/
    componentDidMount() {
        window.addEventListener("load", this.updateDimensions);
        window.addEventListener("resize", this.updateDimensions);
        window.addEventListener('beforeunload',this.beforeUnLoad);
    }

    beforeUnLoad(event){
        if (this.state.context.confirmExit)
            event.returnValue = `Are you sure you want to leave?`;
    }

    /*--------------------------------------------------------------------------------*/
    /*Function that handles sidebar, changes when resizing App                        */
    /*--------------------------------------------------------------------------------*/
    updateDimensions() {
        let element = document.getElementById('main-wrapper');
        this.setState({
            width: window.innerWidth
        });
        switch (this.state.settings[0].sidebartype) {
            case 'full':
            case 'iconbar':
                if (this.state.width < 1170) {
                    element.setAttribute("data-sidebartype", "mini-sidebar");
                    element.classList.add("mini-sidebar");
                } else {
                    element.setAttribute("data-sidebartype", this.state.settings[0].sidebartype);
                    element.classList.remove("mini-sidebar");
                }
                break;

            case 'overlay':
                if (this.state.width < 767) {
                    element.setAttribute("data-sidebartype", "mini-sidebar");
                } else {
                    element.setAttribute("data-sidebartype", this.state.settings[0].sidebartype);
                }
                break;

            default:
        }
        // if (this.state.settings[0].sidebarpos === "fixed") {
        //     document.getElementById("sidebar-position").setAttribute("checked", "");
        // }
        // if (this.state.settings[0].headerpos === "fixed") {
        //     document.getElementById("header-position").setAttribute("checked", "");
        // }
        // if (this.state.settings[0].theme === "dark") {
        //     document.getElementById("theme-view").setAttribute("checked", "");
        // }
        // if (this.state.settings[0].boxed === "boxed") {
        //     document.getElementById("boxed-layout").setAttribute("checked", "");
        // }
    }

    /*--------------------------------------------------------------------------------*/
    /*Life Cycle Hook                                                                 */

    /*--------------------------------------------------------------------------------*/
    componentWillUnmount() {
        window.removeEventListener("load", this.updateDimensions);
        window.removeEventListener("resize", this.updateDimensions);
        window.removeEventListener('beforeunload',this.beforeUnLoad);
    }

    componentDidUpdate(prevProps,prevState,snapshot) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            this.setState(prevState => ({ context: { ...prevState.context, confirmExit: false } }));
        }
    }

    /*--------------------------------------------------------------------------------*/
    /*Theme Setting && Changes default(LIGHT) THEME to DARK COLOR:-                   */
    /*--------------------------------------------------------------------------------*/
    darkTheme = (a) => {
        if (a.target.checked) {
            let darktheme = JSON.parse(JSON.stringify(this.state.settings));
            darktheme[0].theme = 'dark';
            this.setState({settings: darktheme});
        } else {
            let lighttheme = JSON.parse(JSON.stringify(this.state.settings));
            lighttheme[0].theme = 'light';
            this.setState({settings: lighttheme});
        }
    };
    /*--------------------------------------------------------------------------------*/
    /*Theme Setting && Changes Default(FULL) LAYOUT to BOXED LAYOUT                   */
    /*--------------------------------------------------------------------------------*/
    boxedTheme = (b) => {
        if (b.target.checked) {
            let boxtheme = JSON.parse(JSON.stringify(this.state.settings));
            boxtheme[0].boxed = 'boxed';
            this.setState({settings: boxtheme});
        } else {
            let fulltheme = JSON.parse(JSON.stringify(this.state.settings));
            fulltheme[0].boxed = 'full';
            this.setState({settings: fulltheme});
        }
    };
    /*--------------------------------------------------------------------------------*/
    /*Theme Setting && Changes Default(FIXED) POSITION of HEADER to ABSOLUTE POSITION */
    /*--------------------------------------------------------------------------------*/
    headerPosition = (c) => {
        if (c.target.checked) {
            let fixedpos = JSON.parse(JSON.stringify(this.state.settings));
            fixedpos[0].headerpos = 'fixed';
            this.setState({settings: fixedpos});
        } else {
            let absolutepos = JSON.parse(JSON.stringify(this.state.settings));
            absolutepos[0].headerpos = 'absolute';
            this.setState({settings: absolutepos});
        }
    };
    /*--------------------------------------------------------------------------------*/
    /*Theme Setting && Changes Default(FIXED) POSITION of SIDEBAR to ABSOLUTE POSITION*/
    /*--------------------------------------------------------------------------------*/
    sidebarPosition = (d) => {
        if (d.target.checked) {
            let sidebarfixedpos = JSON.parse(JSON.stringify(this.state.settings));
            sidebarfixedpos[0].sidebarpos = 'fixed';
            this.setState({settings: sidebarfixedpos});
        } else {
            let sidebarabsolutepos = JSON.parse(JSON.stringify(this.state.settings));
            sidebarabsolutepos[0].sidebarpos = 'absolute';
            this.setState({settings: sidebarabsolutepos});
        }
    };
    /*--------------------------------------------------------------------------------*/
    /*Theme Setting && Changes NAVBAR BACKGROUND-COLOR from given options             */
    /*--------------------------------------------------------------------------------*/
    navbarbgChange = (e) => {
        let navskin = e.currentTarget.dataset.navbarbg;
        let newsettings = JSON.parse(JSON.stringify(this.state.settings));
        newsettings[0].navbarbg = navskin;
        this.setState({
            settings: newsettings
        })
    };
    /*--------------------------------------------------------------------------------*/
    /*Theme Setting && Changes SIDEBAR-MENU BACKGROUND-COLOR from given options       */
    /*--------------------------------------------------------------------------------*/
    sidebarbgChange = (f) => {
        let sidebarskin = f.currentTarget.dataset.sidebarbg;
        let newsettings = JSON.parse(JSON.stringify(this.state.settings));
        newsettings[0].sidebarbg = sidebarskin;
        this.setState({
            settings: newsettings
        })
    };
    /*--------------------------------------------------------------------------------*/
    /*Theme Setting && Changes LOGO BACKGROUND-COLOR from given options               */
    /*--------------------------------------------------------------------------------*/
    logobgChange = (g) => {
        let logoskin = g.currentTarget.dataset.logobg;
        let newsettings = JSON.parse(JSON.stringify(this.state.settings));
        newsettings[0].logobg = logoskin;
        this.setState({
            settings: newsettings
        })
    };


    render() {

        if (this.Auth.LoggedIn()) {
            if ((String(this.props.history.location.pathname).indexOf(preDashboardUrl)>-1
                || String(this.props.history.location.pathname).indexOf(loginPage)>-1)
                && !this.Auth.ReturnToProjectsPage()){
                return <Redirect to={dashboardPage} />;
            }
            if ((String(this.props.history.location.pathname).indexOf(preDashboardUrl)<0
                || String(this.props.history.location.pathname).indexOf(loginPage)>-1)
                && this.Auth.ReturnToProjectsPage()){
                return <Redirect to={projectsPage} />;
            }
        }
        else {
            if (String(this.props.history.location.pathname).indexOf(loginPage)<0)
                return <Redirect to={loginPage} />;
        }

        /*--------------------------------------------------------------------------------*/
        /* Theme Setting && Layout Options wiil be Change From Here                       */
        /*--------------------------------------------------------------------------------*/
        return (
            <CyaContext.Provider value={this.state.context}>
                <Prompt when={this.state.context.confirmExit} message={UnsavedChangesMsg} />
            <div
                id="main-wrapper"
                dir={this.state.settings[0].dir}
                data-theme={this.state.settings[0].theme}
                data-layout={this.state.settings[0].layout}
                data-sidebartype={this.state.settings[0].sidebartype}
                data-sidebar-position={this.state.settings[0].sidebarpos}
                data-header-position={this.state.settings[0].headerpos}
                data-boxed-layout={this.state.settings[0].boxed}
            >
                <ToastContainer/>
                {/*--------------------------------------------------------------------------------*/}
                {/* Header                                                                         */}
                {/*--------------------------------------------------------------------------------*/}
                <Header data={this.state} history={this.props.history}/>
                {/*--------------------------------------------------------------------------------*/}
                {/* Sidebar                                                                        */}
                {/*--------------------------------------------------------------------------------*/}
                <Sidebar data={this.state} {...this.props} routes={ThemeRoutes}/>
                {/*--------------------------------------------------------------------------------*/}
                {/* Page Main-Content                                                              */}
                {/*--------------------------------------------------------------------------------*/}
                <div className="page-wrapper d-block">
                    <div className="page-content container-fluid">
                        <Switch>
                            <Refresh path="/refresh"/>
                            {ThemeRoutes.map((prop, key) => {
                                if (!this.Auth.GetPathPermission(prop.path, false)) return null;

                                if (prop.navlabel) {
                                    return null;
                                }
                                else if (prop.collapse) {
                                    return prop.child.map((prop2, key2) => {
                                        if (!this.Auth.GetPathPermission(prop2.path, false)) return null;
                                        if (prop2.collapse) {
                                            return prop2.subchild.map((prop3, key3) => {
                                                if (!this.Auth.GetPathPermission(prop3.path, false)) return null;
                                                return (
                                                    <Route path={prop3.path} exact component={prop3.component}
                                                           key={key3}/>
                                                );
                                            });
                                        }
                                        return (
                                            <Route path={prop2.path} exact component={prop2.component} key={key2}/>
                                        );
                                    });
                                }
                                else if (prop.redirect) {
                                    return (<Redirect from={prop.path} to={prop.pathTo} key={key}/>);
                                }
                                else {
                                    return (
                                        <Route path={prop.path} exact component={prop.component} key={key}/>
                                    );
                                }
                            })}
                        </Switch>
                    </div>
                    <Footer/>
                </div>
                {/*--------------------------------------------------------------------------------*/}
                {/* Customizer from which you can set all the Layout Settings                      */}
                {/*--------------------------------------------------------------------------------*/}
                {/*<Customizer*/}
                    {/*darkTheme={this.darkTheme}*/}
                    {/*boxedTheme={this.boxedTheme}*/}
                    {/*rtl={this.rtl}*/}
                    {/*headerPosition={this.headerPosition}*/}
                    {/*sidebarPosition={this.sidebarPosition}*/}
                    {/*navbarbgChange={this.navbarbgChange}*/}
                    {/*sidebarbgChange={this.sidebarbgChange}*/}
                    {/*logobgChange={this.logobgChange}*/}
                {/*/>*/}
            </div>
            </CyaContext.Provider>
        );
    }
}


