import 'rc-progress/assets/index.css';
import React, { Component } from 'react';
import { Line } from 'rc-progress';
import PropTypes from "prop-types";
import CyaHelper from "../../../utils/CyaHelper";

const firstColor = "#F62D51";
const lastColor = "#2DCE89";

export default class ProgressBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            percent: 0,
            color: firstColor
        };
        this.increase = this.increase.bind(this);
    }

    componentDidMount() {
        this.increase();
    }

    increase() {
        const percent = this.state.percent + 1 >= this.props.percent ? this.props.percent : this.state.percent + 1;
        const color = CyaHelper.ColorGradient(firstColor, lastColor, percent);

        this.setState({
            percent: percent,
            color: color
        });
        if (percent >= this.props.percent) {
            clearTimeout(this.tm);
            return;
        }
        this.tm = setTimeout(this.increase, 10);
    }

    render() {
        return (
            <div style={{ margin: "0 auto", width: "100%", textAlign: "center" }}>
                <Line strokeWidth="12" percent={this.state.percent} strokeColor={this.state.color} trailColor={"#999999"} />
                <strong style={{position: "relative", top: "-25px", color: "black"}}>{this.state.percent}%</strong>
            </div>
        );
    }
}

ProgressBar.propTypes = {
    percent: PropTypes.number
};