import React from 'react';
import "react-table/react-table.css";
import {
    Card,
    CardTitle,
    CardBody,
    Row,
    Col,
    Form,
    Label,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    FormGroup,
} from 'reactstrap';
import {LoadingPage} from "../../components/custom/views/LoadingPage";
import {InputForm} from "../../components/custom/inputs/InputForm";
import {toast} from "react-toastify";
import AuthService from "../../utils/AuthService";
import {InvestorDbModel} from "../../models/InvestorDbModel";
import Datetime from "react-datetime";
import {
    DateFormat, InvestorDBDocTypeCommRegister, InvestorDBDocTypePayrollAndAssembly,
    InvestorDBDocTypeRNC, InvestorDBDocTypeSocialStatuses,
} from "../../const/api_consts";
import classnames from 'classnames';
import {SimpleTable} from "../../components/custom/tables/SimpleTable";
import Modal from "react-modal";
import PersonApiClient from "../../utils/api-client/PersonApiClient";
import {InputSelect} from "../../components/custom/inputs/InputSelect";
import {InputImage} from "../../components/custom/inputs/InputImage";
import CyaHelper from "../../utils/CyaHelper";
import {InputSelectForm} from "../../components/custom/inputs/InputSelectForm";
import {InvestorRepresentativeModel} from "../../models/InvestorRepresentativeModel";
import MaskedInput from "react-text-mask";
import {ImageModel} from "../../models/ImageModel";
import {InvestorDocumentModel} from "../../models/InvestorDocumentModel";
import InvestorApiClient from "../../utils/api-client/InvestorApiClient";
import FieldValidator from "../../utils/field-validators/FieldValidator";
import InvestorFieldValidator from "../../utils/field-validators/InvestorFieldValidator";
import {CyaContext} from "../../utils/CyaContext";
import moment from "moment";
import Compress from "compress.js";
import md5 from "md5";

export default class CreateInvestor extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            data: new InvestorDbModel(),
            initialData: new InvestorDbModel(),
            dataRepresentative: new InvestorRepresentativeModel(),
            options: {gender: [], city: [], idType: []},
            representativesData: [],
            civilStatusRepresentativeData: [],
            prefixRepresentativeData: [],
            representativeMask: [],
            mask: [],
            loading: true,
            activeTab: '1',
            selected: null,
            selectedRowId: -1,
            selectedRowData: {},
            modalRepresentatives: false,
            title: "Crear Inversionista",
            modelCreated: "Inversionista Guardado",
            modelUpdated: "Inversionista Actualizado",
            modelError: "Error en Inversionista",
            modalRepresentativesTitle: 'Crear Representante',
            isFormDirty: false,
        };

        this.Auth = new AuthService();
        this.Validator = new FieldValidator(InvestorFieldValidator);
        this.PersonApiClient = new PersonApiClient();
        this.InvestorDbApiClient = new InvestorApiClient();

        this.editPromise = this.editPromise.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.toggle = this.toggle.bind(this);
        this.validSaveButton = this.validSaveButton.bind(this);
        this.tProps = this.tProps.bind(this);
        this.onDateChange = this.onDateChange.bind(this);
        this.submitRepresentative = this.submitRepresentative.bind(this);
        this.validEditButton = this.validEditButton.bind(this);
        this.addRepresentativesModal = this.addRepresentativesModal.bind(this);
        this.getValueFromDocument = this.getValueFromDocument.bind(this);
        this.deleteRepresentativeRow = this.deleteRepresentativeRow.bind(this);
        this.changeRepresentativeGenderOptions = this.changeRepresentativeGenderOptions.bind(this);

        this.closeRepresentativesModal = this.closeRepresentativesModal.bind(this);
        this.openRepresentativesModal = this.openRepresentativesModal.bind(this);
        this.changeRepresentativeMask = this.changeRepresentativeMask.bind(this);
        this.onRepresentativeInputChange = this.onRepresentativeInputChange.bind(this);
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if (!nextState.loading && CyaHelper.ObjectCompare(nextState.data, this.state.data))
            this.Validator.initValidation(nextState.data);

        return true;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!this.state.loading) {
            let isFormDirty = !CyaHelper.ObjectCompare(this.state.initialData, this.state.data);
            this.context.toggleExitConfirmation(isFormDirty);
        }
    }

    componentDidMount() {
        this.PersonApiClient.Options().then(response => {
            let isMale = this.state.data.genderId === 1;
            this.setState({
                options: response,
                prefixRepresentativeData: response.prefix.filter(x => {
                    return x.isMale === isMale;
                }),
                civilStatusRepresentativeData: response.civilStatus.filter(x => {
                    return x.isMale === isMale;
                }),
            });
        }).then(() => {
            if (this.props.location.state !== undefined && this.props.location.state?.id) {
                this.InvestorDbApiClient.GetById(this.props.location.state.id).then(response => {
                    this.editPromise(response);
                });
            } else {
                this.setState({
                    loading: false
                });
            }
        });

    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    closeRepresentativesModal() {
        if (this.state.modalRepresentatives)
            this.setState({
                modalRepresentatives: false,
                selected: null,
                selectedRowId: -1,
                selectedRowData: {},
                dataRepresentative: new InvestorRepresentativeModel()
            });
    }

    addRepresentativesModal() {
        this.changeRepresentativeMask(1);
        this.changeRepresentativeGenderOptions(1);
        this.setState({
            dataRepresentative: new InvestorRepresentativeModel(),
            selected: null,
            selectedRowId: -1,
            selectedRowData: {},
            modalRepresentatives: true,
            modalRepresentativesTitle: "Crear Representante"
        });
    }

    openRepresentativesModal() {
        if (!this.state.modalRepresentatives && this.state.selectedRowId > -1) {
            this.changeRepresentativeMask(this.state.selectedRowData.idtypeId);
            this.changeRepresentativeGenderOptions(this.state.selectedRowData.genderId);
            this.setState({
                dataRepresentative: this.state.selectedRowData,
                modalRepresentatives: true,
                modalRepresentativesTitle: "Editar Representante"
            });
        }
    }

    tProps(state, rowInfo) {
        let self = this;
        if (rowInfo && rowInfo.row) {
            return {
                onClick: () => {
                    this.setState({
                        selected: rowInfo.index,
                        selectedRowId: rowInfo.index,
                        selectedRowData: this.state.data.investorRepresentative[rowInfo.index]
                    });
                },
                onDoubleClick: () => {
                    self.openRepresentativesModal();
                },
                style: {
                    background: rowInfo.index === this.state.selected ? '#00AFEC' : rowInfo.index % 2 === 0 ? '#DDDDDD' : '#FFFFFF',
                    color: rowInfo.index === this.state.selected ? 'white' : 'black'
                }
            }
        }
        return {};
    }

    changeRepresentativeMask(value) {
        let mask = [];
        switch (Number(value)) {
            case 1:
                mask = [/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/];
                break;
            default:
                mask = [];
                break;
        }
        this.setState({
            representativeMask: mask
        });
    }

    editPromise(response) {

        if (response.investorDocument != null && response.investorDocument.length > 0) {
            response.investorDocument.map(x => {
                if (x.image === null)
                    x.image = new ImageModel();
                return true;
            });
        } else {
            response.investorDocument = [];
            response.investorDocument.push(new InvestorDocumentModel(InvestorDBDocTypeCommRegister), new InvestorDocumentModel(InvestorDBDocTypeRNC), new InvestorDocumentModel(InvestorDBDocTypeSocialStatuses), new InvestorDocumentModel(InvestorDBDocTypePayrollAndAssembly));
        }

        if (response.investorRepresentative != null) {
            response.investorRepresentative.map(x => {
                x.idtypeId = 1;
                if (x.image === null)
                    x.image = new ImageModel();
                return true;
            });
        } else {
            response.investorRepresentative = [];
        }
        response.rnc = response.investorDocument.find(x => x.investorDocumentTypeId === InvestorDBDocTypeRNC).value;

        this.setState({
            initialData: JSON.parse(JSON.stringify(response)), //copy data variable
            data: response,
            title: "Editar Inversionista ",
            loading: false
        });
    }

    changeRepresentativeGenderOptions(genderId) {
        let isMale = Number(genderId) === 1;
        let prefixGender = this.state.options.prefix.filter(x => {
            return x.isMale === isMale;
        });
        let civilStatusGender = this.state.options.civilStatus.filter(x => {
            return x.isMale === isMale;
        });
        this.setState({prefixRepresentativeData: prefixGender, civilStatusRepresentativeData: civilStatusGender});
    }

    onDateChange(moment, dateName, type = -1) {
        let aux = this.state.data;
        if (type !== -1) {
            aux.investorDocument.find(x => x.investorDocumentTypeId === type)[dateName] = moment.format("YYYY-MM-DD");
        } else {
            aux[dateName] = moment.format("YYYY-MM-DD");
        }
        this.setState({data: aux});
    }

    onInputChange(event, isInvestorDocument = false, type = 1, valueName = "") {
        let aux = this.state.data;

        if (isInvestorDocument) {
            aux.investorDocument.find(x => x.investorDocumentTypeId === type)[valueName] = event.target.value;
        } else {
            aux[event.target.name] = event.target.value;
        }

        if (isInvestorDocument && type === InvestorDBDocTypeRNC && valueName === "value") {
            aux.rnc = event.target.value;
        }
        this.setState({data: aux});
    }

    getValueFromDocument(type, value) {
        let res = "";
        let aux = this.state.data.investorDocument.find(x => x.investorDocumentTypeId === type);
        if (aux !== undefined) {
            res = aux[value];
        } else {
            if (value === "expires" || value === "issuedAt") {
                res = new Date();
            }
            if (value === "image") {
                res = new ImageModel();
            }
        }
        return res;
    }

    onRepresentativeInputChange(event) {
        if (!this.state.loading && event.target.name === "genderId" && event.target.value !== this.state.dataRepresentative.genderId) {
            this.changeRepresentativeGenderOptions(event.target.value);
        }
        if (event.target.name === "idtypeId") {
            this.changeRepresentativeMask(event.target.value);
        }
        let aux = this.state.dataRepresentative;
        aux[event.target.name] = event.target.value;
        this.setState({dataRepresentative: aux});
    }

    submitRepresentative(event) {
        event.preventDefault();

        if (this.state.selectedRowId < 0) {
            let data = this.state.data;
            data.investorRepresentative.push(this.state.dataRepresentative);
            this.setState({data});
        }

        this.closeRepresentativesModal();
    }

    deleteRepresentativeRow(event) {
        event.preventDefault();
        let data = this.state.data;
        data.investorRepresentative.splice(this.state.selected, 1);
        this.setState({
            data,
            selected: null,
            selectedRowId: -1,
            selectedRowData: {},
        });
        toast.success("Representante Eliminado");
    }

    validSaveButton() {
        return this.Auth.GetPathPermission(this.props.location.pathname, true);
    }

    validEditButton() {
        return !this.state.modalRepresentatives && this.state.selectedRowId > -1;
    }

    onSubmit(event) {
        event.preventDefault();

        this.setState({loading: true});
        if (this.state.data.investorId > 0) {
            this.InvestorDbApiClient.Update(this.state.data.investorId, this.state.data)
                .then(response => {
                    if (response.response !== undefined && response.response.data === "Error") {
                        this.setState({loading: false});
                        toast.error(this.state.modelError);
                    } else {
                        this.editPromise(response);
                        toast.success(this.state.modelUpdated);
                    }
                })
                .catch(err => {
                    this.setState({loading: false});
                    toast.error(this.state.modelError);
                });
        } else {
            this.InvestorDbApiClient.Create(this.state.data)
                .then(response => {
                    if (response.response !== undefined && response.response.data === "Error") {
                        this.setState({loading: false});
                        toast.error(this.state.modelError);
                    } else {
                        this.editPromise(response);
                        toast.success(this.state.modelUpdated);
                    }
                })
                .catch(() => {
                    this.setState({loading: false});
                    toast.error(this.state.modelError);
                });
        }
        window.scrollTo(0, 0);
    }

    dataURLtoFile = (dataurl, filename) => {
        let arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, {type: mime});
    }

    rotatedImage = async (base64, image) => {
        let dataUpdated = this.state.data
        let fileName = moment().format("YYYY-MM-DD-hh-mm-ss-") + image.imageId + '.jpg'
        let imgFile = this.dataURLtoFile(base64, fileName)
        new Compress().compress([imgFile], {
            size: 1,
            quality: .5
        }).then((results) => {
            const compressImg = results[0];
            dataUpdated.image.fileContent = compressImg.data
            dataUpdated.image.hash = md5(compressImg.data)
            dataUpdated.image.filename = fileName
            dataUpdated.image.imageId = 0
            this.setState({
                data: dataUpdated,
            });
        });
    }


    render() {

        let columns = [
            // {Header: "ID", accessor: "investorRepresentativeId", show: false},
            {Header: "Nombre", accessor: "name"},
            {Header: "Identificación", accessor: "idNumber"},
            {Header: "Ciudad", accessor: "cityId"},
            {Header: "Cargo", accessor: "occupation"},
        ];
        const customStyles = {
            content: {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)',
                width: '65%'
            }
        };
        Modal.setAppElement('body');

        return <div className="auth-wrapper d-flex">
            <Modal
                isOpen={this.state.modalRepresentatives}
                onRequestClose={this.closeRepresentativesModal}
                style={customStyles}
            >
                <Row>
                    <Col md={10}><h4>{this.state.modalRepresentativesTitle}</h4></Col>
                    <Col md={2} style={{textAlign: 'right'}}>
                        <button className={"btn btn-danger"} style={{textAlign: 'right'}}
                                onClick={this.closeRepresentativesModal}>X
                        </button>
                    </Col>
                </Row>

                <hr/>

                <Row>
                    <Col md="12">
                        <Form>
                            <Row>
                                <Col md="6">
                                    <InputForm id_label={"name"} label={"Nombre:"} placeholder={""}
                                               type={"text"} value={this.state.dataRepresentative.name}
                                               onInputChange={this.onRepresentativeInputChange}/>
                                    {this.state.dataRepresentative.name.length > 0 ? "" :
                                        <div className="error mb-2">* Campo en blanco</div>}
                                </Col>
                                <Col md="3">
                                    <InputSelect
                                        id_label={"civilStatusId"}
                                        data={this.state.civilStatusRepresentativeData}
                                        label={"Estado Civil:"}
                                        dataDescription={["name"]}
                                        dataValue={"civilStatusId"}
                                        selectedValue={Number(this.state.dataRepresentative.civilStatusId)}
                                        onInputChange={this.onRepresentativeInputChange}/>
                                </Col>
                                <Col md="3">
                                    <Row>
                                        <Col md="5">
                                            <InputSelect
                                                id_label={"prefixId"}
                                                data={this.state.prefixRepresentativeData}
                                                label={"Prefijo:"}
                                                dataDescription={["description"]}
                                                dataValue={"prefixId"}
                                                selectedValue={Number(this.state.dataRepresentative.prefixId)}
                                                onInputChange={this.onRepresentativeInputChange}/>
                                        </Col>
                                        <Col md="7">
                                            <InputSelect
                                                id_label={"genderId"}
                                                data={this.state.options.gender}
                                                label={"Sexo:"}
                                                dataDescription={["name"]}
                                                dataValue={"genderId"}
                                                selectedValue={Number(this.state.dataRepresentative.genderId)}
                                                onInputChange={this.onRepresentativeInputChange}/>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>

                            <Row>
                                <Col md={12}>
                                    <InputForm
                                        id_label={"address"}
                                        label={"Dirección:"}
                                        placeholder={""}
                                        type={"text"}
                                        value={this.state.dataRepresentative.address}
                                        onInputChange={this.onRepresentativeInputChange}
                                    />

                                </Col>
                            </Row>
                            <Row>
                                <Col md="3">
                                    <InputSelect
                                        id_label={"cityId"}
                                        data={this.state.options.city}
                                        label={"Ciudad:"}
                                        dataDescription={["name"]}
                                        dataValue={"cityId"}
                                        selectedValue={Number(this.state.dataRepresentative.cityId)}
                                        onInputChange={this.onRepresentativeInputChange}/>
                                </Col>
                                <Col md="3">
                                    <InputForm
                                        id_label={"nationality"}
                                        label={"Nacionalidad:"}
                                        placeholder={""}
                                        type={"text"}
                                        value={this.state.dataRepresentative.nationality}
                                        onInputChange={this.onRepresentativeInputChange}
                                    />
                                </Col>
                                <Col md="6">
                                    <InputForm
                                        id_label={"occupation"}
                                        label={"Cargo:"}
                                        placeholder={""}
                                        type={"text"}
                                        value={this.state.dataRepresentative.occupation}
                                        onInputChange={this.onRepresentativeInputChange}
                                    />
                                    {this.state.dataRepresentative.occupation.length > 0 ? "" :
                                        <div className="error mb-2">* Campo en blanco</div>}
                                </Col>

                            </Row>
                            <Row>
                                <Col md={12}>
                                    <Row>
                                        <Col style={{marginTop: "1rem"}} md={12}>
                                            <h5>Cedula/Pasaporte</h5>
                                            <hr style={{marginTop: "0rem"}}/>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="6">
                                            <InputSelectForm
                                                label={"ID:"}
                                                idSelect={"idtypeId"}
                                                idForm={"idNumber"}
                                                formValue={this.state.dataRepresentative.idNumber}
                                                data={this.state.options.idType}
                                                dataValue={"idTypeId"}
                                                dataDescription={"description"}
                                                formType={"text"}
                                                placeholder={""}
                                                selectedValue={Number(this.state.dataRepresentative.idtypeId)}
                                                mask={this.state.representativeMask}
                                                showMask={this.state.showMask}
                                                onInputChange={this.onRepresentativeInputChange}
                                            />
                                            {(Number(this.state.dataRepresentative.idtypeId) > 1 || (Number(this.state.dataRepresentative.idtypeId) === 1 && this.state.dataRepresentative.idNumber.length === 13)) ? "" :
                                                <div className="error mb-2">* Faltan caracteres</div>}
                                            {this.state.dataRepresentative.idNumber.length > 0 ? "" :
                                                <div className="error mb-2">* Campo en blanco</div>}
                                        </Col>
                                        <Col md="6">
                                            {CyaHelper.ImageExists(this.state.dataRepresentative.image) ?
                                                <InputImage id_label={"image"} width={"fit-content"}
                                                            rotatedImage={this.rotatedImage}
                                                            label={"Copia de ID:"}
                                                            image={this.state.dataRepresentative.image}
                                                            hash={this.state.dataRepresentative.image.hash}
                                                            acceptPDF={true}
                                                            showViewButton={true} showImageMagnify={false}/>
                                                :
                                                <div>
                                                    <InputImage id_label={"image"} width={"fit-content"}
                                                                rotatedImage={this.rotatedImage}
                                                                label={"Copia de ID:"}
                                                                image={this.state.dataRepresentative.image}
                                                                acceptPDF={true}
                                                                showViewButton={true} showImageMagnify={false}/>
                                                </div>
                                            }
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <hr/>

                            <Row>
                                <Col md="6">
                                    <input type="submit"
                                           disabled={!(this.validSaveButton() && this.state.dataRepresentative.occupation.length > 0 && this.state.dataRepresentative.name.length > 0 && ((Number(this.state.dataRepresentative.idtypeId) === 1 && this.state.dataRepresentative.idNumber.length === 13) || (Number(this.state.dataRepresentative.idtypeId) > 1 && this.state.dataRepresentative.idNumber.length > 0)))}
                                           className={!(this.validSaveButton()) && this.state.dataRepresentative.occupation.length > 0 && this.state.dataRepresentative.name.length > 0 && ((Number(this.state.dataRepresentative.idtypeId) === 1 && this.state.dataRepresentative.idNumber.length === 13) || (Number(this.state.dataRepresentative.idtypeId) > 1 && this.state.dataRepresentative.idNumber.length > 0)) ? 'btn btn-success disabled' : 'btn btn-success'}
                                           value="Guardar"
                                           onClick={this.submitRepresentative}
                                    />
                                </Col>
                            </Row>

                        </Form>
                    </Col>
                </Row>

            </Modal>

            <div className="container">
                <Card>
                    <CardTitle className="mb-0 p-3 border-bottom bg-light"><i
                        className="fas fa-hand-holding-usd mr-2"/>{this.state.title}
                    </CardTitle>
                    <CardBody className="border-top">
                        <LoadingPage color={"3e5569"} loading={this.state.loading} type={"spin"}/>

                        <Nav tabs>
                            <NavItem>
                                <NavLink className={classnames({active: this.state.activeTab === '1'})} onClick={() => {
                                    this.toggle('1');
                                }}>
                                    <Label>General</Label>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink className={classnames({active: this.state.activeTab === '2'})} onClick={() => {
                                    this.toggle('2');
                                }}>
                                    <Label>Representantes</Label>
                                </NavLink>
                            </NavItem>
                        </Nav>

                        <TabContent activeTab={this.state.activeTab}>
                            <TabPane tabId="1">
                                <Row>
                                    <Col md="12">
                                        <Card body>

                                            <Form>
                                                <Row>
                                                    <Col md="12">
                                                        <InputForm
                                                            id_label={"name"}
                                                            label={"Nombre:"}
                                                            placeholder={""}
                                                            type={"text"}
                                                            value={this.state.data.name}
                                                            onInputChange={this.onInputChange}
                                                        />
                                                        {this.Validator.showErrors("name")}
                                                    </Col>

                                                </Row>

                                                <Row>
                                                    <Col md="6">
                                                        <InputForm
                                                            id_label={"address"}
                                                            label={"Direccion:"}
                                                            placeholder={""}
                                                            type={"text"}
                                                            value={this.state.data.address}
                                                            onInputChange={this.onInputChange}
                                                        />
                                                        {this.Validator.showErrors("address")}
                                                    </Col>
                                                    <Col md="3">
                                                        <InputSelect
                                                            id_label={"cityId"}
                                                            data={this.state.options.city}
                                                            label={"Ciudad:"}
                                                            dataDescription={["name"]}
                                                            dataValue={"cityId"}
                                                            selectedValue={Number(this.state.data.cityId)}
                                                            onInputChange={this.onInputChange}/>
                                                    </Col>
                                                    <Col md="3">
                                                        <Label htmlFor={"fiscalEndYear"}>Fecha Año Fiscal: </Label>
                                                        <Datetime inputProps={{readOnly: true}}
                                                                  locale={"es-do"}
                                                                  utc={true}
                                                                  timeFormat={false}
                                                                  defaultValue={new Date()}
                                                                  dateFormat={DateFormat}
                                                                  closeOnSelect={true}
                                                                  value={new Date(this.state.data.fiscalEndYear)}
                                                                  onChange={moment => this.onDateChange(moment, "fiscalEndYear")}
                                                        />
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col md={12}>
                                                        <Row>
                                                            <Col style={{marginTop: "1rem"}} md={12}>
                                                                <h5>RNC</h5>
                                                                <hr style={{marginTop: "0rem"}}/>
                                                            </Col>
                                                        </Row>

                                                        <Row>
                                                            <Col md={12}>
                                                                <Row>
                                                                    <Col md={4}>
                                                                        <FormGroup>
                                                                            <Label>Número</Label>
                                                                            <MaskedInput className="form-control"
                                                                                         showMask={true}
                                                                                         mask={[/\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/]}
                                                                                         type={"text"}
                                                                                         name={"rncNumber"}
                                                                                         id={"rncNumber"}
                                                                                         onChange={(event) => this.onInputChange(event, true, InvestorDBDocTypeRNC, "value")}
                                                                                         guide={false}
                                                                                         value={this.getValueFromDocument(InvestorDBDocTypeRNC, "value")}/>
                                                                            {this.Validator.showErrors("rnc")}
                                                                        </FormGroup>
                                                                    </Col>

                                                                    <Col md={2}>
                                                                        <Label
                                                                            htmlFor={"rncExpirationDate"}>Vence: </Label>
                                                                        <Datetime inputProps={{readOnly: true}}
                                                                                  locale={"es-do"}
                                                                                  utc={true}
                                                                                  timeFormat={false}
                                                                                  defaultValue={new Date()}
                                                                                  dateFormat={DateFormat}
                                                                                  closeOnSelect={true}
                                                                                  value={new Date(this.getValueFromDocument(InvestorDBDocTypeRNC, "expires"))}
                                                                                  onChange={moment => this.onDateChange(moment, "expires", InvestorDBDocTypeRNC)}
                                                                        />
                                                                    </Col>
                                                                    <Col md={6}>
                                                                        {CyaHelper.ImageExists(this.getValueFromDocument(InvestorDBDocTypeRNC, "image")) ?
                                                                            <InputImage id_label={"rncImage"}
                                                                                        width={"fit-content"}
                                                                                        rotatedImage={this.rotatedImage}
                                                                                        label={"Copia de RNC:"}
                                                                                        acceptPDF={true}
                                                                                        image={this.getValueFromDocument(InvestorDBDocTypeRNC, "image")}
                                                                                        hash={(this.getValueFromDocument(InvestorDBDocTypeRNC, "image")).hash}
                                                                                        showViewButton={true}
                                                                                        showImageMagnify={false}/>
                                                                            :
                                                                            <div>
                                                                                <InputImage id_label={"rncImage"}
                                                                                            width={"fit-content"}
                                                                                            rotatedImage={this.rotatedImage}
                                                                                            label={"Copia de RNC:"}
                                                                                            acceptPDF={true}
                                                                                            image={this.getValueFromDocument(InvestorDBDocTypeRNC, "image")}
                                                                                            showViewButton={true}
                                                                                            showImageMagnify={false}/>
                                                                            </div>
                                                                        }
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>

                                                <Row>

                                                    <Col md={12}>
                                                        <Row>
                                                            <Col style={{marginTop: "1rem"}} md={12}>
                                                                <h5>Registro Mercantil</h5>
                                                                <hr style={{marginTop: "0rem"}}/>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col md={12}>
                                                                <Row>
                                                                    <Col md={4}>
                                                                        <InputForm
                                                                            id_label={"commercialRegisterNumber"}
                                                                            label={"Número:"}
                                                                            placeholder={""}
                                                                            type={"text"}
                                                                            value={this.getValueFromDocument(InvestorDBDocTypeCommRegister, "value")}
                                                                            onInputChange={(event) => this.onInputChange(event, true, InvestorDBDocTypeCommRegister, "value")}
                                                                        />
                                                                    </Col>

                                                                    <Col md={2}>
                                                                        <Label
                                                                            htmlFor={"commercialRegisterExpirationDate"}>Vence: </Label>
                                                                        <Datetime inputProps={{readOnly: true}}
                                                                                  locale={"es-do"}
                                                                                  utc={true}
                                                                                  timeFormat={false}
                                                                                  defaultValue={new Date()}
                                                                                  dateFormat={DateFormat}
                                                                                  closeOnSelect={true}
                                                                                  value={new Date(this.getValueFromDocument(InvestorDBDocTypeCommRegister, "expires"))}
                                                                                  onChange={moment => this.onDateChange(moment, "expires", InvestorDBDocTypeCommRegister)}
                                                                        />
                                                                    </Col>

                                                                    <Col md={6}>
                                                                        {CyaHelper.ImageExists(this.getValueFromDocument(InvestorDBDocTypeCommRegister, "image")) ?
                                                                            <InputImage id_label={"rncImage"}
                                                                                        width={"fit-content"}
                                                                                        rotatedImage={this.rotatedImage}
                                                                                        label={"Copia de Registro Mercantil:"}
                                                                                        acceptPDF={true}
                                                                                        image={this.getValueFromDocument(InvestorDBDocTypeCommRegister, "image")}
                                                                                        hash={(this.getValueFromDocument(InvestorDBDocTypeCommRegister, "image")).hash}
                                                                                        showViewButton={true}
                                                                                        showImageMagnify={false}/>
                                                                            :
                                                                            <div>
                                                                                <InputImage id_label={"rncImage"}
                                                                                            width={"fit-content"}
                                                                                            rotatedImage={this.rotatedImage}
                                                                                            label={"Copia de Registro Mercantil:"}
                                                                                            acceptPDF={true}
                                                                                            image={this.getValueFromDocument(InvestorDBDocTypeCommRegister, "image")}
                                                                                            showViewButton={true}
                                                                                            showImageMagnify={false}/>
                                                                            </div>
                                                                        }
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col md={6}>
                                                        <Row>
                                                            <Col style={{marginTop: "1rem"}} md={12}>
                                                                <h5>Estatutos Sociales</h5>
                                                                <hr style={{marginTop: "0rem"}}/>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col md={12}>
                                                                <Row>
                                                                    <Col md={4}>
                                                                        <Label htmlFor={"assemblyExpirationDate"}>Fecha
                                                                            Expiración: </Label>
                                                                        <Datetime inputProps={{readOnly: true}}
                                                                                  locale={"es-do"}
                                                                                  utc={true}
                                                                                  timeFormat={false}
                                                                                  defaultValue={new Date()}
                                                                                  dateFormat={DateFormat}
                                                                                  closeOnSelect={true}
                                                                                  value={new Date(this.getValueFromDocument(InvestorDBDocTypePayrollAndAssembly, "expires"))}
                                                                                  onChange={moment => this.onDateChange(moment, "expires", InvestorDBDocTypePayrollAndAssembly)}
                                                                        />
                                                                    </Col>
                                                                    <Col md={8}>
                                                                        {CyaHelper.ImageExists(this.getValueFromDocument(InvestorDBDocTypePayrollAndAssembly, "image")) ?
                                                                            <InputImage
                                                                                id_label={"assemblyExpirationImage"}
                                                                                width={"fit-content"}
                                                                                rotatedImage={this.rotatedImage}
                                                                                label={"Copia de Estatutos Sociales:"}
                                                                                acceptPDF={true}
                                                                                image={this.getValueFromDocument(InvestorDBDocTypePayrollAndAssembly, "image")}
                                                                                hash={(this.getValueFromDocument(InvestorDBDocTypePayrollAndAssembly, "image")).hash}
                                                                                showViewButton={true}
                                                                                showImageMagnify={false}/>
                                                                            :
                                                                            <div>
                                                                                <InputImage
                                                                                    id_label={"assemblyExpirationImage"}
                                                                                    width={"fit-content"}
                                                                                    rotatedImage={this.rotatedImage}
                                                                                    label={"Copia de Estatutos Sociales:"}
                                                                                    acceptPDF={true}
                                                                                    image={this.getValueFromDocument(InvestorDBDocTypePayrollAndAssembly, "image")}
                                                                                    showViewButton={true}
                                                                                    showImageMagnify={false}/>
                                                                            </div>
                                                                        }

                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                    </Col>

                                                    <Col md={6}>
                                                        <Row>
                                                            <Col style={{marginTop: "1rem"}} md={12}>
                                                                <h5>Nómina y Asamblea</h5>
                                                                <hr style={{marginTop: "0rem"}}/>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col md={12}>
                                                                <Row>
                                                                    <Col md={4}>
                                                                        <Label htmlFor={"bylawsExpirationDate"}>Fecha
                                                                            Expiración: </Label>
                                                                        <Datetime inputProps={{readOnly: true}}
                                                                                  locale={"es-do"}
                                                                                  utc={true}
                                                                                  timeFormat={false}
                                                                                  defaultValue={new Date()}
                                                                                  dateFormat={DateFormat}
                                                                                  closeOnSelect={true}
                                                                                  value={new Date(this.getValueFromDocument(InvestorDBDocTypeSocialStatuses, "expires"))}
                                                                                  onChange={moment => this.onDateChange(moment, "expires", InvestorDBDocTypeSocialStatuses)}
                                                                        />
                                                                    </Col>
                                                                    <Col md={8}>
                                                                        {CyaHelper.ImageExists(this.getValueFromDocument(InvestorDBDocTypeSocialStatuses, "image")) ?
                                                                            <InputImage id_label={"rncImage"}
                                                                                        width={"fit-content"}
                                                                                        rotatedImage={this.rotatedImage}
                                                                                        label={"Copia de Nómina y Asamblea:"}
                                                                                        acceptPDF={true}
                                                                                        image={this.getValueFromDocument(InvestorDBDocTypeSocialStatuses, "image")}
                                                                                        hash={(this.getValueFromDocument(InvestorDBDocTypeSocialStatuses, "image")).hash}
                                                                                        showViewButton={true}
                                                                                        showImageMagnify={false}/>
                                                                            :
                                                                            <div>
                                                                                <InputImage id_label={"rncImage"}
                                                                                            width={"fit-content"}
                                                                                            rotatedImage={this.rotatedImage}
                                                                                            label={"Copia de Nómina y Asamblea:"}
                                                                                            acceptPDF={true}
                                                                                            image={this.getValueFromDocument(InvestorDBDocTypeSocialStatuses, "image")}
                                                                                            showViewButton={true}
                                                                                            showImageMagnify={false}/>
                                                                            </div>
                                                                        }
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            </Form>

                                        </Card>
                                    </Col>
                                </Row>
                            </TabPane>
                            <TabPane tabId="2">
                                <Row>
                                    <Col sm="12">
                                        <Card body>
                                            <SimpleTable data={this.state.data.investorRepresentative} columns={columns}
                                                         trProps={this.tProps}
                                                         loading={this.state.loading} defaultPageSize={5}/>
                                            <br/>
                                            <Row>
                                                <Col md="6">
                                                    <input type="submit"
                                                           disabled={!(this.validSaveButton())}
                                                           className={!(this.validSaveButton()) ? 'btn btn-success disabled' : 'btn btn-success'}
                                                           value="Añadir"
                                                           onClick={this.addRepresentativesModal}
                                                    />{' '}
                                                    <input type="submit"
                                                           disabled={!(this.validEditButton())}
                                                           className={!(this.validEditButton()) ? 'btn btn-warning disabled' : 'btn btn-warning'}
                                                           value="Editar"
                                                           onClick={this.openRepresentativesModal}
                                                    />
                                                </Col>
                                                <Col md="6" style={{textAlign: 'right'}}>
                                                    <input type="submit"
                                                           disabled={!(this.validSaveButton())}
                                                           className={!(this.validSaveButton()) ? 'btn btn-danger disabled' : 'btn btn-danger'}
                                                           value="Eliminar"
                                                           onClick={this.deleteRepresentativeRow}
                                                    />
                                                </Col>
                                            </Row>
                                        </Card>
                                    </Col>
                                </Row>
                            </TabPane>
                        </TabContent>

                        <Row>
                            <Col md={12}>
                                <hr style={{marginTop: 0}}/>
                                <input type="submit"
                                       disabled={!(this.validSaveButton())}
                                       className={!(this.validSaveButton()) ? 'btn btn-primary disabled' : 'btn btn-primary'}
                                       value="Guardar"
                                       onClick={this.onSubmit}/>

                            </Col>
                        </Row>

                    </CardBody>
                </Card>
            </div>
        </div>
    }
}

CreateInvestor.contextType = CyaContext;
