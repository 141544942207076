const PersonFieldValidator = {
    name: {
        secondValue: null,
        rules: [
            {
                test: (value) => value.length > 0,
                message: 'Campo en blanco'
            }
        ],
        errors: [],
        valid: false,
        state: ''
    },
    idNumber: {
        secondValue: "idtypeId",
        rules: [
            {
                test: (value) => value.length > 0,
                message: 'Campo en blanco'
            },
            {
                test: (value, idtypeId) => {
                    let firstCheck = value.length === 13 && Number(idtypeId) === 1;
                    let secondCheck = Number(idtypeId) === 2;

                    return (firstCheck || secondCheck );
                },
                message: 'Formato incorrecto'
            }
        ],
        errors: [],
        valid: false,
        state: ''
    },
    occupation: {
        secondValue: null,
        rules: [
            {
                test: (value) => value.length > 0,
                message: 'Campo en blanco'
            }
        ],
        errors: [],
        valid: false,
        state: ''
    },
    address: {
        secondValue: null,
        rules: [
            {
                test: (value) => value.length > 0,
                message: 'Campo en blanco'
            }
        ],
        errors: [],
        valid: false,
        state: ''
    },
    nationality: {
        secondValue: null,
        rules: [
            {
                test: (value) => value.length > 0,
                message: 'Campo en blanco'
            }
        ],
        errors: [],
        valid: false,
        state: ''
    },
    sirecineNumber: {
        secondValue: null,
        rules: [
            {
                test: (value) => value.length > 0,
                message: 'Campo en blanco'
            }
        ],
        errors: [],
        valid: false,
        state: ''
    },

};

export default PersonFieldValidator;
