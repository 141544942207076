import React from "react/index";
import {Badge, Card, CardBody, CardTitle, Col, Row} from "reactstrap";
import {LoadingPage} from "../../components/custom/views/LoadingPage";
import {CyaContext} from "../../utils/CyaContext";
import {ProjectCountryModel} from "../../models/ProjectCountryModel";
import {toast} from 'react-toastify';
import ProjectApiClient from "../../utils/api-client/ProjectApiClient";
import {PercentMultiTable} from "../../components/custom/tables/PercentMultiTable";

export default class FilmingCountries extends React.Component  {

    constructor(props) {
        super(props);
        this.state={
            data: new ProjectCountryModel(),
            filmingCountries: [],
            countries: [],
            loading:false,
            modalOpened:false,
            selected: null,
            percentValue: 0,
        };
        this.ProjectApiClient = new ProjectApiClient();
    }

    componentDidMount(){
        this.setState({loading:true});
         this.ProjectApiClient.GetProjectCountries(this.context.projectId).then((response)=>{
             this.setState({filmingCountries:response.projectCountry.map(x=>{x.id=x.countryId; return x; })});
         }).then(()=>{
             this.ProjectApiClient.GetCountries().then((response)=>{
                 this.setState({countries:response.data.map(x=>{return{'countryId':x.countryId,'countryDescription':x.description,'percent':0,'projectId':this.context.projectId,'id':x.countryId}}),loading:false});
             });
         });
    }

    onSubmit = (event) => {
        event.preventDefault();
        this.setState({loading:true});
         this.state.filmingCountries.map(x=>{ delete x['id']; return x;});
         this.ProjectApiClient.UpdateProjectCountries(this.context.projectId, {projectCountry: this.state.filmingCountries}).then((response)=>{
             //this.setState({loading:false,filmingCountries:response.data});
             this.setState({loading:false,filmingCountries:response.projectCountry.map(x=>{x.id=x.countryId; return x; })});
             toast.success("Paises de filmación actualizados.");
         });

        this.toggleModal();
    };

    validSaveButton = () => {
        return this.context.authService.GetPathPermission(this.props.location.pathname, true);
    };

    myCallback = (dataLeft, dataRight) => {

        this.setState({
            countries: dataLeft,
            filmingCountries: dataRight,
        });
    };

    onPercentChange = (e) =>{
        e.preventDefault();
        this.setState({percentValue:e.target.value>=100? 100:(e.target.value<=0? 0:e.target.value)});
    };

    render() {

        const columns = [
            {Header: "Nombre", accessor: "countryDescription"},
            {Header: "Porcentaje", accessor: "percent",width: 100,style: {textAlign: 'center'},Cell: props => (props.value*100) + ' %'},
        ];
        const countryColumns = [
            {Header: "Nombre", accessor: "countryDescription"},
        ];
        let sumTotalPercent=0;
        if (this.state.filmingCountries.length > 0){
            sumTotalPercent= this.state.filmingCountries.reduce(function (prev, cur) {
                return prev + cur.percent;
            }, 0);
        }

        return (
            <div>
                <LoadingPage color={"3e5569"} loading={this.state.loading} type={"spin"}/>

                <Card>
                    <CardTitle className="bg-light border-bottom p-3 mb-0">
                        <i className="mdi mdi-film mr-2"/>Paises de Fimación
                    </CardTitle>
                    <CardBody>
                        <Row>
                            <Col md="12">
                                <Row>
                                    <Col md="12">
                                        {this.state.countries.length>0?
                                            <PercentMultiTable
                                                dataLeft={this.state.countries}
                                                columnsLeft={countryColumns}
                                                dataRight={this.state.filmingCountries}
                                                columnsRight={columns}
                                                loading={this.state.loading}
                                                callbackFromParent={this.myCallback}
                                                showButtonsInvestorTable={false}
                                                filterable={true}
                                                defaultPageSize={6}
                                                showPercentInput={true}
                                                percentValue={this.state.percentValue}
                                                onPercentChange={this.onPercentChange}
                                            />
                                            :''}

                                    </Col>
                                </Row>

                                <hr/>
                                <Row >
                                    <Col md={12}>
                                        <div style={{'textAlign':'center'}} className={'mb-1'}>
                                            <Badge color={sumTotalPercent===1 && this.state.filmingCountries.filter(x=> x.percent===0).length===0? 'success':'danger'}>{sumTotalPercent*100} %</Badge>
                                            {sumTotalPercent===1 ? '': <div><small>Sumatoria de porcentajes debe dar 100%  </small></div>}
                                            {this.state.filmingCountries.filter(x=> x.percent===0).length===0 ? '': <div><small>Debe asignar un porcentaje mayor que 0% al pais: {this.state.filmingCountries.filter(x=> x.percent===0)[0]['countryDescription']}  </small></div>}
                                        </div>
                                    </Col>

                                </Row>

                                <Row>
                                    <Col md="12" style={{textAlign: 'center'}}>
                                        <input type="submit"
                                               disabled={!(this.validSaveButton()  && sumTotalPercent===1 && this.state.filmingCountries.filter(x=> x.percent===0).length===0 )}
                                               className={!(this.validSaveButton() && sumTotalPercent===1 && this.state.filmingCountries.filter(x=> x.percent===0).length===0) ? 'btn btn-success disabled' : 'btn btn-success'}
                                               value="Guardar"
                                               onClick={this.onSubmit}
                                        />

                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </div>
        );
    }
}

FilmingCountries.contextType = CyaContext;
