import {ImageModel} from "./ImageModel";

export class HiredPersonModel{
    personId = 0;
    positionId = 1;
    date = new Date();
    imageId = 0;
    notaryId = 1;
    contractPersonId = 0;
    paymentMethodId = 1;
    paymentAmount = 0;
    validity = 1;
    character = "";
    image = new ImageModel();
}
