import React from 'react';
import {
    FormGroup,
    Label,
    Col,
    Row
} from 'reactstrap';
import PropTypes from 'prop-types'
import CurrencyInput from "react-currency-input";
import {CurrencyPrecision, CurrencyPrefix} from "../../../const/api_consts";
import NumberFormat from 'react-number-format';

export class InputTaxForm extends React.Component {

    isAllowed(number){
        return number.floatValue >= 0 && number.floatValue <= 100;
    }

    render() {
        return <FormGroup>
            <Label htmlFor={this.props.amountId}>{this.props.label}</Label>
            <Row>
                <Col style={{paddingRight:'0px' }}>
                    <CurrencyInput
                        className="form-control"
                        prefix={CurrencyPrefix}
                        precision={CurrencyPrecision}
                        id={this.props.amountId}
                        name={this.props.amountId}
                        value={this.props.amountValue}
                        onChangeEvent={this.props.onInputChange}/>
                </Col>
                <Col md={5} >
                    <NumberFormat
                        className="form-control"
                        suffix={"%"}
                        fixedDecimalScale={true}
                        allowNegative={false}
                        decimalScale={CurrencyPrecision}
                        value={this.props.percentValue}
                        isAllowed={this.isAllowed}
                        onValueChange={this.props.onPercentInputChange}/>
                </Col>
            </Row>
        </FormGroup>
    }
}

InputTaxForm.propTypes = {
    label: PropTypes.string,
    amountId: PropTypes.string,
    percentId: PropTypes.string,
    amountValue: PropTypes.string,
    percentValue: PropTypes.number,
    onInputChange: PropTypes.func,
    onPercentInputChange: PropTypes.func
};
