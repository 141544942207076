import {ImageModel} from "./ImageModel";

export class InvestorRepresentativeModel {
    // investorRepresentativeId = 0;
    investorId = 0;
    name = "";
    prefixId = 1;
    genderId = 1;
    address = "";
    occupation = "";
    nationality = "";
    civilStatusId = 1;
    cityId = 1;
    idtypeId = 1;
    idNumber = "";
    idNumberImageId = 1;
    image = new ImageModel();
}
