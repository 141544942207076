import {ImageModel} from "./ImageModel";

export class InvestorDocumentModel {
    constructor(type = 1){
        this.investorDocumentTypeId = type;
    }

    investorDocumentTypeId = 1;
    investorId = 0;
    value = "";
    expires = new Date();
    imageId = 0;
    image = new ImageModel();
}
