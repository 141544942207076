import {
   ProjectDocTypeCDND,
   ProjectDocTypeCPND,
   ProjectDocTypePUR,
} from "../const/api_consts";
import { ProjectDocumentModel } from "./ProjectDocumentModel";

export class ProjectModel {
   constructor() {
      this.projectDocument.push(
         new ProjectDocumentModel(ProjectDocTypePUR),
         new ProjectDocumentModel(ProjectDocTypeCPND),
         new ProjectDocumentModel(ProjectDocTypeCDND)
      );
   }

   projectId = 0;
   name = "";
   producerId = 1;
   movieLength = 0;
   creationDate = new Date();
   filmingStartDate = new Date();
   filmingEndDate = new Date();
   s;
   pur = "";
   purdate = new Date();
   generalProducer = "";
   lineProducer = "";
   legalManager = "";
   notaryId = 1;
   projectDocument = [];
   payRequesterName = "";
   payRequesterEmail = "";
}
