import React, {useContext, useEffect, useRef, useState, useMemo} from 'react';
import {Card, CardBody, CardTitle, Col, Form, FormGroup, Label, Row} from "reactstrap";
import {LoadingPage} from "../../components/custom/views/LoadingPage";
import {InputForm} from "../../components/custom/inputs/InputForm";
import Select from "react-select";
import CreatableSelect from "react-select/lib/Creatable";
import {InputCurrency} from "../../components/custom/inputs/InputCurrency";
import {ItemModel} from "../../models/ItemModel";
import ItemApiClient from "../../utils/api-client/ItemApiClient";
import CyaHelper from "../../utils/CyaHelper";
import {toast} from "react-toastify";
import FieldValidator from "../../utils/field-validators/FieldValidator";
import ItemFieldValidator from "../../utils/field-validators/ItemFieldValidator";
import {CyaContext} from "../../utils/CyaContext";
import AuthService from "../../utils/AuthService";
import BudgetModal from "../../components/custom/modals/BudgetModal";


export default function Create(props) {
    const [title, setTitle] = useState('Crear Partida')
    const [modelCreated, setModelCreated] = useState('Partida Guardada.')
    const [modelUpdated, setModelUpdated] = useState('Partida Actualizada.')
    const [modelError, setModelError] = useState('Error Salvando')
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState(new ItemModel())
    const [initialData, setInitialData] = useState(new ItemModel())
    const [itemsData, setItemsData] = useState([])
    const [itemDetail, setItemDetail] = useState([])
    const [itemDetailOptions, setItemDetailOptions] = useState([])
    const [selectedItem, setSelectedItem] = useState(null)
    const [modalToggle, setModalToggle] = useState(false)

    const context = useContext(CyaContext);

    let {current: apiClient} = useRef(new ItemApiClient())
    let {current: Validator} = useRef(new FieldValidator(ItemFieldValidator))
    let {current: Auth} = useRef(new AuthService())

    useEffect(() => {
        apiClient.GetListForItems().then(response => {
            let items = response.data.map(x => {
                return {
                    value: x.itemId,
                    label: "[" + x.code + "] " + x.name,
                    parentItemId: x.parentItemId
                }
            });
            setItemsData(items)

        }).then(() => {
            if (props.location.state !== undefined && props.location.state.id) {
                apiClient.GetById(props.location.state.id).then(response => {
                    editPromise(response);
                });
            } else {
                setLoading(false)
            }
        });
        if (!loading && CyaHelper.ObjectCompare(data, data)) {
            Validator.initValidation(data);
        }
        if (!loading) {
            let isFormDirty = !CyaHelper.ObjectCompare({...initialData}, {...data});
            context.toggleExitConfirmation(isFormDirty);
        }

    }, [loading, data, initialData])


    const editPromise = (response) => {
        let selectedItem = itemsData.find(x => x.value === response.parentItemId);

        setInitialData(JSON.parse(JSON.stringify(response)))
        setData(response)
        setTitle("Editar Partida No. " + response.code)
        setLoading(false)
        setSelectedItem(selectedItem)
        setItemDetail(typeof (response.itemDetail) !== "undefined" ?
            response.itemDetail.map(x => {
                return {
                    value: x.description,
                    label: x.description
                }
            }) : [],)
        setItemDetailOptions(typeof (response.itemDetail) !== "undefined" ?
            response.itemDetail.map(x => {
                return {
                    value: x.description,
                    label: x.description
                }
            }) : [])
    }

    const onInputChange = (event) => {
        data[event.target.name] = event.target.value;
        setData((prevState) => {
            return {...prevState, ...data};
        });
    }

    const handleCurrencyChange = (event, maskedvalue, floatvalue) => {
        let aux = data;
        aux[event.target.name] = floatvalue;
        aux.total = aux.subtotal + aux.itbis;
        setData(aux);
    }

    const onItemChange = (item) => {
        let aux = data;
        aux.parentItemId = item === null ? 0 : item.value;
        aux.isGrandson = !(typeof (item) === "undefined" ||
            item === null ||
            item.parentItemId === 0 ||
            itemsData
                .filter(x => x.parentItemId === 0)
                .find(x => x.itemId === item.parentItemId));

        if (aux.isGrandson && aux.subtotal === 0)
            Validator.changeValidForField("subtotal", false);

        setData(aux)
        setSelectedItem(item)
    }

    const onMultiItemChange = (item) => {
        let aux = data;
        aux.itemDetail = item.map(x => {
            return {
                description: x.value
            }
        });
        setData(aux)
        setItemDetail(item)
    }

    const onSubmit = (event) => {
        event.preventDefault();
        this.setState({loading: true});

        if (data.itemId > 0) {
            apiClient.Update(data.itemId, data)
                .then(response => {
                    editPromise(response);
                    toast.success(modelUpdated);
                })
                .catch(err => {
                    setLoading(false)
                    toast.error(modelError);
                });
        } else {
            apiClient.Create(data)
                .then(response => {
                    toast.success(modelCreated);
                    context.toggleExitConfirmation(false);
                    props.history.push({pathname: '/refresh/items/new'});
                })
                .catch(err => {
                    setLoading(false)
                    toast.error(modelError);
                });
        }
        window.scrollTo(0, 0);
    }

    const renderColumns = useMemo(() => {
        return [
            {
                id: 'period',
                label: 'Periodo',
            },
            {
                id: 'amount',
                label: 'Monto',
            },
            {
                id: 'commentary',
                label: 'Comentario',
            },
        ];
    }, [])


    const renderRows = useMemo(() => {
        return [
            {period: 1, amount: 1324171354, commentary: ''},
            {period: 1, amount: 1403500365, commentary: ''},
            {period: 1, amount: 60483973, commentary: ''},
        ];
    }, [])

    const onChangeBudget = () => {

    }

    return (
        <div>
            <BudgetModal
                toggle={() => setModalToggle(!modalToggle)}
                isOpen={modalToggle}
                columns={renderColumns}
                onChangeEvent={onChangeBudget}
                rows={renderRows}

            />
            <Card>
                <CardTitle className="bg-light border-bottom p-3 mb-0">
                    <i className="fas fa-file-alt mr-2"/>{title}
                </CardTitle>
                <CardBody>
                    <LoadingPage color={"3e5569"} loading={loading} type={"spin"}/>
                    <Row>
                        <Col md="12">
                            <Form>
                                <Row>
                                    <Col md="4">
                                        <InputForm
                                            id_label={"code"}
                                            label={"Código:"}
                                            placeholder={""}
                                            type={"text"}
                                            value={data.code}
                                            onInputChange={onInputChange}
                                        />
                                        {Validator.showErrors("code")}
                                    </Col>
                                    <Col md="8">
                                        <InputForm
                                            id_label={"name"}
                                            label={"Nombre:"}
                                            placeholder={""}
                                            type={"text"}
                                            value={data.name}
                                            onInputChange={onInputChange}
                                        />
                                        {Validator.showErrors("name")}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="12">
                                        <FormGroup>
                                            <Label htmlFor={"itemId"}>Padre: </Label>
                                            <Select options={itemsData}
                                                    id={"itemId"}
                                                    name={"itemId"}
                                                    value={selectedItem}
                                                    isLoading={loading}
                                                    placeholder={"Seleccione partida padre"}
                                                    isClearable
                                                    onChange={onItemChange}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                {typeof (selectedItem) === "undefined" ||
                                selectedItem === null ||
                                selectedItem.parentItemId === 0 ||
                                itemsData
                                    .filter(x => x.parentItemId === 0)
                                    .find(x => x.itemId === selectedItem.parentItemId)
                                    ? null :
                                    <div>
                                        <Row>
                                            <Col md="12">
                                                <FormGroup>
                                                    <Label htmlFor={"itemDetail"}>Detalles: </Label>
                                                    <CreatableSelect value={itemDetail}
                                                                     options={itemDetailOptions}
                                                                     id={"itemDetail"}
                                                                     name={"itemDetail"}
                                                                     isLoading={loading}
                                                                     isClearable
                                                                     isMulti
                                                                     placeholder={"Digite detalles de la partida"}
                                                                     onChange={onMultiItemChange}/>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="4">
                                                <InputCurrency
                                                    id_label={"subtotal"}
                                                    label={"Subtotal:"}
                                                    placeholder={""}
                                                    value={data.subtotal}
                                                    onInputChange={handleCurrencyChange}
                                                />
                                                {Validator.showErrors("subtotal")}
                                            </Col>
                                            <Col md="4">
                                                <InputCurrency
                                                    id_label={"itbis"}
                                                    label={"ITBIS:"}
                                                    placeholder={""}
                                                    value={data.itbis}
                                                    onInputChange={handleCurrencyChange}
                                                />
                                            </Col>
                                            <Col md="4">
                                                <InputCurrency
                                                    id_label={"total"}
                                                    label={"Total:"}
                                                    placeholder={""}
                                                    value={data.total}
                                                    readOnly={true}
                                                />
                                            </Col>
                                        </Row>
                                    </div>}


                                <hr/>
                                <Row>
                                    <Col md={2}>
                                        <input type="submit"
                                               disabled={!(Validator.validForm() && Auth.GetPathPermission(props.location.pathname, true))}
                                               className={!(Validator.validForm() && Auth.GetPathPermission(props.location.pathname, true)) ? 'btn btn-primary disabled' : 'btn btn-primary'}
                                               value="Guardar"
                                               onClick={onSubmit}/>
                                    </Col>
                                </Row>
                            </Form>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </div>
    )
}

