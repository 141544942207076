import React from 'react';
import {
    FormGroup,
    Label
} from 'reactstrap';
import PropTypes from 'prop-types'
import {CurrencyPrecision} from "../../../const/api_consts";
import NumberFormat from "react-number-format";

export class PercentageFilter extends React.Component {

    isAllowed(number){
        return number.floatValue >= 0 && number.floatValue <= 100;
    }

    render() {
        return <FormGroup>
            <Label style={{fontSize: '20px'}}>{this.props.label}:</Label>
            <NumberFormat
                id={this.props.filterId}
                name={this.props.filterId}
                className="form-control"
                suffix={"%"}
                fixedDecimalScale={true}
                allowNegative={false}
                decimalScale={CurrencyPrecision}
                value={this.props.value === undefined ? null : this.props.value}
                isAllowed={this.isAllowed}
                onValueChange={this.props.onInputChange}/>
        </FormGroup>
    }
}

PercentageFilter.propTypes = {
    filterId: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.number,
    onInputChange: PropTypes.func
};