import CyaApiClient from "./CyaApiClient";
export default class TaxReturnApiClient extends CyaApiClient {

    constructor(){
        super("TaxReturn");
        this.GetTaxReturnTypes = this.GetTaxReturnTypes.bind(this);
        this.GetByDate = this.GetByDate.bind(this);
    }

    GetTaxReturnTypes() {
        return this.MakeCall('get', this.ControllerName + '/taxReturnTypes');
    }

    GetByDate(datetime) {
        return this.MakeCall('get', this.ControllerName + '/byDate', {datetime: datetime});
    }
}