import React from "react";
import {
    Card,
    CardBody,
    CardTitle,
} from 'reactstrap';
import PropTypes from "prop-types";
import {CyaTable} from "./CyaTable";
import {CyaTableNestedRow} from "./CyaTableNestedRow";

export class CyaTableLayout extends React.Component {

    MakeTable() {
        if (this.props.nestedLevel > 0) {
            return <CyaTableNestedRow data={this.props.data} columns={this.props.columns}
                                      subComponent={this.props.subComponent} nestedLevel={this.props.nestedLevel}
                                      loading={this.props.loading} trProps={this.props.trProps}/>;
        }

        if (this.props.tableWithoutCard){
            return <CyaTable data={this.props.data} selectionCheckbox={this.props.selectionCheckbox}
                      columns={this.props.columns} subComponent={this.props.subComponent}
                      loading={this.props.loading} trProps={this.props.trProps}
                      deleteCallback={this.props.deleteCallback}
                      viewCallback={this.props.viewCallback}
                      enableButtons={this.props.enableButtons}
                      modelType={this.props.modelType}
                      multipleActionsButton={this.props.multipleActionsButton}
                      selectedMultiple={this.props.selectedMultiple}
                      viewAnnexesCallback={this.props.viewAnnexesCallback}
                      viewChecksCallback={this.props.viewChecksCallback}
                      multipleActionsCallback={this.props.multipleActionsCallback}
                      showDeleteButton={this.props.showDeleteButton}
                      deleteText={this.props.deleteText}
                      massButtonText={this.props.massButtonText}
                      enableMassButtonDefault={this.props.enableMassButtonDefault}
                      viewButtonText={this.props.viewButtonText}
                             defaultPageSize={this.props.defaultPageSize}
            />
        } else{
            return <Card>
                <CardTitle className="mb-0 p-3 border-bottom bg-light"><i
                    className="mdi mdi-format-list-bulleted mr-2"/>{this.props.title}</CardTitle>
                <CardBody>
                    <CyaTable data={this.props.data} selectionCheckbox={this.props.selectionCheckbox}
                              columns={this.props.columns} subComponent={this.props.subComponent}
                              loading={this.props.loading} trProps={this.props.trProps}
                              deleteCallback={this.props.deleteCallback}
                              viewCallback={this.props.viewCallback}
                              enableButtons={this.props.enableButtons}
                              modelType={this.props.modelType}
                              multipleActionsButton={this.props.multipleActionsButton}
                              selectedMultiple={this.props.selectedMultiple}
                              viewAnnexesCallback={this.props.viewAnnexesCallback}
                              viewChecksCallback={this.props.viewChecksCallback}
                              multipleActionsCallback={this.props.multipleActionsCallback}
                              showDeleteButton={this.props.showDeleteButton}
                              deleteText={this.props.deleteText}
                              massButtonText={this.props.massButtonText}
                              enableMassButtonDefault={this.props.enableMassButtonDefault}
                              viewButtonText={this.props.viewButtonText}
                              checkSelectedData={this.props.checkSelectedData}
                              defaultPageSize={this.props.defaultPageSize}
                    />
                </CardBody>
            </Card>;
        }
    }
    render() {
        return <div>{this.MakeTable()}</div>
    }
}

CyaTableLayout.propTypes = {
    data: PropTypes.array,
    columns: PropTypes.array,
    title: PropTypes.string,
    subComponent: PropTypes.func,
    nestedLevel: PropTypes.number,
    loading: PropTypes.bool,
    selectionCheckbox: PropTypes.bool,
    showViewButton: PropTypes.bool,
    enableButtons: PropTypes.bool,
    showDeleteButton: PropTypes.bool,
    multipleActionsButton: PropTypes.bool,
    enableMassButtonDefault: PropTypes.bool,
    trProps: PropTypes.func,
    multipleActionsCallback: PropTypes.func,
    deleteCallback: PropTypes.func,
    selectedMultiple: PropTypes.func,
    viewAnnexesCallback: PropTypes.func,
    viewChecksCallback: PropTypes.func,
    viewCallback: PropTypes.func,
    modelType: PropTypes.string,
    deleteText: PropTypes.string,
    massButtonText: PropTypes.string,
    viewButtonText: PropTypes.string,
    tableWithoutCard: PropTypes.bool,
    checkSelectedData: PropTypes.object,
    defaultPageSize: PropTypes.number
};

CyaTableLayout.defaultProps = {
    selectionCheckbox: false,
    multipleActionsButton: false,
    enableButtons: false,
    tableWithoutCard: false,
    modelType: "",
    checkSelectedData: null,
    defaultPageSize: 10
};
