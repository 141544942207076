import CyaApiClient from "./CyaApiClient";

export default class InvestorApiClient extends CyaApiClient {

    constructor(){
        super("Investor");
    }
    GetContracts() {
        return this.MakeCall('get', this.ControllerName + '/getContracts');
    }
    GetContractTypes() {
        return this.MakeCall('get', this.ControllerName + '/getContractTypes');
    }
    GetContract(id) {
        return this.MakeCall('get', this.ControllerName + '/getContract',{id: id});
    }
    SaveContract(data) {
        return this.MakeCall('post', this.ControllerName + '/saveContract', data);
    }

}
