import React from 'react';
import {
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Input,
    Form,
    Row,
    Col,
    Button
} from 'reactstrap';

import LoginFieldValidator from '../../utils/field-validators/LoginFieldValidator';
import md5 from 'md5';
import AuthService from '../../utils/AuthService';
import logolighticon from "../../assets/images/logo-light-icon.png";
import logolighttext from "../../assets/images/logo-light-text.png";
import FieldValidator from "../../utils/field-validators/FieldValidator";
import {loginPage, projectsPage} from "../../const/api_consts";
import {LoadingPage} from "../../components/custom/views/LoadingPage";


export default class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            password: '',
            isLoggedIn: false,
            error: null,
            loading:false,
        };
        this.handleClick = this.handleClick.bind(this);
        this.Validator = new FieldValidator(LoginFieldValidator);
        this.Auth = new AuthService();
        this.onInputChange = this.onInputChange.bind(this);
        this.doLogin = this.doLogin.bind(this);
    }

    onInputChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
        this.Validator.formValidators([event.target.name], event.target.value);
    }

    doLogin(event) {
        event.preventDefault();
        this.setState({loading:true});
        const {username, password} = this.state;
        let formData = new FormData();
        formData.append("password", md5(password));
        formData.append("username", username);

        this.Auth.Login(formData)
        .then(() =>{
            if(this.state.loading)this.setState({loading:false});
            this.props.history.replace(projectsPage);
        })
        .catch(err =>{
            alert(err);
            if(this.state.loading)this.setState({loading:false});
            if (err.status === 401) {
                this.Auth.Logout();
                this.props.history.replace(loginPage);
            }
        });
        if(this.state.loading)this.setState({loading:false});
    }

    handleClick() {
        let elem = document.getElementById('loginform');
        elem.style.transition = "all 2s ease-in-out";
        elem.style.display = "none";
        document.getElementById('recoverform').style.display = "block";
    }

    render() {
        return <div className="auth-wrapper  d-flex" >
            <div className="container">
                <div>
                    <LoadingPage color={"3e5569"} loading={this.state.loading} type={"spin"}/>
                    <Row className="no-gutters justify-content-center">
                        <Col md="6" lg="4" className="bg-white">
                            <div className="pt-4 pb-2 text-center" style={{backgroundColor: "#0093FE"}}>
                                <b className="logo-icon">
                                    <img
                                        src={logolighticon}
                                        alt="homepage"
                                        className="light-logo"
                                    />
                                </b>
                                <span className="logo-text" style={{marginLeft:"20px"}} >
                                    <img
                                        src={logolighttext}
                                        className="light-logo"
                                        alt="homepage"
                                    />
                                </span>
                            </div>
                            <div className="p-4">
                                <h3 className="font-medium mb-3 text-center">Inicio de sesi&oacute;n</h3>
                                <Form className="mt-3" id="loginform" action="/dashboard">
                                    <InputGroup className="mb-2" size="lg">
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="ti-user"/>
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input type="text" id="username" name="username"
                                               value={this.state.username} autoComplete="username"
                                               onChange={this.onInputChange} placeholder="Usuario"/>
                                    </InputGroup>
                                    {this.Validator.showErrors('username')}
                                    <InputGroup className="mb-3" size="lg">
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="ti-pencil"/>
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input type="password" id="password" name="password"
                                               value={this.state.password} autoComplete="current-password"
                                               onChange={this.onInputChange} placeholder="Contraseña"/>
                                    </InputGroup>
                                    {this.Validator.showErrors('password')}
                                    <Row className="mb-3">
                                        <Col xs="12">
                                            <Button color="primary" onClick={this.doLogin}
                                                    disabled={!this.Validator.validForm()}
                                                    className={`${this.Validator.validForm() ? '' : 'disabled'}`}
                                                    size="lg"
                                                    style={{backgroundColor: "#0093FE"}}
                                                    type="submit" block>Entrar</Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    }
}
