import CyaApiClient from "./CyaApiClient";

export default class CheckApiClient extends CyaApiClient {

    constructor(){
        super("Check");
        this.GetTypes = this.GetTypes.bind(this);
        this.GetFinals = this.GetFinals.bind(this);
        this.GetDeposits = this.GetDeposits.bind(this);
        this.GetByAnnexId = this.GetByAnnexId.bind(this);
    }

    GetTypes() {
        return this.MakeCall('get', this.ControllerName + '/types');
    }

    GetFinals(){
        return this.MakeCall('get', this.ControllerName + '/finals');
    }

    GetDeposits() {
        return this.MakeCall('get', this.ControllerName + '/deposits');
    }

    GetListForAnnexes() {
        return this.MakeCall('get', this.ControllerName + '/listForAnnexes');
    }

    GetByAnnexId(id) {
        return this.MakeCall('get', this.ControllerName + '/byAnnexId', {id: id});
    }

    ValidateIfExists(number,bankId) {
        return this.MakeCall('get', this.ControllerName + '/validateIfExists', {checkNumber: number,bankId: bankId});
    }

    MassDelete(objects) {
        return this.MakeCall('post', this.ControllerName + '/massDelete', {objects: objects});
    }

    MassChange(changeType,objects) {
        return this.MakeCall('post', this.ControllerName + '/massChange', {changeType:changeType,objects: objects});
    }

    ValidateInvestorOd(InvestorId,CheckIds) {
        return this.MakeCall('post', this.ControllerName + '/validateInvestorOd', {investorId: InvestorId,checkIds: CheckIds});
    }

    GetCheckInfoById(checkId) {
        return this.MakeCall('get', this.ControllerName + '/infoById', {checkId: checkId});
    }
}
