import CyaApiClient from "./CyaApiClient";

export default class HiredPersonApiClient extends CyaApiClient {

    constructor(){
        super("HiredPerson");
    }

    PaymentMethods() {
        return this.MakeCall('get', this.ControllerName + '/paymentMethods');
    }

    GetHiredPerson(personId,positionId) {
        return this.MakeCall('get', this.ControllerName + '/getHiredPerson',{personId: personId,positionId: positionId});
    }

    DeleteHiredPerson(personId,positionId) {
        return this.MakeCall('delete', this.ControllerName + '/deleteHiredPerson',{personId: personId,positionId: positionId});
    }
    //pre project
    DeleteContract(id) {
        return this.MakeCall('delete', 'Hired' + this.ControllerName + '/deleteContract', {id: id});
    }

}
