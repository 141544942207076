import {
    GET_DASHBOARD_DATA_SUCCESS,
} from "../actions";

const INITIAL_STATE = {
    dashboard_data: [],
};

export default function DashboardReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case GET_DASHBOARD_DATA_SUCCESS:
            return {...state, dashboard_data: action.payload};
        default:
            return state;
    }
}
