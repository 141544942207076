import React from "react";

export default class FieldValidator {

    constructor(validator){
        this.validators = validator;
    }

    initValidation(data){
        Object.keys(this.validators).forEach((fieldName) => {
            if(this.validators[fieldName].state !== data[fieldName]){
                this.formValidators(fieldName, data[fieldName], data[this.validators[fieldName].secondValue]);
            }
        });
    }

    changeValidForField(fieldName, value){
        this.validators[fieldName].valid = value;
    }

    validForm(ignoredKeys = []) {
        return Object.keys(this.validators).every((field) =>
            ignoredKeys.find(x => x === field) === undefined ? this.validators[field].valid : true);
    }

    formValidators(fieldName, value, secondValue) {
        this.validators[fieldName].errors = [];
        this.validators[fieldName].state = value;
        this.validators[fieldName].valid = true;
        this.validators[fieldName].rules.forEach((rule) => {
            if (rule.test instanceof RegExp) {
                if (!rule.test.test(value)) {
                    this.validators[fieldName].errors.push(rule.message);
                    this.validators[fieldName].valid = false;
                }
            } else if (typeof rule.test === 'function') {
                if (!rule.test(value, secondValue)) {
                    this.validators[fieldName].errors.push(rule.message);
                    this.validators[fieldName].valid = false;
                }
            }
        });
    }

    showErrors(fieldName) {
        const validator = this.validators[fieldName];
        const result = '';
        if (validator && !validator.valid) {
            const errors = validator.errors.map((info, index) => {
                return <span className="error" key={index}>* {info}<br/></span>
            });
            return (
                <div className="error mb-2">
                    {errors}
                </div>
            );
        }
        return result;
    }
}
