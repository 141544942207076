import decode from 'jwt-decode';

import {ApiURL, dashboardPage, loginPage, loginURL, preDashboardUrl, projectsPage} from '../const/api_consts';

export default class AuthService {
    constructor(domain) {
        this.domain = domain || ApiURL + loginURL; // API server domain
        this.fetch = this.fetch.bind(this); // React binding stuff
        this.Login = this.Login.bind(this);
        this.GetProfile = this.GetProfile.bind(this);
        this.Logout = this.Logout.bind(this);
        this.CyaLogoUrl = this.CyaLogoUrl.bind(this);
        this.ReturnToProjectsPage = this.ReturnToProjectsPage.bind(this);
        this.RemoveLocalProject = this.RemoveLocalProject.bind(this);
        this.GetPermissions = this.GetPermissions.bind(this);
        this.GetPathPermission = this.GetPathPermission.bind(this);

    }

    async fetch(url, options) {
        const headers = {
            // 'Accept': 'application/json',
            // 'Content-Type': 'application/json'
        };

        if (this.LoggedIn()) {
            headers['Authorization'] = 'Bearer ' + this.GetToken()
        }

        return await fetch(url, {
            headers,
            ...options
        })
            .then(this._checkStatus)
    }

    _checkStatus(response) {
        // raises an error in case response status is not a success
        if (response.status >= 200 && response.status < 300) { // Success status lies between 200 to 300
            return response.json();
        } else {
            return response.json().then(response => {
                throw new Error(response.message)
            })
        }
    }

    _SetToken(idToken) {
        localStorage.setItem('id_token', idToken)
    }

    GetToken() {
        return localStorage.getItem('id_token')
    }

    LoggedIn() {
        return this.GetToken();
    }

    CyaLogoUrl(){
        if (this.LoggedIn()){
            if(this.ReturnToProjectsPage()){
                return projectsPage;
            }else {
                return dashboardPage;
            }
        }else {
            return loginPage;
        }
    }

    async Login(formData) {
        return await this.fetch(`${this.domain}`, {
            method: 'POST',
            body: formData
        }).then(res => {
            this._SetToken(res.token); // Setting the token in localStorage
            return Promise.resolve(res);
        })
    }

    Logout() {
        localStorage.removeItem('id_token');
        this.RemoveLocalProject();
    }

    GetPermissions() {
        try {
            let token = decode(this.GetToken());
            return JSON.parse(token.permissions);
        } catch (e) {
            return null;
        }
    }

    GetPathPermission(path, editBool) {

        if (this.LoggedIn()) {
            if (path === "/" || path === undefined)
                return true;

            if (String(path).indexOf(preDashboardUrl)>-1){
                path=path.replace(preDashboardUrl,"");
            }

            let pathToSearch = "/" + path.split("/")[1];
            let secondPath = path.split("/");
            secondPath = secondPath[secondPath.length - 1];
            if (secondPath !== "new" && secondPath !== "edit" && secondPath !== "delete") {
                pathToSearch = path;
                secondPath = undefined;
            }

            let permission = this.GetPermissions().find(p => p.name === pathToSearch);
            if (permission !== undefined) {
                if (secondPath === undefined && !permission.read)
                    return false;
                if (secondPath === "new" && (!permission.write || !permission.read))
                    return false;
                if (secondPath === "edit" && (!permission.write || !permission.read) && editBool)
                    return false;
                return !(secondPath === "delete" && (!permission.delete || !permission.read));
            }
        }
        return false;
    }

    GetProfile() {
        // Using jwt-decode npm package to decode the token
        try {
            return decode(this.GetToken());
        } catch (e) {
            return null;
        }
    }

    RemoveLocalProject() {
        localStorage.removeItem('id_project');
        localStorage.removeItem('projectName');
        localStorage.removeItem('producerName');
    }

    IsLocalProjectSelected() {
        return localStorage.getItem('id_project');
    }

    ReturnToProjectsPage() {
        return !this.IsLocalProjectSelected();
    }

    IsAdmin(){
        return Number(this.GetProfile().role) === 1
    }
}
