import React from 'react';
import {
    Nav,
    Navbar,
    NavbarBrand,
    Collapse,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from 'reactstrap';

import AuthService from "../../utils/AuthService";


/*--------------------------------------------------------------------------------*/
/* Import images which are need for the HEADER                                    */
/*--------------------------------------------------------------------------------*/
import logodarkicon from '../../assets/images/logo-dark-icon.png';
import logolighticon from '../../assets/images/logo-light-icon.png';
import logodarktext from '../../assets/images/logo-dark-text.png';
import logolighttext from '../../assets/images/logo-light-text.png';
import profilephoto from '../../assets/images/users/1.jpg';
import ProjectApiClient from "../../utils/api-client/ProjectApiClient";
import {loginPage, projectsPage} from "../../const/api_consts";
import {CyaContext} from "../../utils/CyaContext";

class Header extends React.Component {
    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.Logout = this.Logout.bind(this);
        this.ChangeProject = this.ChangeProject.bind(this);
        this.showMobilemenu = this.showMobilemenu.bind(this);
        this.state = {
            isOpen: false,
            redirect: false,
        };

        this.Auth = new AuthService();
        this.ProjectApiClient = new ProjectApiClient();
        let user = this.Auth.GetProfile();
        let project = this.ProjectApiClient.GetProject();
        if (user != null) {
            this.state = {
                name: user.given_name,
                username: user.unique_name,
                email: user.email,
                projectName: project.projectName,
                producerName: project.producerName,
            };
        }

    }

    /*--------------------------------------------------------------------------------*/
    /*To open NAVBAR in MOBILE VIEW                                                   */

    /*--------------------------------------------------------------------------------*/
    toggle() {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    /*--------------------------------------------------------------------------------*/
    /*To open SIDEBAR-MENU in MOBILE VIEW                                             */

    /*--------------------------------------------------------------------------------*/
    showMobilemenu() {
        document.getElementById('main-wrapper').classList.toggle('show-sidebar');
    }

    Logout() {
        this.Auth.Logout();
        this.props.history.replace(loginPage);
    }

    ChangeProject() {
        this.Auth.RemoveLocalProject();
        this.props.history.replace(projectsPage);

    }

    render() {
        return (
            <header className="topbar navbarbg" data-navbarbg={this.props.data.settings[0].navbarbg}>
                <Navbar
                    className={"top-navbar " + (this.props.data.settings[0].navbarbg === "skin6" ? 'navbar-light' : 'navbar-dark')}
                    expand="md">
                    <div className="navbar-header" id="logobg" style={{width: "auto"}}
                         data-logobg={this.props.data.settings[0].logobg}>
                        {/*--------------------------------------------------------------------------------*/}
                        {/* Mobile View Toggler  [visible only after 768px screen]                         */}
                        {/*--------------------------------------------------------------------------------*/}
                        <a className="nav-toggler d-block d-md-none" onClick={this.showMobilemenu}>
                            <i className="ti-menu ti-close"/>
                        </a>
                        {/*--------------------------------------------------------------------------------*/}
                        {/* Logos Or Icon will be goes here for Light Layout && Dark Layout                */}
                        {/*--------------------------------------------------------------------------------*/}
                        <NavbarBrand href={this.Auth.CyaLogoUrl()}>
                            <b className="logo-icon">
                                <img src={logodarkicon} alt="homepage" className="dark-logo"/>
                                <img
                                    src={logolighticon}
                                    alt="homepage"
                                    className="light-logo"
                                />
                            </b>
                            <span className="logo-text">
								<img src={logodarktext} alt="homepage" className="dark-logo"/>
								<img
                                    src={logolighttext}
                                    className="light-logo"
                                    alt="homepage"
                                />
							</span>
                        </NavbarBrand>
                        {/*--------------------------------------------------------------------------------*/}
                        {/* Mobile View Toggler  [visible only after 768px screen]                         */}
                        {/*--------------------------------------------------------------------------------*/}
                        <a className="topbartoggler d-block d-md-none" onClick={this.toggle}>
                            <i className="ti-more"/>
                        </a>
                    </div>
                    <Collapse className="navbarbg" isOpen={this.state.isOpen} navbar
                              data-navbarbg={this.props.data.settings[0].navbarbg}>
                        <Nav className="ml-auto float-left" navbar style={{textAlign: "center", color: "white"}}>
                            <div>
                                <h4>{this.state.projectName}</h4>
                                <small>{this.state.producerName}</small>
                            </div>
                        </Nav>

                        <Nav className="ml-auto float-right" navbar>

                            {/*--------------------------------------------------------------------------------*/}
                            {/* Start Profile Dropdown                                                         */}
                            {/*--------------------------------------------------------------------------------*/}
                            <UncontrolledDropdown nav inNavbar>
                                <DropdownToggle nav caret className="pro-pic">
                                    <img
                                        src={profilephoto}
                                        alt="user"
                                        className="rounded-circle"
                                        width="31"
                                    />
                                </DropdownToggle>
                                <DropdownMenu right className="user-dd">
									<span className="with-arrow">
										<span className="bg-primary"/>
									</span>
                                    <div className="d-flex no-block align-items-center p-3 bg-primary text-white mb-2">
                                        <div className="">
                                            <img
                                                src={profilephoto}
                                                alt="user"
                                                className="rounded-circle"
                                                width="60"
                                            />
                                        </div>
                                        <div className="ml-2">
                                            <h4 className="mb-0">{this.state.name}</h4>
                                            <p className=" mb-0">{this.state.email}</p>
                                        </div>
                                    </div>
                                    {this.props.data.settings[0].viewBtnChangeProject? <DropdownItem href="#" onClick={this.ChangeProject}><i
                                        className="fas fa-film mr-1 ml-1"/> Cambiar Proyecto</DropdownItem>:""}

                                    <DropdownItem href="#" onClick={this.Logout}><i
                                        className="fa fa-power-off mr-1 ml-1"/> Salir</DropdownItem>

                                </DropdownMenu>
                            </UncontrolledDropdown>
                            {/*--------------------------------------------------------------------------------*/}
                            {/* End Profile Dropdown                                                           */}
                            {/*--------------------------------------------------------------------------------*/}
                        </Nav>
                    </Collapse>
                </Navbar>
            </header>
        );
    }
}

export default Header;
Header.contextType = CyaContext;
