import React, { useEffect, useState } from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import img2 from "../assets/images/big/film-bg.jpg";
import { NotFound } from "../views/pay-request/components/NotFound";
import { PayRequest } from "../views/pay-request/PayRequest";
import { PayRequesterLogin } from "../views/pay-request/PayRequestLogin";
import AuthService from "../utils/AuthService";

const authService = new AuthService();

export const PayRequestLayout = () => {
   const { path, url } = useRouteMatch();
   const isLoggedIn = authService.LoggedIn();
   const isAuthorized = authService.GetPathPermission("/payrequest");

   return (
      <div
         style={{
            width: "100%",
            minHeight: "100vh",
            backgroundImage: "url(" + img2 + ")",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
            backgroundSize: "cover",
         }}
      >
         <Switch>
            {isLoggedIn && isAuthorized && (
               <Route exact path={path}>
                  <PayRequest />
               </Route>
            )}
            <Route path={`${path}/login/:linkCode`}>
               <PayRequesterLogin />
            </Route>

            <Route path="*">
               <NotFound />
            </Route>
         </Switch>
      </div>
   );
};
