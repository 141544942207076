import React from 'react';
import "react-table/react-table.css";
import {
    Card,
    CardTitle,
    CardBody,
    Row,
    Col,
    Label, FormGroup,
    Modal, ModalHeader, ModalBody, ModalFooter, Button, Tooltip
} from 'reactstrap';
import {LoadingPage} from "../../components/custom/views/LoadingPage";
import {InputForm} from "../../components/custom/inputs/InputForm";
import {toast} from "react-toastify";
import {InputSelect} from "../../components/custom/inputs/InputSelect";
import {DateFormat} from "../../const/api_consts";
import {CyaContext} from "../../utils/CyaContext";
import {SimpleTable} from "../../components/custom/tables/SimpleTable";
import NotaryApiClient from "../../utils/api-client/NotaryApiClient";
import Datetime from "react-datetime";
import PersonApiClient from "../../utils/api-client/PersonApiClient";
import HiredPersonApiClient from "../../utils/api-client/HiredPersonApiClient";
import {ConfirmModal, SimplePersonProfileCard} from "../../components/custom/FunctionalComponents";
import {HiredPersonModel} from "../../models/HiredPersonModel";
import CyaHelper from "../../utils/CyaHelper";
import {InputImage} from "../../components/custom/inputs/InputImage";
import Select from 'react-select';
import {InputCurrency} from "../../components/custom/inputs/InputCurrency";

export default class ProjectPerson extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            data: new HiredPersonModel(),
            hiredPersonData:[],
            selectedHiredPerson:{},
            selectedPosition:null,
            loading: true,
            activeTab: '1',
            modal: false,
            modalHiredPerson: false,
            notaryData: [],
            personData: [],
            positionData: [],
            paymentMethodsData: [],
            contractsData: [{name:'Contrato Predeterminado',personId:0,contractPersonId:0,value:0,label:"Contrato Predeterminado"}],
            selected: null,
            personPosition: null,
            editModal:false,
            confirmModal:false,
            castSearchInput:"",
            crewSearchInput:"",
            toolTipPaymentMethodOpen:false,
        };
        this.PersonApiClient = new PersonApiClient();
        this.NotaryApiClient = new NotaryApiClient();
        this.HiredPersonApiClient = new HiredPersonApiClient();
    }

    componentDidMount() {
        CyaHelper.GetAllRequests([
            this.NotaryApiClient.GetAll(),
            this.PersonApiClient.GetAll(),
            this.PersonApiClient.Positions(),
            this.HiredPersonApiClient.PaymentMethods(),
            this.HiredPersonApiClient.GetAll(),
            this.PersonApiClient.GetContracts(),
        ]).then(responseArr=>{
            //Notary
            if (CyaHelper.isResponseOK(responseArr[0])){
                const notaryData = responseArr[0].data.map(x=>{x.value=x.notaryId;x.label=x.name;return x;});
                this.setState({notaryData});
            }
            //Person
            let personData = [];
            if (CyaHelper.isResponseOK(responseArr[1])){
                personData = responseArr[1].data;
                this.setState({personData});
            }
            //Positions
            let positionData = [];
            if (CyaHelper.isResponseOK(responseArr[2])){
                positionData = responseArr[2].data.map(x=>{x.value=x.positionId;x.label=x.name;return x;});
                this.setState({positionData});
            }
            //Payment Methods
            if (CyaHelper.isResponseOK(responseArr[3])){
                this.setState({paymentMethodsData: responseArr[3].data});
            }
            //Hired Person
            if (CyaHelper.isResponseOK(responseArr[4])){
                const hiredPersonData= responseArr[4].data.map(x=>{
                    const person = personData.find(y=>y.personId===x.personId);
                    x.personName = person.name;
                    x.idNumber = person.idNumber;
                    x.positionName = positionData.find(y => y.positionId === x.positionId).name;
                    return x;
                });

                this.setState({
                    hiredPersonData,
                    filteredHiredCast: hiredPersonData.filter(x => x.positionId === 1).map(x => {return x.personName}),
                    filteredHiredCrew: hiredPersonData.filter(x => x.positionId !== 1).map(x => {return x.personName})
                });
            }
            //Person Contracts
            if (CyaHelper.isResponseOK(responseArr[5])){
                const arr = responseArr[5].data.map(x=>{
                    x.value=x.contractPersonId;
                    x.label=x.name;
                    return x;
                });
                const contractsData = this.state.contractsData.concat(arr);

                this.setState({contractsData});
            }
            this.setState({loading:false});
        });
    }

    addPersonal = ()=>{
        if (this.state.selected==null) {
            toast.warn('Seleccionar un personal de la lista.');
            return;
        }

        //Confirmar si existe este actor con esta posicion
        const hiredPerson = this.state.hiredPersonData.find(x=>
            this.state.personData.filter(x=> x.positions.split(', ').length !== this.state.hiredPersonData.filter(y=>y.personId===x.personId).length)[this.state.selected].personId === x.personId &&
            x.positionId===this.state.data.positionId);

        if (hiredPerson!==undefined && hiredPerson.positionId===this.state.data.positionId){
            toast.error('ERROR. Existe esta persona con esta posición. Seleccione otra posición. ');
            return;
        }

        let data = this.state.data;
        data.personId = this.state.personData[this.state.selected].personId;

        this.setState({loading: true,modal: !this.state.modal});
        this.HiredPersonApiClient.Create(data).then(response=>{
            let hiredPersonData =this.state.hiredPersonData;
            if (response.response!==undefined && response.response.data==="Error"){
                toast.error("Error Guardando Personal");
            }else {
                const person =this.state.personData.filter(y=>y.personId===response.personId)[0];
                response.personName=person.name;
                response.idNumber=person.idNumber;
                response.positionName=this.state.positionData.filter(y=>y.positionId===response.positionId)[0].name;


                hiredPersonData.push(response);

                toast.success("Personal Guardado.");
            }
            this.setState({hiredPersonData,loading: false,selected: null, editModal: false, selectedPosition: null, data: new HiredPersonModel()});
        });

    };

    updatePersonal = () =>{
        this.HiredPersonApiClient.Update(this.state.data.personId, this.state.data).then(response=>{
            if (CyaHelper.isResponseOK(response)){
                toast.success('Personal actualizado satisfactoriamente.');
                //Delete old hiredPerson
                let hiredPersonData = this.state.hiredPersonData;
                const hiredPersonToDeleteIndex=this.state.hiredPersonData.findIndex(x=>x.personId===response.personId && x.positionId===response.positionId);
                if (hiredPersonToDeleteIndex>-1){
                    hiredPersonData.splice(hiredPersonToDeleteIndex,1 );
                }

                //Add updated person
                const person = this.state.personData.find(y=>y.personId===response.personId);
                response.personName = person.name;
                response.idNumber = person.idNumber;
                response.positionName = this.state.positionData.find(y => y.positionId === response.positionId).name;

                hiredPersonData.push(response);
                this.setState({hiredPersonData});
            }
            else{
                toast.error('ERROR. Se ha producido un error mientras se actualizaba.')
            }
            this.setState({selected: null, editModal: false, selectedPosition: null, data: new HiredPersonModel()});
            this.toggleModal();
        });
    };

    toggleModal = (type="") => {
        if (type==="cancel"){
            this.setState({selected: null, editModal: false, selectedPosition: null, data: new HiredPersonModel()});
        }
        this.setState({modal: !this.state.modal});
    };

    onClickPerson = (event,selectedHiredPerson) => {
        event.preventDefault();
        this.setState({loading:true});
        this.HiredPersonApiClient.GetHiredPerson(selectedHiredPerson.personId,selectedHiredPerson.positionId).then(response=>{
            if(CyaHelper.isResponseOK(response)){
                this.setState({
                    loading: false,
                    data: response,
                    editModal: true,
                    selectedPosition: this.state.positionData.find(x => x.positionId === response.positionId)
                });
                this.toggleModal();
            }else {
                toast.error('Error Cargando datos del personal.');
            }
        });
    };

    deleteConfirmation= (confirm) =>{
        if (confirm){
            this.onDeleteHiredPerson();
        }
        this.setState({confirmModal:false});
    };

    onDeleteHiredPerson = () => {
        this.setState({loading:true});
        this.toggleModal();
        this.HiredPersonApiClient.DeleteHiredPerson(this.state.data.personId,this.state.data.positionId).then(response=>{
            if (CyaHelper.isResponseOK(response)){
                //para refrescar lista
                this.HiredPersonApiClient.GetAll().then(response=>{
                    const hiredPersonData= response.data.map(x=>{
                        const person = this.state.personData.find(y=>y.personId===x.personId);
                        x.personName = person.name;
                        x.idNumber = person.idNumber;
                        x.positionName = this.state.positionData.find(y => y.positionId === x.positionId).name;
                        return x;
                    });

                    this.setState({
                        hiredPersonData,
                        filteredHiredCast: hiredPersonData.filter(x => x.positionId === 1).map(x => {return x.personName}),
                        filteredHiredCrew: hiredPersonData.filter(x => x.positionId !== 1).map(x => {return x.personName}),
                        loading: false,
                        selected: null,
                        editModal: false,
                        selectedPosition: null,
                        data: new HiredPersonModel()
                    });
                    toast.success('Personal Eliminado.');
                });
            } else {
                toast.error('Error eliminando personal.');
            }

        });
    };

    onSelectChange = (selectedValue,key) => {
        let data = this.state.data;
        data[key] = selectedValue === null ? "0" : selectedValue[key];
        this.setState({data});
        if (key==='positionId'){
            this.setState({selectedPosition:this.state.positionData.find(x=>x.positionId===selectedValue[key])});
        }
    };

    tProps = (state, rowInfo) => {
        if (rowInfo && rowInfo.row) {
            return {
                onClick: () => {
                    const arrayStringPositions = this.state.personData[rowInfo.index].positions.split(', ')
                        .filter(x=> !this.state.hiredPersonData.filter(x=>x.personId===this.state.personData[rowInfo.index].personId).map(x=>x.positionName).includes(x));
                    const selectedPosition  =this.state.positionData.find(x=>x.name===arrayStringPositions[0]);
                    let data = this.state.data;
                    data.positionId=selectedPosition.positionId;
                    this.setState({data,selected: rowInfo.index,selectedPosition })
                },
                style: {
                    background: rowInfo.index === this.state.selected ? '#00AFEC' : rowInfo.index % 2 === 0 ? '#DDDDDD' : '#FFFFFF',
                    color: rowInfo.index === this.state.selected ? 'white' : 'black'
                }
            }
        }
        return {};
    };

    onSearchInputChange = (event) => {
        if (event.target.name === 'cast') {
            this.setState({castSearchInput: event.target.value});
        }
        if (event.target.name === 'crew') {
            this.setState({crewSearchInput: event.target.value});
        }
    };

    onInputSelectChange = (event) => {
        let aux = this.state.data;
        aux[event.target.name] = Number(event.target.value);
        this.setState({data: aux});
    };

    onDateChange = (moment, dateName) => {
        let data = this.state.data;
        data[dateName] = moment.format("YYYY-MM-DD");
        this.setState({data});
    };

    onCurrencyChange = (event, maskedvalue, floatvalue) => {
        let data = this.state.data;
        data[event.target.name] = floatvalue;
        this.setState({data});
    };

    onInputChange = (event) => {
        let aux = this.state.data;
        aux[event.target.name] = event.target.value;
        this.setState({data: aux});
    };

    toggleTooltip = (keyState) =>{
        this.setState({[keyState]: !this.state[keyState]})
    };

    render() {
        const personColumns = [{Header: "ID", accessor: "personId", show: false}, {Header: "Nombre", accessor: "name"}, {Header: "Apodo", accessor: "nickname"},{Header: "Posiciones", accessor: "positions"}, {Header: "ID", accessor: "idNumber", width: 150},];

        const filteredHiredCast=CyaHelper.GetFilteredStringArrayBySearch(this.state.hiredPersonData.filter(x => x.positionId === 1).map(x => {return x.personName}), this.state.castSearchInput);
        const filteredHiredCrew=CyaHelper.GetFilteredStringArrayBySearch(this.state.hiredPersonData.filter(x => x.positionId !== 1).map(x => {return x.personName}), this.state.crewSearchInput);

        const hiredCast = this.state.hiredPersonData.filter(x => x.positionId === 1).filter(x => filteredHiredCast.includes(x.personName));
        const hiredCrew = this.state.hiredPersonData.filter(x => x.positionId !== 1).filter(x => filteredHiredCrew.includes(x.personName));

        const selectedPersonId=this.state.selected!=null?this.state.personData[this.state.selected].personId:0;
        let contractsAvailableForSelectedPerson = [];

        //Filtering personData for the Add New Person table modal.
        const personDataFiltered = this.state.personData.filter(x=> x.positions.split(', ').length !== this.state.hiredPersonData.filter(y=>y.personId===x.personId).length);

        let positionsToShow = [];

        if (this.state.selected==null && !this.state.editModal) {
            positionsToShow = this.state.positionData;
            contractsAvailableForSelectedPerson = this.state.contractsData.filter(x => x.personId === selectedPersonId || x.personId === 0 );
        }
        else if (this.state.selected==null && this.state.editModal){
            //este caso es si esta readonly en editmode
            contractsAvailableForSelectedPerson = this.state.contractsData;
            positionsToShow = this.state.positionData.filter(x => this.state.personData.find(x=>x.personId===this.state.data.personId).positions.split(', ').includes(x.name));
        }
        else{
            //caso esta seleccionado un person en la lista de add new hiredperson.
            positionsToShow = this.state.positionData.filter(x => personDataFiltered[this.state.selected].positions.split(', ').includes(x.name));
            contractsAvailableForSelectedPerson = this.state.contractsData.filter(x => x.personId === selectedPersonId || x.personId === 0 );
            this.state.hiredPersonData.filter(x => personDataFiltered[this.state.selected].personId === x.personId).map(x => {
                const indexPositionToDelete = positionsToShow.findIndex(y => x.positionId === y.positionId);
                positionsToShow.splice(indexPositionToDelete, 1);
                return true;
            });
        }

        return (
            <div>
                <ConfirmModal title={'Desea eliminar este personal?'} body={this.state.personData.find(x=>x.personId===this.state.data.personId)? this.state.personData.find(x=>x.personId===this.state.data.personId).name:""} confirmation={this.deleteConfirmation} modal={this.state.confirmModal}/>

                <Modal isOpen={this.state.modal} toggle={()=>this.toggleModal("cancel")} className={'modal-lg'}>
                    <ModalHeader toggle={()=>this.toggleModal("cancel")}>{this.state.editModal? "Editar":"Agregar"} personal</ModalHeader>
                    <ModalBody>
                        {this.state.editModal?
                            <div>
                                <h4>General</h4>
                                <Row>
                                    <Col md="3" xs="6" className="border-right">
                                        <strong>Nombre</strong>
                                        <br/>
                                        <p className="text-muted">{this.state.personData.find(x=>x.personId===this.state.data.personId).name}</p>
                                    </Col>
                                    <Col md="3" xs="6" className="border-right">
                                        <strong>Identificación</strong>
                                        <br/>
                                        <p className="text-muted">{this.state.personData.find(x=>x.personId===this.state.data.personId).idNumber}</p>
                                    </Col>
                                    <Col md="3" xs="6" className="border-right">
                                        <strong>Sirecine</strong>
                                        <br/>
                                        <p className="text-muted">{this.state.personData.find(x=>x.personId===this.state.data.personId).sirecineNumber}</p>
                                    </Col>
                                    <Col md="3" xs="6" className="border-right">
                                        <strong>Expiración Sirecine</strong>
                                        <br/>
                                        <p className="text-muted">{CyaHelper.formatDateYYYYMMDD(this.state.personData.find(x=>x.personId===this.state.data.personId).sirecineExpDate)}</p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6} xs={12} className="border-right">
                                        <strong>Posiciones</strong>
                                        <br/>
                                        <p className="text-muted">{this.state.personData.find(x=>x.personId===this.state.data.personId).positions}</p>
                                    </Col>
                                    <Col md="3" xs="6" className="border-right">
                                        <strong>Dirección</strong>
                                        <br/>
                                        <p className="text-muted">{this.state.personData.find(x=>x.personId===this.state.data.personId).address}</p>
                                    </Col>
                                    <Col md="3" xs="6" className="border-right">
                                        <strong>Nacionalidad</strong>
                                        <br/>
                                        <p className="text-muted">{this.state.personData.find(x=>x.personId===this.state.data.personId).nationality}</p>
                                    </Col>
                                </Row>
                            </div>
                            :
                            <Row>
                                <Col md={12}>
                                    <SimpleTable data={personDataFiltered} columns={personColumns} defaultPageSize={5}
                                                 filterable={true} trProps={this.tProps}/>
                                </Col>
                            </Row>
                        }

                        <hr/>
                        <h4>Detalles</h4>
                        <Row>
                            <Col md={this.state.data.positionId===1?6:12}>
                                <FormGroup>
                                    <Label htmlFor={"positionId"}>Posición: </Label>
                                    <Select options={positionsToShow} id={"positionId"} name={"positionId"} value={this.state.selectedPosition===undefined?null:this.state.selectedPosition} isLoading={this.state.loading} placeholder={"Seleccione posición"} onChange={(o)=>this.onSelectChange(o,'positionId')} isDisabled={this.state.editModal}/>
                                </FormGroup>

                            </Col>
                            {this.state.data.positionId===1?
                                <Col md={6}>
                                    <InputForm id_label={"character"} label={"Personaje:"} placeholder={""} type={"text"} value={this.state.data.character} onInputChange={this.onInputChange}/>
                                </Col>
                                :''}

                        </Row>
                        <Row>
                            <Col md={6}>
                                <InputCurrency id_label={"paymentAmount"} label={"Monto:"} placeholder={""} value={this.state.data.paymentAmount} onInputChange={this.onCurrencyChange}/>
                            </Col>
                            <Col md={6}>
                                <Label htmlFor={"date"}>Fecha Contrato: </Label>
                                <Datetime inputProps={{readOnly: true, style: {backgroundColor: "transparent"}}} locale={"es-do"} utc={true} timeFormat={false} defaultValue={new Date()} dateFormat={DateFormat} closeOnSelect={true} value={new Date(this.state.data.date)} onChange={(moment) => this.onDateChange(moment, 'date')}/>
                            </Col>

                        </Row>
                        <Row>
                            <Col md={6}>
                                <InputForm id_label={"validity"} label={"Vigencia (Semanas):"} placeholder={""} type={"number"} value={this.state.data.validity} onInputChange={this.onInputChange}/>
                            </Col>
                            <Col md={6}>
                                <InputSelect id_label={"paymentMethodId"} data={this.state.paymentMethodsData} label={"Forma de Pago:"} dataDescription={["name"]} dataValue={"paymentMethodId"} selectedValue={Number(this.state.data.paymentMethodId)} onInputChange={this.onInputSelectChange}
                                />
                                <Tooltip placement="top" isOpen={this.state.toolTipPaymentMethodOpen} target="paymentMethodId" toggle={()=>this.toggleTooltip('toolTipPaymentMethodOpen')}>
                                    {this.state.paymentMethodsData.length>0? this.state.paymentMethodsData.find(x=>x.paymentMethodId===Number(this.state.data.paymentMethodId)).description :''}
                                </Tooltip>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={6}>
                                <InputSelect id_label={"notaryId"} data={this.state.notaryData} label={"Notario:"} dataDescription={["name"]} dataValue={"notaryId"} selectedValue={Number(this.state.data.notaryId)} onInputChange={this.onInputSelectChange}
                                />
                            </Col>
                            <Col md={6}>
                                <InputSelect id_label={"contractPersonId"} data={contractsAvailableForSelectedPerson} label={"Contrato:"} dataDescription={["name"]} dataValue={"contractPersonId"} selectedValue={Number(this.state.data.contractPersonId)} onInputChange={this.onInputSelectChange}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                {CyaHelper.ImageExists(this.state.data.image) ?
                                    <InputImage id_label={"image"} width={"fit-content"} label={"Copia de contrato:"} image={this.state.data.image} acceptPDF={true} hash={this.state.data.image.hash} showViewButton={true} showImageMagnify={false}/>
                                    :
                                    <div>
                                        <InputImage id_label={"image"} width={"fit-content"} label={"Copia de contrato:"} image={this.state.data.image} acceptPDF={true} showViewButton={true} showImageMagnify={false}/>
                                    </div>
                                }
                            </Col>

                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.state.editModal?this.updatePersonal:this.addPersonal}>{this.state.editModal?'Actualizar':'Agregar'}</Button>{' '}
                        {this.state.editModal?
                            <Button color="danger" onClick={()=>this.setState({confirmModal:true})}>Eliminar</Button>
                        :''}
                        <Button color="secondary" onClick={()=>this.toggleModal("cancel")}>Cancelar</Button>
                    </ModalFooter>

                </Modal>

                <Card>
                    <CardTitle className="mb-0 p-3 border-bottom bg-light">
                        <i className="fas fa-user-circle mr-2"/>Personal
                    </CardTitle>
                    <CardBody>
                        <LoadingPage color={"3e5569"} loading={this.state.loading} type={"spin"}/>
                        <Row>
                            <Col md="6">
                                <input type="submit" className={('btn btn-primary ')} value="Añadir Nuevo" onClick={this.toggleModal}/>
                            </Col>
                        </Row>

                        <hr/>

                        <Row>
                            <Col md={6}>
                                <h3>Actores/Actrices</h3>
                                <Row>
                                    <Col md={12}>
                                        <div className="pb-2">
                                            <form>
                                                <input className="form-control" value={this.state.castSearchInput} type="text" placeholder="Buscar..." name={'cast'} onChange={this.onSearchInputChange}/>
                                            </form>
                                        </div>
                                    </Col>
                                </Row>

                                <Row style={{backgroundColor: "#F6F9FC"}} className={'m-1'}>
                                    {hiredCast.length>0?
                                        hiredCast.map((x,i) => {
                                        return (
                                            <Col md={3} className={'pt-2 pb-0'} key={i}>
                                                <SimplePersonProfileCard noLabel={true} data={x} onClick={e=>this.onClickPerson(e,x)}/>
                                            </Col>
                                        )
                                    }):"No hay Resultados"}

                                </Row>
                            </Col>
                            <Col md={6}>
                                <h3>Técnicos</h3>
                                <Row>
                                    <Col md={12}>
                                        <div className="pb-2">
                                            <form>
                                                <input className="form-control" value={this.state.crewSearchInput} type="text" placeholder="Buscar..." name={'crew'} onChange={this.onSearchInputChange}/>
                                            </form>
                                        </div>
                                    </Col>
                                </Row>

                                <Row style={{backgroundColor: "#F6F9FC"}} className={'m-1'}>
                                    {hiredCrew.length > 0 ?
                                        hiredCrew.map((x, i) => {
                                            return (
                                                <Col md={3} className={'pt-2 pb-0'} key={i}>
                                                    <SimplePersonProfileCard noLabel={false} data={x} onClick={e => this.onClickPerson(e, x)}/>
                                                </Col>
                                            )
                                        }) : "No hay Resultados"}
                                </Row>
                            </Col>
                        </Row>

                        <hr/>
                    </CardBody>
                </Card>
            </div>
        );
    }
}

ProjectPerson.contextType = CyaContext;
