import CyaApiClient from "./CyaApiClient";

export default class ProjectInvestorApiClient extends CyaApiClient {

    constructor() {
        super("ProjectInvestor");
        this.GetListForChecks = this.GetListForChecks.bind(this);
    }

    GetListForChecks() {
        return this.MakeCall('get', this.ControllerName + '/listForChecks');
    }

    GetContractAAnnex(investorId) {
        return this.MakeCall('get', this.ControllerName + '/contractAAnnex',investorId);
    }

    SaveContractAAnnex(data) {
        return this.MakeCall('post', this.ControllerName + '/contractAAnnex', data);
    }

    GetContractInversion(investorId) {
        return this.MakeCall('get', this.ControllerName + '/contractInversion',investorId);
    }

    SaveContractInversion(data) {
        return this.MakeCall('post', this.ControllerName + '/contractInversion', data);
    }

    GetContractPowerRepresentation(investorId) {
        return this.MakeCall('get', this.ControllerName + '/contractPowerRepresentation',investorId);
    }

    SaveContractPowerRepresentation(data) {
        return this.MakeCall('post', this.ControllerName + '/contractPowerRepresentation', data);
    }

    GetContractWarranty(investorId) {
        return this.MakeCall('get', this.ControllerName + '/contractWarranty',investorId);
    }

    SaveContractWarranty(data) {
        return this.MakeCall('post', this.ControllerName + '/contractWarranty', data);
    }

    GetInvestorContracts(investorId) {
        return this.MakeCall('get', this.ControllerName + '/investorContracts',investorId);
    }
    //pre project
    DeleteContract(id) {
        return this.MakeCall('delete', 'Project' + this.ControllerName + '/deleteContract', {id: id});
    }
}
