export class ValidationModel {
    validationId = 0;
    validationNumber = 0;
    validationOrdinal = "Nueva"; 
    closed = false;
    date = new Date();
    investor = [];
}






