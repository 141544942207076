import React from 'react';
import "react-table/react-table.css";
import {CyaTableLayout} from "../../components/custom/tables/CyaTableLayout";
import CyaHelper from "../../utils/CyaHelper";
import ItemApiClient from "../../utils/api-client/ItemApiClient";
import {SuccessResponse} from "../../const/api_consts";
import {toast} from "react-toastify";
import AuthService from "../../utils/AuthService";

export default class Item extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            data:[],
            loading: true,
            selectedRowId: -1,
            selected0: -1,
            selected1: -1,
            selected2: -1,
        };
        this.GetProps0 = this.GetProps0.bind(this);
        this.GetProps1 = this.GetProps1.bind(this);
        this.GetProps2 = this.GetProps2.bind(this);
        this.Auth = new AuthService();
        this.ItemApiClient = new ItemApiClient();
        this.ItemApiClient.GetAll().then(response => this.setState({data: response.data, loading: false}));
    }

    GetProps0(state, rowInfo) {
        if (rowInfo && rowInfo.row) {
            return {
                onClick: (e) => {
                    this.setState({
                        selected0: rowInfo.index,
                        selected1: -1,
                        selected2: -1,
                        selectedRowId: rowInfo.row.itemId
                    });
                },
                onDoubleClick: (e) => {
                    if (this.state.selected0 === rowInfo.index) {
                        this.props.history.push({
                            pathname: '/items/edit',
                            state: {id: rowInfo.row.itemId}
                        });
                    }
                },
                style: {
                    background: rowInfo.row.itemId === this.state.selectedRowId ? '#00AFEC' : (rowInfo.index%2 === 0 ? '#DDDDDD' : '#FFFFFF'),
                    color: rowInfo.row.itemId === this.state.selectedRowId ? 'white' : 'black'
                }
            }
        }
        return {};
    }

    GetProps1(state, rowInfo) {
        if (rowInfo && rowInfo.row) {
            return {
                onClick: (e) => {
                    this.setState({
                        selected0: -1,
                        selected1: rowInfo.index,
                        selected2: -1,
                        selectedRowId: rowInfo.row.itemId
                    });
                },
                onDoubleClick: (e) => {
                    if (this.state.selected1 === rowInfo.index) {
                        this.props.history.push({
                            pathname: '/items/edit',
                            state: {id: rowInfo.row.itemId}
                        });
                    }
                },
                style: {
                    background: rowInfo.row.itemId === this.state.selectedRowId ? '#00AFEC' : (rowInfo.index%2 === 0 ? '#DDDDDD' : '#FFFFFF'),
                    color: rowInfo.row.itemId === this.state.selectedRowId ? 'white' : 'black'
                }
            }
        }
        return {};
    }

    GetProps2(state, rowInfo) {
        if (rowInfo && rowInfo.row) {
            return {
                onClick: (e) => {
                    this.setState({
                        selected0: -1,
                        selected1: -1,
                        selected2: rowInfo.index,
                        selectedRowId: rowInfo.row.itemId
                    });
                },
                onDoubleClick: (e) => {
                    if (this.state.selected2 === rowInfo.index) {
                        this.props.history.push({
                            pathname: '/items/edit',
                            state: {id: rowInfo.row.itemId}
                        });
                    }
                },
                style: {
                    background: rowInfo.row.itemId === this.state.selectedRowId ? '#00AFEC' : (rowInfo.index%2 === 0 ? '#DDDDDD' : '#FFFFFF'),
                    color: rowInfo.row.itemId === this.state.selectedRowId ? 'white' : 'black'
                }
            }
        }
        return {};
    }

    viewCallback = () => {
        if (this.state.selectedRowId >= 0) {
            this.props.history.push({
                pathname: '/items/edit',
                state: {id: this.state.selectedRowId}
            });
        }
    };

    deleteCallback = () => {
        if (this.state.selectedRowId > 0) {
            this.ItemApiClient.Delete(this.state.selectedRowId).then((response) => {
                if (response !== SuccessResponse) {
                    toast.error("Error eliminado partida.");
                } else {
                    this.props.history.push({pathname: '/refresh/items/'});
                    toast.success("Partida eliminada.");
                }
            }).catch(() => {
                toast.warn("Error eliminando partida.");
            });
        }

    };

    render() {
        const { data } = this.state;
        let columns = [
            {Header: "ItemId", accessor: "itemId", show: false},
            {Header: "Código", id: "code", style:{fontWeight: "bold"},accessor: "code", width: 150},
            {Header: "Nombre", accessor: "name", style:{fontWeight: "bold"},minWidth: 300},
            {Header: "Subtotal", accessor: "subtotal", style: {textAlign: 'right', fontWeight: "bold"}, Cell: props => CyaHelper.ToCurrency(props.value), width: 150},
            {Header: "ITBIS", accessor: "itbis", style: {textAlign: 'right', fontWeight: "bold"}, Cell: props => CyaHelper.ToCurrency(props.value), width: 150},
            {Header: "Total", accessor: "total", style: {textAlign: 'right', fontWeight: "bold"}, Cell: props => CyaHelper.ToCurrency(props.value), width: 150}
        ];

        let subComponent = row => {
            let columns2 = [
                {Header: "ItemId", accessor: "itemId", show: false},
                {Header: "", width: 30},
                {Header: "Código", id: "code", style:{fontStyle: "italic"},accessor: "code", width: 150},
                {Header: "Nombre", accessor: "name", style:{fontStyle: "italic"},minWidth: 300},
                {Header: "Subtotal", accessor: "subtotal", style: {textAlign: 'right', fontStyle: "italic"}, Cell: props => CyaHelper.ToCurrency(props.value), width: 150},
                {Header: "ITBIS", accessor: "itbis", style: {textAlign: 'right', fontStyle: "italic"}, Cell: props => CyaHelper.ToCurrency(props.value), width: 150},
                {Header: "Total", accessor: "total", style: {textAlign: 'right', fontStyle: "italic"}, Cell: props => CyaHelper.ToCurrency(props.value), width: 150}
            ];

            return <CyaTableLayout title={row.original.name}
                                   columns={columns2}
                                   data={row.original.items}
                                   nestedLevel={1}
                                   loading={this.state.loading}
                                   trProps={this.GetProps1}
                                   deleteCallback={this.deleteCallback}
                                   viewCallback={this.viewCallback}
                                   subComponent={row2 => {
                                    let columns3 = [
                                        {Header: "ItemId", accessor: "itemId", show: false},
                                        {Header: "", width: 90},
                                        {Header: "Código", id: "code", accessor: "code", width: 150},
                                        {Header: "Nombre", accessor: "name", minWidth: 300},
                                        {Header: "Subtotal", accessor: "subtotal", style: {textAlign: 'right'}, Cell: props => CyaHelper.ToCurrency(props.value), width: 150},
                                        {Header: "ITBIS", accessor: "itbis", style: {textAlign: 'right'}, Cell: props => CyaHelper.ToCurrency(props.value), width: 150},
                                        {Header: "Total", accessor: "total", style: {textAlign: 'right'}, Cell: props => CyaHelper.ToCurrency(props.value), width: 150}
                                    ];
                                    return <CyaTableLayout title={row2.original.name}
                                                           columns={columns3}
                                                           data={row2.original.items}
                                                           nestedLevel={3}
                                                           loading={this.state.loading}
                                                           trProps={this.GetProps2}
                                                           deleteCallback={this.deleteCallback}
                                                           viewCallback={this.viewCallback} />;
                                }}/>;
        };

        return <CyaTableLayout
            title={"Partidas"}
            columns={columns}
            data={data}
            subComponent={subComponent}
            trProps={this.GetProps0}
            deleteCallback={this.deleteCallback}
            enableButtons={this.state.selectedRowId >= 0}
            viewCallback={this.viewCallback}
            showDeleteButton={this.Auth.GetPathPermission(this.props.location.pathname+"/delete",false)}
            loading={this.state.loading} />;
    }
}
