import CyaReadApiClient from "./CyaReadApiClient";

export default class DashboardApiClient extends CyaReadApiClient {

    constructor(){
        super("Dashboard");
        this.GetStagesCompletion = this.GetStagesCompletion.bind(this);
        this.GetAnnexStage = this.GetAnnexStage.bind(this);
        this.GetProjectSummary = this.GetProjectSummary.bind(this);
        this.GetInvestorTable = this.GetInvestorTable.bind(this);
        this.GetItemTable = this.GetItemTable.bind(this);
    }

    GetStagesCompletion() {
        return this.MakeCall('get', this.ControllerName + '/stages');
    }

    GetAnnexStage() {
        return this.MakeCall('get', this.ControllerName + '/annexStage');
    }

    GetProjectSummary() {
        return this.MakeCall('get', this.ControllerName + '/summary');
    }

    GetInvestorTable() {
        return this.MakeCall('get', this.ControllerName + '/investorTable');
    }

    GetItemTable() {
        return this.MakeCall('get', this.ControllerName + '/itemTable');
    }

    GetAnnexIsrTypes() {
        return this.MakeCall('get', this.ControllerName + '/annexIsrType');
    }
}