import CyaApiClient from "./CyaApiClient";

export default class AnnexApiClient extends CyaApiClient {

    constructor(){
        super("Annex");
        this.GetTypes = this.GetTypes.bind(this);
        this.GetIdTypes = this.GetIdTypes.bind(this);
        this.GetStages = this.GetStages.bind(this);
        this.GetIsrRetentionTypes = this.GetIsrRetentionTypes.bind(this);
        this.GetNameByID = this.GetNameByID.bind(this);
        this.GetByCheckId = this.GetByCheckId.bind(this);
    }

    GetTypes() {
        return this.MakeCall('get', this.ControllerName + '/types');
    }

    GetIdTypes() {
        return this.MakeCall('get', this.ControllerName + '/idTypes');
    }

    GetStages() {
        return this.MakeCall('get', this.ControllerName + '/stages');
    }

    GetIsrRetentionTypes() {
        return this.MakeCall('get', this.ControllerName + '/isrRetentionTypes');
    }

    GetExpenseTypes() {
        return this.MakeCall('get', this.ControllerName + '/expenseTypes');
    }

    GetNameByID(id) {
        return this.MakeCall('get', this.ControllerName + '/nameByIdentifier', {identifier: id});
    }

    GetByCheckId(id) {
        return this.MakeCall('get', this.ControllerName + '/byCheckId', {id: id});
    }

    MassDelete(objects) {
        return this.MakeCall('post', this.ControllerName + '/massDelete', {objects: objects});
    }

    MassChange(changeType,objects) {
        return this.MakeCall('post', this.ControllerName + '/massChange', {changeType:changeType,objects: objects});
    }

    GetListForCreditNotes() {
        return this.MakeCall('get', this.ControllerName + '/listForCreditNotes');
    }

    ExistsNfcAndRnc(id,idTypeId,ncf) {
        return this.MakeCall('get', this.ControllerName + '/existsNfcAndRnc',{id,idTypeId,ncf});
    }
}