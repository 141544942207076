import React from 'react';
import Datetime from "react-datetime";
import 'react-datetime/css/react-datetime.css';
import {
    Card,
    CardBody,
    CardTitle,
    Row,
    Col,
    Label,
    Form,
    FormGroup,
    // CustomInput
} from 'reactstrap';
import {InputForm} from '../../components/custom/inputs/InputForm';
import {InputSelect} from '../../components/custom/inputs/InputSelect';
import {InputTextArea} from '../../components/custom/inputs/InputTextArea';
import CheckApiClient from "../../utils/api-client/CheckApiClient";
import BankApiClient from "../../utils/api-client/BankApiClient";
import ProjectInvestorApiClient from "../../utils/api-client/ProjectInvestorApiClient";
import {CheckModel} from "../../models/CheckModel";
import {InputCurrency} from "../../components/custom/inputs/InputCurrency";
import {InputImage} from "../../components/custom/inputs/InputImage";
import {ImageModel} from "../../models/ImageModel";
import {LoadingPage} from "../../components/custom/views/LoadingPage";
import CyaHelper from "../../utils/CyaHelper";
import {MultiTable} from "../../components/custom/tables/MultiTable";
import {DateFormat} from "../../const/api_consts";
import Select from 'react-select';
import ItemApiClient from "../../utils/api-client/ItemApiClient";
import FieldValidator from "../../utils/field-validators/FieldValidator";
import CheckFieldValidator from "../../utils/field-validators/CheckFieldValidator";
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import AuthService from "../../utils/AuthService";
import {CyaContext} from "../../utils/CyaContext";
import {CheckValidator} from "../../utils/validators/CheckValidator";
import md5 from 'md5';
import Compress from "compress.js";
import moment from "moment";
import CyAHelper from "../../utils/CyaHelper";
import RotateImage from "../../components/custom/rotateImage";

require('moment/locale/es-do');

export default class CreateCheck extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: new CheckModel(),
            initialData: new CheckModel(),
            loading: true,
            file: '',
            bankData: [],
            checkTypesData: [],
            finalsCheckData: [],
            selectedFinalsChecks: [],
            depositsCheckData: [],
            selectedDepositChecks: [],
            investorData: [],
            assignInvestor: [],
            investorLoading: true,
            showTypeCheck: true,
            showInvestorTable: true,
            showSaveButton: true,
            selectedTypeCheck: 1,
            checksData: [],
            itemsData: [],
            selectedItem: 1,
            selectedParentCheck: 1,
            bankSizeCol: 6,
            itemSizeCol: 6,
            title: "Crear Cheque",
            remainingAmount: 0,
            modelCreated: "Cheque Guardado.",
            modelUpdated: "Cheque Actualizado.",
            modelError: "Error salvando cheque.",
            responseError: null,
        };

        this.Validator = new FieldValidator(CheckFieldValidator);
        this.onInputChange = this.onInputChange.bind(this);
        this.onCheckboxChange = this.onCheckboxChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onDateChange = this.onDateChange.bind(this);
        this.handleCurrencyChange = this.handleCurrencyChange.bind(this);
        this.onSelectChange = this.onSelectChange.bind(this);
        this.renderEditable = this.renderEditable.bind(this);
        this.editPromise = this.editPromise.bind(this);
        this.onFinalCheckChange = this.onFinalCheckChange.bind(this);
        this.onItemChange = this.onItemChange.bind(this);
        this.onBlurNumber = this.onBlurNumber.bind(this);

        this.Auth = new AuthService();
        this.ItemApiClient = new ItemApiClient();
        this.CheckApiClient = new CheckApiClient();
        this.BankApiClient = new BankApiClient();
        this.InvestorApiClient = new ProjectInvestorApiClient();
    }

    componentDidMount() {
        this.CheckApiClient.GetTypes().then(response =>
            this.setState({checkTypesData: response.data}
            )).then(() => {
            this.BankApiClient.GetAll().then(response => this.setState({bankData: response.data}));
        }).then(() => {
            this.InvestorApiClient.GetListForChecks().then(response => {
                let investors = response.data.map(x => {
                    return {
                        id: x.investorId,
                        name: x.name,
                        amount: x.amountAvailable,
                        depositDate: x.depositDate,
                        newAmount: 0
                    }
                });
                this.setState({investorData: investors, investorLoading: false})
            }).then(() => {
                this.CheckApiClient.GetFinals().then(response => {
                    let finalsCheck = response.data
                        .map(x => {
                            return {
                                label: x.number + " - " + x.bankDescription,
                                value: x.checkId,
                            };
                        });
                    this.setState({finalsCheckData: finalsCheck});
                }).then(() => {
                    this.ItemApiClient.GetListForAnnexes().then(response => {
                        let items = response.data.map(x => {
                            return {
                                value: x.itemId,
                                label: "[" + x.code + "] " + x.name,
                            }
                        });
                        this.setState({itemsData: items});
                    }).then(() => {
                        this.CheckApiClient.GetDeposits().then(response => {
                            let depositsCheckData = response.data
                                .map(x => {
                                    return {
                                        label: x.number + " - " + x.bankDescription,
                                        value: x.checkId,
                                        investorId: x.investorCheck.length > 0 ? x.investorCheck[0].investorId : 0,
                                    };
                                });
                            this.setState({depositsCheckData: depositsCheckData});
                        }).then(() => {
                            if (this.props.location.state !== undefined && this.props.location.state?.id) {
                                this.CheckApiClient.GetById(this.props.location.state.id).then(response => {
                                    let aux = this.state.investorData;
                                    response.investorCheck.map(x => {
                                        let index = aux.findIndex(y => y.id === x.investorId);
                                        aux[index].amount += x.amount;
                                        return x;
                                    });
                                    this.setState({investorData: aux});
                                    this.editPromise(response);
                                });
                            } else {
                                this.setState({loading: false});
                            }
                        });
                    });
                });
            });
        });
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if (!nextState.loading && CyaHelper.ObjectCompare(nextState.data, this.state.data))
            this.Validator.initValidation(nextState.data);

        return true;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!this.state.loading) {
            let isFormDirty = !CyaHelper.ObjectCompare(this.state.initialData, this.state.data);
            this.context.toggleExitConfirmation(isFormDirty);
        }
    }

    editPromise(response) {
        let left = this.state.investorData;
        this.state.assignInvestor.map(x => {
            left.push(x);
            return x;
        });
        this.setState({
            investorData: left,
        });
        if (response.image === null)
            response.image = new ImageModel();
        if (response.comment === null)
            response.comment = "";

        let investors = response.investorCheck.map(y => {
            let investor = this.state.investorData.find(x => x.id === y.investorId);
            return {
                id: investor.id,
                name: investor.name,
                amount: investor.amount,
                depositDate: investor.depositDate,
                newAmount: y.amount
            };
        });


        let selectedDepositChecks = response.children
            .map(x => {
                return {
                    label: x.number + " - " + x.bankDescription,
                    value: x.checkId,
                    investorId: x.investorCheck.length > 0 ? x.investorCheck[0].investorId : 0,
                };
            });

        response.children = response.children.map(x => {
            return {
                checkId: x.checkId,
                number: x.number,
                bankDescription: x.bankDescription,
                investorId: x.investorCheck[0].investorId
            }
        });

        let selectedItem = this.state.itemsData.find(x => x.value === response.itemId);
        let selectedParentCheck = this.state.finalsCheckData.find(x => x.value === response.parentCheckId);

        response.investorBlocked = response.investorCheck.findIndex(x => x.investorBlocked) >= 0;

        this.setState({
            initialData: JSON.parse(JSON.stringify(response)), //copy data variable
            data: response,
            title: "Editar Cheque No. " + response.number,
            assignInvestor: investors,
            selectedDepositChecks: selectedDepositChecks,
            selectedParentCheck: selectedParentCheck,
            selectedItem: selectedItem,
            selectedTypeCheck: response.checkTypeId,
            loading: false
        });

        if (response.pettyCash) {
            this.setState({
                showTypeCheck: false,
                showInvestorTable: false,
                bankSizeCol: 12,
                itemSizeCol: 12
            });
        }
    }

    onInputChange(event) {
        let aux = this.state.data;
        aux[event.target.name] = event.target.type === "checkbox" ? event.target.checked : event.target.value;
        this.setState({
            data: aux,
            selectedTypeCheck: event.target.name === "checkTypeId" ? Number(event.target.value) : this.state.selectedTypeCheck
        });
    }

    onItemChange(item) {
        let aux = this.state.data;
        aux.itemId = item === null ? "0" : item.value;
        this.setState({
            data: aux,
            selectedItem: item
        });
    }

    onFinalCheckChange(parentCheck) {
        let aux = this.state.data;
        aux.parentCheckId = parentCheck === null ? 0 : parentCheck.value;
        this.setState({
            data: aux,
            selectedParentCheck: parentCheck
        });

    }

    onBlurNumber() {
        let aux = this.state.data;
        if (aux.number !== "") {
            this.CheckApiClient.ValidateIfExists(aux.number, Number(aux.bankId)).then(response => {
                    aux.ifExistsCheckNumber = response;
                    this.setState({data: aux});
                }
            );
        }
    }

    onSelectChange(event) {
        let selectedDepositChecks = event.map(x => {
            return {
                checkId: x.value,
                number: x.label.split(' - ')[0],
                bankDescription: x.label.split(' - ')[1],
                investorId: x.investorId
            }
        });
        let aux = this.state.data;
        aux["children"] = selectedDepositChecks;
        this.setState({
            data: aux,
            selectedDepositChecks: event,
        });
    }

    onDateChange(moment) {
        let aux = this.state.data;
        aux.date = moment.format("YYYY-MM-DD");
        this.setState({
            data: aux
        });
    }

    handleCurrencyChange(event, maskedvalue, floatvalue) {
        let aux = this.state.data;
        if (event.target.name === "amount") {
            aux["usedAmount"] = floatvalue;
        }
        aux[event.target.name] = floatvalue;
        let remainingAmount = 0
        // let remainingAmount = floatvalue ===0 ? this.state.data.amount : floatvalue;
        if (aux.investorCheck.length === 0) {
            remainingAmount = floatvalue;
        }
        this.setState({
            data: aux,
            remainingAmount: remainingAmount
        });
    }

    onCheckboxChange(event) {
        let aux = this.state.data;
        aux[event.target.name] = event.target.checked;

        this.setState({
            data: aux,
            showTypeCheck: !this.state.showTypeCheck,
            showInvestorTable: !this.state.showInvestorTable,
            bankSizeCol: this.state.bankSizeCol === 6 ? 12 : 6,
            itemSizeCol: this.state.itemSizeCol === 6 ? 12 : 6,
        });
    }

    onSubmit(event, addNew = false) {
        event.preventDefault();
        this.setState({loading: true});

        let data = this.state.data;
        data.investorCheck = this.state.assignInvestor.map(x => {
            return {
                investorId: x.id,
                amount: x.newAmount,
                amountPercent: data.usedAmount === 0 ? 0 : x.newAmount / data.usedAmount,
                amountAvailable: x.amount,
                depositDate: x.depositDate,
            };
        });
        if (this.state.data.checkId > 0) {
            this.CheckApiClient.Update(this.state.data.checkId, data)
                .then(response => {
                    toast.success(this.state.modelUpdated);
                    if (addNew) {
                        this.props.history.push({pathname: '/refresh/checks/new'});
                        // console.log('3');
                    } else {
                        this.editPromise(response,);
                        this.setState({loading: false});
                    }
                })
                .catch(this.handleResponseError)
                .finally(() => this.setState({loading: false}) );
        } else {
            this.CheckApiClient.Create(data)
                .then(response => {
                    toast.success(this.state.modelCreated);
                    this.context.toggleExitConfirmation(false);

                    if (addNew) {
                        this.props.history.push({pathname: '/refresh/checks/new'});
                    } else {
                        this.editPromise(response);
                        this.setState({loading: false});
                    }

                })
                .catch(this.handleResponseError)
                .finally(()=> this.setState({loading: false}));
        }
        window.scrollTo(0, 0);
    }

    myCallback = (dataLeft, dataRight) => {

        let aux = this.state.data;
        aux.investorCheck = dataRight.map(x => {
            return {
                investorId: x.id,
                amount: x.newAmount,
                amountPercent: aux.usedAmount === 0 ? 0 : x.newAmount / aux.usedAmount,
                amountAvailable: x.amount,
                depositDate: x.depositDate,
            };
        });
        this.setState({
            data: aux,
            investorData: dataLeft,
            assignInvestor: dataRight

        });
        if (this.state.selectedTypeCheck === 2) {
            if (this.state.assignInvestor.length < 1) {
                this.setState({showButtonsInvestorTable: true});
            } else {
                this.setState({showButtonsInvestorTable: false});
            }
        }
    };

    myValidatorCallback = (valid) => {
        if (valid !== this.state.showSaveButton) {
            this.setState({
                showSaveButton: valid,
            });
        }
    };

    renderEditable(cellInfo) {
        try {
            return (
                <span
                    title={"Presionar SHIFT asigna el monto restante del cheque aun no dividido entre los inversionistas"}>
            <div
                contentEditable
                suppressContentEditableWarning
                onBlur={e => {
                    const assignInvestor = [...this.state.assignInvestor];
                    assignInvestor[cellInfo.index][cellInfo.column.id] = CyaHelper.CleanStringToFloat(e.target.innerHTML);
                    let aux = this.state.data;
                    aux.investorCheck = assignInvestor.map(x => {
                        return {
                            investorId: x.id,
                            amount: x.newAmount,
                            amountPercent: aux.usedAmount === 0 ? 0 : x.newAmount / aux.usedAmount,
                            amountAvailable: x.amount,
                            depositDate: x.depositDate
                        };
                    });
                    let sum = aux.investorCheck.reduce(function (prev, cur) {
                        return prev + cur.amount;
                    }, 0);
                    this.setState({
                        data: aux,
                        assignInvestor: assignInvestor,
                        remainingAmount: aux.usedAmount - sum < 0 ? 0 : aux.usedAmount - sum
                    });
                }}
                onKeyUp={e => {
                    if (e.key !== "Shift") return;
                    const assignInvestor = [...this.state.assignInvestor];
                    let sum = assignInvestor.reduce(function (prev, cur) {
                        return prev + cur.newAmount;
                    }, 0) - CyaHelper.CleanStringToFloat(e.target.innerHTML);

                    let aux = this.state.data;
                    assignInvestor[cellInfo.index][cellInfo.column.id] = aux.usedAmount - sum < 0 ? 0 : aux.usedAmount - sum;
                    aux.investorCheck = assignInvestor.map(x => {
                        return {
                            investorId: x.id,
                            amount: x.newAmount,
                            amountPercent: aux.usedAmount === 0 ? 0 : x.newAmount / aux.usedAmount,
                            amountAvailable: x.amount,
                            depositDate: x.depositDate
                        };
                    });
                    this.setState({
                        data: aux,
                        assignInvestor: assignInvestor,
                        remainingAmount: 0
                    });
                }}

                dangerouslySetInnerHTML={{
                    __html: CyaHelper.ToCurrency(CyaHelper.CleanStringToFloat(this.state.assignInvestor[cellInfo.index][cellInfo.column.id].toString()))
                }}
            /></span>
            );
        } catch (e) {
            return (<div> </div>)
        }

    }

    dataURLtoFile = (dataurl, filename) => {
        let arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, {type: mime});
    }

    rotatedImage = async (base64, image) => {
        let dataUpdated = this.state.data
        let fileName = moment().format("YYYY-MM-DD-hh-mm-ss-") + image.imageId +'.jpg'
        let imgFile = this.dataURLtoFile(base64, fileName)
        new Compress().compress([imgFile], {
            size: 1,
            quality: .5
        }).then((results) => {
            const compressImg = results[0];
            dataUpdated.image.fileContent = compressImg.data
            dataUpdated.image.hash = md5(compressImg.data)
            dataUpdated.image.filename = fileName
            dataUpdated.image.imageId = 0
            this.setState({
                data: dataUpdated,
            });
        });
    }

    handleResponseError = (error) => {
        toast.error(this.state.modelError);
        this.setState({responseError: error})
    }

    render() {
        let columnsLeft = [
            {Header: "InvestorID", accessor: "id", show: false},
            {Header: "DepositDate", accessor: "depositDate", show: false},
            {Header: "Nombre", accessor: "name", width: 140},
            {
                Header: "Disponible",
                accessor: "amount",
                style: {textAlign: 'right'},
                Cell: props => CyaHelper.ToCurrency(props.value),
                width: 150
            },
        ];
        let columnsRight = [
            {Header: "InvestorID", accessor: "id", show: false},
            {Header: "DepositDate", accessor: "depositDate", show: false},
            {Header: "Nombre", accessor: "name", width: 120},
            {
                Header: "Disponible",
                accessor: "amount",
                style: {textAlign: 'right'},
                Cell: props => CyaHelper.ToCurrency(props.value),
                width: 140
            },
            {
                Header: "Monto",
                accessor: "newAmount",
                style: {textAlign: 'right'},
                Cell: this.renderEditable,
                width: 130
            }
        ];
        return <div>
            <Card>
                <CardTitle className="bg-light border-bottom p-3 mb-0">
                    <i className="fas fa-edit mr-2"/>{this.state.title}
                </CardTitle>
                <CardBody>
                    <LoadingPage color={"3e5569"} loading={this.state.loading || this.state.investorLoading}
                                 type={"spin"}/>
                    <Row>
                        <Col md="4">
                            {this.state.data.image.hash.length > 0 ?
                            
                                <>
                                    <InputImage id_label={"imageId"} label={"Imagen:"} rotatedImage={this.rotatedImage}
                                                image={this.state.data.image} hash={this.state.data.image.hash}/>
                                </>
                                :
                                <div>
                                    <InputImage id_label={"imageId"} label={"Imagen:"} rotatedImage={this.rotatedImage}
                                                image={this.state.data.image}/>
                                </div>
                            }
                        </Col>
                        <Col md="8">
                            <Form>
                                <Row>
                                    <Col md="6">
                                        <InputForm id_label={"number"}
                                                   label={"Número:"}
                                                   placeholder={""}
                                                   type={"text"}
                                                   onBlur={this.onBlurNumber}
                                                   value={this.state.data.number}
                                                   onInputChange={this.onInputChange}

                                        />
                                        {this.Validator.showErrors("number")}
                                    </Col>
                                    <Col md="6">
                                        <Label htmlFor={"date"}>Fecha: </Label>
                                        <Datetime inputProps={{readOnly: true, style: {backgroundColor: "transparent"}}}
                                                  locale={"es-do"}
                                                  utc={true}
                                                  timeFormat={false}
                                                  defaultValue={new Date(this.state.data.date)}
                                                  dateFormat={DateFormat}
                                                  closeOnSelect={true}
                                                  value={new Date(this.state.data.date)}
                                                  onChange={this.onDateChange}/>
                                    </Col>
                                    {/*<Col md="2">*/}
                                    {/*<br/><br/>*/}
                                    {/*<CustomInput type="checkbox"*/}
                                    {/*id="pettyCash"*/}
                                    {/*name="pettyCash"*/}
                                    {/*label="Caja Chica"*/}
                                    {/*checked={this.state.data.pettyCash}*/}
                                    {/*onChange={this.onCheckboxChange}/>*/}
                                    {/*</Col>*/}
                                </Row>

                                <Row>
                                    <Col md="12">
                                        <InputForm id_label={"orderOf"}
                                                   label={"Dirigido A:"}
                                                   placeholder={""}
                                                   type={"text"}
                                                   value={this.state.data.orderOf}
                                                   onInputChange={this.onInputChange}/>
                                        {this.Validator.showErrors("orderOf")}
                                    </Col>


                                </Row>

                                <Row>
                                    <Col md="12">
                                        <InputForm id_label={"concept"}
                                                   label={"Por Concepto:"}
                                                   placeholder={""}
                                                   type={"text"}
                                                   value={this.state.data.concept}
                                                   onInputChange={this.onInputChange}/>
                                        {this.Validator.showErrors("concept")}
                                    </Col>
                                </Row>

                                <Row>
                                    <Col md={this.state.bankSizeCol}>
                                        <InputSelect id_label={"bankId"}
                                                     data={this.state.bankData}
                                                     label={"Banco:"}
                                                     dataDescription={["description"]}
                                                     dataValue={"bankId"}
                                                     selectedValue={Number(this.state.data.bankId)}
                                                     onInputChange={this.onInputChange}/>
                                    </Col>
                                    <Col md="6">
                                        {this.state.showTypeCheck ?
                                            <InputSelect id_label={"checkTypeId"}
                                                         data={this.state.checkTypesData}
                                                         label={"Tipo:"}
                                                         dataDescription={["description"]}
                                                         dataValue={"checkTypeId"}
                                                         selectedValue={Number(this.state.data.checkTypeId)}
                                                         onInputChange={this.onInputChange}
                                            />
                                            : null}
                                    </Col>
                                </Row>

                                <Row>
                                    <Col md={this.state.itemSizeCol}>
                                        <FormGroup>
                                            <Label htmlFor={"itemId"}>Partida: </Label>
                                            <Select options={this.state.itemsData}
                                                    id={"itemId"}
                                                    name={"itemId"}
                                                    value={this.state.selectedItem}
                                                    isLoading={this.state.loading}
                                                    placeholder={"Seleccione partida"}
                                                    isClearable={true}
                                                    onChange={this.onItemChange}
                                            />
                                        </FormGroup>
                                    </Col>
                                    {this.state.showTypeCheck ?
                                        this.state.selectedTypeCheck === 1 ?
                                            <Col md={6}>
                                                <FormGroup>
                                                    <Label htmlFor={"children"}>Cheques de Abono: </Label>
                                                    <Select options={this.state.depositsCheckData}
                                                            id={"children"}
                                                            name={"children"}
                                                            isMulti
                                                            closeMenuOnSelect={false}
                                                            value={this.state.selectedDepositChecks}
                                                            isLoading={this.state.loading}
                                                            placeholder={"Seleccione cheque de abono"}
                                                            isClearable={true}
                                                            onChange={this.onSelectChange}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            :
                                            <Col md={6}>
                                                <FormGroup>
                                                    <Label htmlFor={"parentCheckId"}>Cheque Final: </Label>
                                                    <Select options={this.state.finalsCheckData}
                                                            id={"parentCheckId"}
                                                            name={"parentCheckId"}
                                                            value={this.state.selectedParentCheck}
                                                            isLoading={this.state.loading}
                                                            placeholder={"Seleccione cheque final"}
                                                            isClearable={true}
                                                            onChange={this.onFinalCheckChange}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        : null
                                    }
                                </Row>

                                <Row>
                                    <Col md="6">
                                        <InputCurrency id_label={"amount"}
                                                       label={"Importe:"}
                                                       placeholder={""}
                                                       value={this.state.data.amount}
                                                       onInputChange={this.handleCurrencyChange}/>
                                        {this.Validator.showErrors("amount")}
                                    </Col>

                                    <Col md="6">
                                        <InputCurrency id_label={"usedAmount"}
                                                       label={"Importe utilizado:"}
                                                       placeholder={""}
                                                       value={this.state.data.usedAmount}
                                                       onInputChange={this.handleCurrencyChange}/>
                                        {this.Validator.showErrors("usedAmount")}
                                    </Col>
                                </Row>


                                {this.state.investorLoading === false && this.state.showInvestorTable ?
                                    <Row>
                                        <Col md={12}>
                                            <FormGroup>
                                                <Label>Inversionistas:</Label>
                                                {this.state.investorLoading === false && this.state.loading === false ?
                                                    <MultiTable
                                                        dataLeft={this.state.investorData}
                                                        dataRight={this.state.assignInvestor}
                                                        columnsRight={columnsRight}
                                                        columnsLeft={columnsLeft}
                                                        loading={this.state.investorLoading}
                                                        callbackFromParent={this.myCallback}
                                                        amount={this.state.data.amount}
                                                        defaultAmount={this.state.remainingAmount}
                                                        showButtonsInvestorTable={this.state.showButtonsInvestorTable}
                                                        isNewAmountNeeded={true}
                                                    />
                                                    : <div></div>}
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    : <div></div>}
                                <br/>
                                <Row>
                                    <Col md={12}>
                                        <InputTextArea id_label={"comment"}
                                                       label={"Comentario:"}
                                                       placeholder={""}
                                                       rows={"4"}
                                                       value={this.state.data.comment}
                                                       onInputChange={this.onInputChange}/>
                                                       {this.Validator.showErrors("comment")}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12}>
                                        <hr/>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={2}>
                                        <input type="submit"
                                               disabled={!(this.state.showSaveButton && this.Validator.validForm() && this.Auth.GetPathPermission(this.props.location.pathname, true))}
                                               className={!(this.state.showSaveButton && this.Validator.validForm() && this.Auth.GetPathPermission(this.props.location.pathname, true)) ? 'btn btn-primary btn-block disabled' : 'btn btn-block btn-primary'}
                                               value="Guardar"
                                               onClick={this.onSubmit}/>
                                    </Col>
                                    <Col md={3}>
                                        <input type="submit"
                                               disabled={!(this.state.showSaveButton && this.Validator.validForm() && this.Auth.GetPathPermission(this.props.location.pathname, true))}
                                               className={!(this.state.showSaveButton && this.Validator.validForm() && this.Auth.GetPathPermission(this.props.location.pathname, true)) ? 'btn btn-block btn-success disabled' : 'btn btn-block btn-success'}
                                               value="Guardar y añadir nuevo"
                                               onClick={(event) => this.onSubmit(event, true)}/>
                                    </Col>
                                    <Col md={7}>
                                        <CheckValidator 
                                            model={this.state.data}
                                            callbackFromParent={this.myValidatorCallback}
                                            responseError={this.state.responseError}
                                        />
                                    </Col>
                                </Row>
                            </Form>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </div>;
    }
}
CreateCheck.contextType = CyaContext;
