import {ImageModel} from "./ImageModel";

export class PersonModel {
    personId = 0;
    name = "";
    nickname = "";
    prefixId = 1;
    genderId = 1;
    address = "";
    occupation = "";
    nationality = "";
    civilStatusId = 1;
    cityId = 1;
    personPosition = [];
    idNumber = "";
    idtypeId = 1;
    sirecineNumber = "";
    sirecineExpDate = new Date();
    idNumberImage = new ImageModel();
    sirecineImage = new ImageModel();
}






