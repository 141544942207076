import React from 'react';
import {NavLink} from 'react-router-dom';
import {Nav, Collapse} from 'reactstrap';
import AuthService from "../../utils/AuthService";

class Sidebar extends React.Component {

    constructor(props) {
        super(props);
        this.expandLogo = this.expandLogo.bind(this);
        this.activeRoute.bind(this);
        this.state = {
            permissions: [],
            authentication: this.activeRoute("/authentication") !== "" ? true : false,
            chequesname: this.activeRoute("/cheques") !== "" ? true : false,
            anexosname: this.activeRoute("/anexos") !== "" ? true : false,

        };
        this.Auth = new AuthService();
    }

    /*--------------------------------------------------------------------------------*/
    /*To Expand SITE_LOGO With Sidebar-Menu on Hover                                  */
    /*--------------------------------------------------------------------------------*/
    expandLogo() {
        document.getElementById("logobg").classList.toggle("expand-logo");
    }

    /*--------------------------------------------------------------------------------*/
    /*Verifies if routeName is the one active (in browser input)                      */
    /*--------------------------------------------------------------------------------*/
    activeRoute(routeName) {
        return this.props.location.pathname.indexOf(routeName) > -1 ? 'selected' : '';
    }


    render() {
        return (
            <aside className="left-sidebar" id="sidebarbg" data-sidebarbg={this.props.data.settings[0].sidebarbg}
                   onMouseEnter={this.expandLogo} onMouseLeave={this.expandLogo}>
                <div className="scroll-sidebar">
                    <div className="sidebar-nav">
                        {/*--------------------------------------------------------------------------------*/}
                        {/* Sidebar Menus will go here                                                */}
                        {/*--------------------------------------------------------------------------------*/}
                        <Nav id="sidebarnav">
                            {this.props.routes.map((prop, key) => {

                                if (!this.Auth.GetPathPermission(prop.path, false)) return null;

                                if (prop.redirect || (prop.isVisible !== undefined && !prop.isVisible)) {
                                    return null;
                                }
                                else if (prop.navlabel) {
                                    return (
                                        <li className="nav-small-cap" key={key}>
                                            <i className={prop.icon}></i>
                                            <span className="hide-menu">{prop.name}</span>
                                        </li>
                                    );
                                }
                                else if (prop.collapse) {
                                    let firstdd = {};
                                    firstdd[prop["state"]] = !this.state[prop.state];

                                    return (

                                        /*--------------------------------------------------------------------------------*/
                                        /* Menus wiil be goes here                                                        */
                                        /*--------------------------------------------------------------------------------*/
                                        <li className={this.activeRoute(prop.path) + ' sidebar-item ' + prop.extra}
                                            key={key}>
                                            <a data-toggle="collapse" className="sidebar-link has-arrow"
                                               aria-expanded={this.state[prop.state]}
                                               onClick={() => this.setState(firstdd)}>
                                                <i className={prop.icon}/>
                                                <span className="hide-menu">{prop.name}</span>
                                            </a>
                                            {/*--------------------------------------------------------------------------------*/}
                                            {/* Sub-Menus wiil be goes here                                                    */}
                                            {/*--------------------------------------------------------------------------------*/}
                                            <Collapse className="first-level" isOpen={this.state[prop.state]}>
                                                <ul >
                                                    {prop.child.map((prop, key) => {
                                                        if (prop.redirect) return null;
                                                        if (!this.Auth.GetPathPermission(prop.path, false)) return null;
                                                        if (prop.collapse) {
                                                            let seconddd = {};
                                                            seconddd[prop["state"]] = !this.state[prop.state];
                                                            return (
                                                                <li className={this.activeRoute(prop.path) + ' sidebar-item'}
                                                                    key={key}>
                                                                    <a data-toggle="collapse"
                                                                       className="sidebar-link has-arrow"
                                                                       aria-expanded={this.state[prop.state]}
                                                                       onClick={() => this.setState(seconddd)}>
                                                                        <i className={prop.icon}/>
                                                                        <span className="hide-menu">{prop.name}</span>
                                                                    </a>
                                                                    {/*--------------------------------------------------------------------------------*/}
                                                                    {/* Sub-Menus wiil be goes here                                                    */}
                                                                    {/*--------------------------------------------------------------------------------*/}
                                                                    <Collapse className="second-level"
                                                                              isOpen={this.state[prop.state]}>
                                                                        <ul>
                                                                            {prop.subchild.map((prop, key) => {
                                                                                if (!this.Auth.GetPathPermission(prop.path, false)) return null;
                                                                                if (prop.redirect) return null;
                                                                                return (
                                                                                    <li className={this.activeRoute(prop.path) + ' sidebar-item'}
                                                                                        key={key}>
                                                                                        <NavLink to={prop.path}
                                                                                                 activeClassName="active"
                                                                                                 className="sidebar-link">
                                                                                            <i className={prop.icon}/>
                                                                                            <span
                                                                                                className="hide-menu">{prop.name}</span>
                                                                                        </NavLink>
                                                                                    </li>
                                                                                );
                                                                            })}
                                                                        </ul>
                                                                    </Collapse>
                                                                </li>
                                                            );
                                                        }
                                                        return (
                                                            /*--------------------------------------------------------------------------------*/
                                                            /* Adding Sidebar Item                                                            */
                                                            /*--------------------------------------------------------------------------------*/
                                                            <li className={this.activeRoute(prop.path) + (prop.pro ? ' active active-pro' : '') + ' sidebar-item'}
                                                                key={key}>
                                                                <NavLink to={prop.path} className="sidebar-link"
                                                                         activeClassName="active">
                                                                    <i className={prop.icon}/>
                                                                    <span className="hide-menu">{prop.name}</span>
                                                                </NavLink>
                                                            </li>
                                                        );
                                                    })}
                                                </ul>
                                            </Collapse>
                                        </li>
                                    );
                                }
                                else {
                                    return (
                                        /*--------------------------------------------------------------------------------*/
                                        /* Adding Sidebar Item                                                            */
                                        /*--------------------------------------------------------------------------------*/
                                        <li className={this.activeRoute(prop.path) + (prop.pro ? ' active active-pro' : '') + ' sidebar-item'}
                                            key={key}>
                                            <NavLink to={prop.path} className="sidebar-link" activeClassName="active">
                                                <i className={prop.icon}/>
                                                <span className="hide-menu">{prop.name}</span>
                                            </NavLink>
                                        </li>
                                    );
                                }
                            })}
                        </Nav>
                    </div>
                </div>
            </aside>
        );
    }
}

export default Sidebar;
