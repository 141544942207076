import React from 'react';
import {
    Label,
    FormGroup
} from 'reactstrap';
import PropTypes from 'prop-types'
import Select from "react-select";

export class SelectFilter extends React.Component {
    render() {
        return <FormGroup>
                <Label style={{fontSize: '20px'}}>{this.props.label}:</Label>
                <Select options={this.props.data}
                        id={this.props.filterId}
                        name={this.props.filterId}
                        isMulti={false}
                        closeMenuOnSelect={true}
                        value={this.props.value === undefined ? this.props.defaultValue : this.props.value}
                        defaultValue={this.props.defaultValue}
                        isClearable={false}
                        onChange={this.props.onInputChange}
                />
            </FormGroup>
    }
}

SelectFilter.propTypes = {
    filterId: PropTypes.string,
    label: PropTypes.string,
    data: PropTypes.array,
    value: PropTypes.object,
    defaultValue: PropTypes.object,
    onInputChange: PropTypes.func
};