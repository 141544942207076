import React from "react";
import ReactTable from "react-table";
import PropTypes from 'prop-types'
import {CardSubtitle, Col, Row, CardHeader} from "reactstrap";
import CyaHelper from "../../../utils/CyaHelper";

export class CyaTable extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            expanded: {},
            selected: {},
            selectAll: false,
            enableMultipleActionsButton: false,
            actualPageSize:10,
        };
        this.toggleRow = this.toggleRow.bind(this);
        CyaTable.isEmpty = CyaTable.isEmpty.bind(this);
    }

    static isEmpty(obj){
        for(let key in obj) {
            if(obj.hasOwnProperty(key))
                return false;
        }
        return true;
    }

    toggleRow(Id) {
        const newSelected = Object.assign({}, this.state.selected);
        newSelected[Id] = !this.state.selected[Id];
        this.setState({selected: newSelected});

        for (let key in newSelected) {
            if (newSelected.hasOwnProperty(key))
                if (newSelected[key] !== true)
                    delete newSelected[key];
        }

        if (CyaTable.isEmpty(newSelected))
            this.setState({enableMultipleActionsButton:false});
        else
            this.setState({enableMultipleActionsButton:true});

        this.props.selectedMultiple(newSelected);
    }

    selectAll = (e,r)=>{
        const actualDisplayData = e.slice(0,this.state.actualPageSize);
        const columnName =this.props.columns[0].accessor;
        if (actualDisplayData.length>0){
            let newSelected = Object.assign({}, this.state.selected);
            actualDisplayData.forEach(x => {
                if (!this.state.selectAll){
                    newSelected[x[columnName]] =!this.state.selectAll;
                }else {
                    newSelected={};
                }
            });
            if (CyaTable.isEmpty(newSelected))
                this.setState({enableMultipleActionsButton:false});
            else
                this.setState({enableMultipleActionsButton:true});
            this.setState({selected: newSelected});
            this.props.selectedMultiple(newSelected);
        }
        this.setState({selectAll:!this.state.selectAll});

    };

    changePageSize = (size)=>{
        this.setState({actualPageSize:size});
    };

    render() {
        let columns = this.props.columns;

        if (this.props.selectionCheckbox) {
            let checkColumn = [
                {
                    id: "checkbox",
                    accessor: "",
                    style: {textAlign: 'center'},
                    sortable: false,
                    width: 40,
                    Cell: ({original}) => {
                        let nameFirstColumn = Object.keys(original)[0];
                        // console.log(original);
                        return (
                            <input
                                type="checkbox"
                                className="checkbox"
                                checked={this.state.selected[original[nameFirstColumn]] === true}
                                onChange={() => this.toggleRow(original[nameFirstColumn])}
                            />
                        );
                    },
                    Header: x=>{return <input type="checkbox" checked={this.state.selectAll} className="checkbox" onChange={()=>this.selectAll(x.data,x)}/>}
                }
            ];
            columns = checkColumn.concat(columns);

        }

        this.props.columns.map((col) => {
            return col.Header = <strong>{col.Header}</strong>;
        });
        return <div>
            <ReactTable
                nextText={"Siguiente"}
                previousText={"Anterior"} onPageSizeChange={this.changePageSize}
                loadingText={"Cargando..."}
                loading={this.props.loading}
                noDataText={"No se encontraron resultados"}
                pageText={"Página"}
                ofText={"de"}
                rowsText={"filas"}
                data={this.props.data}
                columns={columns}
                filterable
                defaultFilterMethod={(filter, row, column) => {
                    const id = filter.pivotId || filter.id;
                    return row[id] !== undefined ? String(row[id].toString().toLowerCase()).includes(filter.value.toLowerCase()) : true
                }}
                showPaginationTop={true}
                defaultPageSize={this.props.defaultPageSize}
                pageSizeOptions={[10, 25, 50, 100, 500, 1000]}
                className="-striped -highlight"
                onExpandedChange={(expanded, index, event) => {
                    this.setState({expanded});
                }}
                expanded={this.state.expanded}
                SubComponent={this.props.subComponent}
                getTrProps={this.props.trProps}
            />
            {
                this.props.checkSelectedData!=null?
                    <CardHeader style={{marginTop:'10px'}}>
                    <Row>
                        <Col md={4} style={{textAlign: 'center'}}>
                            <h4 className="font-bold mt-0 mb-0">{CyaHelper.ToCurrency(this.props.checkSelectedData.amount)}</h4>
                            <CardSubtitle className="mt-1 mb-0">Monto del cheque</CardSubtitle>
                        </Col>
                        <Col md={4} style={{textAlign: 'center'}}>
                            <h4 className="font-bold mt-0 mb-0">{CyaHelper.ToCurrency(this.props.checkSelectedData.amountAvailable)}</h4>
                            <CardSubtitle className="mt-1 mb-0">Por ejecutar</CardSubtitle>
                        </Col>
                        <Col md={4} style={{textAlign: 'center'}}>
                            <h4 className="font-bold mt-0 mb-0">{CyaHelper.ToCurrency(this.props.checkSelectedData.usedAmount)}</h4>
                            <CardSubtitle className="mt-1 mb-0">Ejecutado</CardSubtitle>
                        </Col>
                    </Row>
                    </CardHeader>
                    :""
            }

            <hr/>
            <input
                type="submit"
                disabled={!(this.props.enableButtons)}
                className={!(this.props.enableButtons) ? 'btn btn-info disabled' : 'btn btn-info'}
                value={this.props.viewButtonText}
                style={{marginLeft: "5px"}}
                onClick={() => this.props.viewCallback()}
            />

            {this.props.modelType === "check" ?
                <input
                    type="submit"
                    disabled={!(this.props.enableButtons)}
                    className={!(this.props.enableButtons) ? 'btn btn-primary disabled' : 'btn btn-primary'}
                    value="Ver Anexos"
                    style={{marginLeft: "5px"}}
                    onClick={() => this.props.viewAnnexesCallback()}
                />
                : ""
            }
            {this.props.modelType === "annex" ?
                <input
                    type="submit"
                    disabled={!(this.props.enableButtons)}
                    className={!(this.props.enableButtons) ? 'btn btn-primary disabled' : 'btn btn-primary'}
                    value="Ver Cheques"
                    style={{marginLeft: "5px"}}
                    onClick={() => this.props.viewChecksCallback()}
                />
                : ""
            }

            {this.props.multipleActionsButton ?
                <input
                    type="submit"
                    disabled={!(this.props.enableMassButtonDefault || this.state.enableMultipleActionsButton)}
                    className={!(this.props.enableMassButtonDefault || this.state.enableMultipleActionsButton) ? 'btn btn-warning disabled' : 'btn btn-warning'}
                    value={this.props.massButtonText}
                    style={{marginLeft: "5px"}}
                    onClick={() => this.props.multipleActionsCallback()}
                />
                : ""
            }
            {!this.props.showDeleteButton ? '' :
                <input
                    type="submit"
                    disabled={!(this.props.enableButtons)}
                    className={!(this.props.enableButtons) ? 'btn btn-danger disabled float-right' : 'btn btn-danger float-right'}
                    value={this.props.deleteText}
                    style={{marginRight: "5px"}}
                    onClick={() => this.props.deleteCallback()}
                />
            }
        </div>
    }
}

CyaTable.propTypes = {
    data: PropTypes.array,
    columns: PropTypes.array,
    subComponent: PropTypes.func,
    loading: PropTypes.bool,
    selectionCheckbox: PropTypes.bool,
    showViewButton: PropTypes.bool,
    enableButtons: PropTypes.bool,
    showDeleteButton: PropTypes.bool,
    multipleActionsButton: PropTypes.bool,
    enableMassButtonDefault: PropTypes.bool,
    modelType: PropTypes.string,
    trProps: PropTypes.func,
    selectedMultiple: PropTypes.func,
    multipleActionsCallback: PropTypes.func,
    deleteCallback: PropTypes.func,
    viewAnnexesCallback: PropTypes.func,
    viewChecksCallback: PropTypes.func,
    viewCallback: PropTypes.func,
    deleteText: PropTypes.string,
    massButtonText: PropTypes.string,
    viewButtonText: PropTypes.string,
    checkSelectedData: PropTypes.object,
    defaultPageSize: PropTypes.number
};

CyaTable.defaultProps={
    enableMassButtonDefault:false,
    deleteText:"Borrar",
    viewButtonText:"Ver",
    massButtonText:"Acciones Masivas"
};
