import React from 'react';
import {
    CustomInput,
    FormGroup,
    Label
} from 'reactstrap';
import PropTypes from 'prop-types'

export class BooleanFilter extends React.Component {

    render() {
        return <FormGroup>
            <Label style={{fontSize: '20px'}}>{this.props.label}:</Label>
            <CustomInput
                type="checkbox"
                id={this.props.filterId}
                name={this.props.filterId}
                label=""
                checked={this.props.checked === undefined ? false : this.props.checked}
                onChange={this.props.onInputChange}
            />
        </FormGroup>
    }
}

BooleanFilter.propTypes = {
    filterId: PropTypes.string,
    label: PropTypes.string,
    checked: PropTypes.bool,
    onInputChange: PropTypes.func
};