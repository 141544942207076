import React from "react";
import PropTypes from 'prop-types'
import {SimpleTable} from "./SimpleTable";
import {
    Row,
    Col,
    Button,
    Label
} from 'reactstrap';
import {InputForm} from "../inputs/InputForm";

export class PercentMultiTable extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedRight: -1,
            selectedLeft: -1,
            dataLeft:[],
            dataRight:[],
            percentValue: '0',

        };
        this.RightToLeftClick = this.RightToLeftClick.bind(this);
        this.LeftToRightClick = this.LeftToRightClick.bind(this);
        this.SelectRowLeft = this.SelectRowLeft.bind(this);
        this.SelectRowRight = this.SelectRowRight.bind(this);
    }

    RightToLeftClick() {
        if (this.state.selectedRight>=0){

            let left = this.state.dataLeft;
            let right =  this.state.dataRight;

            left.push(right[this.state.selectedRight]);
            right.splice(this.state.selectedRight,1);

            this.setState({
                dataRight: right,
                dataLeft: left
            });

            this.props.callbackFromParent(this.state.dataLeft,this.state.dataRight);
            this.resetSelected();
        }
    }

    LeftToRightClick() {
        if (this.state.selectedLeft>=0){
            let right =  this.state.dataRight;
            let left = this.state.dataLeft;
            let item = left[ this.state.selectedLeft];

            if (this.props.isNewAmountNeeded){
                item.newAmount = this.props.defaultAmount;
            }

            right.push(item);
            left.splice(this.state.selectedLeft,1);

            this.setState({
                dataRight: right,
                dataLeft: left
            });

            this.resetSelected();
            this.props.callbackFromParent(this.state.dataLeft,this.state.dataRight);
        }
    }

    resetSelected(){
        this.setState({
            selectedRight:-1,
            selectedLeft:-1,
        });
    }

    findById(element){
        return element["id"] === this["id"];
    }

    componentDidMount(){
        let right = this.props.dataRight;
        let left = this.props.dataLeft;

        right.map(rightData => {
            let index = left.findIndex(this.findById, rightData);
            if (index!==-1)
                left.splice(index, 1);
            return rightData;
        });

        this.setState({
            dataLeft:left,
            dataRight:right
        });
    }

    SelectRowLeft(state, rowInfo) {
        if (rowInfo && rowInfo.row) {
            return {
                onClick: (e) => {
                    this.setState({
                        selectedLeft: rowInfo.index,
                        selectedRight: -1
                    });
                },
                onDoubleClick: (e) => {
                    this.LeftToRightClick();
                },
                style: {
                    background: rowInfo.index === this.state.selectedLeft ? '#00AFEC' : rowInfo.index % 2 === 0 ? '#DDDDDD' : '#FFFFFF',
                    color: rowInfo.index === this.state.selectedLeft ? 'white' : 'black',
                }
            }
        }
        return {};
    }

    SelectRowRight(state, rowInfo) {
        if (rowInfo && rowInfo.row) {
            return {
                onClick: (e) => {
                    this.setState({
                        selectedRight: rowInfo.index,
                        selectedLeft: -1
                    });
                    if (this.props.showPercentInput){
                        this.changePercentValue(this.state.dataRight[rowInfo.index].percent);
                    }
                },
                onDoubleClick: (e) => {
                    this.RightToLeftClick();
                },
                style: {
                    background: rowInfo.index === this.state.selectedRight ? '#00AFEC' : rowInfo.index % 2 === 0 ? '#DDDDDD' : '#FFFFFF',
                    color: rowInfo.index === this.state.selectedRight ? 'white' : 'black',
                }
            }
        }
        return {};
    }

    onPercentChange = (e) => {
        const value= Number(e.target.value);
        if (this.state.selectedRight>=0 && this.props.showPercentInput){
            let dataRight = this.state.dataRight;
            dataRight[this.state.selectedRight].percent= value/100 ;
            this.setState({dataRight,percentValue: String(value)});
            this.props.callbackFromParent(this.state.dataLeft,dataRight);
        }
    };

    changePercentValue = (value) =>{
        if (this.props.showPercentInput){
            this.setState({percentValue: value*100});
        }
    };

    render() {
        return <Row style={{display: "flex", alignItems: "center"}}>
            <Col md={5}>
                <div className="btn-group-vertical" style={{width: "100%"}}>
                    <Label style={{textDecoration: "underline", alignSelf: "center"}}>Disponibles</Label>
                    <SimpleTable data={this.state.dataLeft} columns={this.props.columnsLeft} loading={this.props.loading}
                                 trProps={this.SelectRowLeft}  defaultPageSize={this.props.defaultPageSize} filterable={this.props.filterable}/>
                </div>
            </Col>

            <Col md={2}>
                <div className="text-center">
                    <div className="btn-group-vertical">
                        {this.props.showPercentInput ?
                            <InputForm id_label={"number"}
                                       readOnly={this.state.selectedRight<0}
                                       label={"Porcentaje (%):"}
                                       placeholder={"%"}
                                       type={"number"}
                                       value={this.state.percentValue}
                                       onInputChange={this.onPercentChange}
                            />
                            : ''}

                        <Button size="lg" onClick={this.LeftToRightClick} color="info">{'>'}</Button>
                        <br/>
                        <Button size="lg" onClick={this.RightToLeftClick} color="info">{'<'}</Button>
                    </div>
                </div>
            </Col>
            <Col md={5}>
                <div className="btn-group-vertical" style={{width: "100%"}}>
                    <Label style={{textDecoration: "underline", alignSelf: "center"}}>Asignados</Label>
                    <SimpleTable data={this.state.dataRight} columns={this.props.columnsRight} loading={this.props.loading}
                                 trProps={this.SelectRowRight} defaultPageSize={this.props.defaultPageSize} filterable={this.props.filterable}/>
                </div>
            </Col>
        </Row>
    }
}

PercentMultiTable.propTypes = {
    dataRight: PropTypes.array,
    dataLeft: PropTypes.array,
    columnsRight: PropTypes.array,
    columnsLeft: PropTypes.array,
    trPropsRight: PropTypes.func,
    trPropsLeft: PropTypes.func,
    callbackFromParent: PropTypes.func,
    loading: PropTypes.bool,
    defaultPageSize:PropTypes.number,
    filterable: PropTypes.bool,
    defaultAmount:PropTypes.number,
    isNewAmountNeeded:PropTypes.bool,
    showPercentInput:PropTypes.bool,
    percentValue:PropTypes.number,
    onPercentChange:PropTypes.func,
};

PercentMultiTable.defaultProps = {
    defaultPageSize:5,
    dataRight: [],
    dataLeft: [],
    filterable: false,
    defaultAmount: 0,
    isNewAmountNeeded : false,
    showPercentInput : false,
};
