export class AnnexModel {
    annexId = 0;
    ncf = "";
    id = "";
    idTypeId = 1;
    name = "";
    date = new Date();
    annexTypeId = 1;
    subtotal = 0;
    itbis = 0;
    itbispercentage = 0;
    itbisretention = 0;
    itbisretentionPercentage = 0;
    isrretention = 0;
    isrretentionPercentage = 0;
    isc = 0;
    tip = 0;
    otherTaxes = 0;
    totalExpense = 0;
    totalDue = 0;
    comment = "";
    checkId = 0;
    investorId = 0;
    stageId = 1;
    annexIsrretentionTypeId = 1;
    annexExpenseTypeId = 1;
    annexItem = [];
    annexImage = [];
// Para validaciones
    checkDate = new Date();
    checkAvailable = 0;
    investorAvailable = 0;
    investorBlocked = false;
}