import CyaApiClient from "./CyaApiClient";

export default class AnnexCreditNoteApiClient extends CyaApiClient {

    constructor(){
        super("AnnexCreditNote");
    }

    ExistsAnnexIdAndRnc(annexId,ncf) {
        return this.MakeCall('get', this.ControllerName + '/existsAnnexIdAndRnc',{annexId,ncf});
    }
}